/* eslint-disable @typescript-eslint/ban-types */
import React, { ReactElement, useEffect } from 'react';
import i18n from '~/i18n';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  TableInstance,
  HeaderGroup,
  UseSortByColumnProps,
  usePagination,
} from 'react-table';
import cx from 'classnames';
import { Panel } from '~/components/src/Containers';
import Empty from '~/components/src/Empty';
import removeWhitespace from '~/common/utils/removeWhitepace';
import { JO_PAGINATION_DEFAULT_SIZE } from '~/customer/journeys/dataService';
import Spin from '~/components/src/Spin';
import './style.scss';
import Filter from './Filter';
import { columns, data, fuzzyTextFilterFn, dateBetweenFilterFn, textFilterFn } from './TableHelpers';
import { JOListProps, TableJourney } from './types';

const filterTypes = {
  fuzzyText: fuzzyTextFilterFn,
  dateBetween: dateBetweenFilterFn,
  text: textFilterFn,
};
interface CustomProps {
  colspan: number;
  className: string;
}

interface ExtendedHeaderGroup<D extends object, C extends object> extends Omit<HeaderGroup<D>, 'headers'> {
  headers: (HeaderGroup<D> & UseSortByColumnProps<D> & C)[];
}
interface MyTableInstance<D extends object, C extends object = {}> extends Omit<TableInstance<D>, 'headerGroups'> {
  headerGroups: ExtendedHeaderGroup<D, C>[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  state: Record<string, number>;
}

const JOList = (props: JOListProps): ReactElement => {
  const { isFiltersPanelOpen, toggleFiltersPanel, fetchJourneys, total, isFetching } = props;

  const tableInstance = useTable<TableJourney>(
    {
      columns: columns(props),
      data: data(props.list),
      initialState: { hiddenColumns: ['status'], pageSize: JO_PAGINATION_DEFAULT_SIZE, pageIndex: 0 },
      manualPagination: true,
      pageCount: Math.ceil(total / JO_PAGINATION_DEFAULT_SIZE),
      filterTypes,
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  ) as MyTableInstance<TableJourney, CustomProps>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex },
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = tableInstance;
  const EmptyList = () => (
    <Empty
      header={i18n.t('journey:list.empty.header')}
      body={i18n.t(!!props.list.length && !rows.length ? 'journey:list.empty.filter' : 'journey:list.empty.body')}
      excludePanel
    />
  );

  useEffect(() => {
    fetchJourneys(pageIndex);
  }, [pageIndex]);

  const JoTable = () => (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                  ...column.getSortByToggleProps(),
                  colspan: column.colspan,
                  width: column.width,
                })}
                className={cx('JOList-head', column.className)}
              >
                {column.render('Header')}
                {column?.isSorted &&
                  (column?.isSortedDesc ? (
                    <span className="JOList-head--sort">&#8593;</span>
                  ) : (
                    <span className="JOList-head--sort">&#8595;</span>
                  ))}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              key={row.original.journeyId}
              className={`JOList-row t-${removeWhitespace(row.original.journeyName)}`}
            >
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} colSpan={cell.column.colspan} style={{ width: cell.column.width }}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const showPagination = canPreviousPage || canNextPage;
  return (
    <>
      <Panel className={cx('JOListPanel')}>
        <div className={cx('JOList')}>
          {isFetching && <Spin className="mt-8" />}
          {!rows.length && !isFetching && <EmptyList />}
          {!!rows.length && !isFetching && <JoTable />}
        </div>
        {isFiltersPanelOpen && <Filter tableInstance={tableInstance} toggleFiltersPanel={toggleFiltersPanel} />}
      </Panel>
      {showPagination && (
        <div className="flex justify-center">
          <button
            onClick={previousPage}
            disabled={!canPreviousPage || isFetching}
            className="rounded-l-lg border border-blue-500 bg-transparent px-4 py-2 text-blue-700 hover:border-transparent hover:bg-blue-500 hover:text-white disabled:opacity-25"
          >
            Prev
          </button>
          <button
            onClick={nextPage}
            disabled={!canNextPage || isFetching}
            className="rounded-r-lg border border-blue-500 bg-transparent px-4 py-2 text-blue-700 hover:border-transparent hover:bg-blue-500 hover:text-white disabled:opacity-25"
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default JOList;
