import React, { MouseEvent, ReactElement } from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import Tippy from '@tippyjs/react';
import { Placement } from 'tippy.js';
import Icons from '~/components/src/Icons';
import icons from '../Icons/icons';

type BtnIconType = {
  className?: string;
  icon: keyof typeof icons;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  testHook?: string;
  color?: keyof typeof COLORS;
  tooltip?: string;
  placement?: Placement;
  disabled?: boolean;
  hideOnClick?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const COLORS = {
  gray: 'text-gray-600 dark:text-gray-400 bg-gray-200 dark:bg-gray-700',
  grayTransparent: 'text-gray-600 dark:text-gray-400 bg-transparent dark:bg-transparent',
  blue: 'text-white bg-blue-600 dark:bg-blue-700',
  blueTransparent: 'text-r42-blue bg-transparent dark:bg-transparent',
  red: 'text-white bg-red-600 dark:bg-red-700',
  amber: 'text-white bg-amber-600 dark:bg-amber-700',
} as const;

const COLOR_STATES: Record<string, string> = {
  gray: `${COLORS.gray} hover:bg-gray-300 dark:hover:bg-gray-600 focus:ring-gray-300 dark:focus:ring-gray-500`,
  blue: `${COLORS.blue} hover:bg-blue-700 dark:hover:bg-blue-600 focus:ring-blue-300 dark:focus:ring-blue-500`,
  red: `${COLORS.red} hover:bg-red-700 dark:hover:bg-red-600 focus:ring-red-300 dark:focus:ring-red-500`,
  amber: `${COLORS.amber} hover:bg-amber-700 dark:hover:bg-amber-600 focus:ring-amber-300 dark:focus:ring-amber-500`,
  blueTransparent: `${COLORS.blueTransparent} hover:bg-transparent focus:ring-0 dark:focus:ring-0`,
  grayTransparent: `${COLORS.grayTransparent} hover:bg-transparent focus:ring-0 dark:focus:ring-0`,
};

function BtnIcon({
  className,
  icon,
  onClick,
  tooltip,
  placement = 'bottom',
  color = 'gray',
  disabled = false,
  testHook = 'button',
  hideOnClick = true,
  ...props
}: BtnIconType): ReactElement {
  return (
    <Tippy content={tooltip} disabled={!tooltip} placement={placement} hideOnClick={hideOnClick}>
      <div className="inline-block">
        <button
          type="button"
          className={twMerge(
            cx(
              'flex h-8 w-8 items-center justify-center rounded p-1',
              COLORS[color],
              disabled ? 'cursor-not-allowed opacity-50' : `focus:outline-none focus:ring-4 ${COLOR_STATES[color]}`,
              `t-${testHook}`,
              className,
            ),
          )}
          onClick={onClick}
          disabled={disabled}
          {...props}
        >
          <Icons icon={icon} />
        </button>
      </div>
    </Tippy>
  );
}

export default BtnIcon;
