import React from 'react';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import { isRequired } from '~/common';
import { Notification } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { updateExperimentWeights } from '~/workflows/dataService';
import { UpdateExperimentsPayloadItem } from '../types';

const validateWeight = (value: string) => {
  if (value === '') {
    return i18n.t('workflow:journey.experimentModal.validations.notEmpty');
  }
  if (Number(value) + 0 !== Number(value)) {
    return i18n.t('workflow:journey.experimentModal.validations.isNumber');
  }
  if (Number(value) < 0 || Number(value) > 100) {
    return i18n.t('workflow:journey.experimentModal.validations.isPositive');
  }

  return undefined;
};

const hasError = (formProps: any, index: number, fieldName: string) => {
  const touched = formProps.touched.weights;
  const errors = formProps.errors.weights;
  if (!touched || !errors) {
    return false;
  }
  return touched[index] && touched[index][fieldName] && errors[index] && errors[index][fieldName];
};

const hasWeightError = (values: any) =>
  Object.values(values?.weights).reduce((acc: number, variant: any) => acc + (variant.weight || 0), 0) !== 100;

const hasUniqueNameError = (values: any) => {
  const names = Object.values(values?.weights)?.map(({ name }: any) => name.toLowerCase()) || [];

  return names.length !== new Set(names).size;
};

const hasValidationsError = (values: any) => hasWeightError(values) || hasUniqueNameError(values);

const EditExperiment = ({
  experimentSteps,
  hideModal,
  fetchJourneyData,
}: {
  experimentSteps: any[];
  hideModal: () => void;
  fetchJourneyData: () => void;
}) => {
  const initialValue = experimentSteps
    .map(step => ({
      ...(step?.experimentVariant ?? {}),
    }))
    .reduce(
      (acc, val) => ({
        ...acc,
        [val.experimentVariantId]: { ...val },
      }),
      {},
    );

  const onSubmit = async ({ weights }: any) => {
    const payload = Object.values(weights) as UpdateExperimentsPayloadItem[];

    await updateExperimentWeights({ experimentVariantConfig: payload });
    hideModal();
    fetchJourneyData();

    showSuccess({
      header: i18n.t('workflow:journey.journeyModal.successToast'),
    });
  };

  return (
    <div className="EditExperiment">
      <Formik initialValues={{ weights: initialValue }} onSubmit={onSubmit}>
        {formProps => (
          <form className="EditExperiment-form" onSubmit={formProps.handleSubmit}>
            <Notification kind="information" header="" className="">
              <span>{i18n.t('workflow:journey.experimentModal.weightTip')}</span>
            </Notification>

            {experimentSteps.map(step => {
              const experimentVariantId = step?.experimentVariant?.experimentVariantId;
              return (
                <div key={experimentVariantId}>
                  <div className="EditExperiment-propertyRow" key={experimentVariantId}>
                    <div className="EditExperiment-propertyRow-variantName">
                      <Field
                        name={`weights[${experimentVariantId}].name`}
                        as={FormikInputField}
                        label={step.label}
                        hintText={i18n.t('workflow:journey.experimentModal.hintText.variantName')}
                        validate={isRequired}
                        className={cx(`${experimentVariantId}-variantName`, {
                          'is-error': hasError(formProps, experimentVariantId, 'name'),
                        })}
                        placeholder={i18n.t('workflow:journey.experimentModal.placeholders.name')}
                      />
                    </div>
                    <div className="EditExperiment-propertyRow-weight">
                      <Field
                        type="number"
                        as={FormikInputField}
                        hintText={i18n.t('workflow:journey.experimentModal.hintText.weight')}
                        validate={validateWeight}
                        name={`weights[${experimentVariantId}].weight`}
                        placeholder={i18n.t('workflow:journey.experimentModal.placeholders.weight')}
                        className={cx(`${experimentVariantId}-weight`, {
                          'is-error': hasError(formProps, experimentVariantId, 'weight'),
                        })}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            {hasWeightError(formProps?.values) && (
              <Notification testHook="experimentError" kind="error" className="mt-4">
                <span>{i18n.t('workflow:journey.experimentModal.weightError')}</span>
              </Notification>
            )}

            {hasUniqueNameError(formProps?.values) && (
              <Notification testHook="uniqueNameError" kind="error" className="mt-4">
                <span>{i18n.t('workflow:journey.experimentModal.uniqueNameError')}</span>
              </Notification>
            )}

            <ActionButtons
              className="mr-0"
              onConfirm={formProps.handleSubmit}
              onDecline={hideModal}
              confirmText={i18n.t('workflow:journey.experimentModal.update')}
              declineText={i18n.t('workflow:journey.experimentModal.cancel')}
              isConfirmEnabled={formProps.isValid && !hasValidationsError(formProps?.values)}
              testHook="editExperiment"
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditExperiment;
