angular
    .module('webUi.ui.workflows.base', ['webUi.ui.siteState'])

    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.workflows', {
                url: '/workflows',
                abstract: true,
                resolve: {
                    allowView: [
                        'securityContext',
                        function getPermissions(securityContext) {
                            if (
                                securityContext.hasPermission('WORKFLOW_VIEW') ||
                                securityContext.hasPermission('WORKFLOW_AUDIENCE_EDIT') ||
                                securityContext.hasPermission('WORKFLOW_JOURNEY_EDIT') ||
                                securityContext.isContextAdmin() ||
                                securityContext.isSupportEngineer()
                            ) {
                                return true;
                            }
                            throw new AccessDeniedError('Access to the Customer Journey module is not allowed');
                        },
                    ],
                    allowEdit: [
                        'securityContext',
                        function getPermissions(securityContext) {
                            return (
                                securityContext.hasPermission('WORKFLOW_AUDIENCE_EDIT') ||
                                securityContext.hasPermission('WORKFLOW_JOURNEY_EDIT') ||
                                securityContext.isContextAdmin()
                            );
                        },
                    ],
                },
                views: {
                    'mainContent@': {
                        templateUrl: 'ui/customer/base/base.tpl.html',
                    },
                },
            }
            );
        },
    ]);
