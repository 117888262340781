/**
 * @description Angular main module for Customer Journey -> select a journey > Edit
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form
 */
angular.module('webUi.ui.customer.journey.form',
    [
        'webUi.ui.siteState',
        'webUi.ui.customer.journey.base',
        'webUi.ui.customer.journey.form.statsView',
        'webUi.ui.customer.journey.form.joStatsView',
        'webUi.ui.customer.journey.form.step',
        'webUi.ui.customer.journey.form.stepEdit',
        'webUi.ui.customer.journey.form.stepNew',
        'webUi.ui.customer.journey.form.goal',
        'webUi.ui.customer.journey.form.goalEdit',
        'webUi.ui.customer.journey.form.trigger',
        'webUi.ui.customer.journey.form.triggerEdit',
        'webUi.ui.customer.journey.form.triggerNew',
        'webUi.ui.customer.journey.form.exitCondition',
        'webUi.ui.customer.journey.form.exitConditionEdit',
    ]).config(['$stateProvider',
    function config($stateProvider) {
        $stateProvider
            .state('site.customer.journeys.form', {
                url: '/edit/{journeyId}',
                isReact: true
            });
    }
]
);