import React from 'react';
import cx from 'classnames';
import './styles.scss';

interface OverlayPageContainerProps {
  children: React.ReactNode;
  className?: string;
  isWhiteBackground?: boolean;
}

const OverlayPageContainer = ({
  className,
  children,
  isWhiteBackground = false,
}: OverlayPageContainerProps): JSX.Element => (
  <div
    className={cx(
      'OverlayPageContainer absolute h-full',
      isWhiteBackground ? 'u-whiteBackground' : 'u-greyBackground',
      className,
    )}
  >
    {children}
  </div>
);

export default OverlayPageContainer;
