import EngagementsService from '~/profiles/engagements/dataService';
import FactsService from '~/profiles/customerFacts/dataService';
import { AddableEventDefinition, ValueTransformationOption, Transformer } from './transformerTypes';
import { createTransformer } from './transformer';
import {
  fetchKnownDestinations,
  fetchKnownValueTransformations,
  getEventConnectorTypeById,
  fetchTransformerEvents,
  fetchInternalEventProperties,
} from './api';

const initTransformer = async (eventConnectorId: string, changeCallback: () => void): Promise<Transformer> => {
  const destinationsAndTransformationsPromise = getEventConnectorTypeById(eventConnectorId).then(eventConnectorType =>
    Promise.all([fetchKnownDestinations(eventConnectorType), fetchKnownValueTransformations(eventConnectorType)]),
  );

  const otherDependenciesPromise = Promise.all([
    fetchTransformerEvents(eventConnectorId),
    EngagementsService.getEngagements(),
    FactsService.getCustomerFacts(),
    fetchInternalEventProperties(),
  ]);

  const [destinationsAndTransformations, otherDependencies] = await Promise.all([
    destinationsAndTransformationsPromise,
    otherDependenciesPromise,
  ]);

  const [knownDestinations, allValueTransformations] = destinationsAndTransformations;
  const [eventsPayload, engagements, facts, internalEventProperties] = otherDependencies;

  const engagementDefinitions = engagements.map(
    engagement =>
      ({
        eventType: 'ENGAGEMENT',
        eventName: engagement.type,
        properties: engagement.properties,
      } as const),
  );

  const externalFactDefinitions = facts
    .filter(fact => fact.type === 'EXTERNAL')
    .map(
      externalFact =>
        ({
          eventType: 'EXTERNAL_FACT',
          eventName: externalFact.name,
          properties: externalFact.properties,
        } as const),
    );

  const eventDefinitions: AddableEventDefinition[] = [...engagementDefinitions, ...externalFactDefinitions];

  const knownValueTransformations: ValueTransformationOption[] = allValueTransformations
    .flatMap(valueTransformationsResponseItem => valueTransformationsResponseItem.options)
    .map(({ type, name }) => ({ transformationType: type, title: name }));

  const transformer = createTransformer(
    eventsPayload,
    { eventDefinitions, internalEventProperties, knownDestinations, knownValueTransformations },
    changeCallback,
  );

  return transformer;
};

export default initTransformer;
