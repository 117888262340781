import React from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { CARD_WIDTH } from './constants';

export const ExpandIcon = ({ x = 0, y = 0, scale = 1, className }) => (
  <g transform={`translate(${x}, ${y}) scale(${scale})`} className={cx('fill-emerald-500', className)}>
    <path d="M3 17v-6h1.5v3.438L14.438 4.5H11V3h6v6h-1.5V5.562L5.562 15.5H9V17Z" />
  </g>
);
export const CollapseIcon = ({ x = 0, y = 0, scale = 1, className }) => (
  <g transform={`translate(${x}, ${y}) scale(${scale})`} className={cx('fill-gray-500', className)}>
    <path d="M3.062 18 2 16.938 7.438 11.5H4V10h6v6H8.5v-3.438ZM10 10V4h1.5v3.438L16.938 2 18 3.062 12.562 8.5H16V10Z" />
  </g>
);

export const TriggerIcon = ({ x = 0, y = 0, scale = 1, className }) => (
  <g transform={`translate(${x} ${y}) scale(${scale})`} className={twMerge(cx('fill-emerald-500', className))}>
    <path d="M9 1q1.875 0 3.188 1.312Q13.5 3.625 13.5 5.5q0 1.042-.427 1.969T11.792 9H11V7.708q.479-.437.74-1.01Q12 6.125 12 5.5q0-1.25-.875-2.125T9 2.5q-1.25 0-2.125.875T6 5.5q0 .646.25 1.229.25.583.75.979V9.5q-1.167-.542-1.833-1.625Q4.5 6.792 4.5 5.5q0-1.875 1.312-3.188Q7.125 1 9 1Zm-.375 17q-.292 0-.563-.104-.27-.104-.5-.334L3 13l1.396-1.396q.292-.292.666-.396.376-.104.75 0L7 11.5V6q.021-.833.594-1.417Q8.167 4 9 4t1.417.583Q11 5.167 11 6v3h1l2.896 1.354q.604.292.896.854.291.563.187 1.209l-.625 4.291q-.104.542-.521.917-.416.375-.958.375Zm0-1.5h5.25l.667-4.646-2.896-1.354H9.5V6q0-.208-.146-.354T9 5.5q-.208 0-.354.146T8.5 6v7.417l-3.042-.75-.333.333Zm5.25 0h-5.25 5.25Z" />
  </g>
);

export const TransitionIcon = ({ x = 0, y = 0, scale = 1, className }) => (
  <g transform={`translate(${x} ${y}) scale(${scale})`} className={cx('fill-blue-400', className)}>
    <path d="m6 16.5-4-4 4-4 1.062 1.062-2.187 2.188H11v1.5H4.875l2.187 2.188Zm8-5-1.062-1.062 2.187-2.188H9v-1.5h6.125l-2.187-2.188L14 3.5l4 4Z" />
  </g>
);

export const AntennaIcon = ({ x = 0, y = 0, scale = 1, className }) => (
  <g transform={`translate(${x} ${y}) scale(${scale})`} className={cx('fill-blue-500', className)}>
    <path d="M9.25 18v-6.708q-.354-.188-.552-.532Q8.5 10.417 8.5 10q0-.625.438-1.062Q9.375 8.5 10 8.5t1.062.438q.438.437.438 1.062 0 .417-.198.76-.198.344-.552.532V18Zm-4.917-2.333q-1.083-1.105-1.708-2.552Q2 11.667 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.667-.625 3.115-.625 1.447-1.729 2.531l-1.063-1.063q.896-.875 1.407-2.052.51-1.177.51-2.531 0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 1.354.51 2.531.511 1.177 1.386 2.073Zm2.125-2.125q-.666-.688-1.062-1.594Q5 11.042 5 10q0-2.083 1.458-3.542Q7.917 5 10 5q2.083 0 3.542 1.458Q15 7.917 15 10q0 1.042-.396 1.948-.396.906-1.083 1.573l-1.042-1.042q.479-.479.75-1.114.271-.636.271-1.365 0-1.458-1.021-2.479Q11.458 6.5 10 6.5q-1.458 0-2.479 1.021Q6.5 8.542 6.5 10q0 .729.271 1.365.271.635.75 1.114Z" />
  </g>
);

export const StepIcon = ({ x = 0, y = 0, scale = 1, className }) => (
  <g transform={`translate(${x} ${y}) scale(${scale})`} className={twMerge(cx('fill-purple-500', className))}>
    <path d="M9 14H6q-1.667 0-2.833-1.167Q2 11.667 2 10q0-1.667 1.167-2.833Q4.333 6 6 6h3v1.5H6q-1.042 0-1.771.729Q3.5 8.958 3.5 10q0 1.042.729 1.771.729.729 1.771.729h3Zm-2-3.25v-1.5h6v1.5ZM11 14v-1.5h3q1.042 0 1.771-.729.729-.729.729-1.771 0-1.042-.729-1.771Q15.042 7.5 14 7.5h-3V6h3q1.667 0 2.833 1.167Q18 8.333 18 10q0 1.667-1.167 2.833Q15.667 14 14 14Z" />
  </g>
);

export const PlusIcon = ({ x, y, className, scale = 1 }) => (
  <g transform={`translate(${x}, ${y}) scale(${scale})`} className={className}>
    <path d="M9.25 15v-4.25H5v-1.5h4.25V5h1.5v4.25H15v1.5h-4.25V15Z" />
  </g>
);
export const MinusIcon = ({ x, y, className }) => (
  <g transform={`translate(${x}, ${y})`} className={className}>
    <line x1={0} y1={8} x2={16} y2={8} />
  </g>
);

export const GoalIcon = ({ x = 0, y = 0, scale = 1 }) => (
  <g transform={`translate(${x} ${y}) scale(${scale})`} className="JourneyView-goalIcon">
    <path d="M16.55 7.2a.249.249 0 0 1 0-.39l5.988-4.789c.521-.418.485-.8.417-1S22.674.5 22.006.5H5.993a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h16.013c.668 0 .882-.325.949-.519s.1-.58-.417-1z"></path>
    <path d="M2.506 0a1.5 1.5 0 0 0-1.5 1.5v21a1.5 1.5 0 0 0 3 0v-21a1.5 1.5 0 0 0-1.5-1.5z"></path>
  </g>
);

export const JourneyIcon = ({ x = 0, y = 0, scale = 1 }) => (
  <g transform={`translate(${x} ${y}) scale(${scale})`} className="JourneyView-journeyIcon">
    <g id="jo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M23,0 L23,6 L17,6 L17,4 L11,4 L11,10 L14,10 L14,8 L20,8 L20,14 L14,14 L14,12 L11,12 L11,18 L17,18 L17,16 L23,16 L23,22 L17,22 L17,20 L9,20 L9,12 L6,12 L6,14 L0,14 L0,8 L6,8 L6,10 L9,10 L9,2 L17,2 L17,0 L23,0 Z M21.5,17.5 L18.5,17.5 L18.5,20.5 L21.5,20.5 L21.5,17.5 Z M18.5,9.5 L15.5,9.5 L15.5,12.5 L18.5,12.5 L18.5,9.5 Z M4.5,9.5 L1.5,9.5 L1.5,12.5 L4.5,12.5 L4.5,9.5 Z M21.5,1.5 L18.5,1.5 L18.5,4.5 L21.5,4.5 L21.5,1.5 Z"
        id="Shape"
        fill="#20B25F"
      ></path>
    </g>
  </g>
);

export const ExperimentIcon = ({ x, y, className, scale = 1 }) => (
  <g transform={`translate(${x}, ${y}) scale(${scale})`} className={twMerge(cx('fill-blue-500', className))}>
    <path d="M4.5 17q-.958 0-1.375-.844-.417-.844.187-1.573L7.5 9.5v-5h-.75q-.312 0-.531-.219Q6 4.062 6 3.75q0-.312.219-.531Q6.438 3 6.75 3h6.5q.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219h-.75v5l4.188 5.083q.583.729.166 1.573Q16.438 17 15.5 17Zm0-1.5h11L11 10V4.5H9V10Zm5.521-5.5Z" />
  </g>
);

export const getIconPositions = iconsCount => {
  const iconWidth = 48;
  const offsetBetweenIcons = 16;

  const iconPositions = new Array(iconsCount).fill().map((placeholder, iconIndex) => {
    const isLastIcon = iconIndex === iconsCount;
    const offset = isLastIcon ? iconWidth : iconWidth + offsetBetweenIcons;
    const position = iconIndex * offset;

    return position;
  });

  const contentWidth = iconPositions[iconPositions.length - 1] + iconWidth;

  const leftOffset = (CARD_WIDTH - contentWidth) / 2;

  return iconPositions.map(position => position + leftOffset);
};
