import * as d3 from 'd3';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import { IIntegration } from '~/common/modules/connectors/components/EditableConnectorsList/ConnectorContent/Adwords/types';
import { RulesMap } from '~/common/modules/rules/selectors';
import { RulesTypes } from '~/common/modules/rules/types';
import constants from '~/profiles/connectors/form/constants';
import { NodeTypes } from './constants';
import { TActivityStatsResponse } from './form/Statistics/types';
import { JourneyNode, TExperimentExit, TExperimentGoal, TExperimentStats, TExperimentStatsData, TRule } from './types';

export const useQuery = () => new URLSearchParams(useLocation().search);

export const formatExpiryTime = (expiryTime: string) => {
  const numberValue = expiryTime.substring(0, expiryTime.length - 1);
  const timeValue = expiryTime[expiryTime.length - 1];
  switch (timeValue) {
    case 'd':
      return `${numberValue} Days`;
    case 'm':
      return `${numberValue} Minutes`;
    case 'h':
      return `${numberValue} Hours`;
    case 'w':
      return `${numberValue} Weeks`;
  }
  return expiryTime;
};

export const joinCriteriaVariables = (criteria: any[], variables: any[]) => {
  const variablesToRules = Immutable.asMutable(variables).reduce((hashmap, variable) => {
    hashmap[variable.variableId] = {
      // ES6 Set exclude duplicates by design, so we write less code dsv
      dependantGroups: new Set(),
      dependantRules: new Set(),
    };
    return hashmap;
  }, {});

  criteria.forEach((group, groupIndex) =>
    group
      .filter((rule: any) => Array.isArray(rule.filters))
      .forEach((rule: any) =>
        rule?.filters
          .filter(({ profileVarId }: { profileVarId: string }) => profileVarId && profileVarId !== null)
          .forEach(({ profileVarId }: { profileVarId: string }) => {
            const meta = variablesToRules[profileVarId];
            if (meta) {
              meta.dependantGroups.add(groupIndex);
              meta.dependantRules.add(rule.ruleId);
            }
          }),
      ),
  );

  const result = criteria.map((group, groupIndex) =>
    group.map((rule: any) => {
      // to prevent mutations of rule, since it's the reference
      const newRule = { ...rule };
      newRule.availableVariables = variables.map(variable => {
        const meta = variablesToRules[variable.variableId];
        const isDependantOnGroup = !!meta.dependantGroups.size && !meta.dependantGroups.has(groupIndex);
        const isDenendantOnRule = !!meta.dependantRules.size && meta.dependantRules.has(rule.ruleId);
        const isDisabled = isDependantOnGroup || isDenendantOnRule;

        return { ...variable, disabled: isDisabled };
      });
      return newRule;
    }),
  );
  return result;
};

// TODO: temporary fix to get rid of deprecated WEADAPT partner, BE will remove it from the API
export const onlyStepConnectors = (connectors: any[]) =>
  connectors.filter(
    connector =>
      connector.partnerDetails.partnerType !== constants.partnerTypes.RELAY42_API &&
      connector.partnerDetails.partnerType !== constants.partnerTypes.WEADAPT &&
      // TODO: temporary filter to remove ADWORDS from UI, change back when API is updated
      connector.partnerDetails.partnerType !== constants.partnerTypes.ADWORDS,
  );

export const getExperimentNodes = (nodes: JourneyNode) => {
  const expNodes = d3
    .hierarchy(nodes)
    .descendants()
    .filter(
      (node: any) =>
        (node.data.type === NodeTypes.TRIGGER || node.data.type === NodeTypes.START_TRIGGER) &&
        node.children?.length > 1,
    );

  const hasExperiments = !!expNodes.length;
  const experimentSteps =
    expNodes.map(node => node.data.children.map(child => ({ ...child, parentTriggerId: node.data.nodeId })))[0] || [];

  return { experimentSteps, hasExperiments };
};

export const shapeIntegrationsRequest = (integrations: IIntegration[]) =>
  integrations.map(integration => {
    const data = integration?.partnerDetails?.data;
    if (!data || isEmpty(data)) return integration.partnerDetails;

    // Info: Remove data properties with empty keys
    const newData = {} as any;
    Object.keys(data).forEach(key => {
      if (key.trim() !== '') {
        newData[key] = data[key];
      }
    });

    return { ...integration?.partnerDetails, data: newData };
  });

export const shapeEventListeners = (listeners: any[]) =>
  listeners.map(listener => ({
    ruleDefinitionId: listener.ruleDefinitionId,
    ruleType: { name: RulesMap[listener.type as keyof typeof RulesMap].ruleTypeName, label: listener.typeLabel },
    properties: listener.filters.map((filter: any) => ({
      propertyName: filter.dataField,
      variableId: filter.profileVarId,
      variableName: filter.profileVarName,
    })),
  }));

export const removeItemsNotAvailableInOrchestration = (ruleTypesTree: TRule[]) =>
  ruleTypesTree.filter(
    typesGroup => typesGroup.type !== RulesTypes.ReusableAudience && typesGroup.type !== RulesTypes.Experiment,
  );

export const parseActivityStats = (result: TActivityStatsResponse[]) => {
  const series = result.map(stat => stat.profilesActive) || [];
  const timestamp = result.map(stat => moment(stat.dayTimestamp).format('MMM D'));

  return {
    timestamp,
    series,
  };
};

export const mapExperimentsStats = (experimentsStats: TExperimentStatsData[]) => {
  const goals = experimentsStats[0]?.goals?.map((item: { name: string }) => item?.name);
  const exitConditions = experimentsStats[0]?.exitConditions?.map((item: { name: string }) => item?.name);

  const stats: TExperimentStats[] = [];

  experimentsStats.forEach((experiment: TExperimentStatsData) => {
    const transformedExperiment: TExperimentStats = {
      ...experiment,
    } as TExperimentStats;

    transformedExperiment.totalProfilesReached = experiment.goals.reduce(
      (sum, goal) => sum + goal.profilesReachedGoal,
      0,
    );

    transformedExperiment.totalProfilesExitedCondition = experiment.exitConditions.reduce(
      (sum, exitRule) => sum + exitRule.profilesExitedByCondition,
      0,
    );

    experiment.goals.forEach((goal: TExperimentGoal) => {
      transformedExperiment[goal.name] = goal.profilesReachedGoal;
    });

    experiment.exitConditions.forEach((exitCondition: TExperimentExit) => {
      transformedExperiment[exitCondition.name] = exitCondition.profilesExitedByCondition;
    });

    stats.push(transformedExperiment);
  });

  return { goals, exitConditions, stats };
};
