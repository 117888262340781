import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { components } from '~/components/src/Table';
import { DateRangePicker } from '~/components';
import { formatNumber } from '~/common';
import Notification from '~/components/src/Notification';
import StackedChart from '~/components/src/StackedChart';
import OverlayPageContainer from '~/customer/components/OverlayPageContainer';
import { useAPI } from '~/common/ApiHooks';
import JourneyDataService from '~/customer/journeys/dataService';
import { goalStatsSelector } from '~/customer/pages/JourneyView/selector';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import { MediumWrapper, Panel } from '~/components/src/Containers';
import Heading from '~/components/src/Heading';
import { formatPercentage } from '~/customer/pages/JourneyView/utils';
import { VariantAnalysis } from '~/customer/journeys/types';
import './styles.scss';

const { Table, Row, Cell, HeaderCell } = components;

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const GoalStats = ({ handleDecline, t }: { handleDecline: () => void; t: any }) => {
  const { journeyId, goalId } = useParams();

  if (!goalId || !journeyId) return <></>;

  const [filter, setFilter] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });
  const { startDate, endDate } = filter;
  const {
    data: goalData,
    isLoading,
    error: stepFetchError,
  } = useAPI(async () => {
    const data = await JourneyDataService.fetchGoalStats(journeyId, goalId);
    const goalStats = {
      ...data,
      variantAnalysis: data.variantAnalysis.map((variant: VariantAnalysis) => ({
        ...variant,
        conversionRate: variant.profilesEntered
          ? formatPercentage(variant.profilesReachedGoal / variant.profilesEntered)
          : 0,
      })),
    };
    return goalStats;
  }, [journeyId, goalId]);

  const {
    data: goalDailyStats,
    isLoading: isGoalStatsLoading,
    error: stepFetchDailyStatsError,
  } = useAPI(
    async () =>
      JourneyDataService.fetchGoalDailyStats(
        journeyId,
        goalId,
        startDate.startOf('day').toDate().getTime(),
        endDate.endOf('day').toDate().getTime(),
      ),
    [journeyId, goalId, startDate, endDate],
  );

  const tooltip = {
    formatter() {
      const header = `<p style="font-size:12px; font-weight:bold; margin-top: 24px">${this.points[0].key}</p>`;
      const barValues = this.points
        .map(
          (point: any) => `<table style="padding:0">
                <tr>
                    <td style="color:${point.series.color};padding:2px">${point.series.name}: </td>
                    <td style="padding:2px"><b>${formatNumber(point.y)}</b></td>
                </tr>
            </table>`,
        )
        .join('');
      return header + barValues;
    },
    footerFormat: null,
    backgroundColor: '#F1F1F1',
    shared: true,
    useHTML: true,
    crosshairs: true,
  };

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setFilter({ startDate, endDate });
  };

  return (
    <OverlayPageContainer>
      <MediumWrapper className="mb-2">
        <>
          <Heading
            testHook="goalStatsTitle"
            title={goalData?.name}
            crumbs={[
              {
                title: t('journey:back'),
                onClick: () => {
                  handleDecline();
                },
              },
            ]}
          />
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <div className="flex items-center gap-2">
                <Icons icon="goal" className="h-8 w-8 text-amber-500" />
                <p className="PageTitleHeader-totalProfiles flex gap-2">
                  {t('journey:chart.totalProfilesReachedGoal')}
                  <span className="font-bold">{formatNumber(goalData?.totalProfilesReachedGoal)}</span>
                </p>
              </div>

              {goalData?.variantAnalysis && goalData.variantAnalysis.length > 0 && (
                <div className="GoalStats-experimentContainer py-2">
                  <Heading title={t('orchestration:goalStats.experimentStatisticsSectionName')} kind="h2" />
                  <Notification kind="information" className="GoalStats-notification">
                    {t('orchestration:goalStats.conversionRateFormula')}
                  </Notification>
                  <Panel>
                    <Table className="t-variantTable GoalStats-table">
                      <Row>
                        <HeaderCell className="u-size3of12">{t('orchestration:goalStats.variant')}</HeaderCell>
                        <HeaderCell className="u-size2of12 u-alignRight">
                          {t('orchestration:goalStats.weight')}
                        </HeaderCell>
                        <HeaderCell className="u-size2of12 u-alignRight">
                          {t('orchestration:goalStats.entered')}
                        </HeaderCell>
                        <HeaderCell className="u-size2of12 u-alignRight">
                          {t('orchestration:goalStats.reachedGoal')}
                        </HeaderCell>
                        <HeaderCell className="u-size2of12 u-alignRight">
                          {t('orchestration:goalStats.conversionRate')}
                        </HeaderCell>
                      </Row>
                      {goalData.variantAnalysis.map(variant => (
                        <Row key={variant.experimentVariantId}>
                          <Cell className="u-size3of12">{variant.experimentVariantName}</Cell>
                          <Cell className="u-size2of12 u-alignRight">{variant.weight}</Cell>
                          <Cell className="u-size2of12 u-alignRight">{formatNumber(variant.profilesEntered)}</Cell>
                          <Cell className="u-size2of12 u-alignRight">{formatNumber(variant.profilesReachedGoal)}</Cell>
                          <Cell className="u-size2of12 u-alignRight">{variant.conversionRate}</Cell>
                        </Row>
                      ))}
                    </Table>
                  </Panel>
                </div>
              )}
              {goalData?.goalAnalysis && goalData.goalAnalysis.length > 0 && (
                <div className="GoalStats-experimentContainer py-2">
                  <Heading title={t('orchestration:goalStats.goalAnalysis')} kind="h2" />
                  <Panel>
                    <Table className="t-goalAnalysis GoalStats-table">
                      <Row>
                        <HeaderCell className="u-size3of12">{t('orchestration:goalStats.branch')}</HeaderCell>
                        <HeaderCell className="u-size3of12">{t('orchestration:goalStats.variantGroup')}</HeaderCell>
                        <HeaderCell className="u-size3of12 u-alignRight">
                          {t('orchestration:goalStats.entered')}
                        </HeaderCell>
                        <HeaderCell className="u-size3of12 u-alignRight">
                          {t('orchestration:goalStats.reachedGoal')}
                        </HeaderCell>
                      </Row>
                      {goalData.goalAnalysis.map(goal => (
                        <Row key={goal.nodeId}>
                          <Cell className="u-size3of12">{goal.nodeName}</Cell>
                          <Cell className="u-size3of12">{goal.experimentVariantName}</Cell>
                          <Cell className="u-size3of12 u-alignRight">{formatNumber(goal.profilesEntered)}</Cell>
                          <Cell className="u-size3of12 u-alignRight">{formatNumber(goal.profilesReachedGoal)}</Cell>
                        </Row>
                      ))}
                    </Table>
                  </Panel>
                </div>
              )}
            </>
          )}

          <div className="py-2">
            <Heading title={t('orchestration:goalStats.goalStatisticSectionName')} kind="h2" />
            {(stepFetchError || stepFetchDailyStatsError) && <p>{t('orchestration:goalStats.error')}</p>}
            <Panel className="p-6">
              <DateRangePicker
                className="StepStat-statisticsFrequency"
                startDate={startDate}
                endDate={endDate}
                handleDatesChange={handleDatesChange}
              />
              {isGoalStatsLoading ? (
                <Spin />
              ) : (
                <div>
                  <StackedChart
                    data={goalStatsSelector(goalDailyStats?.stats || [])}
                    height={300}
                    tooltip={tooltip}
                    className="t-goalStatisticsChart"
                  />
                </div>
              )}
            </Panel>
          </div>
        </>
      </MediumWrapper>
    </OverlayPageContainer>
  );
};

export default translate()(GoalStats);
