import { getSiteId } from '~/common/SecurityMetaService';
import { getConnectorImage } from '~/profiles/connectors';
import { RulesMap } from '~/common/modules/rules/selectors';
import { getTitleAndTypeLabel, getVariablesUsedInRules } from '~/profiles/audiences/audienceUtils';

export const makeIntegration = (
  partnerName,
  partnerDetails,
  supportsEventListeners,
  activated = true,
  hasError = false,
) => {
  const imageUrl = getConnectorImage(getSiteId())(partnerDetails);

  return {
    activated,
    partnerName,
    partnerDetails,
    image: imageUrl,
    hasError,
    supportsEventListeners,
  };
};

export const getUsedVariables = (allVariables, flatCriteria) => {
  const usedVarIds = getVariablesUsedInRules(flatCriteria);
  return usedVarIds.map(varId => ({
    profileVarId: varId,
    name: allVariables.find(variable => variable.variableId === varId)?.name,
  }));
};

export const getUsedVariablesForJO = (allVariables = []) =>
  allVariables.map(variable => ({
    variableId: variable.variableId,
    name: variable.name,
  }));

export const getIntegrations = step =>
  step.integrations.map(integration => {
    const supportedChangeTypes = integration.supportedChangeTypes || [];
    const supportsEventListeners = supportedChangeTypes.includes('MODIFIED');

    return makeIntegration(integration.name, integration.partnerDetails, supportsEventListeners, integration.activated);
  });

export const getRuleTypeByCapitalizedValue = capitalizedTypeValue =>
  Object.entries(RulesMap).find(([, value]) => value.ruleTypeName === capitalizedTypeValue)?.[0];

export const getVariableModificationsFromStep = (variableModifications = []) =>
  variableModifications.map(variable => {
    const type = getRuleTypeByCapitalizedValue(variable.ruleType.name);
    const { title, typeLabel } = getTitleAndTypeLabel(type, variable.ruleName);

    return {
      id: variable.variableModificationId,
      ruleDefinitionId: variable.ruleDefinitionId,
      title,
      typeLabel,
      type,
      filters: variable.properties.map(property => ({
        dataField: property.propertyName,
        profileVarId: property.variableId,
        profileVarName: property.variableName,
      })),
    };
  });
