import { useLocation } from 'react-router-dom';
import * as d3 from 'd3';

export const useQuery = () => new URLSearchParams(useLocation().search);

export const getExperimentNodes = nodes => {
  const expNodes = d3
    .hierarchy(nodes)
    .descendants()
    .filter(node => (node.data.type === 'TRIGGER' || node.data.type === 'START_TRIGGER') && node.children?.length > 1);

  const hasExperiments = expNodes.length;
  const experimentSteps =
    expNodes.map(node => node.data.children.map(child => ({ ...child, parentTriggerId: node.data.nodeId })))[0] || [];

  return { experimentSteps, hasExperiments };
};
