import i18n from '~/i18n';
import { showError } from '~/notificationCenter';
import connectorFormActionTypes from '../../connectorFormActionTypes';
import { getConnectorDatabases } from '../../connectorFormService';
import { errorMessage } from '../../connectorFormActions';
import constants from '../../constants';

const tripolisActionTypes = {
  FETCH_CONNECTOR_DATABASES: {
    pending: 'FETCH_CONNECTOR_DATABASES/pending',
    fulfilled: 'FETCH_CONNECTOR_DATABASES/fulfilled',
    rejected: 'FETCH_CONNECTOR_DATABASES/rejected',
  },
  SET_CONNECTOR_DATABASES: 'SET_CONNECTOR_DATABASES',
};

const fetchConnectorDatabases = ({
  partner,
  client,
  instanceNumber,
  password,
  username,
  partnerId,
} = {}) => dispatch => {
  dispatch({
    name: tripolisActionTypes.FETCH_CONNECTOR_DATABASES.pending,
    type: connectorFormActionTypes.TRIPOLIS,
  });

  return getConnectorDatabases(partner, {
    client,
    instanceNumber,
    password,
    username,
    partnerId,
  })
    .then(payload => {
      dispatch({
        name: tripolisActionTypes.FETCH_CONNECTOR_DATABASES.fulfilled,
        type: connectorFormActionTypes.TRIPOLIS,
      });
      dispatch({
        name: tripolisActionTypes.SET_CONNECTOR_DATABASES,
        data: payload.data,
        type: connectorFormActionTypes.TRIPOLIS,
      });
    })
    .catch(error => {
      dispatch({
        type: connectorFormActionTypes.TRIPOLIS,
        name: tripolisActionTypes.FETCH_CONNECTOR_DATABASES.rejected,
      });
      dispatch({ type: connectorFormActionTypes.CREATE_CONNECTOR.rejected });

      if (error.status && error.status.error.errorType) {
        dispatch(
          showError({
            header: i18n.t('partners:form.notification.error'),
            body: errorMessage(constants[partner.partnerType], error.status.error.message),
          }),
        );
      }
    });
};

const setConnectorDatabases = (databases = []) => dispatch =>
  dispatch({
    name: tripolisActionTypes.SET_CONNECTOR_DATABASES,
    data: databases,
    type: connectorFormActionTypes.TRIPOLIS,
  });

export { tripolisActionTypes, fetchConnectorDatabases, setConnectorDatabases };
