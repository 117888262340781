import Api, { API_BASE } from '~/common/ApiService';

const getAudienceStats = (segmentId, startDate, endDate) => {
  const url = `${API_BASE}/profiles/segments/${segmentId}/stats?rangeFrom=${startDate}&rangeTo=${endDate}`;
  return Api.callGet(url, { shouldShowToast: false });
};

const getDataExchangeStats = (segmentId, days) => {
  const url = `${API_BASE}/remote/partners/segments/${segmentId}/stats?days=${days}`;
  return Api.callGet(url, { shouldShowToast: false });
};

const getPartnerStats = (partnerId, partnerType, sourceId, startDate, endDate) => {
  const baseURL = `${API_BASE}/v1/partners/stats/${partnerId}?rangeFrom=${startDate}&rangeTo=${endDate}&partnerType=${partnerType}`;
  const url = sourceId ? `${baseURL}&sourceId=${sourceId}` : baseURL;
  return Api.callGet(url, { shouldShowToast: false });
};

export { getAudienceStats, getDataExchangeStats, getPartnerStats };
