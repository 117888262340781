import _, { get } from 'lodash';

import i18n from '~/i18n';
import { showError } from '~/notificationCenter';
import { getCsrfToken, getSiteId } from '~/common/SecurityMetaService';
import { handleCommonErrors } from './commonErrorsHandling';
import { ResponseJson } from './types';

require('whatwg-fetch');

export const API_BASE = '/api';

const getHeaders = () => ({
  'Content-Type': 'application/json;charset=UTF-8',
  'X-CSRF-TOKEN': getCsrfToken(),
  'X-siteId': getSiteId()?.toString(),
});

const isValidJsonResponse = (response: Response) => {
  const contentType = response.headers.get('content-type');

  // There are cases when backend sends us plain text
  if (!contentType || !contentType.includes('application/json')) {
    return false;
  }

  // There are cases when backend sends us empty responses
  const contentLength = response.headers.get('Content-Length');

  if (contentLength === '0') {
    return false;
  }

  return true;
};

/**
 * Parses response object to JSON
 * @param {Response} response Response object provided by fetch
 * @returns {Object} Response JSON
 */
export const parseResponseToJson = async (response: Response): Promise<ResponseJson> => {
  const fallbackJson = {
    responseParsingMessage: 'Response is not a valid JSON response.',
  };

  if (isValidJsonResponse(response)) {
    try {
      return await response.json();
    } catch {
      // Ignore thrown errors
    }
  }

  return Promise.resolve(fallbackJson);
};

interface ErrorHandlingOptions {
  shouldHandleCommonErrors?: boolean;
  shouldShowToast?: boolean;
  toastText?: string | null;
}

const defaultErrorHandlingOptions = {
  shouldHandleCommonErrors: true,
  shouldShowToast: true,
  toastText: null,
};

const getErrorHandlingConfig = (options?: Partial<ErrorHandlingOptions>): ErrorHandlingOptions => {
  if (typeof options !== 'object') {
    return defaultErrorHandlingOptions;
  }

  return {
    ...defaultErrorHandlingOptions,
    ...options,
  };
};

export const getToastMessage = (responseJson: ResponseJson, toastText?: ErrorHandlingOptions['toastText']): string => {
  const responseErrorMessage = get(responseJson, 'status.error.message', null) as string | null;
  const genericErrorMessage = i18n.t('common:errors.genericError') as string;

  return toastText || responseErrorMessage || genericErrorMessage;
};

/**
 * Handles response JSON. Used for both successful and error responses.
 * Either returns a JSON (successful response) or throws an error (error response)
 * Default error handling behavior can be modified by errorHandlingOptions
 */
export const handleResponseJson = (
  responseJson: ResponseJson,
  response: Response,
  errorHandlingOptions?: ErrorHandlingOptions,
): ResponseJson => {
  if (response.ok) {
    // Continue to "then" / finish "await"
    return responseJson;
  }

  const { shouldHandleCommonErrors, shouldShowToast, toastText } = getErrorHandlingConfig(errorHandlingOptions);

  if (shouldHandleCommonErrors) {
    const { isCommonErrorHandled } = handleCommonErrors(response, responseJson);

    if (isCommonErrorHandled) {
      // Continue to "catch" for custom error handling
      throw responseJson;
    }
  }

  if (shouldShowToast) {
    const toastMessage = getToastMessage(responseJson, toastText);

    showError({
      body: toastMessage,
    });
  }

  // Continue to "catch" for custom error handling
  throw responseJson;
};

const handleResponse = async (response: Response, errorHandlingOptions?: ErrorHandlingOptions) => {
  const responseJson = await parseResponseToJson(response);
  return handleResponseJson(responseJson, response, errorHandlingOptions);
};

interface CallMe {
  url: string;
  method?: string;
  errorHandlingOptions?: ErrorHandlingOptions;
  payload?: any;
}

const callMe = ({ url, payload, method = 'get', errorHandlingOptions }: CallMe) =>
  fetch(url, {
    method,
    body: (payload && JSON.stringify(payload)) || null,
    credentials: 'include',
    headers: getHeaders(),
  }).then(response => handleResponse(response, errorHandlingOptions));

export const callGet = (url: string, errorHandlingOptions?: ErrorHandlingOptions): Promise<Record<string, any>> =>
  callMe({ url, errorHandlingOptions });

/**
 * Api Delete Method
 * @param url
 */
export const callDelete = (url: string, errorHandlingOptions?: ErrorHandlingOptions): Promise<Record<string, any>> =>
  callMe({ url, errorHandlingOptions, method: 'delete' });

/**
 * Api Post Method
 * @param url
 * @param payload
 */
export const callPost = (
  url: string,
  payload?: Record<string, any> | null,
  errorHandlingOptions?: ErrorHandlingOptions,
): Promise<Record<string, any>> => callMe({ url, payload, errorHandlingOptions, method: 'post' });

/**
 * Api Post as multipart
 * for sending files
 * @param url
 * @param payload
 */
export const callPostMultiPart = (
  url: string,
  payload: FormData,
  errorHandlingOptions?: ErrorHandlingOptions,
): Promise<Record<string, any>> =>
  fetch(url, {
    method: 'post',
    body: payload,
    credentials: 'include',
    headers: {
      'X-CSRF-TOKEN': getCsrfToken(),
      'X-siteId': getSiteId()?.toString(),
    },
  }).then(response => handleResponse(response, errorHandlingOptions));

/**
 * Api Put Method
 * @param url
 * @param payload
 */
export const callPut = (
  url: string,
  payload: Record<string, any>,
  errorHandlingOptions?: ErrorHandlingOptions,
): Promise<Record<string, any>> => callMe({ url, payload, errorHandlingOptions, method: 'put' });

export const parseResponse = (response: { [property: string]: any }): any => response.data;

export default {
  callGet,
  callDelete,
  callPost,
  callPostMultiPart,
  callPut,
  parseResponse,
};
