import React from 'react';
import cx from 'classnames';
import Icons from '~/components/src/Icons';
import { CARD_WIDTH, CARD_HEIGHT } from './constants';
import i18n from '../i18n';

type ProfilesCountProps = {
  profilesCount: number;
  experimentWeight: number;
  isVariant: boolean;
  isWeightValid: boolean;
  isTrigger: boolean;
  showExperimentSettingsModal?: () => void;
};

const InfoTooltip = ({ tooltip }: { tooltip: string }) => (
  <Icons icon="info" className="t-profilesCountInfo h-6 w-6 text-white" tooltip={tooltip} />
);

const ProfilesCount = ({
  profilesCount,
  experimentWeight,
  isVariant,
  isWeightValid,
  showExperimentSettingsModal,
  isTrigger = false,
}: ProfilesCountProps): React.ReactNode => (
  <g>
    <rect
      className={cx({
        'fill-purple-400': !isVariant && !isTrigger,
        'fill-green-500': isTrigger,
        'fill-blue-500': isVariant && !isTrigger,
        'fill-red-500/60': isVariant && !isWeightValid && !isTrigger,
      })}
      x={1}
      y={CARD_HEIGHT - 31}
      width={CARD_WIDTH - 2}
      height={30}
      clipPath="url(#jo-clip-outside-node)"
    />
    <foreignObject x={12} y={CARD_HEIGHT - 28} width={CARD_WIDTH - 20} height={30}>
      <div
        className={cx('flex justify-between', { 'cursor-pointer': isVariant })}
        onClick={isVariant ? showExperimentSettingsModal : () => ({})}
      >
        <text
          x={12}
          y={CARD_HEIGHT - 10}
          className="t-profilesCount flex items-center gap-2 text-xl font-medium text-white"
        >
          {profilesCount} {i18n.t('journey:profiles')}
          {isVariant && <InfoTooltip tooltip={i18n.t('journey:stepProfilesCountMessage')} />}
        </text>
        {isVariant ? (
          <text x={12} y={CARD_HEIGHT - 10} className={cx('t-experimentWeight text-xl font-medium text-white')}>
            {experimentWeight}%
          </text>
        ) : (
          <InfoTooltip
            tooltip={i18n.t(`journey:${isTrigger ? 'triggerProfilesCountMessage' : 'stepProfilesCountMessage'}`)}
          />
        )}
      </div>
    </foreignObject>
  </g>
);

export default ProfilesCount;
