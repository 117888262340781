import React, { ReactElement } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Heading, Notification } from '~/components';
import { buildUrl, useAPI } from '~/common';
import i18n from '~/i18n';
import Spin from '~/components/src/Spin';

import { getJourneyDataAndStats } from './util';
import JourneyAnalysis from './components/JourneyAnalysis';
import AnalysisTable from './components/AnalysisTable';

function JourneyStatsView(): ReactElement {
  const params = useParams();
  const navigate = useNavigate();
  const journeyId = params.journeyId || '';
  const goToJourneyView = () => navigate(buildUrl(`customer/journeys/edit/${journeyId}`), { replace: true });
  const { data: stats, isLoading, error } = useAPI(() => getJourneyDataAndStats(journeyId));

  const title = !isLoading && stats ? stats.journeyName : i18n.t('journey:journeyStatistics.header');

  return (
    <div className="flex h-full flex-col items-center bg-gray-50">
      <div className="flex w-2/3 flex-col gap-4">
        <Heading
          testHook="journeyStatsHeader"
          title={title}
          crumbs={[
            {
              title: i18n.t('journey:back'),
              onClick: () => {
                goToJourneyView();
              },
            },
          ]}
        />
        {isLoading && <Spin />}
        {!isLoading && error && <Notification kind="error" header={error.message} />}
        {!isLoading && stats && (
          <div className="flex flex-col gap-10 pb-8">
            <JourneyAnalysis totalStats={stats.totalStats} />
            {stats.stepAnalysis && (
              <AnalysisTable
                analytics={stats.stepAnalysis}
                hasVariants={stats.experimentAnalysis.length > 0}
                header={i18n.t('journey:journeyStatistics.stepAnalysis.header')}
                totalStats={stats.totalStats}
              />
            )}
            {stats.experimentAnalysis.length > 0 && (
              <AnalysisTable
                isExperimentAnalysis
                analytics={stats.experimentAnalysis}
                header={i18n.t('journey:journeyStatistics.experimentAnalysis.header')}
                totalStats={stats.totalStats}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default JourneyStatsView;
