import React, { ReactElement, useState } from 'react';
import { Column, CellProps, useSortBy, useTable } from 'react-table';
import { Heading, Tooltip } from '~/components';
import { Panel } from '~/components/src/Containers';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import i18n from '~/i18n';
import { formatNumber } from '~/common';
import { getColumnTotal, getExitConditionNames, getGoalNames, getPercent } from '../util';
import { StatsType, TotalStats } from '../types';
import FilterButton from './FilterButton';

const filterStats = (stats: StatsType[], query: string): StatsType[] =>
  query
    ? stats.filter(
        stat =>
          (stat.nodeName && stat.nodeName.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
          (stat.experimentVariantName && stat.experimentVariantName.toLowerCase().indexOf(query.toLowerCase()) !== -1),
      )
    : stats;

const goalFooterTooltip = () => (
  <>
    <p>{i18n.t('journey:journeyStatistics.messages.goalTotalCalculation')}</p>
    <p className="pt-2">{i18n.t('journey:journeyStatistics.messages.goalPercentCalculation')}</p>
  </>
);

const exitConditionFooterToolTip = () => (
  <>
    <p>{i18n.t('journey:journeyStatistics.messages.exitConditionTotalCalculation')}</p>
    <p className="pt-2">{i18n.t('journey:journeyStatistics.messages.exitConditionPercentCalculation')}</p>
  </>
);

function AnalysisTable({
  analytics,
  header,
  isExperimentAnalysis = false,
  hasVariants = false,
  totalStats,
}: {
  analytics: StatsType[];
  header: string;
  isExperimentAnalysis?: boolean;
  hasVariants?: boolean;
  totalStats: TotalStats;
}): ReactElement {
  const goalNames = getGoalNames(analytics);
  const exitConditionNames = getExitConditionNames(analytics);
  const { profilesEnteredJourney } = totalStats;

  const [query, setQuery] = useState<string>('');

  const prepareCellWithPercentage = (value: number, testHook: string, entered: number, tooltip?: ReactElement) => (
    <Tooltip tooltip={tooltip} placement="bottom">
      <div className="flex items-center">
        <p className={`t-${testHook} flex-1 text-right`}>{formatNumber(value)}</p>
        <p className={`t-${testHook}Percent w-4 pl-1 text-right text-xs text-blue-400`}>
          {`${getPercent(value, entered)}%`}
        </p>
      </div>
    </Tooltip>
  );

  const tableColumns = React.useMemo(
    () =>
      [
        {
          Header: (
            <span className="cursor-pointer truncate text-ellipsis text-gray-600">
              {i18n.t(`journey:journeyStatistics.stepAnalysis.${isExperimentAnalysis ? 'variantName' : 'stepName'}`)}
            </span>
          ),
          accessor: isExperimentAnalysis ? 'experimentVariantName' : 'nodeName',
          Cell: (cell: CellProps<StatsType>) => (
            <Tooltip tooltip={cell.value}>
              <span className="t-stepVariantName cursor-pointer truncate text-ellipsis font-medium text-gray-600">
                {cell.value}
              </span>
            </Tooltip>
          ),
          Footer: <span className="font-medium">{i18n.t('journey:journeyStatistics.stepAnalysis.total')}</span>,
        },
        hasVariants &&
          !isExperimentAnalysis && {
            Header: (
              <span className="cursor-pointer truncate text-ellipsis text-gray-600">
                {i18n.t('journey:journeyStatistics.stepAnalysis.variantName')}
              </span>
            ),
            accessor: 'experimentVariantName',
            Cell: (cell: CellProps<StatsType>) => (
              <Tooltip tooltip={cell.value}>
                <span className="t-variantName cursor-pointer truncate text-ellipsis text-gray-600">{cell.value}</span>
              </Tooltip>
            ),
          },
        {
          Header: (
            <span className="cursor-pointer truncate text-ellipsis text-gray-600">
              {i18n.t('journey:journeyStatistics.stepAnalysis.entered')}
            </span>
          ),
          accessor: 'profilesEntered',
          Cell: (cell: CellProps<StatsType>) => (
            <p className="t-profilesEntered text-right text-gray-600">{formatNumber(cell.value)}</p>
          ),
        },
        {
          Header: (
            <span className="cursor-pointer truncate text-ellipsis text-gray-600">
              {i18n.t('journey:journeyStatistics.stepAnalysis.enteredHP')}
            </span>
          ),
          accessor: 'profilesEnteredHp',
          Cell: (cell: CellProps<StatsType>) => (
            <p className="t-profilesHPEntered text-right text-gray-600">{formatNumber(cell.value)}</p>
          ),
        },
        !isExperimentAnalysis && {
          Header: (
            <Tooltip tooltip={i18n.t('journey:journeyStatistics.messages.removedNote')}>
              <span className="cursor-pointer truncate text-ellipsis text-gray-600">
                {i18n.t('journey:journeyStatistics.stepAnalysis.removed')}
              </span>
            </Tooltip>
          ),
          accessor: 'profilesExited',
          Cell: (cell: CellProps<StatsType>) => (
            <p className="t-profilesExited text-right text-gray-600">{formatNumber(cell.value)}</p>
          ),
        },
        !isExperimentAnalysis && {
          Header: (
            <span className="cursor-pointer truncate text-ellipsis text-gray-600">
              {i18n.t('journey:journeyStatistics.stepAnalysis.moved')}
            </span>
          ),
          accessor: 'profilesMovedToNextSteps',
          Cell: (cell: CellProps<StatsType>) => (
            <p className="t-profilesMoved text-right text-gray-600">{formatNumber(cell.value)}</p>
          ),
        },
        !isExperimentAnalysis && {
          Header: (
            <span className="cursor-pointer truncate text-ellipsis text-gray-600">
              {i18n.t('journey:journeyStatistics.stepAnalysis.active')}
            </span>
          ),
          accessor: 'profilesActive',
          Cell: (cell: CellProps<StatsType>) =>
            prepareCellWithPercentage(
              cell.value,
              'profilesActive',
              cell.row.original.profilesEntered + cell.row.original.profilesEnteredHp,
            ),
          Footer: (info: CellProps<StatsType>) =>
            prepareCellWithPercentage(
              getColumnTotal(info, 'profilesActive'),
              'totalProfilesActive',
              profilesEnteredJourney,
            ),
        },
        {
          Header: i18n.t('journey:journeyStatistics.goals'),
          disableSortBy: true,
          columns: goalNames.map((goalName, index) => ({
            Header: <span className="cursor-pointer truncate text-ellipsis text-gray-600">{goalName}</span>,
            accessor: `goals[${index}].profilesReachedGoal`,
            Cell: (cell: CellProps<StatsType>) =>
              prepareCellWithPercentage(
                cell.value,
                'profilesReachedGoal',
                cell.row.original.profilesEntered + cell.row.original.profilesEnteredHp,
              ),
            Footer: (info: CellProps<StatsType>) =>
              prepareCellWithPercentage(
                getColumnTotal(info, `goals[${index}].profilesReachedGoal`),
                `goals[${index}].profilesReachedGoal`,
                profilesEnteredJourney,
                goalFooterTooltip(),
              ),
          })),
        },
        goalNames.length && {
          Header: (
            <Tooltip tooltip={i18n.t('journey:journeyStatistics.messages.totalReachedNote')}>
              <span className="cursor-pointer truncate text-ellipsis text-gray-600">
                {i18n.t('journey:journeyStatistics.stepAnalysis.totalReached')}
              </span>
            </Tooltip>
          ),
          accessor: 'totalProfilesReached',
          Cell: (cell: CellProps<StatsType>) =>
            prepareCellWithPercentage(
              cell.value,
              'totalProfilesReached',
              cell.row.original.profilesEntered + cell.row.original.profilesEnteredHp,
            ),
          Footer: (info: CellProps<StatsType>) =>
            prepareCellWithPercentage(
              getColumnTotal(info, 'totalProfilesReached'),
              'profilesTotal',
              profilesEnteredJourney,
            ),
        },
        {
          Header: i18n.t('journey:journeyStatistics.exitConditions'),
          disableSortBy: true,
          columns: exitConditionNames.map((exitConditionName, index) => ({
            Header: <span className="cursor-pointer truncate text-ellipsis text-gray-600">{exitConditionName}</span>,
            accessor: `exitConditions[${index}].profilesExitedByCondition`,
            Cell: (cell: CellProps<StatsType>) =>
              prepareCellWithPercentage(
                cell.value,
                'profilesExitedByCondition',
                cell.row.original.profilesEntered + cell.row.original.profilesEnteredHp,
              ),
            Footer: (info: CellProps<StatsType>) =>
              prepareCellWithPercentage(
                getColumnTotal(info, `exitConditions[${index}].profilesExitedByCondition`),
                `exitConditions[${index}].profilesExitedByCondition`,
                profilesEnteredJourney,
                exitConditionFooterToolTip(),
              ),
          })),
        },
        exitConditionNames.length && {
          Header: (
            <Tooltip tooltip={i18n.t('journey:journeyStatistics.messages.totalProfilesExitedConditionNote')}>
              <span className="cursor-pointer truncate text-ellipsis text-gray-600">
                {i18n.t('journey:journeyStatistics.stepAnalysis.totalProfilesExitedCondition')}
              </span>
            </Tooltip>
          ),
          accessor: 'totalProfilesExitedCondition',
          Cell: (cell: CellProps<StatsType>) =>
            prepareCellWithPercentage(
              cell.value,
              'totalProfilesExitedCondition',
              cell.row.original.profilesEntered + cell.row.original.profilesEnteredHp,
            ),
          Footer: (info: CellProps<StatsType>) =>
            prepareCellWithPercentage(
              getColumnTotal(info, 'totalProfilesExitedCondition'),
              'profilesTotalExitedByCondition',
              profilesEnteredJourney,
            ),
        },
      ].filter(Boolean) as Column<StatsType>[],
    [],
  );

  const filteredStats = filterStats(analytics, query);

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: filteredStats || [],
      autoResetSortBy: false,
      autoResetFilters: false,
      manualPagination: false,
      pageCount: 0,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, footerGroups, prepareRow, allColumns } = tableInstance;
  return (
    <div className="flex flex-col gap-4">
      <Heading kind="h3" title={header} className="py-0">
        <div className="flex items-center gap-2">
          <SearchElement
            value={query}
            onChange={event => setQuery(event.target.value)}
            placeholder={i18n.t('common:actions.search')}
          />
          <FilterButton allColumns={allColumns} />
        </div>
      </Heading>
      <Panel className="t-analysisTable flex h-auto w-full flex-nowrap overflow-x-auto p-0">
        <table className="w-full border" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`border border-gray-200 bg-gray-50 px-4 py-2 text-center font-medium text-gray-600 ${column.className}`}
                  >
                    <span {...column.getSortByToggleProps()} title="">
                      {column.render('Header')}
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <span className="pl-1 text-gray-600">&#8593;</span>
                        ) : (
                          <span className="pl-1 text-gray-600">&#8595;</span>
                        ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td className="border-x border-gray-200 bg-white px-4 py-2" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {/* If looping we get extra row which is empty hence considering only the first row [0] */}
            <tr {...footerGroups[0].getFooterGroupProps()}>
              {footerGroups[0].headers.map(column => (
                <td {...column.getFooterProps()} className="border border-gray-200 bg-gray-50 px-4 px-4 py-2">
                  {column.render('Footer')}
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
      </Panel>
    </div>
  );
}

export default AnalysisTable;
