import { connect } from 'react-redux';
import { fetchConnectorDatabases, setConnectorDatabases } from './tripolisActions';
import { fetchingConnectorDatabases, getConnectorDatabases } from './tripolisSelector';
import TripolisForm from './TripolisForm';

const mapStateToProps = state => ({
  fetchingConnectorDatabases: fetchingConnectorDatabases(state),
  databases: getConnectorDatabases(state),
});

const mapDispatchToProps = {
  fetchConnectorDatabases,
  setConnectorDatabases,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripolisForm);
