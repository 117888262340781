import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { buildUrl } from '~/common';
import Empty from '~/components/src/Empty';
import Link from '~/components/src/Link';
import { components, enhancements } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import { ElementTypes } from '~/profiles/components/constants';
import { WORKFLOW_TYPE } from '~/workflows/constants';
import './DependantJourneysList.scss';

const { Table, Row, Cell, HeaderCell } = components;

const { withSort, withPaginate, withEmpty } = enhancements;

// TODO: Workflow in Beta Mode
const isBeta = localStorage.getItem('useBeta');

const baseUrl = isBeta ? 'workflows' : 'customer/journeys';

const getStepByElementType = elementType => {
  switch (elementType) {
    case ElementTypes.JOURNEY_STEP:
      return '?step=3';
    case ElementTypes.JOURNEY_GOAL:
      return '?step=4';
    case ElementTypes.JOURNEY_EXIT_BY_CONDITION:
      return '?step=5';
    default:
      return '?step=1';
  }
};

const EmptyList = ({ t, hasAccess, emptyListMessageBody }) => {
  if (!hasAccess) {
    return <Empty excludePanel header={t('list.noAccess.header')} body={t('list.noAccess.body')} />;
  }
  return <Empty excludePanel header={t('list.empty.header')} body={emptyListMessageBody} />;
};

const prepareDependancyLink = (dependantInfo, dependantArgument, urlArgument) => {
  const nodeId = dependantArgument.id;
  if (dependantInfo.workflowType.name === WORKFLOW_TYPE.Audience) {
    const stepQuery = getStepByElementType(dependantArgument.dependantType);
    return buildUrl(`${baseUrl}/edit/${dependantInfo.id}${stepQuery}&nodeId=${nodeId}`);
  }
  return buildUrl(`${baseUrl}/edit/${dependantInfo.id}/${urlArgument}/${nodeId}`);
};

const UsedInTriggerOrStep = ({ dependantInfo }) => {
  const triggerDependancyArrayLength = dependantInfo.dependantTriggers.length;
  let urlArgument = 'trigger';
  let dependantArguments = dependantInfo.dependantTriggers;
  if (triggerDependancyArrayLength === 0) {
    dependantArguments = dependantInfo.dependantSteps;
    urlArgument = 'step';
  }
  return (
    <ul className="JourneyDependancy-list">
      {dependantArguments.map(dependantArgument => (
        <li className="JourneyDependancy-listItem">
          <Link
            className="JourneyDependancy-link"
            key={dependantArgument.id}
            href={prepareDependancyLink(dependantInfo, dependantArgument, urlArgument)}
          >
            {dependantArgument.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export const DependantJourneysList = ({ list, stateKey, t }) => (
  <Table stateKey={stateKey} testHook="dependantsListTable">
    <Row>
      <SortableHeader sortKey="name" title={t('list.journeyHeader')} className="u-size1of2" />
      <HeaderCell className="u-size1of2">{t('list.triggerStepHeader')}</HeaderCell>
      <HeaderCell className="u-size1of2">{t('list.workflowType')}</HeaderCell>
    </Row>
    {list.map(dependantInfo => (
      <Row key={dependantInfo.id}>
        <Cell className="u-size1of2 JourneyDependancy">
          <Link className="JourneyDependancy-link" href={buildUrl(`${baseUrl}/edit/${dependantInfo.id}`)}>
            {dependantInfo.name}
          </Link>
        </Cell>
        <Cell className="u-size1of2 JourneyDependancy">
          <UsedInTriggerOrStep dependantInfo={dependantInfo} />
        </Cell>
        <Cell className="u-size1of2 JourneyDependancy">{dependantInfo.workflowType.label}</Cell>
      </Row>
    ))}
  </Table>
);

DependantJourneysList.propTypes = {
  list: PropTypes.array.isRequired,
  stateKey: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool,
  emptyListMessageBody: PropTypes.string.isRequired,
};

export default compose(withEmpty({ component: EmptyList }), withSort(), withPaginate())(DependantJourneysList);
