/* eslint-disable class-methods-use-this */
import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const API_CONNECTORS = `${API_BASE}/remote/partners`;
const API_CONNECTORS_V1 = `${API_BASE}/v1/partners`;

/**
 * A service for Connectors related queries
 * Warning: Methods cannot be static as long as are used in Angular
 */
export class ProfileConnectorsClass {
  deleteConnector(connectorId: string) {
    return Api.callDelete(`${API_CONNECTORS}/${connectorId}`).then(parseResponse);
  }

  deleteEventConnector(eventConnectorId: string) {
    return Api.callDelete(`/api/v1/eventConnectors/${eventConnectorId}`);
  }

  toggleActivation(payload: { partnerId: string; activated: boolean }) {
    return Api.callPost(`${API_CONNECTORS}/activate`, payload).then(parseResponse);
  }

  toggleEventConnectorActivation(eventConnectorId: string, activated: boolean) {
    return Api.callPut(
      `/api/v1/eventConnectors/${eventConnectorId}/activate`,
      {
        active: !activated,
      },
      { shouldShowToast: false },
    );
  }

  getAllConnectors(connectorCategory: string) {
    return Api.callGet(`${API_CONNECTORS_V1}?category=${connectorCategory}`).then(parseResponse);
  }

  getEventConnectors() {
    return Api.callGet('/api/v1/eventConnectors').then(parseResponse);
  }

  getEventConnectorTypes() {
    return Api.callGet(`${API_CONNECTORS}/types?supportType=EVENT`).then(parseResponse);
  }

  getConnectorTypes() {
    return Api.callGet(`${API_CONNECTORS}/types`).then(parseResponse);
  }

  getConnectorDetail(connectorId: string) {
    return Api.callGet(`${API_CONNECTORS_V1}/${connectorId}`).then(parseResponse);
  }
}

const profileConnectorsService = new ProfileConnectorsClass();
export default profileConnectorsService;
