import { getPartnerInformation, getPayloadTypes } from '../../connectorFormService';
import { getPartnerInformation as getPartnerInformationSelector } from './adwordsSelector';
import connectorFormActionTypes from '../../connectorFormActionTypes';
import constants from '../../constants';
import { selectFormValues } from '../../connectorFormSelector';

const adwordsActionTypes = {
  FETCH_PAYLOAD_TYPES: {
    pending: 'FETCH_PAYLOAD_TYPES/pending',
    fulfilled: 'FETCH_PAYLOAD_TYPES/fulfilled',
    rejected: 'FETCH_PAYLOAD_TYPES/rejected',
  },
  FETCH_PARTNER_INFORMATION: {
    pending: 'FETCH_PARTNER_INFORMATION/pending',
    fulfilled: 'FETCH_PARTNER_INFORMATION/fulfilled',
    rejected: 'FETCH_PARTNER_INFORMATION/rejected',
  },
  SET_PAYLOAD_TYPES: 'SET_PAYLOAD_TYPES',
  SET_PARTNER_INFORMATION: 'SET_PARTNER_INFORMATION',
};

const fetchPayloadTypes = partner => dispatch => {
  dispatch({
    name: adwordsActionTypes.FETCH_PAYLOAD_TYPES.pending,
    type: connectorFormActionTypes.ADWORDS,
  });

  return getPayloadTypes(partner)
    .then(payload => {
      dispatch({
        name: adwordsActionTypes.FETCH_PAYLOAD_TYPES.fulfilled,
        type: connectorFormActionTypes.ADWORDS,
      });
      dispatch({
        name: adwordsActionTypes.SET_PAYLOAD_TYPES,
        data: payload.data,
        type: connectorFormActionTypes.ADWORDS,
      });
    })
    .catch(() => {
      dispatch({
        type: connectorFormActionTypes.ADWORDS,
        name: adwordsActionTypes.FETCH_PAYLOAD_TYPES.rejected,
      });
    });
};

const fetchPartnerInformation = () => dispatch => {
  const currentUrl = `${window.location.origin}${window.location.pathname}`;
  const redirectUrl = `/${constants.ADWORDS}/authorizationUrl?redirectUrl=${encodeURI(currentUrl)}`;

  dispatch({
    name: adwordsActionTypes.FETCH_PARTNER_INFORMATION.pending,
    type: connectorFormActionTypes.ADWORDS,
  });

  return getPartnerInformation(redirectUrl)
    .then(payload => {
      dispatch({
        name: adwordsActionTypes.FETCH_PARTNER_INFORMATION.fulfilled,
        type: connectorFormActionTypes.ADWORDS,
      });
      dispatch({
        name: adwordsActionTypes.SET_PARTNER_INFORMATION,
        data: payload.data,
        type: connectorFormActionTypes.ADWORDS,
      });
    })
    .catch(() => {
      dispatch({
        type: connectorFormActionTypes.ADWORDS,
        name: adwordsActionTypes.FETCH_PARTNER_INFORMATION.rejected,
      });
    });
};

const redirectToAuthorizationUrl = () => (dispatch, getState) => {
  const partnerInformation = getPartnerInformationSelector(getState());
  window.location.href = partnerInformation.url;
  localStorage.setItem('partnerValues', JSON.stringify(selectFormValues(getState())));
};

export { adwordsActionTypes, fetchPayloadTypes, redirectToAuthorizationUrl, fetchPartnerInformation };
