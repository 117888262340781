import React from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import * as d3 from 'd3';
import { useParams } from 'react-router-dom';
import { useAPI } from '~/common/ApiHooks';
import JourneyDataService from '~/customer/journeys/dataService';
import withSpinner from '~/components/src/Spinner/withSpinner';
import { getRuleTypeByCapitalizedValue } from '~/customer/components/EditStep/utils';
import { getTitleAndTypeLabel } from '~/profiles/audiences/audienceUtils';
import ViewStep from './presenter';

const withDataFetching =
  ViewStep =>
  ({ canEdit, nodes, goToEditStepScreen, ...props }) => {
    const { stepId } = useParams();
    const hierarchy = d3.hierarchy(nodes);
    const parentTrigger = hierarchy.descendants().find(node => node.data.nodeId === stepId).parent;

    const { data, isLoading, error } = useAPI(() => JourneyDataService.fetchStepData(stepId), [stepId]);

    const name = data?.name;
    const integrations = (data?.integrations || []).map(integration => {
      const supportedChangeTypes = integration.supportedChangeTypes || [];
      const supportsEventListeners = supportedChangeTypes.includes('MODIFIED');

      return {
        ...integration,
        supportsEventListeners,
      };
    });
    const variableModifications = data?.variableModifications || [];

    const eventListeners = variableModifications.map(variableModification => {
      const ruleType = getRuleTypeByCapitalizedValue(variableModification.ruleType.name);
      const { title, typeLabel } = getTitleAndTypeLabel(ruleType, variableModification.ruleName);

      const properties = variableModification.properties.map(property => ({
        property: property.propertyName,
        storedVariable: property.variableName,
      }));

      return {
        ruleType,
        title,
        typeLabel,
        properties,
        variableModificationId: variableModification.variableModificationId,
      };
    });

    return (
      <ViewStep
        isLoading={isLoading}
        error={error}
        name={name}
        integrations={integrations}
        eventListeners={eventListeners}
        canEdit={canEdit}
        handleEdit={() => {
          goToEditStepScreen(stepId, parentTrigger.data.nodeId);
        }}
        {...props}
      />
    );
  };

export default compose(translate(), withDataFetching, withSpinner('blankOverlay--lightGray'))(ViewStep);
