import React from 'react';
import { useAPI } from '~/common/ApiHooks';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { Field } from 'formik';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import CardMessage, { MessageTypes } from '~/components/src/CardMessage';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { getContactGroupsOptions } from './service.js';

const validations = t =>
  Yup.object().shape({
    contactGroup: Yup.string().required(t('connectors.tripolis.contactGroupValidation')),
  });

const Tripolis = ({ onEdit, connector, t, isSubmitted }) => {
  const { partnerId } = connector.partnerDetails;

  const { data: contactGroups, isLoading: isListLoading } = useAPI(() => getContactGroupsOptions(partnerId), [
    partnerId,
  ]);

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {formProps => (
        <>
          <SmallWrapperNoOffset>
            <Field
              testHook="tripolisContactGroup"
              name="contactGroup"
              as={FormikSelectField}
              label={t('connectors.tripolis.contactGroup')}
              errorText={formProps?.touched?.contactGroup && formProps?.errors?.contactGroup?.name}
              options={contactGroups || []}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.id}
              value={formProps.values.contactGroup}
              isLoading={isListLoading}
              isClearable={true}
            />
          </SmallWrapperNoOffset>
          <CardMessage message={t('connectors.tripolis.info')} type={MessageTypes.INFO} />
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null), translate('audiences'))(Tripolis);
