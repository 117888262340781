import React from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import { useAPI } from '~/common/ApiHooks';
import * as TriggersService from '~/customer/triggers/dataService';
import * as RulesService from '~/common/modules/rules/dataService';
import withSpinner from '~/components/src/Spinner/withSpinner';
import ViewTrigger from './presenter';
import { prepareDataForViewTrigger } from './utils';

const withDataFetching = ViewTrigger => props => {
  const { canEdit, goToEditGoalScreen, goToEditTriggerScreen, goToEditExitConditionScreen } = props;
  const params = useParams();
  const isGoal = !!params.goalId;
  const isExitCondition = !!params.exitConditionId;

  const triggerId = params.goalId || params.exitConditionId || params.triggerId;
  const { data, isLoading, error } = useAPI(async () => {
    const [trigger, ruleDefinitions, variables] = await Promise.all([
      TriggersService.fetchTrigger(triggerId),
      RulesService.getRulesDefinitions(),
      RulesService.getAllRuleVariables(),
    ]);

    return prepareDataForViewTrigger(trigger, ruleDefinitions, variables);
  });

  const editTrigger = () => {
    if (isGoal) return goToEditGoalScreen(triggerId);
    if (isExitCondition) return goToEditExitConditionScreen(triggerId);
    return goToEditTriggerScreen(triggerId);
  };

  return (
    <ViewTrigger
      isLoading={isLoading}
      error={error}
      triggerId={data?.triggerId}
      triggerTitle={data?.title}
      triggerCriteria={data?.criteria}
      canEdit={canEdit}
      goToEditScreenForThisTrigger={() => editTrigger()}
      {...props}
    />
  );
};

const enhance = compose(withDataFetching, withSpinner('blankOverlay--lightGray'), translate('audiences'));

export default enhance(ViewTrigger);
