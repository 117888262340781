import React from 'react';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { getAngularService } from 'ReactAngular/react.service';
import { curry } from 'lodash';
import RulesList, { RuleOptions, RuleCardTypes, validateCriteria } from '~/components/src/RuleList';
import AudienceRuleControls from '~/profiles/components/AudienceRuleControls';
import { RulesTypes } from '~/common/modules/rules/types';
import {
  RULE_DEFAULT_TIME_PERIOD,
  RULE_DEFAULT_FIXED_WAIT_TIME,
  hasTimeCondition,
  canBeInverted,
} from '~/common/modules/rules/selectors';
import { getTitleAndTypeLabel } from '~/profiles/audiences/audienceUtils';
import { getMandatoryProperties } from './utils';

const makeNewRule = (ruleId, clickedRuleDescription, defaultWaitTime = RULE_DEFAULT_TIME_PERIOD) => {
  const { ruleDefinitionId, type, title: clickedItemTitle, availableFilters, extra } = clickedRuleDescription;

  const { title, typeLabel } = getTitleAndTypeLabel(type, clickedItemTitle);

  const newRule = {
    ruleId,
    ruleDefinitionId,
    type,
    title,
    typeLabel,
  };

  if (type === RulesTypes.FixedTime) {
    newRule.waitTime = RULE_DEFAULT_FIXED_WAIT_TIME;
  }

  if (type === RulesTypes.JourneyOverlap) {
    newRule.dependantJourneyId = extra.dependantJourneyId;
  }

  if (hasTimeCondition(type)) {
    newRule.timeCondition = defaultWaitTime;
  }

  if (canBeInverted(type)) {
    newRule.negation = false;
  }

  const mandatoryProperties = getMandatoryProperties(availableFilters);

  if (mandatoryProperties.length) {
    newRule.filters = mandatoryProperties;
  }

  return newRule;
};

const addCriteria = curry(
  (defaultWaitTime, onChange, prevCriteriaList, isAudience, kind, clickedRuleDescription, index = -1) => {
    const UUIDService = getAngularService(document, 'UUIDService');
    const ruleId = UUIDService.newId();

    const newRule = makeNewRule(ruleId, clickedRuleDescription, defaultWaitTime);

    const nextCriteriaList = [...prevCriteriaList];
    const lastAndGroupIndex = nextCriteriaList.length - 1;

    switch (kind) {
      case RuleOptions.AND:
        nextCriteriaList.push([newRule]);
        break;
      case RuleOptions.OR:
        if (index !== -1) {
          nextCriteriaList[index].push(newRule);
        } else {
          nextCriteriaList[lastAndGroupIndex].push(newRule);
        }
        break;
    }
    onChange({ criteria: nextCriteriaList, isValid: validateCriteria(nextCriteriaList, isAudience) });
  },
);

// TODO: Think if we can make this component unaware of "overlap"
const lastRuleSetContainsOverlap = criteria => {
  if (!criteria?.length) {
    return false;
  }

  const lastRuleset = criteria[criteria.length - 1];
  return lastRuleset[0].type === RulesTypes.JourneyOverlap;
};

const EditableRulesList = ({
  criteria,
  ruleTypesTree,
  onChange,
  isSubmitted,
  fetchAllVariables,
  isUsedBySegment,
  isJourneyCondition,
  isJoTrigger,
  isUsedInJourneys,
  audienceId,
  type,
  bindFormikForm,
  enableGroupBy,
  defaultWaitTime,
}) => (
  <div>
    <RulesList
      defaultWaitTime={defaultWaitTime}
      criteria={criteria}
      ruleTypesTree={ruleTypesTree}
      ruleCardType={RuleCardTypes.EditCard}
      onChange={onChange}
      addCriteria={addCriteria}
      isSubmitted={isSubmitted}
      fetchAllVariables={fetchAllVariables}
      isJourneyCondition={isJourneyCondition}
      isJoTrigger={isJoTrigger}
      isUsedInJourneys={isUsedInJourneys}
      type={type}
      bindFormikForm={bindFormikForm}
      enableGroupBy={enableGroupBy}
    />
    <AudienceRuleControls
      enableGroupBy={enableGroupBy}
      onCriteriaSelect={addCriteria(defaultWaitTime, onChange, criteria, type === 'audience')}
      criteria={criteria}
      ruleTypesTree={ruleTypesTree}
      isUsedBySegment={isUsedBySegment}
      isUsedInJourneys={isUsedInJourneys}
      isOrButtonHidden={lastRuleSetContainsOverlap(criteria)}
      audienceId={audienceId}
    />
  </div>
);

export default compose(translate('audiences'))(EditableRulesList);
