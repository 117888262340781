import { connect } from 'react-redux';
import { fetchPayloadTypes, redirectToAuthorizationUrl, fetchPartnerInformation } from './adwordsActions';
import {
  fetchingPayloadTypes,
  getPayloadTypes,
  fetchingPartnerInformation,
  getPartnerInformation,
} from './adwordsSelector';
import AdwordsForm from './AdwordsForm';

const mapStateToProps = state => ({
  fetchingPayloadTypes: fetchingPayloadTypes(state),
  payloadTypes: getPayloadTypes(state),
  fetchingPartnerInformation: fetchingPartnerInformation(state),
  partnerInformation: getPartnerInformation(state),
});

const mapDispatchToProps = {
  fetchPayloadTypes,
  redirectToAuthorizationUrl,
  fetchPartnerInformation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdwordsForm);
