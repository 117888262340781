import React from 'react';
import { translate } from 'react-i18next';
import RulePicker from '~/common/modules/rules/components/RulePicker/index';
import { getTitleAndTypeLabel } from '~/profiles/audiences/audienceUtils';
import { getPropertyTypesForRule } from '~/components/src/RuleList';
import '~/components/src/RuleList/styles.scss';
import EventListenerEditCard from './EventListenerEditCard';

const validateEventListeners = events => {
  const hasOnlyValidFilters = event =>
    event.filters.length && event.filters.every(filter => filter.dataField && filter.profileVarId);

  return events.every(event => hasOnlyValidFilters(event));
};

function EditableEventListenerList({
  t,
  eventsListeners,
  onChange,
  showRulePicker = true,
  disallowDuplicates = false,
  ruleTypesTree = [],
  usedVariables = [],
  actionName = t('eventListener.add'),
  viewOnly = false,
}) {
  const handleOnChange = eventsListeners => {
    const areEventsListenersValid = validateEventListeners(eventsListeners);
    onChange({
      areEventsListenersValid,
      eventsListeners,
    });
  };

  const handleAddNewRule = data => {
    const altData = getTitleAndTypeLabel(data.type, data.title);
    const title = data.title || altData.title;
    const typeLabel = data.typeLabel || altData.typeLabel;

    const listeners = [
      ...eventsListeners,
      {
        id: Math.random().toString().slice(2),
        ruleDefinitionId: data.ruleDefinitionId,
        ruleDefinitionName: data.ruleDefinitionName,
        ruleName: data.ruleName,
        title,
        typeLabel,
        type: data.type,
        filters: [],
      },
    ];
    handleOnChange(listeners);
  };

  const handlePropertyChange = (data, eventIndex) => {
    const updatedEventListeners = eventsListeners.map((event, index) => {
      if (eventIndex === index) {
        return { ...event, filters: data.filters };
      }
      return event;
    });
    handleOnChange(updatedEventListeners);
  };

  const handleEventDelete = index => {
    const updatedEventListeners = eventsListeners.filter((_, i) => i !== index);
    handleOnChange(updatedEventListeners);
  };

  return (
    <div className="flex flex-col gap-2">
      {eventsListeners?.map((listener, i) => {
        const propertyTypes = getPropertyTypesForRule(ruleTypesTree, listener.ruleDefinitionId);

        return (
          <EventListenerEditCard
            key={listener.id}
            id={listener.id}
            title={listener.title}
            type={listener.type}
            typeLabel={listener.typeLabel}
            onChange={data => handlePropertyChange(data, i)}
            onDelete={() => handleEventDelete(i)}
            isSubmitted={false}
            filters={listener?.filters}
            propertyTypes={propertyTypes}
            availableVariables={usedVariables}
            showRulePicker={showRulePicker}
            viewOnly={viewOnly}
            url={listener.url}
          />
        );
      })}
      {!viewOnly && (
        <RulePicker
          disallowDuplicates={disallowDuplicates}
          selectedRules={eventsListeners}
          onSelect={handleAddNewRule}
          ruleTypesTree={ruleTypesTree}
          actionName={actionName}
          testHook="addEventListner"
          className="u-inlineBlock"
        />
      )}
    </div>
  );
}

export default translate('orchestration')(EditableEventListenerList);
