/**
 * @description Angular main module for Customer Journey -> Dashboard
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.dashboard
 */
angular
    .module('webUi.ui.customer.journey.dashboard', ['webUi.ui.siteState', 'webUi.ui.customer.journey.base'])
    .config([
        '$stateProvider',
        function config($stateProvider) {
            $stateProvider.state('site.customer.journeys.dashboard', {
                url: '/dashboard',
                isReact: true,
            }).state('site.customer.journeys.exp', {
                url: '/joDemoMode',
                isReact: true,
            });
        },
    ]);