/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { reducer as form } from 'redux-form';
import thunk from 'redux-thunk';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import modal from '~/modals';
import support from '~/support';
import context from '~/context';
import admin from '~/admin';
import permissions from '~/permissions';
import profiles, { epics as profileEpics } from '~/profiles';
import common from '~/common/modules';
import customer from '~/customer';
import reducers from 'react-redux-composable-list';
import user from '~/user';

const epicMiddleware = createEpicMiddleware();

const reducer = combineReducers({
  admin,
  profiles,
  customer,
  context,
  support,
  modal,
  form,
  permissions,
  user,
  common,
  ...reducers,
});

/**
 * Everytime we switch to a different site, we should reset the store
 */

const rootReducer = (state, action) => {
  let newState = { ...state };
  if (action.type === 'USER_CHANGE_SITE') {
    newState = undefined;
  }
  return reducer(newState, action);
};

const composeEnhancers =
  process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const rootEpic = combineEpics(...profileEpics);
  const middlewares = [thunk, epicMiddleware];

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

  epicMiddleware.run(rootEpic);

  return store;
};

export default configureStore;
