import React from 'react';
import PropTypes from 'prop-types';
import { Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Form from '~/components/src/Form/Form';
import FormActions from '~/components/src/Form/ActionsDefault';
import { isRequired, defaultMaxInput, isValueUniqueCaseInsensitive } from '~/common';
import { ReduxFormInputField, ReduxFormTextAreaField } from '~/components/src/Form/Fields/ReduxFormFields';
import PropertiesForm from '../components/PropertiesForm';
import { ALLOWED_NUMBER_OF_PROPERTIES, ENGAGEMENT_USED_IN } from '../engagements/types';

const engagementNameIsUnique = (value, allValues, props) => {
  if (!props.isNew) return undefined;
  return isValueUniqueCaseInsensitive(value.trim(), props.engagementNames);
};

export const EngagementForm = ({
  selectedPropertyId,
  initialValues,
  propertiesWithUsage,
  handleSubmit,
  submitting,
  cancelHref,
  onDependantIconClick,
  setUserIsEditing,
  userIsEditing,
  t,
  form,
}) => (
  <Form onSubmit={handleSubmit} isSubmitting={submitting}>
    <div>
      <Field
        label={t('edit.name')}
        autoFocus={true}
        name="type"
        component={ReduxFormInputField}
        placeholder={t('edit.namePlaceholder')}
        validate={[isRequired, defaultMaxInput, engagementNameIsUnique]}
        type="text"
        disabled={!!initialValues.engagementId}
      />
      <Field
        label={t('edit.description')}
        name="description"
        component={ReduxFormTextAreaField}
        placeholder={t('edit.descriptionPlaceholder')}
      />
      <Field
        name="propertiesWithUsage"
        formName={form}
        component={PropertiesForm}
        elementId={initialValues.engagementId}
        onDependantIconClick={onDependantIconClick}
        selectedPropertyId={selectedPropertyId}
        initialValues={initialValues}
        list={propertiesWithUsage}
        stateKey={`PROFILES/ENGAGEMENT/${initialValues.engagementId || 'NEW'}/PROPERTIES/EDIT/TABLE`}
        setUserIsEditing={setUserIsEditing}
        userIsEditing={userIsEditing}
        usedIn={ENGAGEMENT_USED_IN}
        maxNumberOfProperties={ALLOWED_NUMBER_OF_PROPERTIES}
        t={t}
      />
    </div>
    <FormActions t={t} submitting={submitting} cancelHref={cancelHref} />
  </Form>
);

EngagementForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  submitting: PropTypes.bool,
  cancelHref: PropTypes.string.isRequired,
  selectedPropertyId: PropTypes.string,
  onDependantIconClick: PropTypes.func,
  userIsEditing: PropTypes.bool,
  setUserIsEditing: PropTypes.func,
  t: PropTypes.func.isRequired,
};

/* State Props */
const mapStateToProps = (state, ownProps) => ({
  propertiesWithUsage: getFormValues(ownProps.form)(state).propertiesWithUsage,
});

export default compose(connect(mapStateToProps, null))(EngagementForm);
