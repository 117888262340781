import Immutable from 'seamless-immutable';
import { omit } from 'lodash';
import { combineReducers } from 'redux';
import * as types from './actionTypes';

const defaultAction = {
  payload: {},
};

/**
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = Immutable({}), action = defaultAction) => {
  switch (action.type) {
    case types.JOURNEYS_FETCH_SUCCESS:
      return {
        data: Immutable({
          ...action.payload.content.reduce((acc, current) => ({ ...acc, [current.journeyId]: current }), {}),
        }),
        total: action.payload.totalElements,
      };
    case types.JOURNEYS_DELETE_SUCCESS: {
      return Immutable(omit(state, action.payload));
    }
    case types.JOURNEY_DEPENDANTS_INFO_FETCH_SUCCESS:
      return Immutable({
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          dependantsInfo: action.payload.dependantsInfo,
        },
      });
    case types.JOURNEY_LABELS_UPDATE_START:
      return Immutable({
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            isUpdatingLabels: true,
          },
        },
      });
    case types.JOURNEY_LABELS_UPDATE_SUCCESS:
      return Immutable({
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            labels: action.payload.labels?.map(label => label.name),
            isUpdatingLabels: false,
          },
        },
      });
    case types.JOURNEY_LABELS_UPDATE_FAIL:
      return Immutable({
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isUpdatingLabels: false,
        },
      });
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 * @returns []
 */
const allIds = (state = Immutable([]), action = defaultAction) => {
  switch (action.type) {
    case types.JOURNEYS_FETCH_SUCCESS:
      return Immutable([...action.payload.content.map(item => item.journeyId)]);
    case types.JOURNEYS_DELETE_SUCCESS:
      return Immutable(state.filter(item => item !== action.payload));
    default:
      return state;
  }
};

const labels = (state = Immutable([]), action = defaultAction) => {
  switch (action.type) {
    case types.JOURNEY_LABELS_FETCH_SUCCESS:
      return Immutable([...action.payload]);
    default:
      return state;
  }
};

const defaultUI = {
  isFetchingAll: false,
  error: null,
  dependantsContainerIsVisible: false,
  selectedId: null,
};

const ui = (state = defaultUI, action = defaultAction) => {
  switch (action.type) {
    case types.JOURNEYS_FETCH_START:
      return {
        ...state,
        isFetchingAll: true,
        shouldFetchAll: false,
      };
    case types.JOURNEYS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        shouldFetchAll: false,
      };
    case types.SHOW_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: true,
        selectedId: action.payload.journeyId,
      };
    case types.HIDE_DEPENDANTS_CONTAINER:
      return {
        ...state,
        dependantsContainerIsVisible: false,
        selectedId: null,
      };
    default:
      return state;
  }
};

const adminJourneyReducer = combineReducers({
  byId,
  allIds,
  ui,
  labels,
});

export default adminJourneyReducer;
