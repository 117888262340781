import Api, { API_BASE } from '~/common/ApiService';
import i18n from '~/i18n';

const BASE_PATH = `${API_BASE}/customer/journeys`;

export const updateTitle = (journeyId, name) =>
  Api.callPost(
    `${BASE_PATH}/${journeyId}/updateName`,
    { name },
    {
      toastText: i18n.t('journey:updateNameRequestFailed'),
    },
  );

export const updateExpiryTime = (journeyId, expiryTime) =>
  Api.callPost(
    `${BASE_PATH}/updateExpiryTime`,
    { journeyId, expiryTime },
    {
      toastText: i18n.t('journey:updateExpiryTimeRequestFailed'),
    },
  );
