import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { buildUrl, changeUrl } from '~/common';
import { translate } from 'react-i18next';
import Heading from '~/components/src/Heading';
import Notification from '~/components/src/Notification';
import * as fromModals from '~/modals';
import { createAndOpenEmptyJourney } from '~/customer/helpers/clientsHelper.js';
import DetailContainer from '~/components/src/Table/components/DetailContainer';
import OverlapJourneyList from '~/customer/components/OverlapJourneyList';
import BtnIcon from '~/components/src/BtnIcon';
import {
  fetchJourneys,
  fetchJourneyLabels,
  deleteJourney,
  showDependantsContainer,
  hideDependantsContainer,
  updateJourneyLabels,
  deleteLabel,
} from '../actions';
import {
  getJourneys,
  getAllLabels,
  isFetchingJourneys,
  isDependantsContainerVisible,
  getSelectedJourney,
  getSelectedId,
} from '../selectors';
import JOList from '../../components/JOList';

const JOURNEYS_STATE_KEY = 'CUSTOMER/JOURNEYS';

const redirectToEditPage = id => {
  changeUrl(`customer/journeys/edit/${id}?showStats=0`);
};

const redirectToStatisticsPage = id => {
  changeUrl(`customer/journeys/edit/${id}/joStatsView`);
};

class CustomerJourneyContainer extends Component {
  constructor() {
    super();
    this.state = { isFiltersPanelOpen: false };
    this.toggleFiltersPanel = this.toggleFiltersPanel.bind(this);
  }
  toggleFiltersPanel() {
    this.setState(state => ({ isFiltersPanelOpen: !state.isFiltersPanelOpen }));
  }
  componentDidMount() {
    this.props.fetchJourneyLabels();
  }

  componentWillUnmount() {
    this.props.onDependantsContainerClose();
  }

  render() {
    const {
      isFetching,
      t,
      data: { list, total },
      labels,
      itemUrl,
      canEditCustomerJourney,
      onDeleteClick,
      onDependantIconClick,
      dependantsContainerIsVisible,
      onDependantsContainerClose,
      selectedJourney,
      updateJourneyLabels,
      maxJourneyLimit,
      fetchJourneys,
      onDeleteLabel,
    } = this.props;
    const journeyExceedMaxLimit = maxJourneyLimit !== null && list?.length >= maxJourneyLimit;
    return (
      <div className="flex flex-col gap-4 p-4">
        <Heading title={t('common.header')} className="py-0">
          <BtnIcon icon="filter" tooltip={t('common:filters')} onClick={this.toggleFiltersPanel} testHook="jo-filter" />
          {/* AngularJS Service, not migrated to redux yet */}
          {canEditCustomerJourney && (
            <BtnIcon
              icon="add"
              color="blue"
              onClick={() => createAndOpenEmptyJourney(document)}
              testHook="addNew"
              tooltip={t(`common.${journeyExceedMaxLimit ? 'addNewDisabled' : 'addNew'}`)}
              disabled={journeyExceedMaxLimit}
            />
          )}
        </Heading>
        <Notification kind="information">
          <p>{t('common.intro')}</p>
        </Notification>
        <JOList
          list={list}
          labels={labels}
          t={t}
          itemUrl={itemUrl}
          onDeleteClick={onDeleteClick}
          redirectToEditPage={redirectToEditPage}
          redirectToStatisticsPage={redirectToStatisticsPage}
          canEditCustomerJourney={canEditCustomerJourney}
          onDependantIconClick={onDependantIconClick}
          updateJourneyLabels={updateJourneyLabels}
          isFiltersPanelOpen={this.state.isFiltersPanelOpen}
          toggleFiltersPanel={this.toggleFiltersPanel}
          fetchJourneys={fetchJourneys}
          total={total}
          isFetching={isFetching}
          onDeleteLabel={onDeleteLabel}
        />
        <DetailContainer
          className="!top-10"
          onClose={onDependantsContainerClose}
          isVisible={dependantsContainerIsVisible}
          title={`${t('dependants:list.journeyHeader')} - ${selectedJourney?.name}`}
        >
          <OverlapJourneyList
            stateKey="PROFILES/DEPENDANTS/JOURNEY"
            list={selectedJourney?.dependantsInfo}
            t={t}
            hideModal={onDependantsContainerClose}
          />
        </DetailContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stateKey: JOURNEYS_STATE_KEY,
  data: getJourneys(state),
  labels: getAllLabels(state),
  isFetching: isFetchingJourneys(state),
  itemUrl: item => buildUrl(`customer/journeys/edit/${item}`),
  dependantsContainerIsVisible: isDependantsContainerVisible(state),
  selectedJourney: getSelectedJourney(state, getSelectedId(state)),
});

const mapDispatchToProps = dispatch => ({
  fetchJourneys: page => dispatch(fetchJourneys(page)),
  fetchJourneyLabels: () => dispatch(fetchJourneyLabels()),
  updateJourneyLabels: (labels, journeyId) => dispatch(updateJourneyLabels(journeyId, labels)),
  onDependantIconClick: journeyId => {
    dispatch(showDependantsContainer(journeyId));
  },
  onDeleteLabel: labelId => dispatch(deleteLabel(labelId)),
  onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
  onDeleteClick: (journey, t) => {
    dispatch(
      fromModals.showModal(fromModals.DELETE_MODAL, {
        title: t('journey:deleteModal.title'),
        message: t('journey:deleteModal.message', { title: journey.journeyName }),
        onConfirm: () => dispatch(deleteJourney(journey.journeyId)),
      }),
    );
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('journey'))(CustomerJourneyContainer);
