import moment from 'moment';
import { random } from 'lodash';
import { JourneyContents } from '~/customer/components/JourneyHeader/types';
import {
  GoalDailyStats,
  GoalStats,
  StepDailyStats,
  StepDailyStatsOrigin,
  TotalStepStats,
} from '~/customer/journeys/types';
import { JourneyVersion } from '../components/JOList/types';
import { StatsResponse } from '../pages/JourneyStatsView/types';

export const demoJourneyId = 'demoJourney';

const FakeVersionTrees: Record<string, any> = {
  '160335e4-c7bf-4068-83ae-f68137b47e1a': {
    nodes: {
      nodeId: '4c34068b-667f-478a-acb6-36e0af3e12b7',
      type: 'START_TRIGGER',
      label: 'Visit website',
      children: [
        {
          nodeId: '131aaf63-cf90-4292-bf14-0264166055ee',
          type: 'STEP',
          label: 'Promote offers via banners',
          children: [
            {
              nodeId: '5f304947-17df-45fc-b63a-2bf575fe2e8d',
              type: 'TRIGGER',
              label: 'Update product variable with interest',
              children: [
                {
                  nodeId: 'a4fd9e60-9349-4004-949e-7e942af040c1',
                  type: 'STEP',
                  label: 'Retarget via paid ADs',
                  children: [],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
          ],
          stats: {
            profilesInStep: 0,
          },
          hasVariableModifications: false,
        },
      ],
      stats: {
        profilesEntered: 0,
      },
    },
    goals: [
      {
        nodeId: 'aca4c96d-d29d-48e2-b30f-3b534f90db5a',
        label: 'Buy Jeans',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
      {
        nodeId: '6b23757a-ac7c-4919-b2d0-18b605fa0f35',
        label: 'Buy summer apparels',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
    ],
    exitConditions: [
      {
        nodeId: 'd0993f6a-8275-41ec-b243-72d2cbcdac6d',
        label: 'No engagement for 7 days',
        stats: {
          profilesExitedByCondition: 0,
          variantStats: [],
        },
      },
    ],
    profilesEnteredJourney: 0,
    totalProfilesReachedGoal: 0,
    profilesExited: {
      totalProfilesRemoved: 0,
      totalProfilesExpired: 0,
      totalProfilesMerged: 0,
      totalProfilesExitedByCondition: 0,
      variantStats: [],
    },
    profilesActive: 0,
  },
  '160335e4-c7bf-4068-83ae-f68137b47e2a': {
    nodes: {
      nodeId: '4c34068b-667f-478a-acb6-36e0af3e12b7',
      type: 'START_TRIGGER',
      label: 'Visit website',
      children: [
        {
          nodeId: '131aaf63-cf90-4292-bf14-0264166055ee',
          type: 'STEP',
          label: 'Promote offers via banners',
          children: [
            {
              nodeId: '5f304947-17df-45fc-b63a-2bf575fe2e8d',
              type: 'TRIGGER',
              label: 'Added to cart but abandoned',
              children: [
                {
                  nodeId: 'a4fd9e60-9349-4004-949e-7e942af040c1',
                  type: 'STEP',
                  label: 'Retarget via paid ADs',
                  children: [],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
            {
              nodeId: 'ba5fac59-e4b8-47ee-acff-707f755067a6',
              type: 'TRIGGER',
              label: 'Searching for jeans',
              children: [
                {
                  nodeId: 'c7f66390-b180-4439-aef3-a72e074b98a6',
                  type: 'STEP',
                  label: 'Retargeting on paid media with related offers',
                  children: [],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
          ],
          stats: {
            profilesInStep: 0,
          },
          hasVariableModifications: false,
        },
      ],
      stats: {
        profilesEntered: 0,
      },
    },
    goals: [
      {
        nodeId: 'aca4c96d-d29d-48e2-b30f-3b534f90db5a',
        label: 'Buy Jeans',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
      {
        nodeId: '6b23757a-ac7c-4919-b2d0-18b605fa0f35',
        label: 'Buy summer apparels',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
    ],
    exitConditions: [
      {
        nodeId: 'd0993f6a-8275-41ec-b243-72d2cbcdac6d',
        label: 'No engagement for 7 days',
        stats: {
          profilesExitedByCondition: 0,
          variantStats: [],
        },
      },
    ],
    profilesEnteredJourney: 0,
    totalProfilesReachedGoal: 0,
    profilesExited: {
      totalProfilesRemoved: 0,
      totalProfilesExpired: 0,
      totalProfilesMerged: 0,
      totalProfilesExitedByCondition: 0,
      variantStats: [],
    },
    profilesActive: 0,
  },
  '160335e4-c7bf-4068-83ae-f68137b47e3a': {
    nodes: {
      nodeId: '4c34068b-667f-478a-acb6-36e0af3e12b7',
      type: 'START_TRIGGER',
      label: 'Visit website',
      children: [
        {
          nodeId: '131aaf63-cf90-4292-bf14-0264166055ee',
          type: 'STEP',
          label: 'Promote offers via banners',
          children: [
            {
              nodeId: '5f304947-17df-45fc-b63a-2bf575fe2e8d',
              type: 'TRIGGER',
              label: 'Added to cart but abandoned',
              children: [
                {
                  nodeId: 'a4fd9e60-9349-4004-949e-7e942af040c1',
                  type: 'STEP',
                  label: 'Retarget via paid ADs',
                  hasTransition: true,
                  children: [],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
            {
              nodeId: 'ba5fac59-e4b8-47ee-acff-707f755067a6',
              type: 'TRIGGER',
              label: 'Searching for jeans',
              children: [
                {
                  nodeId: 'c7f66390-b180-4439-aef3-a72e074b98a6',
                  type: 'STEP',
                  label: 'Retargeting on paid media with related offers',
                  children: [
                    {
                      nodeId: 'ca17783c-fb18-4e31-8fd6-f130841ead8c',
                      type: 'TRIGGER',
                      label: 'No engagement for 7 days',
                      children: [
                        {
                          nodeId: 'df52ced5-d549-482d-bea5-bf509c73f166',
                          type: 'STEP',
                          label: 'Email campaign with limited time offer',
                          children: [
                            {
                              nodeId: 'aae9f964-3804-4a4e-b720-382f8b2b9040',
                              type: 'TRIGGER',
                              label: 'No engagement',
                              hasTransition: true,
                              children: [],
                              transitionNodeId: 'a4fd9e60-9349-4004-949e-7e942af040c1',
                              stats: {
                                profilesEntered: 0,
                              },
                            },
                          ],
                          stats: {
                            profilesInStep: 0,
                          },
                          hasVariableModifications: false,
                        },
                      ],
                      stats: {
                        profilesEntered: 0,
                      },
                    },
                  ],
                  stats: {
                    profilesInStep: 0,
                  },
                  hasVariableModifications: false,
                },
              ],
              stats: {
                profilesEntered: 0,
              },
            },
          ],
          stats: {
            profilesInStep: 0,
          },
          hasVariableModifications: false,
        },
      ],
      stats: {
        profilesEntered: 0,
      },
    },
    goals: [
      {
        nodeId: 'aca4c96d-d29d-48e2-b30f-3b534f90db5a',
        label: 'Buy Jeans',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
      {
        nodeId: '6b23757a-ac7c-4919-b2d0-18b605fa0f35',
        label: 'Buy summer apparels',
        stats: {
          profilesReachedGoal: 0,
          variantStats: [],
        },
      },
    ],
    exitConditions: [
      {
        nodeId: '1cf1f06b-c505-47d6-82ad-31041555d208',
        label: 'Exit from No engagement',
        stats: {
          profilesExitedByCondition: 0,
          variantStats: [],
        },
      },
      {
        nodeId: 'd0993f6a-8275-41ec-b243-72d2cbcdac6d',
        label: 'No engagement for 7 days',
        stats: {
          profilesExitedByCondition: 0,
          variantStats: [],
        },
      },
    ],
    profilesEnteredJourney: 0,
    totalProfilesReachedGoal: 0,
    profilesExited: {
      totalProfilesRemoved: 0,
      totalProfilesExpired: 0,
      totalProfilesMerged: 0,
      totalProfilesExitedByCondition: 0,
      variantStats: [],
    },
    profilesActive: 0,
  },
};

export const demoJourneyListData = {
  activeProfiles: 0,
  goalsReached: 0,
  historicProcessing: { state: { name: 'SUCCESSFUL', label: 'Successful' }, triggeredOn: 1695904136416 },
  isUsedInJourneys: false,
  journeyId: demoJourneyId,
  labels: [],
  lastModified: 1695993887415,
  name: 'Demo Journey',
  profilesEntered: 0,
  profilesExited: 0,
  state: { name: 'PUBLISHED', label: 'Published' },
};

export const demoJourneydetails = {
  journeyId: '68676371-3d8c-405f-b37a-fc0a1ba1d967',
  name: 'Demo Journey',
  expiryTime: '90d',
  state: {
    name: 'PUBLISHED',
    label: 'Published',
  },
  description: '',
  lastModified: 1695898164074,
  lastModifiedBy: '2fe8db4a-086f-4b10-a959-81d01f92fc18',
  historicProcessing: {
    eligible: false,
  },
} as any;

const makeId = (...params: string[]) => `fake:${params.join('_')}`;

export const getIsFakeMode = () => localStorage.getItem('useJoDemoMode') === 'true';

export const makeFake = (fn: any) => (data: any) => {
  const isFakeMode = getIsFakeMode();

  if (isFakeMode) {
    return fn(data);
  }
  return data;
};

export const getFakeStepStats = (journeyId: string, stepId: string, origin: StepDailyStatsOrigin): StepDailyStats[] => {
  const id = makeId(journeyId, stepId, origin);

  try {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    let data: StepDailyStats[] = [];

    if (dataFromLocalStorage) {
      data = dataFromLocalStorage;
    } else {
      for (let i = 90; i > 0; i -= 1) {
        const timestamp = moment().subtract(i, 'days').valueOf();
        data.push({
          key: {
            dayTimestamp: timestamp,
            journeyId,
            origin,
            nodeId: stepId,
            timestamp,
          },
          profilesAdded: random(0, 30000),
          profilesMovedToNextStep: random(0, 15000),
          profilesReachedGoal: random(0, 10000),
          profilesReachedExit: random(0, 1000),
          profilesRemoved: random(0, 1000),
          profilesMerged: 0,
          profilesExitedByCondition: 0,
        });
      }
      localStorage.setItem(id, JSON.stringify(data));
    }

    return data;
  } catch (error) {
    return [];
  }
};

export const getFakeConnectorStats = (partnerId: string, sourceId: string, _startDate: number, _endDate: number) => {
  const id = makeId(partnerId, sourceId);
  const makeResponse = (stats: []) => ({
    data: {
      stats,
    },
  });

  try {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    let data = [];

    if (dataFromLocalStorage) {
      data = dataFromLocalStorage;
    } else {
      for (let i = 90; i > 0; i -= 1) {
        const timestamp = moment().subtract(i, 'days').valueOf();
        data.push({
          day: timestamp,
          statusCounts: [
            { status: 'ADD', count: random(0, 30000) },
            { status: 'REMOVE', count: random(0, 30000) },
          ],
        });
      }
      localStorage.setItem(id, JSON.stringify(data));
    }

    return makeResponse(data.slice(-days));
  } catch (error) {
    return makeResponse([]);
  }
};

export const getFakeTriggerData = (
  triggerId: string,
  _startDate: number,
  _endDate: number,
  originalStatsData: GoalDailyStats,
): GoalDailyStats => {
  const id = makeId(`triggerDailyStats_${triggerId}`);
  const makeResponse = (stats: []) => ({
    nodeId: originalStatsData.nodeId,
    profilesEnteredJourney: random(0, 30000),
    stats,
  });

  try {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    let data = [];
    const { stats } = originalStatsData;

    if (dataFromLocalStorage) {
      data = dataFromLocalStorage;
    } else {
      data = stats.map(stat => ({
        ...stat,
        profilesReachedGoal: random(0, 30000),
        variantStats: stat.variantStats.map(vs => ({
          ...vs,
          profilesEnteredVariant: random(0, 30000),
          profilesReached: random(0, 30000),
        })),
      }));
    }

    localStorage.setItem(id, JSON.stringify(data));
    return makeResponse(data);
  } catch (error) {
    return originalStatsData;
  }
};

export const getFakeGoalStats = (goalId: string, originalGoalStats: GoalStats): GoalStats => {
  const id = makeId(`goalStats_${goalId}`);

  try {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    let data = originalGoalStats;

    if (dataFromLocalStorage) {
      data = dataFromLocalStorage;
    } else {
      data = {
        ...originalGoalStats,
        goalAnalysis: originalGoalStats.goalAnalysis.map(ga => ({
          ...ga,
          profilesEntered: random(40000, 50000),
          profilesReachedGoal: random(0, 40000),
        })),
        variantAnalysis: originalGoalStats.variantAnalysis.map(va => ({
          ...va,
          profilesEntered: random(40000, 50000),
          profilesReachedGoal: random(0, 40000),
        })),
      };

      data.totalProfilesReachedGoal = data.goalAnalysis.reduce(
        (acc: any, val: any) => acc + val.profilesReachedGoal,
        0,
      );
      localStorage.setItem(id, JSON.stringify(data));
    }

    return data;
  } catch (error) {
    return originalGoalStats;
  }
};

export const getFakeTrees = (journeyId: string, versionId: string): JourneyContents => FakeVersionTrees[versionId];

export const getFakeVersions = (journeyId: string): JourneyVersion[] => [
  {
    versionId: '160335e4-c7bf-4068-83ae-f68137b47e3a',
    journeyId,
    name: 'Retargeting users',
    description: 'Retargeting by transitioning users with constraints',
    lastModifiedBy: 'demo-user@relay2.com',
    lastModified: 1686731854207,
    isLatest: true,
  },
  {
    versionId: '160335e4-c7bf-4068-83ae-f68137b47e2a',
    journeyId,
    name: 'Cluster profile scenarios',
    description: 'October enhanced campaign with experiments',
    lastModifiedBy: 'user-marketing@relay2.com',
    lastModified: 1686297468756,
    isLatest: false,
  },
  {
    versionId: '160335e4-c7bf-4068-83ae-f68137b47e1a',
    journeyId,
    name: 'October user campaign',
    description: "Assign product interest to user profiles and target via banners & AD's",
    lastModifiedBy: 'demo-user@relay2.com',
    lastModified: 1683293584196,
    isLatest: false,
  },
];

export const getFakeTotalStatsForStep = (journeyId: string, stepId: string): TotalStepStats => ({
  journeyId,
  nodeId: stepId,
  totalProfilesAdded: random(0, 30000),
  totalProfilesMovedToNextStep: random(0, 15000),
  totalProfilesReachedGoal: random(0, 2000),
  totalProfilesReachedExit: random(0, 3000),
  totalProfilesRemoved: random(0, 10000),
  totalProfilesMerged: random(0, 5000),
  totalProfilesActive: random(0, 5000),
});

export const getFakeJourneyStats = (originalStats: StatsResponse): StatsResponse => {
  try {
    let data = originalStats;

    data = {
      ...originalStats,
      experimentAnalysis: originalStats.experimentAnalysis.map(ea => ({
        ...ea,
        exitConditions: ea.exitConditions.map(ec => ({ ...ec, profilesExitedByCondition: random(0, 10000) })),
        goals: ea.goals.map(goal => ({ ...goal, profilesReachedGoal: random(0, 10000) })),
        profilesEntered: random(50000, 100000),
        profilesEnteredHp: random(0, 10000),
        profilesExited: random(0, 10000),
        profilesExpired: random(0, 10000),
        profilesMerged: random(0, 10000),
        profilesRemoved: random(0, 10000),
      })),
      stepAnalysis: originalStats.stepAnalysis.map(sa => ({
        ...sa,
        exitConditions: sa.exitConditions.map(ec => ({ ...ec, profilesExitedByCondition: random(0, 10000) })),
        goals: sa.goals.map(goal => ({ ...goal, profilesReachedGoal: random(0, 10000) })),
        profilesActive: random(0, 10000),
        profilesEntered: random(50000, 100000),
        profilesEnteredHp: random(0, 10000),
        profilesExited: random(0, 10000),
        profilesExpired: random(0, 10000),
        profilesMerged: random(0, 10000),
        profilesMovedToNextSteps: random(0, 10000),
        profilesRemoved: random(0, 10000),
      })),
    };

    data.journeyAnalysis = {
      ...data.journeyAnalysis,
      profilesActive: data.stepAnalysis.reduce((acc, val) => acc + val.profilesActive, 0),
      profilesEntered: random(700000, 900000),
      profilesExited: data.stepAnalysis.reduce(
        (acc, val) =>
          acc +
          val.exitConditions.reduce((ecAcc, ec) => ecAcc + ec.profilesExitedByCondition, 0) +
          val.profilesExpired +
          val.profilesExited,
        0,
      ),
      profilesReachedGoals: data.stepAnalysis.reduce(
        (acc, val) => acc + val.goals.reduce((gAcc, goal) => gAcc + goal.profilesReachedGoal, 0),
        0,
      ),
    };

    return data;
  } catch (error) {
    return originalStats;
  }
};

export const addFakeDataForJourney = (journeyId: string, originalJourney: JourneyContents): JourneyContents => {
  const getTotalFromVariantStats = (stats: [], key: string) => stats.reduce((acc: any, val: any) => acc + val[key], 0);
  const getFromLocalStorageOrGenerateRandom = (id: string, from: number, to: number) => {
    try {
      const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');
      if (dataFromLocalStorage) {
        return dataFromLocalStorage;
      }
      const randomValue = random(from, to);
      localStorage.setItem(id, String(randomValue));
      return randomValue;
    } catch (err) {
      return 0;
    }
  };

  try {
    // const dataFromLocalStorage = JSON.parse(localStorage.getItem(id) || 'false');

    const data = { ...originalJourney };
    data.goals = data.goals.map((item: any) => {
      const newVariantStats = item.stats.variantStats.map((variant: any) => ({
        ...variant,
        profilesEnteredVariant: getFromLocalStorageOrGenerateRandom(
          makeId(journeyId, variant.experimentVariantId, 'profilesEnteredVariant'),
          20000,
          100000,
        ),
        profilesReached: getFromLocalStorageOrGenerateRandom(
          makeId(journeyId, variant.experimentVariantId, 'profilesReached'),
          0,
          20000,
        ),
      }));
      return {
        ...item,
        stats: {
          ...item.stats,
          profilesReachedGoal: getTotalFromVariantStats(newVariantStats, 'profilesReached') || random(10000, 20000),
          variantStats: newVariantStats,
        },
      };
    });

    data.exitConditions = data.exitConditions.map((item: any) => {
      const newVariantStats = item.stats.variantStats.map((variant: any) => ({
        ...variant,
        profilesEnteredVariant: getFromLocalStorageOrGenerateRandom(
          makeId(journeyId, variant.experimentVariantId, 'exit_profilesEnteredVariant'),
          20000,
          100000,
        ),
        profilesReached: getFromLocalStorageOrGenerateRandom(
          makeId(journeyId, variant.experimentVariantId, 'exit_profilesReached'),
          0,
          20000,
        ),
      }));
      return {
        ...item,
        stats: {
          ...item.stats,
          profilesExitedByCondition:
            getTotalFromVariantStats(newVariantStats, 'profilesReached') || random(10000, 20000),
          variantStats: newVariantStats,
        },
      };
    });

    const traverseNode = (node: any) => {
      if (node.type === 'STEP') {
        node.stats = {
          profilesInStep: getFromLocalStorageOrGenerateRandom(makeId(journeyId, node.nodeId, 'tree'), 0, 100000),
        };
      }
      if (node.type === 'TRIGGER') {
        node.stats = {
          profilesEntered: getFromLocalStorageOrGenerateRandom(makeId(journeyId, node.nodeId, 'tree'), 0, 100000),
        };
      }
      if (node.children) {
        node.children.forEach(traverseNode);
      }
      return node;
    };

    traverseNode(data.nodes);

    const newExpiredVariantStats = data.profilesExited.variantStats.map((variant: any) => ({
      ...variant,
      profilesRemoved: getFromLocalStorageOrGenerateRandom(
        makeId(journeyId, variant.experimentVariantId, 'profilesRemoved'),
        0,
        20000,
      ),
      profilesExpired: getFromLocalStorageOrGenerateRandom(
        makeId(journeyId, variant.experimentVariantId, 'profilesExpired'),
        0,
        20000,
      ),
      profilesMerged: getFromLocalStorageOrGenerateRandom(
        makeId(journeyId, variant.experimentVariantId, 'profilesMerged'),
        0,
        20000,
      ),
      profilesExitedByCondition: getFromLocalStorageOrGenerateRandom(
        makeId(journeyId, variant.experimentVariantId, 'profilesExitedByCondition'),
        0,
        20000,
      ),
    }));

    data.profilesActive = random(2000, 20000);
    data.profilesEnteredJourney = random(200000, 400000);
    data.totalProfilesReachedGoal = random(10000, 20000);
    data.profilesExited = {
      ...data.profilesExited,
      variantStats: newExpiredVariantStats,
      totalProfilesRemoved: getTotalFromVariantStats(newExpiredVariantStats, 'profilesRemoved'),
      totalProfilesExpired: getTotalFromVariantStats(newExpiredVariantStats, 'profilesExpired'),
      totalProfilesMerged: getTotalFromVariantStats(newExpiredVariantStats, 'profilesMerged'),
      totalProfilesExitedByCondition: getTotalFromVariantStats(newExpiredVariantStats, 'profilesExitedByCondition'),
    };

    return data;
  } catch (error) {
    return originalJourney;
  }
};
