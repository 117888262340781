import React, { ReactElement } from 'react';
import i18n from '~/i18n';
import Btn from '../Btn';
import BtnIcon from '../BtnIcon';
import Icons from '../Icons';
import Spin from '../Spin';

type DeletePopupProps = {
  hidePopup: () => void;
  onClick?: () => void;
  title: string;
  children: ReactElement;
  isLoading?: boolean;
};

function DeletePopup({ hidePopup, onClick, title, children, isLoading }: DeletePopupProps): ReactElement {
  return (
    <div className="h-modal fixed left-0 right-0 top-0 z-50 flex items-center justify-center  overflow-y-auto overflow-x-hidden bg-gray-400/50 md:inset-0 md:h-full">
      <div className="relative h-full w-full max-w-md p-4 md:h-auto">
        <div className="relative flex flex-col gap-4 rounded bg-white p-4 shadow dark:bg-gray-700">
          <div className="flex items-center justify-between gap-2 border-b border-gray-200 pb-4">
            <Icons icon="delete" className="h-6 w-6 text-red-600" />
            <p className="text-md t-deleteTitle flex flex-1 items-center font-medium text-red-600">{title}</p>
            <BtnIcon
              icon="close"
              onClick={hidePopup}
              tooltip={i18n.t('common:close')}
              className="h-6 w-6"
              testHook="deleteClose"
            />
          </div>
          <div className="t-deleteMessage text-gray-400">{children}</div>
          {onClick && (
            <Btn color="red" onClick={onClick} className="w-full" testHook="deleteConfirm" disabled={isLoading}>
              {isLoading ? <Spin /> : <>{i18n.t('common:delete')}</>}
            </Btn>
          )}
        </div>
      </div>
    </div>
  );
}

export default DeletePopup;
