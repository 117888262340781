import React from 'react';
import cx from 'classnames';

interface FieldWrapperProps {
  children: React.ReactNode;
  className?: string;
  labelClassName?: string;
  label?: React.ReactNode;
  htmlFor?: string;
  errorText?: string;
  hintText?: string;
  horizontal?: boolean;
  suffix?: React.ReactNode;
  name?: string;
  testHook?: string;
  fieldType?: 'inline';
  excludeErrorOrHint?: boolean;
  isEditMode?: boolean;
}

interface ErrorOrHintProps {
  errorText?: string;
  hintText?: string;
}

const ErrorOrHint = ({ errorText, hintText }: ErrorOrHintProps) => {
  const className = cx('FieldWrapper-errorOrHintContainer', {
    'FieldWrapper-errorOrHintContainer--error': errorText,
    'FieldWrapper-errorOrHintContainer--hint': !errorText && hintText,
  });

  return <div className={className}>{errorText || hintText}</div>;
};

const FieldWrapper = ({
  children,
  className,
  labelClassName,
  label,
  htmlFor,
  errorText,
  hintText,
  horizontal,
  suffix,
  name,
  testHook,
  fieldType,
  excludeErrorOrHint = false,
  isEditMode = false,
}: FieldWrapperProps) => (
  <div
    className={cx(
      'FieldWrapper',
      {
        'FieldWrapper--horizontal': horizontal,
        'FieldWrapper--inline': fieldType === 'inline',
        [`t-${testHook || name}Field`]: testHook || name,
      },
      className,
    )}
  >
    {label && !isEditMode && (
      <label className={cx('FieldWrapper-label', labelClassName)} htmlFor={htmlFor}>
        {label}
      </label>
    )}

    <div className="FieldWrapper-fieldWrap">
      {children}
      {!excludeErrorOrHint && <ErrorOrHint errorText={errorText} hintText={hintText} />}
    </div>
    {suffix}
  </div>
);

export default FieldWrapper;
