import { RulesTypes } from '~/common/modules/rules/types';
import { getHistoricProcessingModes } from '~/customer/journeys/dataService';
import { fetchTrigger } from '~/customer/triggers/dataService';
import i18n from '~/i18n';
import { HpOptionIds, HpOptions } from './constants';
import { HpOptionsType } from './types';

const handleAllOptionsSelected = (selectedOptions: HpOptionsType[]): string => {
  const hasBackfillingSelected = selectedOptions.find(({ id }) => id === HpOptionIds.BACKFILLING);
  const hasGoalsSelected = selectedOptions.find(({ id }) => id === HpOptionIds.REEVALUATE_GOALS);
  const hasExitsSelected = selectedOptions.find(({ id }) => id === HpOptionIds.REEVALUATE_EXIT_CONDITIONS);
  const backfillingHasOnlyCustomerFacts = hasBackfillingSelected?.hasOnlyCustomerFacts;
  const goalsHaveOnlyCustomerFacts = hasGoalsSelected?.hasOnlyCustomerFacts;
  const exitsHaveOnlyCustomerFacts = hasExitsSelected?.hasOnlyCustomerFacts;
  const selectedModes = selectedOptions
    .filter(({ hasOnlyCustomerFacts }) => hasOnlyCustomerFacts)
    .map(({ mode }) => mode)
    .join('/');

  if (backfillingHasOnlyCustomerFacts && goalsHaveOnlyCustomerFacts && exitsHaveOnlyCustomerFacts)
    return i18n.t('orchestration:historicProcessing.reprocessingModes.message3');
  else if (!backfillingHasOnlyCustomerFacts && !goalsHaveOnlyCustomerFacts && !exitsHaveOnlyCustomerFacts)
    return i18n.t('orchestration:historicProcessing.reprocessingModes.message4');
  return i18n.t('orchestration:historicProcessing.reprocessingModes.message5', { modes: selectedModes });
};

const handleTwoOptionsSelected = (selectedOptions: HpOptionsType[]): string => {
  const jobsHavingCustomerFacts = selectedOptions.filter(({ hasOnlyCustomerFacts }) => hasOnlyCustomerFacts);
  const hasBackfillingSelected = !!selectedOptions.find(({ id }) => id === HpOptionIds.BACKFILLING);
  const selectedModes = selectedOptions
    .filter(({ hasOnlyCustomerFacts }) => hasOnlyCustomerFacts)
    .map(({ mode }) => mode)
    .join('/');

  if (jobsHavingCustomerFacts.length === 2)
    return i18n.t('orchestration:historicProcessing.reprocessingModes.message6', { modes: selectedModes });
  else if (jobsHavingCustomerFacts.length === 1)
    return i18n.t('orchestration:historicProcessing.reprocessingModes.message5', { modes: selectedModes });
  return i18n.t(
    `orchestration:historicProcessing.reprocessingModes.${hasBackfillingSelected ? 'message4' : 'message7'}`,
  );
};

const handleSingleOptionSelected = (selectedOptions: HpOptionsType[]): string => {
  const { mode, hasOnlyCustomerFacts, id } = selectedOptions[0];

  if (id === HpOptionIds.BACKFILLING) {
    if (hasOnlyCustomerFacts) return i18n.t('orchestration:historicProcessing.reprocessingModes.message1');
    return i18n.t('orchestration:historicProcessing.reprocessingModes.message2');
  } else if (hasOnlyCustomerFacts)
    return i18n.t('orchestration:historicProcessing.reprocessingModes.message6', { modes: mode });
  return i18n.t('orchestration:historicProcessing.reprocessingModes.message7');
};

export const getModelHeader = (selectedOptions: HpOptionsType[]): string => {
  switch (selectedOptions.length) {
    case 3:
      return handleAllOptionsSelected(selectedOptions);
    case 2:
      return handleTwoOptionsSelected(selectedOptions);
    case 1:
      return handleSingleOptionSelected(selectedOptions);
    default:
      return '';
  }
};

export const checkForCustomerFacts = async (startTriggerId: string): Promise<boolean> => {
  const { criteria } = await fetchTrigger(startTriggerId);
  const ruleClassNames: string[] = [];
  criteria.forEach(({ rules }: any) => rules.forEach(({ clazz }: { clazz: string }) => ruleClassNames.push(clazz)));
  const nonDuplicateFacts = [...new Set(ruleClassNames)];

  const filtered = nonDuplicateFacts.filter(
    factType =>
      factType !== RulesTypes.JourneyOverlap && // Journey Overlap has no influence on whether to show HP calendar or not
      factType !== RulesTypes.ExternalFact &&
      factType !== RulesTypes.AiFact,
  );
  return !filtered.length;
};

export const mapReprocessingModes = async (journeyId: string): Promise<HpOptionsType[]> => {
  const { reprocessingModes } = await getHistoricProcessingModes(journeyId);
  return HpOptions.map(option => ({
    ...option,
    ...reprocessingModes.find(({ reprocessingMode }) => reprocessingMode.name === option.id),
  }));
};
