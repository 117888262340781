import React from 'react';
import i18n from '~/i18n';
import Notification from '~/components/src/Notification';
import Icons from '~/components/src/Icons';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import ActionButtons from '~/components/src/ActionButtons';
import { publishJourney } from '~/workflows/dataService';
import { showSuccess } from '~/notificationCenter';
import { WORKFLOW_TYPE } from '~/workflows/constants';

type PublishModalProps = {
  journeyId: string;
  journeyName: string;
  isHpRunningOrScheduled: boolean;
  isLatestVersion: boolean;
  refreshJourney: () => void;
  hideModal: () => void;
  workflowType?: string;
};

const VersionInput = () => {
  const setVersionName = (values: any) => {
    console.log({ values });
  };

  const initialValues = { versionName: '' };

  const validationSchema = Yup.object().shape({
    versionName: Yup.string().trim().required(i18n.t('validation:validation.required')),
  });

  return (
    <>
      <Formik
        onSubmit={setVersionName}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ handleSubmit, errors }) => (
          <form className="mt-8 flex items-center gap-2" onSubmit={handleSubmit}>
            <Field
              className="w-full"
              name="versionName"
              id="versionName"
              as={FormikInputField}
              placeholder={i18n.t('dataFeeds:form.placeholder')}
              label={i18n.t('workflow:orchestration.version.name')}
              errorText={errors?.versionName}
              hintText={i18n.t('workflow:orchestration.version.nameHint')}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

const PublishModal = ({
  journeyId,
  journeyName,
  isHpRunningOrScheduled,
  isLatestVersion,
  refreshJourney,
  hideModal,
  workflowType = WORKFLOW_TYPE.Journey,
}: PublishModalProps) => {
  const isDemo = localStorage.getItem('useDemo') === 'true';
  const type = workflowType.toLowerCase();
  return (
    <div className="w-[550px]">
      <p className="t-publishMessage text-xl text-gray-600">
        {i18n.t('workflow:orchestration.publish.message', { workflowType: type })}
      </p>
      <p className="t-journeyName my-2 rounded bg-blue-50 p-2 text-xl text-blue-500">
        <Icons icon="journey" className="mr-2 h-6 w-6" /> {journeyName}
      </p>
      <p className="t-publishInfo text-sm text-gray-400">
        {i18n.t('workflow:orchestration.publish.info', { workflowType: type })}
      </p>
      {isHpRunningOrScheduled && (
        <Notification kind="information" className="mt-4" testHook="hpIsRunningMessage">
          {i18n.t('workflow:orchestration.publish.hpIsRunning', { workflowType: type })}
        </Notification>
      )}
      {isDemo && (
        <>
          <VersionInput />
          {!isLatestVersion && (
            <Notification kind="warning" className="mt-4" testHook="oldVersionMessage">
              {i18n.t('workflow:orchestration.version.publishPreviousVersionMessage', { workflowType: type })}
            </Notification>
          )}
        </>
      )}
      <ActionButtons
        onDecline={() => hideModal()}
        confirmText={i18n.t('workflow:orchestration.publish.publish')}
        testHook="publishJourney"
        onConfirm={() => {
          publishJourney(journeyId).then(() => {
            refreshJourney();
            showSuccess({
              header: i18n.t('workflow:orchestration.publish.published', { name: journeyName, workflowType: type }),
            });
            hideModal();
          });
        }}
      />
    </div>
  );
};

export default PublishModal;
