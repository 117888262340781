import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { useAPI } from '~/common';
import { DateRangePicker, StackedChart } from '~/components';
import { Panel } from '~/components/src/Containers';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import { Tab, TabList, Tabs } from '~/components/src/Tabs';
import i18n from '~/i18n';
import { NodeTypes } from '~/workflows/constants';
import { ORIGIN_TYPES, VARIANT_STATS_TABS } from '~/workflows/form/Statistics/constants';
import { getTriggerDailyStats, mapTriggerStats } from '../utils';

type TriggerStatsChartProps = {
  journeyId: string;
  triggerId: string;
  type: keyof typeof NodeTypes;
  isGoalOrExit: string;
};

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const TriggerStatsChart = ({ journeyId, triggerId, type, isGoalOrExit }: TriggerStatsChartProps) => {
  const [filter, setFilter] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const origin = selectedTabIndex ? ORIGIN_TYPES.HISTORIC_PROCESSING : undefined;
  const { startDate, endDate } = filter;

  const { data: triggerDailyStats, isLoading: isTriggerStatsLoading } = useAPI(
    () => getTriggerDailyStats(journeyId, triggerId, startDate, endDate, type, origin),
    [journeyId, triggerId, startDate, endDate, origin],
  );

  const handleDatesChange = ({ startDate, endDate }: { startDate: Moment; endDate: Moment }) => {
    setFilter({ startDate, endDate });
  };

  return (
    <div className="py-2">
      <h2 className="!mt-4 text-xl text-gray-600">
        {i18n.t(`workflow:orchestration.triggerStats.${isGoalOrExit}StatisticSectionName`)}
      </h2>

      <div className="flex flex-row-reverse items-baseline justify-between">
        <DateRangePicker
          className="StepStat-statisticsFrequency"
          startDate={startDate}
          endDate={endDate}
          handleDatesChange={handleDatesChange}
        />
        <Tabs className="!pt-2" selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
          <TabList>
            {VARIANT_STATS_TABS.map(origin => (
              <Tab key={origin.name} testHook={`${origin.name}-tab`}>
                <span className="flex items-center gap-2">
                  {origin.label}
                  {origin?.description && (
                    <Icons icon="info" tooltip={origin.description} className="h-4 w-4 text-gray-500" />
                  )}
                </span>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </div>

      <Panel className="p-6">
        {isTriggerStatsLoading ? (
          <Spin />
        ) : (
          <div>
            <StackedChart
              hideGenealInfo
              reversedYStacks={false}
              data={mapTriggerStats(triggerDailyStats?.stats || [], type === NodeTypes.GOAL)}
              height={300}
              className="t-triggerStatsChart"
            />
          </div>
        )}
      </Panel>
    </div>
  );
};

export default TriggerStatsChart;
