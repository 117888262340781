import { get } from 'lodash';
import { getAdvertisers } from '~/profiles/connectors/form/partners/doubleClick/doubleClickSelector';
import constants from './constants';

export const selectEventConnectors = state => {
  const isBeta = localStorage.getItem('useBeta') === 'true';
  const eventConnectorsById = get(state, 'profiles.eventConnectors.byId', {});
  let eventConnectors = Object.values(eventConnectorsById);
  if (!isBeta) {
    eventConnectors = eventConnectors?.filter(type => type?.destinationPartnerType !== 'DIY');
  }
  return eventConnectors;
};

export const selectEventConnectorByPartnerId = (state, partnerId) => {
  const eventConnectorList = selectEventConnectors(state);
  const eventConnector = eventConnectorList.find(eventConnector => eventConnector.destinationPartnerId === partnerId);

  return eventConnector;
};

export const selectEventConnectorById = (state, eventConnectorId) =>
  get(state, `profiles.eventConnectors.byId.${eventConnectorId}`, null);

const getPartnerTypes = state => get(state, 'profiles.connectorsForm.partnerTypes');

const getPartner = state => get(state, 'profiles.connectorsForm.partner');

const fetchingPartnerTypes = state => get(state, 'profiles.connectorsForm.promise.fetchingPartnerTypes');

export const selectEventConnectorId = state => {
  const partner = get(state, 'profiles.connectorsForm.partner');
  const eventConnector = selectEventConnectorByPartnerId(state, partner.partnerId);

  return eventConnector?.eventConnectorId;
};

const selectFormValues = state => {
  const partner = getPartner(state);
  const connectorId = partner && partner.partnerType;
  return get(state, `form.connectorForm.values.${connectorId}`) || {};
};

const getConnectorId = ownProps => ownProps?.params.connectorId;

const setConnectorInitialValuesForEdit = (partner, value, advertisers) => {
  switch (partner.partnerType) {
    case constants.partnerTypes.DOUBLE_CLICK:
      return {
        ...value,
        processConsent: !!partner?.configuration?.processConsent,
        userIdType: partner.configuration.userIdType.name,
        advertiserId: partner.configuration.advertiserId,
        inviteType:
          advertisers.length > 0 &&
          advertisers.find(advertiser => advertiser.advertiserId === partner.configuration.advertiserId),
        partnerNumber: partner.partnerNumber,
      };
    case constants.partnerTypes.DOUBLE_CLICK_OFFLINE:
      return {
        ...value,
        userIdType: partner.configuration.userIdType?.name ?? '',
        advertiserId: partner.configuration.advertiserId,
        userProfileId: partner.configuration.userProfileId,
      };
    case constants.partnerTypes.ADFORM: {
      const formValues = {
        ...value,
        authenticationType: partner.configuration.authenticationType.name,
        dataProviderId: partner.configuration.dataProviderId,
        payloadType: partner.configuration.payloadType,
        partnerNumber: partner.partnerNumber,
      };

      if (partner.configuration.authenticationType.name === constants.STANDARD) {
        formValues.username = partner.configuration.username;
        formValues.password = partner.configuration.password;
      } else if (partner.configuration.authenticationType.name === constants.OAUTH) {
        formValues.clientId = partner.configuration.clientId;
      }

      return formValues;
    }

    case constants.partnerTypes.ORACLE_RESPONSYS:
      return {
        ...value,
        profileListName: partner.configuration.profileListName,
        authenticationEndpoint: partner.configuration.authenticationEndpoint,
        payloadType: partner.configuration.payloadType,
        partnerNumber: partner.partnerNumber,
      };
    case constants.partnerTypes.ADWORDS:
      return {
        ...value,
        clientCustomerId: partner.configuration.clientCustomerId,
        payloadType: partner.configuration.payloadType,
      };
    case constants.partnerTypes.GOOGLE_ADS:
      return {
        ...value,
        clientCustomerId: partner.configuration.clientCustomerId,
        managerCustomerId: partner.configuration.managerCustomerId,
        payloadType: partner.configuration.payloadType,
        consent: partner.configuration.consent,
      };
    case constants.partnerTypes.YAHOO:
      return {
        ...value,
        mdmId: partner.configuration.mdmId,
        advertiser:
          advertisers.length > 0 &&
          advertisers.find(advertiser => advertiser.mdmId === partner.configuration.mdmId)?.mdmId,
        partnerNumber: partner.partnerNumber,
        payloadType: partner.configuration.payloadType,
      };

    case constants.partnerTypes.LINKEDIN:
      return {
        ...value,
        accountId: partner.configuration.accountId,
        accountName: partner.configuration.accountName,
        expiryTimeInMillis: partner.configuration.expiryTimeInMillis,
        partnerNumber: partner.partnerNumber,
        payloadType: partner.configuration.payloadType,
      };
    case constants.partnerTypes.CLANG:
      return {
        ...value,
        endpoint: partner.configuration.endpoint,
        identifierType: partner.configuration.identifierType,
        partnerNumber: partner.partnerNumber,
        advancedSettings: !!partner.partnerNumber,
      };
    case constants.partnerTypes.CLANG_BATCH:
      return {
        ...value,
        clangUrl: partner.configuration.clangUrl,
        identifierType: partner.configuration.identifierType,
        partnerNumber: partner.partnerNumber,
        advancedSettings: !!partner.partnerNumber,
      };
    case constants.partnerTypes.EXACT_TARGET:
      return {
        ...value,
        authentication: partner.configuration.authentication,
        domain: partner.configuration.domain,
        exactTargetClientId: partner.configuration.exactTargetClientId,
        partnerNumber: partner.partnerNumber,
        advancedSettings: !!partner.partnerNumber,
        segmentKey: partner.configuration.segmentKey,
        variablesKey: partner.configuration.variablesKey,
      };
    case constants.partnerTypes.FACEBOOK:
      return {
        ...value,
        accountId: partner.configuration.accountId,
        payloadType: partner.configuration.payloadType,
      };
    case constants.partnerTypes.MOB_PRO:
      return {
        ...value,
        advertiserId: partner.configuration.advertiserId,
        customer: partner.configuration.customer,
      };
    case constants.partnerTypes.SELLIGENT:
      return {
        ...value,
        username: partner.configuration.username,
        endpoint: partner.configuration.endpoint,
      };
    case constants.partnerTypes.TRIPOLIS:
      return {
        ...value,
        client: partner.configuration.client,
        instanceNumber: partner.configuration.instanceNumber,
        username: partner.configuration.username,
        partnerNumber: partner.partnerNumber,
        advancedSettings: !!partner.partnerNumber,
        contactDatabaseId: partner.configuration.contactDatabaseId,
      };
    case constants.partnerTypes.ADNUNTIUS:
      return {
        ...value,
        siteId: partner.configuration.siteId,
        networkId: partner.configuration.networkId,
        teamId: partner.configuration.teamId,
      };
    case constants.partnerTypes.CUSTOM:
      return {
        ...value,
        partnerNumber: partner.partnerNumber,
        newUrl: partner.configuration.urls.NEW,
        modifiedUrl: partner.configuration.urls.MODIFIED,
        removedUrl: partner.configuration.urls.REMOVED,
        logo: partner.partnerName,
      };
    case constants.partnerTypes.APPNEXUS: {
      const { appNexusSeparators } = partner.configuration;
      return {
        ...value,
        username: partner.configuration.username,
        advertiserId: partner.configuration.advertiserId,
        useDefaults: partner.configuration.useDefaults,
        separator1: appNexusSeparators.separator1,
        separator2: appNexusSeparators.separator2,
        separator3: appNexusSeparators.separator3,
        includeValue: appNexusSeparators.includeValue,
      };
    }
    case constants.partnerTypes.KINESIS:
      return {
        ...value,
        partnerNumber: partner.partnerNumber,
        accessKey: partner.configuration.accessKey,
        secretKey: partner.configuration.secretKey,
        region: partner.configuration.region,
        streamName: partner.configuration.streamName,
      };
    case constants.partnerTypes.GOOGLE_PUBSUB:
      return {
        ...value,
        project: partner.configuration.project,
        topic: partner.configuration.topic,
      };
    case constants.partnerTypes.ADOBE:
      return {
        ...value,
        accessKey: partner.configuration.accessKey,
        accountToken: partner.configuration.accountToken,
        apiSecret: partner.configuration.apiSecret,
        bucket: partner.configuration.bucket,
        region: partner.configuration.region,
        retentionWindowInDays: partner.configuration.retentionWindowInDays,
        secretKey: partner.configuration.secretKey,
        userId: partner.configuration.userId,
      };
    case constants.partnerTypes.SALESFORCE:
      return {
        ...value,
        ...partner.configuration,
      };
    case constants.partnerTypes.TWITTER:
      return {
        ...value,
        identifierType: partner.configuration.identifierType.name,
        partnerType: partner.configuration.partnerType,
        accountId: partner.configuration.accountId,
        accountName: partner.configuration.accountName,
        consumerKey: partner.configuration.consumerKey,
        consumerSecret: partner.configuration.consumerSecret,
        accessToken: partner.configuration.accessToken,
        tokenSecret: partner.configuration.tokenSecret,
      };
    case constants.partnerTypes.TRADEDESK:
      return {
        ...value,
        advertiserId: partner.configuration.advertiserId,
        location: partner.configuration.location,
        payloadType: partner.configuration.payloadType,
      };
    case constants.partnerTypes.MARKETO:
      return {
        ...value,
        clientId: partner.configuration.clientId,
        authEndpoint: partner.configuration.authEndpoint,
        partnerType: partner.configuration.partnerType,
        dataEndpoint: partner.configuration.dataEndpoint,
      };
    case constants.partnerTypes.FACEBOOK_CONVERSIONS:
      return {
        ...value,
        pixelId: partner.configuration ? partner.configuration.pixelId : '',
      };
    case constants.partnerTypes.DIY:
      return {
        ...value,
        ...partner?.configuration,
        logo: partner.partnerName,
      };
    case constants.partnerTypes.AMAZON_DSP:
      return {
        ...value,
        partnerNumber: partner.partnerNumber,
        advertiserId: partner.configuration.advertiserId,
        payloadType: partner.configuration.payloadType,
        region: partner.configuration.region,
        amazonDspAuthentication: {
          authenticationType: partner.configuration?.amazonDspAuthentication?.authenticationType,
          clientId: partner.configuration?.amazonDspAuthentication?.clientId,
          scope: partner.configuration?.amazonDspAuthentication?.scope,
        },
      };
    case constants.partnerTypes.GOOGLE_CUSTOMER_MATCH:
      return {
        ...value,
        customerId: partner.configuration.customerId,
        payloadType: partner.configuration.payloadType,
        productType: partner.configuration.productType,
        consentStatus: partner.configuration.consentStatus,
      };
    case constants.partnerTypes.AMNET:
    case constants.partnerTypes.MEDIA_MATH:
    case constants.partnerTypes.OPTIMIZELY:
    case constants.partnerTypes.RELAY42_API:
    case constants.partnerTypes.TURN:
    default:
      return { ...value };
  }
};

const setConnectorInitialValues = (partner, value) => {
  switch (partner.partnerType) {
    case constants.partnerTypes.ADFORM:
      return {
        ...value,
        authenticationType: constants.OAUTH,
      };
    case constants.partnerTypes.APPNEXUS:
      return {
        ...value,
        useDefaults: true,
      };
    case constants.partnerTypes.EXACT_TARGET:
      return {
        ...value,
        partnerNumber: 1009,
        segmentKey: 'Relay42AudienceProd',
        variablesKey: 'Relay42VariablesProd',
      };
    case constants.partnerTypes.DIY:
      return {
        ...value,
        // Remove Default hard coding from UI in V2
        headers: [{ key: 'Content-Type', value: 'application/json', secure: false }],
      };
    case constants.partnerTypes.AMAZON_DSP:
      return {
        ...value,
        amazonDspAuthentication: {
          ...value.amazonDspAuthentication,
          authenticationType: constants.STANDARD,
        },
      };
    default:
      return { ...value };
  }
};

const getInitialValues = (state, ownProps) => {
  const partner = getPartner(state, ownProps.mode);
  const advertisers = getAdvertisers(state);
  const value = {
    name: partner && partner.partnerName,
  };
  const partnerType = partner && partner.partnerType;
  const data =
    ownProps.canUpdate && partner
      ? setConnectorInitialValuesForEdit(partner, value, advertisers)
      : setConnectorInitialValues(partner, value);

  return partnerType
    ? {
        [partnerType]: {
          ...data,
        },
      }
    : {};
};

export { getConnectorId, getInitialValues, getPartnerTypes, fetchingPartnerTypes, getPartner, selectFormValues };
