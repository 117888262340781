import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const getSalesforceURL = parnerId => `${API_BASE}/v1/partners/salesforce/${parnerId}/audiences`;

const getSalesforceList = parnerId =>
  Api.callGet(getSalesforceURL(parnerId), { shouldShowToast: false, shouldHandleCommonErrors: false }).then(
    parseResponse,
  );

const addAudienceInSalesforce = (parnerId, payload) =>
  Api.callPost(getSalesforceURL(parnerId), payload, { shouldShowToast: false, shouldHandleCommonErrors: false }).then(
    parseResponse,
  );

export { getSalesforceList, addAudienceInSalesforce };
