/**
 * @description Angular main module for Customer Module, including journeys, in the future other modules related
 * @memberOf webUi
 * @namespace webUi.ui.customer
 */
angular.module('webUi.ui.customer',
    [
        'webUi.ui.customer.journey'
    ]
);
