import { get } from 'lodash';

import { changeUrl, history, buildUrl } from '~/common/history';
import { getSiteId } from '~/common/SecurityMetaService';
import i18n from '~/i18n';
import { isCustomConnector, isDIYConnector } from '~/profiles/helpers/connectorsHelper';
import { showSuccess, showError } from '~/notificationCenter';
import { selectEventConnectorById } from '~/profiles/connectors/form/connectorFormSelector';
import { getConnectorListPath } from '~/profiles/connectors/containers/commonActions';
import { SERVER_CONNECTOR_CATEGORY, EVENT_CONNECTOR_CATEGORY } from '~/profiles/connectors/types';
import { PARTNER_TYPES_WITH_TRANSFORMER_SUPPORT } from '~/profiles/connectors/form/partners/facebookConversions/core/constants';
import connectorFormActionTypes from './connectorFormActionTypes';
import {
  getPartnerTypes,
  requestEventConnectorCreation,
  requestConnectorCreation,
  requestConnectorUpdate,
  requestLogoUpload,
  updateEventConnector,
} from './connectorFormService';
import {
  getPartner,
  selectFormValues,
  getPartnerTypes as selectPartnerTypes,
  selectEventConnectorId,
} from './connectorFormSelector';
import constants from './constants';
import { getConnectorById } from '../selectors';
import { fetchConnectors, fetchEventConnectors } from '../actions';
import profileConnectorsService from '../dataService';

const { CREATE_SERVER, CREATE_EVENT, EDIT_SERVER, EDIT_EVENT } = constants.modes;

const clearConnectorForm = () => ({
  type: connectorFormActionTypes.CLEAR_CONNECTOR_FORM,
});

const fetchPartnerTypes = () => dispatch => {
  dispatch({ type: connectorFormActionTypes.FETCH_PARTNER_TYPES.pending });

  return getPartnerTypes()
    .then(payload => {
      const partnerTypes = payload.data;
      dispatch({
        type: connectorFormActionTypes.SET_PARTNER_TYPES,
        data: partnerTypes,
      });
      dispatch({
        type: connectorFormActionTypes.FETCH_PARTNER_TYPES.fulfilled,
      });
    })
    .catch(() => {
      dispatch({ type: connectorFormActionTypes.FETCH_PARTNER_TYPES.rejected });
    });
};

const setPartner = (connectorId, canUpdate) => (dispatch, getState) => {
  const partnerTypes = selectPartnerTypes(getState());
  const partner = canUpdate
    ? getConnectorById(getState(), connectorId)
    : partnerTypes?.find(partner => partner.partnerType === connectorId);

  dispatch({ type: connectorFormActionTypes.SET_PARTNER, partner });
};

const fetchDetails = (connectorId, partnerType, mode) => async (dispatch, getState) => {
  if (mode === CREATE_SERVER || mode === CREATE_EVENT) {
    await dispatch(fetchPartnerTypes());

    const partnerTypes = selectPartnerTypes(getState());
    const type = partnerTypes?.find(type => type.partnerType === partnerType);

    dispatch({ type: connectorFormActionTypes.SET_PARTNER, partner: type });
    return;
  }

  if (mode === EDIT_SERVER) {
    const res = await profileConnectorsService.getConnectorDetail(connectorId);
    const partner = { ...res, partnerType: res?.partnerType?.name };
    dispatch({ type: connectorFormActionTypes.SET_PARTNER, partner });
    return;
  }

  if (mode === EDIT_EVENT) {
    await Promise.all([dispatch(fetchEventConnectors())]);

    const state = getState();
    const eventConnector = selectEventConnectorById(state, connectorId);
    const connectorDetails = await profileConnectorsService.getEventConnectorDetail(connectorId);
    const { partnerDetailResponse, processJourneyEvents } = connectorDetails || {};

    const destinationConnector = {
      ...{
        ...partnerDetailResponse,
        partnerType: partnerDetailResponse?.partnerType?.name,
        configuration: {
          ...partnerDetailResponse?.configuration,
          processJourneyEvents,
        },
      },
    };
    const partner = { ...destinationConnector, partnerName: eventConnector.name };

    dispatch({ type: connectorFormActionTypes.SET_PARTNER, partner });
  }
};

const errorMessage = (partnerName, message = '', code = '') => {
  if (message) {
    switch (partnerName) {
      case constants.MAILCHIMP:
        break;
      case constants.SELLIGENT:
        return `${i18n.t('partners:form.notification.connectionErrorPrefix')} ${message}`;
      default:
        return message;
    }
  }

  if (code) {
    switch (partnerName) {
      /* eslint-disable no-fallthrough */
      case constants.GOOGLE_PUBSUB:
        if (code === 'GooglePubSubInvalidCertificateException') {
          return i18n.t('partners:form.notification.pleaseCheckCertificate');
        }
      case constants.MAILCHIMP:
        if (code === 'UnknownMailChimpUnrecoverableException') {
          return i18n.t('partners:form.notification.invalidApiKey');
        }
      default:
        return code;
    }
  }

  return i18n.t('common:errors.genericError');
};

const getCustomPartnerConf = data => {
  let { configuration } = data;
  const { newUrl, modifiedUrl, removedUrl, ...other } = configuration;
  const urls = {};
  if (newUrl) urls.NEW = newUrl;
  if (modifiedUrl) urls.MODIFIED = modifiedUrl;
  if (removedUrl) urls.REMOVED = removedUrl;

  configuration = { ...other, urls };
  return { ...data, configuration };
};

const payload = (data, partnerType) => {
  const { configuration, ...noConf } = data;
  switch (partnerType) {
    case constants.partnerTypes.AMNET:
    case constants.partnerTypes.MEDIA_MATH:
    case constants.partnerTypes.CLICK_DISTRICT:
    case constants.partnerTypes.RELAY42_API:
    case constants.partnerTypes.TURN:
      return noConf;
    case constants.partnerTypes.CUSTOM:
      return getCustomPartnerConf(data);
    case constants.partnerTypes.DOUBLE_CLICK:
      return { ...noConf, configuration: { ...configuration, inviteType: configuration.inviteType.inviteType } };
    case constants.partnerTypes.TWITTER:
      return {
        activated: true,
        partnerType: constants.partnerTypes.TWITTER,
        partnerNumber: data.partnerNumber,
        partnerName: data.partnerName,
        partnerId: data.partnerId,
        configuration: {
          // "partnerType" exists in both root and "configuration" to make backend validation happy
          partnerType: constants.partnerTypes.TWITTER,
          consumerKey: data.configuration.consumerKey,
          consumerSecret: data.configuration.consumerSecret,
          accessToken: data.configuration.accessToken,
          tokenSecret: data.configuration.tokenSecret,
          accountId: data.configuration.accountId,
          accountName: data.configuration.accountName,
          identifierType: data.configuration.identifierType,
        },
      };
    case constants.partnerTypes.ADFORM: {
      const payload = {
        activated: true,
        partnerType: constants.partnerTypes.ADFORM,
        partnerNumber: data.partnerNumber,
        partnerName: data.partnerName,
        partnerId: data.partnerId,
        configuration: {
          partnerType: constants.partnerTypes.ADFORM,
          dataProviderId: data.configuration.dataProviderId,
          payloadType: data.configuration.payloadType,
          authenticationType: data.configuration.authenticationType,
        },
      };

      if (data.configuration.authenticationType === constants.STANDARD) {
        payload.configuration.username = data.configuration.username;
        payload.configuration.password = data.configuration.password;
      } else if (data.configuration.authenticationType === constants.OAUTH) {
        payload.configuration.clientId = data.configuration.clientId;
        payload.configuration.clientSecret = data.configuration.clientSecret;
      }

      return payload;
    }
    // WUI-1567 Hardcoded to handle create/update untill backend removes authentication field
    case constants.partnerTypes.EXACT_TARGET:
      return { ...data, configuration: { ...data.configuration, authentication: 'ENHANCED' } };
    case constants.partnerTypes.YAHOO:
      delete configuration.advertiser;
      return { ...noConf, configuration };
    case constants.partnerTypes.LINKEDIN:
      return { ...noConf, configuration };
    case constants.partnerTypes.APPNEXUS:
      return {
        ...noConf,
        configuration: {
          ...configuration,
          appNexusSeparators: configuration.useDefaults ? null : configuration.appNexusSeparators,
        },
      };
    default:
      return data;
  }
};

const removeLogoFromConfig = connectorConfig => {
  // Do not send an image blob to server. It is uploaded in a separate step.
  const configurationClone = { ...connectorConfig.configuration };
  delete configurationClone.logo;

  return { ...connectorConfig, configuration: configurationClone };
};

export const preparePayload = connectorConfig => {
  const { configuration } = connectorConfig || {};

  return isCustomConnector(configuration?.partnerType) || isDIYConnector(configuration?.partnerType)
    ? removeLogoFromConfig(connectorConfig)
    : connectorConfig;
};

const getLogoFile = connectorConfig => {
  const fileFieldValue = get(connectorConfig, 'configuration.logo[0]');

  // In connector editing mode file field value is a URL string and not a file
  return fileFieldValue instanceof File ? fileFieldValue : null;
};

const getPayloadForLogoUpload = fileToUpload => {
  const requestBody = new FormData();
  requestBody.append('file', fileToUpload);

  return requestBody;
};

export const getErrorText = (error, partnerType) => {
  const errorDescription = get(error, 'status.error', {});
  const { message, code } = errorDescription;
  const notificationBody = errorMessage(constants[partnerType], message, code);

  return {
    header: i18n.t('partners:form.notification.error'),
    body: notificationBody,
  };
};

const getLogoUploadErrorText = () => ({
  header: i18n.t('partners:form.notification.logoUploadFailed'),
  body: i18n.t('partners:form.notification.connectorCreatedWithoutLogo'),
});

const getCreationSuccessText = () => ({
  header: i18n.t('partners:form.notification.connectorSuccessfullyCreated'),
});

const getUpdateSuccessText = () => ({
  header: i18n.t('partners:form.notification.connectorSuccessfullyUpdated'),
});

const handleError = (error, connectorConfig) => {
  const errorText = getErrorText(error, connectorConfig.partnerType || connectorConfig.configuration.partnerType);
  showError(errorText);
  throw error;
};

const createConnector = connectorConfig => {
  const connectorCreationPayload = preparePayload(connectorConfig);

  return requestConnectorCreation(connectorCreationPayload)
    .then(response => response.data.connectorId)
    .catch(error => handleError(error, connectorConfig));
};

const createEventConnector = connectorConfig => {
  const connectorCreationPayload = preparePayload(connectorConfig);

  return requestEventConnectorCreation(connectorCreationPayload)
    .then(response => response.data)
    .catch(error => handleError(error, connectorConfig));
};

const updateConnector = connectorConfig => {
  const connectorUpdatePayload = preparePayload(connectorConfig);

  return requestConnectorUpdate(connectorUpdatePayload).catch(error => handleError(error, connectorConfig));
};

const uploadLogoIfNeeded = (connectorConfig, connectorId, dispatch) => {
  const fileToUpload = getLogoFile(connectorConfig);
  if (fileToUpload) {
    const payloadForLogoUpload = getPayloadForLogoUpload(fileToUpload);
    const siteId = getSiteId();

    return requestLogoUpload(
      siteId,
      connectorId,
      connectorConfig?.configuration?.partnerType,
      payloadForLogoUpload,
    ).catch(error => {
      const errorText = getLogoUploadErrorText();
      showError(errorText);

      // Not a very clean solution - we need to preload "Edit" form state before redirect
      // This is because our routing doesn't work as expected
      // TODO: Investigate why internal URL changes do not fully reload form component
      dispatch(fetchConnectors()).then(() => {
        dispatch(setPartner(connectorId, true));
        changeUrl(`profiles/partners/edit/${connectorId}`);
      });

      throw error;
    });
  }

  return Promise.resolve(connectorId);
};

const createConnectorAction = mode => (dispatch, getState) => {
  const partner = getPartner(getState());
  const { partnerType } = partner;
  const formValues = selectFormValues(getState());

  if (mode === CREATE_EVENT) {
    const {
      name,
      filters,
      transformations,
      processJourneyEvents = false,
      partnerNumber,
      ...formFieldValues
    } = formValues;
    const filterIds = filters?.map(({ filterId }) => filterId) || [];
    const transformationIds = transformations?.map(({ transformationId }) => transformationId) || [];
    const connectorConfig = {
      configuration: {
        ...formFieldValues,
        partnerType,
      },
      filterIds,
      transformationIds,
      name,
      processJourneyEvents,
      partnerNumber: partnerNumber || partner.partnerNumber,
      partnerType,
    };

    return createEventConnector(connectorConfig)
      .then(connectorId => uploadLogoIfNeeded(connectorConfig, connectorId, dispatch))
      .then(connectorId => {
        const isConnectorWithTransformations = PARTNER_TYPES_WITH_TRANSFORMER_SUPPORT.includes(partnerType);

        const redirectPath = isConnectorWithTransformations
          ? `profiles/partners/editEvent/${connectorId}/transformations`
          : getConnectorListPath(EVENT_CONNECTOR_CATEGORY);

        if (isConnectorWithTransformations) {
          history.replace(buildUrl(`profiles/partners/editEvent/${connectorId}`));
          changeUrl(redirectPath);
          return;
        }

        changeUrl(redirectPath);

        const successText = getCreationSuccessText();
        showSuccess(successText);
      })
      .catch(() => {
        // Ignore rejected promises - stop function execution
      });
  }

  const data = {
    /*
      Why do we have both "partnerNumber" and "configuration.partnerNumber"? Because backend validation is inconsistent.
      For some connectors it reads partnerType from the root, and for some - from "configuration" field.
      Backend validation checks both places (even though only one of them is used). So we add it in two places just to be safe.
    */
    configuration: { ...formValues, partnerType },
    partnerNumber: formValues.partnerNumber || partner.partnerNumber,
    partnerType,
    partnerName: formValues.name,
    activated: true, // Required. Otherwise the connector will be created as "disabled" and user will have to "activate" it first
  };

  const connectorConfig = payload(data, partnerType);

  return createConnector(connectorConfig)
    .then(connectorId => uploadLogoIfNeeded(connectorConfig, connectorId, dispatch))
    .then(() => {
      const redirectPath = getConnectorListPath(SERVER_CONNECTOR_CATEGORY);

      changeUrl(redirectPath);

      const successText = getCreationSuccessText();
      showSuccess(successText);
    })
    .catch(() => {
      // Ignore rejected promises - stop function execution
    });
};

const updateConnectorAction = mode => (dispatch, getState) => {
  const state = getState();
  const formValues = selectFormValues(state);
  const partner = getPartner(state);

  if (mode === EDIT_EVENT) {
    const eventConnectorId = selectEventConnectorId(state);

    const { name, filters, transformations, processJourneyEvents, ...restFormValues } = formValues;

    const filterIds = filters?.map(({ filterId }) => filterId) || [];
    const transformationIds = transformations?.map(({ transformationId }) => transformationId) || [];

    const payload = {
      name,
      processJourneyEvents,
      configuration: {
        partnerType: partner.partnerType,
        ...restFormValues,
      },
      filterIds,
      transformationIds,
    };

    return updateEventConnector(eventConnectorId, preparePayload(payload))
      .then(() => uploadLogoIfNeeded(payload, eventConnectorId, dispatch))
      .then(() => {
        const isConnectorWithTransformations = PARTNER_TYPES_WITH_TRANSFORMER_SUPPORT.includes(partner.partnerType);

        const successText = getUpdateSuccessText();
        showSuccess(successText);
        if (!isConnectorWithTransformations) {
          changeUrl(getConnectorListPath(EVENT_CONNECTOR_CATEGORY));
        }
      })
      .catch(() => {
        // Ignore rejected promises
      });
  }

  const data = {
    configuration: { ...formValues, partnerType: partner.partnerType },
    partnerId: partner.partnerId,
    partnerName: formValues.name,
  };

  const partnerNumber = formValues.partnerNumber || partner.partnerNumber;

  if (partnerNumber) {
    data.partnerNumber = partnerNumber;
  }

  const connectorConfig = payload(data, partner.partnerType);

  return updateConnector(connectorConfig)
    .then(() => uploadLogoIfNeeded(connectorConfig, partner.partnerId, dispatch))
    .then(() => {
      const successText = getUpdateSuccessText();
      showSuccess(successText);
      changeUrl('profiles/partners/list');
    })
    .catch(() => {
      // Ignore rejected promises - stop function execution
    });
};

export { fetchDetails, createConnectorAction, clearConnectorForm, updateConnectorAction, errorMessage };
