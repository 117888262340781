import React from 'react';
import ReactDOM from 'react-dom';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '../i18n';
import './styles.scss';

export const ZoomButtons = ({ onZoomInClick, onZoomOutClick, onZoomReset }) => (
  <div className="fixed bottom-4 left-4 flex flex-row gap-2">
    <BtnIcon tooltip={i18n.t('journey:zoomIn')} icon="zoomIn" onClick={onZoomInClick} />
    <BtnIcon tooltip={i18n.t('journey:zoomOut')} icon="zoomOut" onClick={onZoomOutClick} />
    <BtnIcon tooltip={i18n.t('journey:zoomCenter')} icon="zoomCenter" onClick={onZoomReset} />
  </div>
);
export const PortalToReactRoot = props => ReactDOM.createPortal(props.children, document.getElementById('reactRoot'));
