import React, { ComponentType } from 'react';
import cx from 'classnames';

import Spinner from '.';

interface WithSpinnerProps {
  isLoading: true;
  error: Error;
}

const defaultClassName = 'blankOverlay blankOverlay--withCenteredContent';

const withSpinner = (className?: string) => (WrappedComponent: ComponentType) => ({
  isLoading,
  error,
  ...props
}: WithSpinnerProps) => {
  if (error) {
    return null;
  }

  if (isLoading) {
    return (
      <div className={cx(defaultClassName, className)}>
        <Spinner />
      </div>
    );
  }

  return <WrappedComponent {...props} />;
};

export default withSpinner;
