import React from 'react';
import i18n from '~/i18n';
import Icons from '~/components/src/Icons';
import './styles.scss';

const MAX_CHARACTERS_NAME_OF_JOURNEY_TRIGGER_OR_STEP = 255;

const getVariableNameById = (variableId, variables) =>
  variables.find(variable => variable.variableId === variableId).name;

const getStepNameById = (stepId, journeyContents) => {
  const hierarchy = d3.hierarchy(journeyContents.nodes);
  return hierarchy.descendants().find(node => node.data.nodeId === stepId)?.data.label;
};

const ValidationModal = ({ journeyName, validations, variables, journeyContents }) => {
  const parseValidation = validation => {
    const { nodeName, nodeType, errorCode, stepId, variableId } = validation;

    const constructData = (reason, message, { isCustomReason = false, isCustomMessage = false } = {}) => ({
      name: errorCode,
      reason: isCustomReason ? reason : i18n.t(`orchestration:validations.${reason}`),
      message: isCustomMessage ? message : i18n.t(`orchestration:validations.${message}`),
    });

    const type =
      nodeType === i18n.t('orchestration:goals.goal')
        ? i18n.t('orchestration:goals.goal')
        : i18n.t('orchestration:triggers.trigger');

    switch (errorCode) {
      case 'JOURNEY_HAS_NO_START_TRIGGER':
        return constructData('mustHaveOneStartTrigger', 'hasNoStartTrigger');
      case 'JOURNEY_ALREADY_HAS_START_TRIGGER':
        return constructData('mustHaveOneStartTrigger', 'hasaStartTrigger');
      case 'JOURNEY_HAS_MORE_THAN_ONE_START_TRIGGERS':
        return constructData('mustHaveOneStartTrigger', 'hasaMoreStartTrigger');
      case 'JOURNEY_HAS_NO_GOALS':
        return constructData('mustHaveOneGoal', 'noGoal');
      case 'STEP_PARENT_DOES_NOT_EXIST':
        return constructData('stepMustBeCreatedFromExistingTrigger', 'canNotAddNodeToTrigger');
      case 'INVALID_EXPERIMENT_TOTAL_WEIGHT':
        return constructData('experimentWeigtValidation', 'defaultMessage');
      case 'PARENT_CHILD_OF_SAME_TYPE':
        return constructData(
          'parentChildMustBeDifferent',
          i18n.t('orchestration:validations.parentType', { nodeName }),
          { isCustomMessage: true },
        );
      case 'INVALID_NAME':
        return constructData(
          i18n.t('orchestration:validations.nameConstaint', { size: MAX_CHARACTERS_NAME_OF_JOURNEY_TRIGGER_OR_STEP }),
          'journeyNodeInvalid',
          { isCustomReason: true },
        );
      case 'TRIGGER_DOES_NOT_HAVE_STEP':
        return constructData(
          'triggerMustHaveExactlyOneStep',
          i18n.t('orchestration:validations.nodeHasNoStep', { nodeName }),
          { isCustomMessage: true },
        );
      case 'TRIGGER_MUST_HAVE_MIN_ONE_RULE':
        return constructData(
          i18n.t('orchestration:validations.mustHaveRule', { type }),
          nodeName
            ? i18n.t('orchestration:validations.nodeHasNoRule', { nodeName })
            : i18n.t('orchestration:validations.triggerGoalHasNoRules'),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'TRIGGER_ALL_RULES_NEGATED':
        return constructData(
          i18n.t('orchestration:validations.mustHaveOneRuleNotNegated', { type }),
          nodeName
            ? i18n.t('orchestration:validations.nodeHasAllRulesNegated', { nodeName })
            : i18n.t('orchestration:validations.triggerGoalHasRulesNegated'),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'INVALID_STRING_FILTER':
        return constructData(
          i18n.t('orchestration:validations.ruleWithInvalidString', { type }),
          nodeName
            ? i18n.t('orchestration:validations.nodeHasInvalidString', { nodeName })
            : i18n.t('orchestration:validations.triggerGoalHasInvalidString'),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'INVALID_TIME_DURATION_FORMAT':
        return constructData(
          i18n.t('orchestration:validations.ruleMustHaveValidTime', { type: nodeType, nodeName }),
          nodeName
            ? i18n.t('orchestration:validations.nodeHasInvalidTime', { nodeName })
            : i18n.t('orchestration:validations.triggerGoalHasInvalidTime'),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'VARIABLE_NOT_PRESENT_IN_PREVIOUS_JOURNEY_TRIGGERS': {
        const variableName = getVariableNameById(variableId, variables);
        const stepName = getStepNameById(stepId, journeyContents);

        return constructData(
          'pleaseCheckPrecedingTriggers',
          i18n.t('orchestration:validations.stepUsesRemovedVariable', {
            stepName,
            variableName,
          }),
          {
            isCustomMessage: true,
          },
        );
      }
      case 'FLEXIBLE_TRANSITION_CANNOT_LINK_TO_DIFFERENT_EXPERIMENT':
        return constructData(
          i18n.t('orchestration:validations.differentExperiment'),
          i18n.t('orchestration:validations.cannotLinkToDifferentExperiment', { nodeName }),
          { isCustomReason: true, isCustomMessage: true },
        );
      case 'FLEXIBLE_TRANSITION_POSSIBLE_LOOP_CONDITION':
        return constructData('cannotBeLoop', 'possibleLoopCondition');
      default:
        return constructData('defaultReason', 'defaultMessage');
    }
  };

  return (
    <div className="JourneyModal">
      <p className="JourneyModal-title mb-4 rounded bg-blue-50 p-2 text-xl text-blue-500">
        <Icons icon="journey" className="mr-2 h-6 w-6" /> {journeyName}
      </p>
      <div className="JourneyModal-validationContent flex flex-col gap-4">
        {validations &&
          validations.map(validation => {
            const data = parseValidation(validation);
            return (
              <div key={data.name} className="flex gap-2">
                <Icons icon="error" className="h-6 w-6 text-red-500" />
                <div className="flex flex-col">
                  <p className={`t-${data.name}-message JourneyModal-message`}>{data?.message}</p>
                  <p className={`JourneyModal-reason t-${data.name}-reason text-sm text-gray-400`}>{data?.reason}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ValidationModal;
