import { connect } from 'react-redux';
import AppNexusForm from './AppNexusForm';
import { fetchAdvertisers, setAdvertisers } from './appNexusActions';
import { getAdvertisers, fetchingAdvertisers } from './appNexusSelector';

const mapStateToProps = state => ({
  fetchingAdvertisers: fetchingAdvertisers(state),
  advertisers: getAdvertisers(state),
});

const mapDispatchToProps = {
  fetchAdvertisers,
  setAdvertisers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppNexusForm);
