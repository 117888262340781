import React, { useState } from 'react';
import moment from 'moment';
import i18n from '~/i18n';
import ActionButtons from '~/components/src/ActionButtons';
import Spin from '~/components/src/Spin';
import { Notification } from '~/components';
import { getHistoricProcessingDetails, stopHistoricProcessingJob } from '~/customer/journeys/dataService';
import { showError, showSuccess } from '~/notificationCenter';
import { useAPI } from '~/common';
import { getDate } from '~/common/utils/DateUtil';
import { HP_STATUS } from '~/customer/components/constants';

import { HPContext } from './context';
import { HpOptionSelector } from './components/HpOptionSelector';
import { getModelHeader, mapReprocessingModes } from './util';
import { ViewScheduledHPProps, HpOptionsType, HPJobDetails } from './types';

const renderHpTimeFrame = (hpJobDetails: HPJobDetails): React.ReactChild => {
  const { startTs, endTs } = hpJobDetails;
  if (!startTs || !endTs) return <></>;

  const rangeFrom = startTs && getDate(startTs);
  const rangeTo = endTs && getDate(endTs);
  const diff = moment(endTs).diff(moment(startTs), 'days') + 1;

  return (
    <p className="flex items-center gap-1">
      <span className="text-gray-500">{i18n.t('orchestration:historicProcessing.timeFrame')}:</span> {rangeFrom} -{' '}
      {rangeTo}{' '}
      <span className="text-r42-blue">
        ({diff} {i18n.t('common:days')})
      </span>
    </p>
  );
};

const ViewScheduledHP = ({
  journeyContents,
  hpJobId,
  journeyId,
  hideModal,
  fetchJourneyData,
}: ViewScheduledHPProps): React.ReactNode => {
  const [selectedHpOptions, setSelectedHpOptions] = useState<HpOptionsType[]>([]);
  const [reprocessingModes, setReprocessingModes] = useState<HpOptionsType[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);

  const { data: hpJobDetails, isLoading } = useAPI(async () => {
    const data = await getHistoricProcessingDetails(journeyId, hpJobId);
    const modes = await mapReprocessingModes(journeyId);
    setReprocessingModes(modes);

    const selectedModes = data.reprocessingModes.map(({ name }) => name);
    setSelectedHpOptions(modes.filter(({ id }) => selectedModes.includes(id)));
    return data;
  });

  if (isLoading || !hpJobDetails) return <Spin />;

  const handleCancelHPJob = () => {
    setIsProcessing(true);
    stopHistoricProcessingJob(journeyId, hpJobId)
      .then(() => {
        hideModal();
        setIsProcessing(false);
        showSuccess({
          header: i18n.t('orchestration:historicProcessing.cancelSuccess'),
        });
        fetchJourneyData();
      })
      .catch(() => {
        setIsProcessing(false);
        showError({
          header: i18n.t('orchestration:historicProcessing.cancelFail'),
          body: i18n.t('common:tryAgainLater'),
        });
      });
  };

  const hasGoals = !!journeyContents.goals.length;
  const hasExits = !!journeyContents.exitConditions.length;
  const isCancelledAllowed = hpJobDetails.status.name !== HP_STATUS.RUNNING;

  const joContextValue = {
    hasGoals,
    hasExits,
    selectedHpOptions,
    currentStep,
    reprocessingModes: reprocessingModes || [],
    setSelectedHpOptions,
    setCurrentStep,
  };

  return (
    <HPContext.Provider value={joContextValue}>
      <div className="flex h-auto w-[640px] flex-col">
        <Notification testHook="hpHeader" kind="information">
          {getModelHeader(selectedHpOptions)}
        </Notification>
        <HpOptionSelector disabled />
      </div>
      <div className="flex h-auto w-[640px] flex-col gap-2">
        <div className="my-2 flex flex-col gap-2 rounded border border-gray-200 p-2">
          {renderHpTimeFrame(hpJobDetails)}
          <p className="flex items-center gap-1">
            <span className="text-gray-500">{i18n.t('orchestration:historicProcessing.status')}:</span>
            <span>{hpJobDetails.status.label}</span>
          </p>
          <p className="flex items-center gap-1">
            <span className="text-gray-500">{i18n.t('orchestration:historicProcessing.triggeredBy')}:</span>
            <span>{hpJobDetails.triggeredBy}</span>
            <span className="text-sm text-r42-blue">({getDate(hpJobDetails.requestedAt)})</span>
          </p>
        </div>
        <div className="flex justify-end ">
          <ActionButtons
            className="pt-2"
            onDelete={() => handleCancelHPJob()}
            isDeleteEnabled={!isProcessing && isCancelledAllowed}
            deleteText={i18n.t('orchestration:historicProcessing.action.stopHP')}
            onDecline={hideModal}
            declineText={i18n.t('common:actions.cancel')}
            testHook="scheduledHp-actionButtons"
            paddingClassName={null}
            disabledButtonToolTip={i18n.t(
              `orchestration:historicProcessing.action.${isProcessing ? 'processing' : 'cancelNotAllowed'}`,
            )}
          />
        </div>
      </div>
    </HPContext.Provider>
  );
};

export default ViewScheduledHP;
