import React from 'react';
import * as fromModals from '~/modals';
import i18n from '~/i18n';
import AddAdwordsUserForm from './AddAdwordsUserForm';
import { IOpenCreateUserModal } from './types';

const hideModal = () => (dispatch: (action: any) => any) => {
  dispatch(fromModals.hideModal());
};

const openCreateUserModal: IOpenCreateUserModal = (createNewUser, setFieldValue, isMobileId, existingUserNames) => (
  dispatch: (action: any) => any,
) => {
  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: i18n.t('audiences:connectors.adwords.userAdd.header'),
      confirmText: i18n.t('audiences:connectors.adwords.userAdd.confirmText'),
      content: (
        <AddAdwordsUserForm
          hideModal={() => dispatch(hideModal())}
          createNewUser={createNewUser}
          setFieldValue={setFieldValue}
          isMobileId={isMobileId}
          existingUserNames={existingUserNames}
        />
      ),
    }),
  );
};

export { openCreateUserModal, hideModal };
