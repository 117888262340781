import React, { ReactNode, useState } from 'react';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { round } from 'lodash';
import Spinner from '~/components/src/Spinner';
import Page from '~/components/src/Page';
import Heading from '~/components/src/Heading';
import { UITable } from '~/components/src/UITable';
import { useAPI } from '~/common/ApiHooks';
import i18n from '~/i18n';
import { Empty, Link } from '~/components';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import { fetchSnapshot } from './dataService';
import { SnapshotResponse } from './types';
import { NumericSnapshotProperty, StringSnapshotProperty } from '../types';
import './styles.scss';

const MIN_VALUES_TO_DISPLAY = 5;
const columnHelper = createColumnHelper<StringSnapshotProperty | NumericSnapshotProperty>();

const EmptyList = ({ hasProperties, searchKey }: { hasProperties: number; searchKey: string }) =>
  hasProperties ? (
    <Empty
      header={i18n.t('ai:snapshotsPage.details.emptySearchHeader')}
      body={i18n.t('ai:snapshotsPage.details.emptySearchBody', { key: searchKey })}
    />
  ) : (
    <Empty
      header={i18n.t('ai:snapshotsPage.details.emptyHeader')}
      body={i18n.t('ai:snapshotsPage.details.emptyBody')}
    />
  );

function SnapshotMetadataCell({ row }: { row: Row<StringSnapshotProperty | NumericSnapshotProperty> }) {
  const { metadata } = row.original;

  if (Array.isArray(metadata)) {
    const [showMore, setShowMore] = useState(false);
    const valuesToDisplay = showMore ? metadata.length : MIN_VALUES_TO_DISPLAY;

    return (
      <ol className="SnapshotTable-nonNumericValues">
        {metadata.slice(0, valuesToDisplay).map(stringValue => (
          <li key={stringValue}>{stringValue}</li>
        ))}
        {metadata.length > MIN_VALUES_TO_DISPLAY && !showMore && (
          <Link onClick={() => setShowMore(!showMore)}> [+] Show more</Link>
        )}
      </ol>
    );
  }

  const mean = typeof metadata.mean === 'number' ? round(metadata.mean, 2) : '-';
  const standardDeviation = typeof metadata.stdev === 'number' ? round(metadata.stdev, 2) : '-';

  return (
    <ul className="SnapshotTable-numericValues">
      <li>
        {i18n.t('ai:numericLabels.min')}: {metadata.min}
      </li>
      <li>
        {i18n.t('ai:numericLabels.max')}: {metadata.max}
      </li>
      <li>
        {i18n.t('ai:numericLabels.mean')}: {mean}
      </li>
      <li>
        {i18n.t('ai:numericLabels.stdev')}: {standardDeviation}
      </li>
    </ul>
  );
}

function SnapshotDetails({ snapshot, navigate }: { snapshot: SnapshotResponse; navigate: NavigateFunction }) {
  const columns = [
    columnHelper.accessor('name', {
      header: () => <div>{i18n.t('ai:columns.properties')}</div>,
    }),
    columnHelper.accessor('type', {
      header: () => <div>{i18n.t('ai:columns.type')}</div>,
    }),
    columnHelper.accessor('metadata', {
      header: () => <div>{i18n.t('ai:columns.values')}</div>,
      cell: SnapshotMetadataCell,
    }),
  ];

  const properties = snapshot.properties || [];

  const [filteredRows, setFilteredRows] = useState(properties);
  const [query, setQuery] = useState('');
  return (
    <Page className="SnapshotPage">
      <Heading
        title={snapshot?.snapshotName}
        crumbs={[
          {
            title: i18n.t('ai:actions.backToSnap'),
            onClick: () => {
              navigate(-1);
            },
          },
        ]}
        children={
          <SearchElement
            className="HeaderActions-listSearch"
            value={query}
            onChange={e => {
              setQuery(e.target.value);
              setFilteredRows(
                properties.filter(property => property.name.toLowerCase().includes(e.target.value.toLowerCase())),
              );
            }}
            placeholder={i18n.t('common:actions.search')}
          />
        }
      />

      {filteredRows.length === 0 ? (
        <EmptyList hasProperties={properties.length} searchKey={query} />
      ) : (
        <UITable testHook="snapshotPropertiesTable" data={filteredRows || []} columns={columns} />
      )}
    </Page>
  );
}

export default (): ReactNode => {
  const params = useParams();
  const snapshotId = params.id || '';
  const navigate = useNavigate();

  const { data: snapshot, isLoading, error } = useAPI(() => fetchSnapshot(snapshotId));

  if (error) {
    return null;
  }

  if (!snapshot || isLoading) {
    return (
      <Page className="SnapshotPage">
        <Spinner />
      </Page>
    );
  }

  return <SnapshotDetails snapshot={snapshot} navigate={navigate} />;
};
