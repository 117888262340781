import React, { useEffect } from 'react';
import { FormSection, Field } from 'redux-form';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormSensitiveField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Spin from '~/components/src/Spin';
import _ from 'lodash';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import { Notification } from '~/components';
import { changeUrl, isRequired, numberRange, useAPI } from '~/common';
import { useQuery } from '~/customer/components/utils';
import Btn from '~/components/src/Btn';
import FormHeader from '../formHeader/FormHeader';
import constants from '../../constants';
import { fetchAmazonPayloadTypes, fetchAmazonRegions } from './dataService';
import { AmazonDspProps } from './types';

const AMAZON_DSP_PAYLOAD_TYPES = {
  AMAZON_COOKIE: 'AMAZON_COOKIE',
  MOBILE_ADVERTISER_ID: 'MOBILE_ADVERTISER_ID',
  EXTERNAL_USER_ID: 'EXTERNAL_USER_ID',
};
const AmazonDspForm = ({
  t,
  values,
  partner,
  canUpdate: isEditMode,
  change,
  touch,
}: AmazonDspProps): React.ReactNode => {
  const currentUrl = `${window.location.origin}${window.location.pathname}`;
  const { data: regionsData, isLoading: regionIsLoading } = useAPI(() => fetchAmazonRegions(currentUrl));

  const { data: payloadsData, isLoading: payloadIsLoading } = useAPI(() => fetchAmazonPayloadTypes());

  const payloadTypes = payloadsData || [];
  const regionTypes = regionsData || [];
  const region = _.find(regionsData, { name: values.region });
  const redirect = () => {
    localStorage.setItem('partnerValues', JSON.stringify(values));
    changeUrl(region?.authorizationUrl, true, true);
  };

  const query = useQuery();
  const refreshToken = query.get('refreshToken');
  const error = query.get('error');

  const setPartnerValues = () => {
    const partnerValues = JSON.parse(localStorage.getItem('partnerValues') || '{}');

    if (partnerValues?.name) {
      change('AMAZON_DSP.name', partnerValues?.name);
    }
    if (partnerValues?.payloadType) {
      change('AMAZON_DSP.payloadType', partnerValues?.payloadType);
    }
    if (partnerValues?.advertiserId) {
      change('AMAZON_DSP.advertiserId', partnerValues?.advertiserId);
    }
    if (partnerValues?.region) {
      change('AMAZON_DSP.region', partnerValues?.region);
    }
    if (partnerValues?.amazonDspAuthentication?.authenticationType) {
      change(
        'AMAZON_DSP.amazonDspAuthentication.authenticationType',
        partnerValues?.amazonDspAuthentication?.authenticationType,
      );
    }
  };

  useEffect(() => {
    if (refreshToken) {
      change('AMAZON_DSP.amazonDspAuthentication.refreshToken', refreshToken);
      setPartnerValues();
    }
  }, []);

  if (regionIsLoading || payloadIsLoading) {
    return <Spin />;
  }

  const getPartnerNumber = (value: keyof typeof constants.AMAZON_DSP_PARTNER_NUMBERS) => {
    const payloadType = payloadTypes.find(payloadType => payloadType.name === value);
    change('AMAZON_DSP.overRidePartnerNumber', payloadType?.allowsPartnerNumberOverride);
    return payloadType?.partnerNumber || constants.AMAZON_DSP_PARTNER_NUMBERS[value];
  };

  const isOuauthType = values.amazonDspAuthentication.authenticationType === constants.OAUTH;

  return (
    <>
      <FormSection name={constants.partnerTypes.AMAZON_DSP}>
        <FormHeader
          t={t}
          title={values.name}
          partner={partner}
          description={
            values?.payloadType === AMAZON_DSP_PAYLOAD_TYPES.AMAZON_COOKIE
              ? t('form.notification.cookieSyncMessage')
              : t('form.notification.storeMappingMessage')
          }
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection>
          <Field
            label={t('form.formFields.region')}
            name="region"
            component={ReduxFormSelectField}
            options={regionTypes}
            validate={isRequired}
            touch={touch}
          />
          <Field
            label={t('form.formFields.authenticationType')}
            name="amazonDspAuthentication.authenticationType"
            component={ReduxFormSelectField}
            options={[
              { value: constants.STANDARD, label: t('form.formFields.standard') },
              { value: constants.OAUTH, label: t('form.formFields.oauth') },
            ]}
            onChange={(event, value, props) => {
              change('AMAZON_DSP.amazonDspAuthentication.authenticationType', props?.name);
            }}
            validate={isRequired}
            touch={touch}
            disabled={isEditMode}
          />
          {isOuauthType ? (
            <>
              <div className="u-marginVerticalL u-flex-justify-end">
                <Btn color="blue" onClick={() => redirect()} disabled={!values.region}>
                  {t('form.formFields.getRefreshToken')}
                </Btn>
                {error && <Notification kind="error">{error}</Notification>}
              </div>
            </>
          ) : (
            <>
              <Field
                label={t('form.formFields.scope')}
                name="amazonDspAuthentication.scope"
                component={ReduxFormInputField}
                placeholder={t('form.formFields.inputPlaceHolder')}
                isNeedActivateEditMode={isEditMode}
                validate={isRequired}
                type="text"
              />
              <Field
                label={t('form.formFields.clientId')}
                name="amazonDspAuthentication.clientId"
                component={ReduxFormInputField}
                placeholder={t('form.formFields.inputPlaceHolder')}
                validate={isRequired}
                type="text"
              />
              <FieldWithEditMode
                label={t('form.formFields.clientSecret')}
                name="amazonDspAuthentication.clientSecret"
                component={ReduxFormSensitiveField}
                placeholder={t('form.formFields.inputPlaceHolder')}
                isNeedActivateEditMode={isEditMode}
                validate={!isEditMode && isRequired}
                type="text"
              />
            </>
          )}
          <FieldWithEditMode
            label={t('form.formFields.refreshToken')}
            name="amazonDspAuthentication.refreshToken"
            component={ReduxFormSensitiveField}
            isNeedActivateEditMode={isEditMode && !isOuauthType}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={!isEditMode && isRequired}
            type="text"
            disabled={isOuauthType}
          />
          <Field
            label={t('form.formFields.advertiserId')}
            name="advertiserId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            validate={isRequired}
            onChange={(event, value) => {
              change('AMAZON_DSP.partnerNumber', getPartnerNumber(value));
            }}
            touch={touch}
            disabled={isEditMode}
          />
          {values.overRidePartnerNumber && (
            <Field
              label={t('form.formFields.partnerNumber')}
              name="partnerNumber"
              component={ReduxFormInputField}
              validate={[isRequired, numberRange(1, 9999)]}
              type="number"
              disabled={isEditMode}
            />
          )}
        </ContainerFormSection>
      </FormSection>
    </>
  );
};

export default AmazonDspForm;
