import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import i18n from '~/i18n';
import { buildUrl } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import { getToastMessage } from '~/common/ApiService';
import DeletePopup from '~/components/src/DeletePopup';
import { Notification, PickerView } from '~/components';
import { showError, showSuccess } from '~/notificationCenter';

import DeployModel from './ModelDeploy';
import { ModelActionProps } from '../types';
import { MODEL_ACTIONS } from '../constants';
import { ModelContext } from '../ModelContext';
import { deleteModel, retractModel, trainModel } from '../dataService';

const ModelActions = ({
  allowedActions,
  modelInfo,
  refetchData,
  isListPage = false,
}: ModelActionProps): React.ReactElement => {
  const { setSelectedModelId } = useContext(ModelContext);
  const navigate = useNavigate();

  const [modelIdForDeployment, setModelIdForDeployment] = useState<string | null>(null);
  const [selectedModelForDelete, setSelectedModelForDelete] = useState<string | null>(null);

  const { modelId, modelName } = modelInfo;

  const hideDeploymentModal = () => {
    setModelIdForDeployment(null);
    refetchData();
  };

  const deployModelAction = () => {
    setModelIdForDeployment(modelId);
  };

  const trainModelAction = async () => {
    try {
      await trainModel(modelId);
      refetchData();
      showSuccess({ header: i18n.t('ai:notifications.modelTrainSuccess') });
    } catch (error) {
      showError({ body: getToastMessage(error) });
    }
  };

  const retractModelAction = async () => {
    try {
      await retractModel(modelId);
      refetchData();
      showSuccess({ header: i18n.t('ai:notifications.modelRetractSuccess') });
    } catch (error) {
      showError({ body: getToastMessage(error) });
    }
  };

  const deleteModelAction = async () => {
    try {
      await deleteModel(modelId);
      if (isListPage) refetchData();
      else navigate(buildUrl('ai/models/list'));

      showSuccess({ header: i18n.t('ai:notifications.modelDeleteSuccess') });
      setSelectedModelForDelete(null);
    } catch (error) {
      showError({ body: getToastMessage(error) });
    }
  };

  return (
    <div className="flex items-center gap-4">
      {allowedActions.find(action => action.name === MODEL_ACTIONS.AUDIT_LOG) && (
        <BtnIcon
          icon="schedule"
          onClick={() => setSelectedModelId(modelId)}
          tooltip={i18n.t('ai:actions.auditLog')}
          testHook="auditLog"
        />
      )}
      {allowedActions.find(action => action.name === MODEL_ACTIONS.STATS) && (
        <BtnIcon
          icon="dataUsage"
          onClick={() => navigate(buildUrl(`ai/models/stats/${modelId}`))}
          testHook="modelStats"
          tooltip={i18n.t('ai:modelsPage.stats')}
        />
      )}
      {allowedActions.find(action => action.name === MODEL_ACTIONS.DEPLOY) && (
        <BtnIcon
          icon="play"
          onClick={() => deployModelAction()}
          tooltip={i18n.t('ai:actions.deploy')}
          testHook="deployModel"
        />
      )}
      {allowedActions.find(action => action.name === MODEL_ACTIONS.TRAIN) && (
        <BtnIcon
          icon="train"
          onClick={() => trainModelAction()}
          tooltip={i18n.t('ai:actions.train')}
          testHook="trainModel"
        />
      )}
      {allowedActions.find(action => action.name === MODEL_ACTIONS.RETRACT) && (
        <BtnIcon
          icon="retract"
          onClick={() => retractModelAction()}
          tooltip={i18n.t('ai:actions.retract')}
          testHook="retractModel"
        />
      )}
      {allowedActions.find(action => action.name === MODEL_ACTIONS.EDIT) && (
        <BtnIcon
          icon="edit"
          onClick={() => navigate(buildUrl(`ai/models/edit/${modelId}`))}
          tooltip={i18n.t('ai:actions.editModel')}
          testHook="editModel"
        />
      )}
      {allowedActions.find(action => action.name === MODEL_ACTIONS.DELETE) && (
        <BtnIcon
          icon="delete"
          onClick={() => setSelectedModelForDelete(modelId)}
          tooltip={i18n.t('ai:actions.delete')}
          testHook="deleteModel"
        />
      )}

      {modelIdForDeployment && (
        <PickerView
          pickerTitle={i18n.t('ai:modelsPage.modelActions.deploy')}
          handlePickerVisibility={hideDeploymentModal}
          className="ModelActionPicker"
        >
          <DeployModel modelId={modelIdForDeployment} hideModal={hideDeploymentModal} />
        </PickerView>
      )}
      {selectedModelForDelete && (
        <DeletePopup
          title={i18n.t('ai:modelsPage.delete.header')}
          hidePopup={() => setSelectedModelForDelete(null)}
          onClick={() => deleteModelAction()}
        >
          <Notification testHook="modelDeleteDescription">
            {i18n.t('ai:modelsPage.delete.body', { name: modelName })}
          </Notification>
        </DeletePopup>
      )}
    </div>
  );
};

export default ModelActions;
