import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import * as fromModals from '~/modals';
import i18n from '~/context/i18n';
import { getClients, byClientId, fetchClientsIfNeeded } from '~/context/clients';
import {
  EditUserPermissionsToSiteContainer,
  fetchPermissionsMapIfNeeded,
  removeUserAccessToSite,
  getUiPermissions,
  getApiPermissions,
  isFetchingPermissions,
  updateUserAccessToSite,
} from '~/permissions';
import { fetchSiteUsers, getUsersForCurrentSite, isFetchingUsers, updateUserInfo, USERS_KEY } from '~/admin/users';
import { getSiteInfo } from '~/admin/site';
import UserLockSettings from '~/userLockSettings/UserLockSettings';
import UserListContainer from './presenter';

const flattenPermissions = permissions =>
  permissions.reduce((permissions, group) => [...permissions, ...group.permissions.map(permission => permission)], []);

const filterPermissions = (site, permissions) =>
  site.siteModules ? permissions.filter(permission => site.siteModules.includes(permission.name)) : permissions;

/* State Props */
const mapStateToProps = (state, ownProps) => {
  const { siteNumber, isSiteAdmin, site } = ownProps;
  const permissionsUiAll = getUiPermissions(state);
  const permissionsUi = filterPermissions(site, permissionsUiAll);
  const permissionsApiAll = getApiPermissions(state);
  const permissionsApi = filterPermissions(site, permissionsApiAll);
  return {
    info: getSiteInfo(state),
    users: getUsersForCurrentSite(state),
    clients: ownProps.isContextAdmin && getClients(state),
    site,
    stateKey: USERS_KEY,
    siteNumber,
    isSiteAdmin,
    isContextAdmin: ownProps.isContextAdmin,
    addUserHref: buildUrl('admin/users/add'),
    isFetchingPermissions: isFetchingPermissions(state),
    isFetchingUsers: isFetchingUsers(state),
    permissionsUi,
    permissionsApi,
    permissionsUiFlat: flattenPermissions(permissionsUi),
    permissionsApiFlat: flattenPermissions(permissionsApi),
    getClientName: clientId => (byClientId(state, clientId) || {}).name || '-',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { siteNumber, loggedInUser, site, canEditInfo } = ownProps;
  const siteClientId = site.clientId;
  return {
    fetchClients: () => dispatch(fetchClientsIfNeeded()),
    fetchSiteUsers: () => dispatch(fetchSiteUsers(siteNumber, siteClientId)),
    fetchPermissionsMap: () => dispatch(fetchPermissionsMapIfNeeded()),
    onUserLockSettingsClick: (t, user, site) =>
      dispatch(
        fromModals.showModal(fromModals.CUSTOM_MODAL, {
          size: 550,
          content: (
            <UserLockSettings
              canEditInfo={canEditInfo}
              user={user}
              initialValues={user}
              site={site}
              t={t}
              loggedInUser={loggedInUser}
            />
          ),
        }),
      ),
    onEditUser: (t, user, site) =>
      dispatch(
        fromModals.showModal(fromModals.CONTENT_MODAL, {
          title: t('users:editPermissions.header'),
          confirmText: t('users:editPermissions.confirmText'),
          onConfirm: () =>
            dispatch(
              updateUserAccessToSite({
                userId: user.userId,
                siteId: site.siteId,
                updateInfo: data => updateUserInfo(data),
                callback: () => fetchSiteUsers(site.siteNumber, site.clientId),
              }),
            ),
          content: (
            <EditUserPermissionsToSiteContainer
              canEditInfo={ownProps.canEditInfo}
              user={user}
              initialValues={user}
              site={site}
              availablePermissions={site.siteModules?.map(item => (item.name ? item.name : item))}
              permissions={user.permissions}
              t={t}
              loggedInUser={loggedInUser}
            />
          ),
          testHook: 'editUserModal',
        }),
      ),
    onDeleteUser: (user, site) => {
      const userName = (user && user.name) || '';
      dispatch(
        fromModals.showDeleteModal({
          title: i18n.t('users:view.sites.deleteModal.title'),
          message: i18n.t('users:view.sites.deleteModal.message', { userName, siteName: site.name }),
          onConfirm: () => dispatch(removeUserAccessToSite(user.userId, site.siteId)),
        }),
      );
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('adminUsers'))(UserListContainer);
