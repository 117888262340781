import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fetchPermissionsMapIfNeeded, getUiPermissions, getApiPermissions } from '~/permissions';
import Permissions from './presenter';

const mapStateToProps = (state, ownProps) => {
  const { t } = ownProps;
  return {
    uiPermissions: getUiPermissions(state),
    apiPermissions: getApiPermissions(state),
    t,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchPermissionsMap: () => dispatch(fetchPermissionsMapIfNeeded()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Permissions);
