import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../actions';
import ModalWrapper from './ModalWrapper.js';

const CustomModal = ({ content, size }) => <ModalWrapper size={size}>{content}</ModalWrapper>;

CustomModal.propTypes = {
  size: PropTypes.number,
  content: PropTypes.any.isRequired,
};

export default connect(
  null,
  { hideModal },
)(CustomModal);
