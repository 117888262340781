import React, { Component } from 'react';
import _ from 'lodash';
import { Field, FormSection } from 'redux-form';
import { isRequired, tokenIsRequired } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormSensitiveField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import { GoogleButton } from '~/components';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

const renderMessage = t => (
  <ol>
    <li>{t('form.notification.oAuthMessageStep1', { partner: 'Google' })}</li>
    <li>{t('form.notification.oAuthMessageStep2')}</li>
    <li>{t('form.notification.oAuthMessageStep3')}</li>
    <li>{t('form.notification.oAuthMessageStep4')}</li>
    <li>{t('form.notification.oAuthMessageStep5')}</li>
  </ol>
);

class AdwordsForm extends Component {
  componentDidMount() {
    const { fetchPayloadTypes, fetchPartnerInformation } = this.props;
    fetchPayloadTypes(constants.ADWORDS);
    fetchPartnerInformation();
  }

  componentDidUpdate() {
    const { change, values } = this.props;

    const params = new URL(window.location.href).searchParams;

    const refreshToken = params.get(constants.refreshToken);
    const error = params.get('error');
    const partnerValues = JSON.parse(localStorage.getItem('partnerValues'));
    if (refreshToken && !_.isEmpty(values) && !values.refreshToken) {
      change('ADWORDS.refreshToken', refreshToken || '');
      change('ADWORDS.clientCustomerId', partnerValues.clientCustomerId || '');
      change('ADWORDS.name', partnerValues.name || '');
    }
    // Filling fields once from localStorage in case access denied. This is only in 2 places, thats why refactoring for now is not a good idea
    if (error === 'access_denied' && !_.isEmpty(values) && !values.clientCustomerId) {
      change('ADWORDS.clientCustomerId', partnerValues.clientCustomerId || '');
      change('ADWORDS.name', partnerValues.name || '');
    }
  }

  render() {
    const {
      t,
      values,
      partner,
      payloadTypes,
      change,
      redirectToAuthorizationUrl,
      touch,
      canUpdate: isEditMode,
    } = this.props;

    const initialPayaladType = partner?.configuration?.payloadType?.name ?? '';

    const getPartnerNumber = value => constants.ADWORDS_PARTNER_NUMBERS[value];

    const isMobileOrCRMPartnerType = payloadType => payloadType === 'CRM_ID' || payloadType === 'MOBILE_ID';
    const isShowPartnerNumber = payloadType => !isEditMode && isMobileOrCRMPartnerType(payloadType);

    return (
      <FormSection name={constants.partnerTypes.ADWORDS}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.storeMappingMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection
          title={t('form.formFields.refreshToken')}
          description={t('form.notification.oAuthMessage', { partner: 'Google Ads' })}
          extra={renderMessage(t)}
        >
          <Field
            label={t('form.formFields.clientCustomerId')}
            name="clientCustomerId"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
          />
          <div className="u-marginVerticalL u-flex-justify-end">
            <GoogleButton
              disabled={!values.clientCustomerId}
              onClick={() => redirectToAuthorizationUrl()}
              label={t('form.formFields.grantPermissionWithGoogle')}
            />
          </div>
          <FieldWithEditMode
            label={t('form.formFields.refreshToken')}
            name="refreshToken"
            component={ReduxFormSensitiveField}
            isNeedActivateEditMode={isEditMode}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={!isEditMode && tokenIsRequired}
            type="text"
            disabled={true}
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={isEditMode ? payloadTypes.filter(option => !isMobileOrCRMPartnerType(option.value)) : payloadTypes}
            validate={isRequired}
            disabled={isEditMode && isMobileOrCRMPartnerType(initialPayaladType)}
            onChange={(event, value) => {
              change('ADWORDS.partnerNumber', getPartnerNumber(value));
            }}
            touch={touch}
          />
          {isShowPartnerNumber(values.payloadType) && (
            <Field
              label={t('form.formFields.partnerNumberLabel')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
            />
          )}
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default AdwordsForm;
