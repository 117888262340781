import React from 'react';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '../../i18n';
import './styles.scss';

const EditExperimentTrigger = ({ showExperimentSettingsModal, children = null, isSVG = false, canEdit }) => {
  if (isSVG) {
    return (
      <g className="EditExperiment-clickableArea" onClick={showExperimentSettingsModal}>
        {children}
      </g>
    );
  }
  return (
    <BtnIcon
      icon="experiment"
      testHook="editExperiment"
      onClick={showExperimentSettingsModal}
      tooltip={i18n.t(`orchestration:${canEdit ? 'tooltips.experimentSettings' : 'permissions.notEnoughPermissions'}`)}
      disabled={!canEdit}
    />
  );
};

export default EditExperimentTrigger;
