import React, { useContext } from 'react';
import { get } from 'lodash';
import CardBase from './CardBase';
import { TriggerNode, TriggerNodePlaceholder } from './TriggerNode';
import { StepNode } from './StepNode';
import { NodeProps } from './types';
import { CanvasContext } from '../JourneyCanvas/CanvasContext';

const componentsByNodeType = {
  START_TRIGGER: TriggerNode,
  TRIGGER: TriggerNode,
  STEP: StepNode,
};

const getNodeComponentByType = (nodeType: keyof typeof componentsByNodeType) =>
  componentsByNodeType[nodeType] || TriggerNodePlaceholder;

const FilterDefinitions = () => (
  <defs>
    <clipPath id="jo-clip-outside-node">
      <CardBase />
    </clipPath>
    <filter id="jo-card-shadow" height="1.4" width="1.3">
      <feDropShadow dx="1" dy="3" stdDeviation="3" floodColor="#c2c2c2" floodOpacity="0.5" />
    </filter>
  </defs>
);

const Nodes = ({ nodes, canEdit, startTriggerError, showExperimentSettingsModal }: NodeProps) => {
  const { nodesErrors } = useContext(CanvasContext);
  return (
    <>
      <FilterDefinitions />
      {nodes.map(({ x, y, data, parent }) => {
        const NodeComponent = getNodeComponentByType(data.type);
        const isStartTriggerError = !data.type && startTriggerError;
        const nodeErrors = nodesErrors[data.nodeId];
        const hasNodeError = isStartTriggerError || get(nodeErrors, 'DATA_ERROR.length', 0) > 0;
        const hasButtonError = get(nodeErrors, 'STRUCTURE_ERROR.length', 0) > 0;

        return (
          <NodeComponent
            hasNodeError={hasNodeError}
            hasLinkError={hasButtonError}
            key={data.nodeId}
            x={x}
            y={y}
            data={data}
            parent={parent}
            canEdit={canEdit}
            showExperimentSettingsModal={showExperimentSettingsModal}
          />
        );
      })}
    </>
  );
};

export default Nodes;
