/**
 * @description Angular main module for list and create the Journey
 * @memberOf webUi
 * @namespace webUi.ui.customer.journey
 */
angular.module('webUi.ui.customer.journey',
    [
        'webUi.ui.customer.journey.dashboard',
        'webUi.ui.customer.journey.form'
    ]
);
