import React from 'react';
import { Formik, Field } from 'formik';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import ActionButtons from '~/components/src/ActionButtons';
import Notification from '~/components/src/Notification';
import { showSuccess } from '~/notificationCenter';
import { updateTitle, updateExpiryTime } from './dataService';

import './styles.scss';

const handleSubmit = (journeyId, hideModal, fetchJourneyData, t) => async values => {
  const requests = [
    updateTitle(journeyId, values.name),
    updateExpiryTime(journeyId, values.expiryTime + values.timeUnit),
  ];

  await Promise.all(requests).then(() => {
    hideModal();
    fetchJourneyData();
    showSuccess({
      header: t('journeyModal.successToast'),
    });
  });
};

const makeValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string().trim().required(t('validation:validation.required')),
    expiryTime: Yup.number()
      .min(1, t('journeyModal.validations.expiryTime'))
      .required(t('validation:validation.required')),
  });

const EditJourney = ({ name = '', expiryTime = '', journeyId, fetchJourneyData, hideModal, t }) => {
  const validationSchema = makeValidationSchema(t);
  const initialValues = {
    name,
    expiryTime: expiryTime ? expiryTime.substring(0, expiryTime.length - 1) : 90,
    timeUnit: expiryTime ? expiryTime[expiryTime.length - 1] : 'd',
  };
  return (
    <div className="EditJourney">
      <Formik
        onSubmit={handleSubmit(journeyId, hideModal, fetchJourneyData, t)}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ handleSubmit, setFieldValue, errors, isValid, isSubmitting }) => (
          <>
            <Notification kind="information">
              <span>{t('journeyModal.expiryTip')}</span>
            </Notification>
            <Field
              name="name"
              as={FormikInputField}
              label={t('journeyModal.placeholders.name')}
              placeholder={t('connectors:common.commonField.pleaseProvideValue')}
              errorText={errors.name}
              autoFocus={true}
            />
            <div className="EditJourney-timeSettings">
              <Field
                name="expiryTime"
                as={FormikInputField}
                label={t('journeyModal.placeholders.expiryTime')}
                errorText={errors.expiryTime}
                placeholder={t('connectors:common.commonField.pleaseProvideValue')}
                min={1}
                type="number"
                className="EditJourney-expiryTime"
              />
              <Field
                name="timeUnit"
                as={FormikSelectField}
                className="EditJourney-timeUnit"
                options={[
                  { value: 'm', label: t('common:minutes') },
                  { value: 'h', label: t('common:hours') },
                  { value: 'd', label: t('common:days') },
                  { value: 'w', label: t('common:weeks') },
                ]}
                customSetFieldValue={(name, option) => {
                  setFieldValue(name, option.value);
                }}
                menuPosition="fixed"
              />
            </div>

            <ActionButtons
              className="mr-0"
              onConfirm={handleSubmit}
              onDecline={hideModal}
              isConfirmEnabled={isValid && !isSubmitting}
              testHook="editJourneyButtons"
              confirmText={t('journeyModal.update')}
              declineText={t('journeyModal.cancel')}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default translate('journey')(EditJourney);
