import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const getFacebookURL = parnerId => `${API_BASE}/v1/partners/facebook/${parnerId}/audiences`;

const getFacebookList = parnerId =>
  Api.callGet(getFacebookURL(parnerId), { shouldShowToast: false, shouldHandleCommonErrors: false }).then(
    parseResponse,
  );

const addAudienceInFacebook = (parnerId, payload) =>
  Api.callPost(getFacebookURL(parnerId), payload, { shouldHandleCommonErrors: false }).then(parseResponse);

export { getFacebookList, addAudienceInFacebook };
