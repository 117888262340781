import React from 'react';
import ReactDOM from 'react-dom';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '~/i18n';

export const ZoomButtons = ({ onZoomInClick, onZoomOutClick, onZoomReset }: any) => (
  <div className="fixed bottom-4 left-4 flex flex-row gap-2">
    <BtnIcon tooltip={i18n.t('workflow:journey.zoomIn')} icon="zoomIn" onClick={onZoomInClick} />
    <BtnIcon tooltip={i18n.t('workflow:journey.zoomOut')} icon="zoomOut" onClick={onZoomOutClick} />
    <BtnIcon tooltip={i18n.t('workflow:journey.zoomCenter')} icon="zoomCenter" onClick={onZoomReset} />
  </div>
);
export const PortalToReactRoot = (props: any) =>
  ReactDOM.createPortal(props.children, document.getElementById('reactRoot') as HTMLElement);
