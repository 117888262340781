import {
  CreateMapping,
  FetchKnownDestinationsResponseItem,
  Destination,
  ValueTransformation,
  ValueTransformationPayload,
  PredefinedSources,
  Sources,
} from './transformerTypes';
import {
  createValueTransformation,
  createValueTransformationsFromPayload,
  temporaryValueTransformationId,
} from './valueTransformation/valueTransformation';
import { withChangeHandler } from './utils';

export const temporaryMappingId = (): string =>
  `newMapping-${Math.random()
    .toString()
    .slice(-5)}`;

const getDestinationByName = (
  knownDestinations: FetchKnownDestinationsResponseItem[],
  destinationName: string,
): Destination => {
  const knownDestination = knownDestinations.find(destination => destination.name === destinationName);

  if (!knownDestination) {
    return {
      name: destinationName,
      label: destinationName,
      element: 'custom_data',
    };
  }

  const destination = {
    name: knownDestination.name,
    label: knownDestination.label,
    element: knownDestination.element,
  } as Destination;

  if (Array.isArray(knownDestination.values)) {
    destination.values = knownDestination.values;
  }

  return destination;
};

const getSuggestedValueTransformations = (
  destinationName: string,
  knownDestinations: FetchKnownDestinationsResponseItem[],
): ValueTransformationPayload[] => {
  const knownDestination = knownDestinations.find(knownDestination => knownDestination.name === destinationName);

  if (!knownDestination?.suggestedValueTransformations) {
    return [];
  }

  return knownDestination.suggestedValueTransformations;
};

export const createMapping: CreateMapping = (mappingConfiguration, additionalParameters, onChange) => {
  const suggestedValueTransformations = additionalParameters.shouldAddSuggestedValueTransformations
    ? getSuggestedValueTransformations(mappingConfiguration.destinationName, additionalParameters.knownDestinations)
    : [];

  const valueTransformationsConfiguration = mappingConfiguration.valueTransformations.concat(
    suggestedValueTransformations,
  );

  let _valueTransformations = createValueTransformationsFromPayload(valueTransformationsConfiguration, onChange);

  let _sourceName = mappingConfiguration.sourceName;
  let _destination = getDestinationByName(additionalParameters.knownDestinations, mappingConfiguration.destinationName);
  let _allowsCustomSources = !Array.isArray(_destination.values);
  let _required = mappingConfiguration.required;

  const listAvailableSources = (): Sources | PredefinedSources => {
    if (Array.isArray(_destination.values)) {
      // If destination specifies which values are allowed - return them instead of normal sources
      return _destination.values;
    }

    return mappingConfiguration.sources;
  };

  const listAvailableValueTransformations = () => additionalParameters.knownValueTransformations;

  const addValueTransformation = (): ValueTransformation => {
    const valueTransformationConfiguration = {
      id: temporaryValueTransformationId(),
      transformationType: '' as const,
    };

    const valueTransformation = createValueTransformation(valueTransformationConfiguration, onChange);
    _valueTransformations.push(valueTransformation);

    return valueTransformation;
  };

  const deleteValueTransformation = (valueTransformationId: string) => {
    _valueTransformations = _valueTransformations.filter(
      valueTransformation => valueTransformation.id !== valueTransformationId,
    );
  };

  const moveValueTransformation = (fromIndex: number, toIndex: number) => {
    const itemToMove = _valueTransformations[fromIndex];
    _valueTransformations.splice(fromIndex, 1);
    _valueTransformations.splice(toIndex, 0, itemToMove);
  };

  const setSourceName = (sourceName: string) => {
    if (Array.isArray(_destination.values)) {
      if (_destination.values.includes(sourceName)) {
        _sourceName = sourceName;
      }
      return;
    }

    _sourceName = sourceName;
  };

  const setDestination = (destinationName: string) => {
    const doesPreviousDestinationAllowCustomSources = !Array.isArray(_destination.values);

    const newDestination = getDestinationByName(additionalParameters.knownDestinations, destinationName);
    _destination = newDestination;

    const doesNewDestinationAllowCustomSources = !Array.isArray(_destination.values);
    _allowsCustomSources = doesNewDestinationAllowCustomSources;

    if (
      doesPreviousDestinationAllowCustomSources &&
      !doesNewDestinationAllowCustomSources &&
      _destination.values?.length
    ) {
      // Set new sourceName if selected destination doesn't allow custom sources anymore
      const newSourceName = _destination.values[0];
      _sourceName = newSourceName;
    }

    if (
      !doesPreviousDestinationAllowCustomSources &&
      doesNewDestinationAllowCustomSources &&
      mappingConfiguration.sources.length
    ) {
      // Set new sourceName if selected destination now allows custom sources
      const newSourceName = mappingConfiguration.sources[0];
      _sourceName = newSourceName;
    }

    const suggestedValueTransformations = getSuggestedValueTransformations(
      destinationName,
      additionalParameters.knownDestinations,
    );

    _valueTransformations = createValueTransformationsFromPayload(suggestedValueTransformations, onChange);
  };

  const setRequired = (required: boolean) => {
    _required = required;
  };

  const methods = withChangeHandler(
    {
      addValueTransformation,
      deleteValueTransformation,
      moveValueTransformation,
      setSourceName,
      setDestination,
      setRequired,
    },
    onChange,
  );

  const mapping = {
    id: mappingConfiguration.id,
    isDeletable: mappingConfiguration.isDeletable,
    listAvailableSources,
    listAvailableValueTransformations,
    get sourceName() {
      return _sourceName;
    },
    get destination() {
      return _destination;
    },
    get allowsCustomSources() {
      return _allowsCustomSources;
    },
    get required() {
      return _required;
    },
    get valueTransformations() {
      return _valueTransformations;
    },
    setSourceName: methods.setSourceName,
    setDestination: methods.setDestination,
    setRequired: methods.setRequired,
    addValueTransformation: methods.addValueTransformation,
    deleteValueTransformation: methods.deleteValueTransformation,
    moveValueTransformation: methods.moveValueTransformation,
  };

  return mapping;
};
