import React from 'react';
import cx from 'classnames';
import i18n from '~/i18n';
import { ExpandIcon, CollapseIcon } from './Icons';
import { TooltipForJourneyTree } from './ButtonsPanel';

const Rect = ({ x, y, onClick, className, children, isCollapsed }: any) => (
  <g
    transform={`translate(${x}, ${y})`}
    onClick={onClick}
    className={cx('JourneyView-expandCollapseButton', className)}
  >
    <rect
      x="-16"
      y="-16"
      rx="10"
      ry="10"
      strokeWidth={2}
      className={cx('h-10 w-10 fill-white stroke-gray-400', { '': isCollapsed })}
    />
    {children}
  </g>
);

export const ExpandCollapseButton = ({
  x,
  y,
  onClick,
  className,
  tooltipText,
  tooltipHeight,
  tooltipWidth,
  isCollapsed,
}: any) => (
  <Rect x={x} y={y} onClick={onClick} className={className} isCollapsed={isCollapsed}>
    {isCollapsed ? (
      <>
        <ExpandIcon x={-10} y={-10} scale={1.2} />
        <TooltipForJourneyTree
          x={-tooltipWidth / 2}
          y={48}
          height={tooltipHeight}
          width={tooltipWidth}
          text={i18n.t('workflow:journey.expandNodes')}
        />
      </>
    ) : (
      <>
        <CollapseIcon x={-10} y={-10} scale={1.2} />
        {tooltipText && (
          <TooltipForJourneyTree
            x={-tooltipWidth / 2}
            y={48}
            height={tooltipHeight}
            width={tooltipWidth}
            text={tooltipText}
          />
        )}
      </>
    )}
  </Rect>
);
