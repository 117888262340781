import i18n from '~/i18n';
import { boolean, string, object, array, date, ObjectSchema } from 'yup';
import { dateValidator } from '~/components/src/DateValidator/validation';
import { FormikInputFieldType } from '~/context/components/APIKeys/types';
import { FieldLengths, VALUEMAPPING_DEFAULT_OPTION, transformationTypes } from './constants';
import { TransformationListType } from '../types';

const makeUniqueTransformationValidator =
  (existingTransformations: TransformationListType[]) => (inputValue: FormikInputFieldType) => {
    const lowerCaseVariables = existingTransformations.map(existingTransformation =>
      existingTransformation.name.toLowerCase(),
    );
    const isUsedAlready = inputValue && lowerCaseVariables.includes(inputValue?.toLowerCase());
    return !isUsedAlready;
  };

export const valueTransformationSchema = object({
  transformationType: string(),
  inputFormat: string()
    .when('transformationType', {
      is: transformationTypes.DATE_FORMAT,
      then: () => dateValidator.nullable(),
    })
    .when('transformationType', {
      is: transformationTypes.DATE_TO_EPOCH_SECONDS,
      then: () => string().required(i18n.t('validation:validation.required')),
    }),
  outputFormat: date().when('transformationType', {
    is: transformationTypes.DATE_FORMAT,
    then: () => dateValidator.nullable(),
  }),
  dateFormat: string().when('transformationType', {
    is: transformationTypes.EPOCH_SECONDS_TO_DATE,
    then: () => string().required(i18n.t('validation:validation.required')),
  }),
  offset: string().when('transformationType', {
    is: transformationTypes.EPOCH_SECONDS_TO_DATE,
    then: () =>
      string()
        .matches(/^[+-](((0[0-9]|1[0-7])(:?)[0-5][0-9])|((1[0-8])(:?)0{2}))$/, {
          excludeEmptyString: true,
          message: i18n.t('validation:validation.timeZoneOffset'),
        })
        .nullable(),
  }),

  algorithm: string().when('transformationType', {
    is: transformationTypes.HASHING,
    then: () => string().required(i18n.t('validation:validation.required')),
  }),

  delimiter: string().when('transformationType', {
    is: transformationTypes.STRING_TO_ARRAY,
    then: () => string().required(i18n.t('validation:validation.required')),
  }),

  valueMappings: array().when('transformationType', {
    is: transformationTypes.VALUE_MAPPING,
    then: () =>
      array()
        .of(
          object().shape({
            from: string(),
            to: string().required(i18n.t('validation:validation.required')),
          }),
        )
        .required(i18n.t('validation:validation.required')),
  }),
});

export const mappingSchema = object().shape({
  id: string(),
  sourceName: string().trim().required(i18n.t('validation:validation.required')),
  destinationName: string().trim().required(i18n.t('validation:validation.required')),
  required: boolean(),
  transformations: array().of(valueTransformationSchema),
});

export const eventSchema = object().shape({
  mappings: array().of(mappingSchema).min(1).required(i18n.t('validation:validation.required')),
});

export const makeTransformationSchema = (existingTransformations: TransformationListType[]): ObjectSchema =>
  object().shape({
    name: string()
      .trim()
      .min(FieldLengths.MIN, i18n.t('validation:validation.minLength', { min: FieldLengths.MIN }))
      .max(FieldLengths.MAX, i18n.t('validation:validation.maxLength', { max: FieldLengths.MAX }))
      .test(
        'transformationName-name-is-unique',
        i18n.t('transformations:validation.uniqueTransformation'),
        makeUniqueTransformationValidator(existingTransformations),
      )
      .required(i18n.t('validation:validation.required')),
    events: array().of(eventSchema).min(1),
  });
