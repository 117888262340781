import React, { useEffect, useRef, useState, useContext } from 'react';
import cx from 'classnames';

import { TooltipForJourneyTree } from '~/customer/components/ButtonsPanel';
import { PlusIcon, ExperimentIcon, StepIcon, TransitionIcon, TriggerIcon } from './Icons';
import { ActionsContext, TOOLTIP_WIDTH } from './constants';

export const RoundButton = ({
  x,
  y,
  onClick,
  className,
  children,
  hasError,
  hasTransition,
  circleClassName,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
}) => (
  <g
    transform={`translate(${x}, ${y})`}
    onClick={onClick}
    className={cx('JourneyView-roundAddButton', { 't-nodeHasError': hasError }, className)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onMouseMove={onMouseMove}
  >
    <circle
      className={cx('fill-white stroke-gray-500', circleClassName, {
        'fill-green-50 stroke-green-400': hasTransition,
        'fill-red-100 stroke-red-500': hasError,
      })}
      cx={0}
      cy={0}
      r={hasTransition ? 32 : 24}
      strokeWidth={hasTransition ? 4 : 2}
    />
    {children}
  </g>
);

export const RoundAddButton = ({ x, y, onClick, className, tooltipText, tooltipHeight, tooltipWidth }) => (
  <RoundButton x={x} y={y} onClick={onClick} className={cx('cursor-pointer', className)}>
    <PlusIcon x={-20} y={-20} className="fill-gray-500" scale={2} />
    {tooltipText && (
      <TooltipForJourneyTree
        x={-tooltipWidth / 2}
        y={48}
        height={tooltipHeight}
        width={tooltipWidth}
        text={tooltipText}
      />
    )}
  </RoundButton>
);

export const RoundExperimentButton = ({ x, y, onClick, className, tooltipText, tooltipHeight, tooltipWidth }) => (
  <RoundButton x={x} y={y} onClick={onClick} className={cx(className, 'cursor-pointer')}>
    <ExperimentIcon x={-15} y={-15} scale={1.5} />
    {tooltipText && (
      <TooltipForJourneyTree
        x={-tooltipWidth / 2}
        y={48}
        height={tooltipHeight}
        width={tooltipWidth}
        text={tooltipText}
      />
    )}
  </RoundButton>
);

export const RoundTriggerButton = ({
  x,
  y,
  onClick,
  className,
  tooltipText,
  tooltipHeight,
  tooltipWidth,
  hasError,
}) => (
  <RoundButton x={x} y={y} onClick={onClick} className={cx(className, 'cursor-pointer')} hasError={hasError}>
    <TriggerIcon x={-15} y={-15} scale={1.5} className={cx('fill-gray-500', { 'fill-red-500': hasError })} />
    {tooltipText && (
      <TooltipForJourneyTree
        x={-tooltipWidth / 2}
        y={48}
        height={tooltipHeight}
        width={tooltipWidth}
        text={tooltipText}
      />
    )}
  </RoundButton>
);

export const RoundTransitionButton = ({
  x,
  y,
  onClick,
  className,
  tooltipText,
  tooltipHeight,
  tooltipWidth,
  hasError,
  circleClassName,
}) => (
  <RoundButton
    x={x}
    y={y}
    onClick={onClick}
    className={cx(className, 'cursor-pointer')}
    hasError={hasError}
    circleClassName={circleClassName}
  >
    <TransitionIcon x={-14} y={-14} scale={1.5} className={cx('fill-blue-400', { 'fill-red-400': hasError })} />
    {tooltipText && (
      <TooltipForJourneyTree
        x={-tooltipWidth / 2}
        y={48}
        height={tooltipHeight}
        width={tooltipWidth}
        text={tooltipText}
      />
    )}
  </RoundButton>
);

export const RoundStepButton = ({ x, y, onClick, hasError }) => (
  <RoundButton x={x} y={y} className="t-create-step-button cursor-pointer" onClick={onClick} hasError={hasError}>
    <StepIcon x={-15} y={-15} scale={1.5} className={cx('fill-gray-500', { 'fill-red-500': hasError })} />
    <TooltipForJourneyTree x={-TOOLTIP_WIDTH / 2} y={48} text="Add step" />
  </RoundButton>
);

export const RoundStepOrTransitionButton = ({ x, y, className, hasError, triggerId }) => {
  const { goToCreateStepScreen, showTransitionModal } = useContext(ActionsContext);

  const [areAdditionalButtonsVisible, setAreAdditionalButtonsVisible] = useState(false);
  const [isAnimationAdded, setIsAnimationAdded] = useState(false);

  const timeoutIdRef = useRef(null);

  const hideAdditionalButtons = () => {
    setAreAdditionalButtonsVisible(false);
  };

  const showAdditionalButtons = () => {
    clearTimeout(timeoutIdRef.current);
    setAreAdditionalButtonsVisible(true);
  };

  const hideAdditionalButtonsAfterTimeout = () => {
    timeoutIdRef.current = setTimeout(() => {
      setIsAnimationAdded(false);
      setTimeout(hideAdditionalButtons, 500);
    }, 1000);
  };

  useEffect(() => {
    if (areAdditionalButtonsVisible && !isAnimationAdded) {
      setIsAnimationAdded(true);
    }
  }, [areAdditionalButtonsVisible]);

  return (
    <>
      {areAdditionalButtonsVisible && (
        <>
          <polyline
            className="opacity-0"
            points={`
            ${x - 30},${y} ${x + 20},${y - 70} ${x + 60},${y - 90} ${x + 90},${y - 70} 
            ${x + 90},${y + 70} ${x + 60},${y + 90} ${x + 20},${y + 70}`}
            onMouseEnter={showAdditionalButtons}
            onMouseLeave={hideAdditionalButtonsAfterTimeout}
          />
          <RoundButton
            x={x}
            y={y}
            className={cx('t-create-step-button cursor-pointer transition-all duration-500 ease-in-out', {
              'scale-50': !isAnimationAdded,
              'translate-x-[16px]': !isAnimationAdded,
              'translate-y-[16px]': !isAnimationAdded,
              'translate-x-[70px]': isAnimationAdded,
              '-translate-y-[30px]': isAnimationAdded,
            })}
            onClick={() => {
              goToCreateStepScreen(triggerId);
            }}
            onMouseEnter={showAdditionalButtons}
          >
            <StepIcon x={-15} y={-15} scale={1.5} className={cx('fill-gray-500', { 'fill-red-500': hasError })} />
            <TooltipForJourneyTree x={-TOOLTIP_WIDTH / 2} y={-84} text="Add step" position="top" />
          </RoundButton>
          <RoundButton
            x={x}
            y={y}
            className={cx(
              className,
              't-create-transition-button cursor-pointer transition-all delay-100 duration-500 ease-in-out',
              {
                'scale-50': !isAnimationAdded,
                'translate-x-[16px]': !isAnimationAdded,
                'translate-y-[16px]': !isAnimationAdded,
                'translate-x-[70px]': isAnimationAdded,
                'translate-y-[60px]': isAnimationAdded,
              },
            )}
            onClick={() => {
              showTransitionModal(triggerId);
              hideAdditionalButtonsAfterTimeout();
            }}
            onMouseEnter={showAdditionalButtons}
          >
            <TransitionIcon x={-14} y={-14} scale={1.5} className="!fill-gray-500" />
            <TooltipForJourneyTree x={-TOOLTIP_WIDTH / 2} y={48} text="Add transition" />
          </RoundButton>
        </>
      )}
      <RoundButton
        x={x}
        y={y}
        className={cx(
          className,
          'cursor-default',
          'transition-all',
          'translate-x-[16px]',
          'translate-y-[16px]',
          'ease-in-out',
          {
            'scale-50': isAnimationAdded,
            'translate-x-[16px]': !isAnimationAdded,
          },
        )}
        hasError={hasError}
        onMouseEnter={showAdditionalButtons}
      >
        <PlusIcon
          x={-20}
          y={-20}
          className={cx('fill-gray-500', { 'fill-red-500': hasError, 'opacity-0': isAnimationAdded })}
          scale={2}
        />
      </RoundButton>
    </>
  );
};
