import React from 'react';
import { buildUrl } from '~/common';
import Link from '~/components/src/Link';
import Spinner from '~/components/src/Spinner';
import { components } from '~/components/src/Table';
import SortableHeader from '~/components/src/Table/sortableHeader';
import './styles.scss';

const { Table, Row, Cell, HeaderCell } = components;

export const OverlapJourneyList = ({ list, stateKey, t, hideModal }) => {
  if (!list) {
    return <Spinner />;
  }
  return (
    <div className="OverlapJourneyList">
      <Table stateKey={stateKey} testHook="dependantsListTable">
        <Row>
          <SortableHeader sortKey="name" title={t('dependants:list.journeyHeader')} className="u-size1of2" />
          <HeaderCell className="u-size1of2">{t('dependants:list.triggerHeader')}</HeaderCell>
        </Row>
        {list.map(dependantInfo => (
          <Row key={dependantInfo.journeyId}>
            <Cell className="u-size1of2 JourneyDependancy">
              <Link
                className="JourneyDependancy-link"
                href={buildUrl(`customer/journeys/edit/${dependantInfo.journeyId}`)}
                onClick={hideModal}
                testHook="journeyLink"
              >
                {dependantInfo.journeyName}
              </Link>
            </Cell>
            <Cell className="u-size1of2 JourneyDependancy">
              <Link
                className="JourneyDependancy-link"
                href={buildUrl(
                  `customer/journeys/edit/${dependantInfo.journeyId}/triggerEdit/${dependantInfo.triggerId}`,
                )}
                onClick={hideModal}
                testHook="triggerLink"
              >
                {dependantInfo.triggerName}
              </Link>
            </Cell>
          </Row>
        ))}
      </Table>
    </div>
  );
};

export default OverlapJourneyList;
