import React, { useState } from 'react';
import Heading from '~/components/src/Heading';
import OverlayPageContainer from '~/customer/components/OverlayPageContainer';
import EditableIntegrationsList from '~/common/modules/connectors/components/EditableConnectorsList';
import JoNodeTitleForm from '~/customer/components/JoNodeTitleForm';
import { getExperimentNodes } from '~/customer/components/utils';
import JourneyDataService from '~/customer/journeys/dataService';
import Btn from '~/components/src/Btn';
import { showSuccess } from '~/notificationCenter/actions';
import { shapeIntegrationsRequest, shapeEventListeners } from '~/profiles/audiences/utils';
import Page from '~/components/src/Page';
import { MediumWrapper } from '~/components/src/Containers';
import Notification from '~/components/src/Notification';
import { updateStep, createStep } from './service';
import EditableEventListenerList from './EditableEventListenerList';
import './styles.scss';

const EditStepScreen = ({
  goToJourneyView,
  allConnectors,
  integrations,
  eventsListeners,
  usedVariables,
  stepName,
  stepId,
  t,
  journeyId,
  setJourneyContents,
  parentTriggerId,
  fetchJourneyData,
  showExperimentSettingsModal,
  ruleTypesTree,
  // eslint-disable-next-line
}) => {
  const isEdit = !!stepId;

  const [stepTitleFormState, setStepTitleFormState] = useState({
    isValid: isEdit,
    title: isEdit ? stepName : '',
  });

  const [integrationsFormState, setIntegrationsFormState] = useState({
    areIntegrationsValid: true,
    integrations: isEdit ? integrations : [],
  });

  const [eventsListenerFormState, setEventsListenerFormState] = useState({
    areEventsListenersValid: true,
    eventsListeners: isEdit ? eventsListeners : [],
  });

  const isFormValid =
    stepTitleFormState.isValid &&
    integrationsFormState.areIntegrationsValid &&
    eventsListenerFormState.areEventsListenersValid;

  const showEventsWarning =
    eventsListenerFormState?.eventsListeners.length > 0 &&
    !integrationsFormState?.integrations?.every(integration => integration?.supportsEventListeners);

  const pageTitle = isEdit ? t('steps.editStep') : t('steps.createStep');

  const [wasSubmitButtonClicked, setWasSubmitButtonClicked] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!wasSubmitButtonClicked) {
      setWasSubmitButtonClicked(true);
    }

    const canSubmit = isFormValid && !isSubmitting;

    if (!canSubmit) {
      return;
    }

    setIsSubmitting(true);
    const integrationsPayload = shapeIntegrationsRequest(integrationsFormState.integrations);
    const evenListenersPayload = shapeEventListeners(eventsListenerFormState.eventsListeners);

    try {
      if (isEdit) {
        await updateStep(journeyId, stepId, stepTitleFormState.title, integrationsPayload, evenListenersPayload);
        showSuccess({ header: t('steps.stepSaved') });
      } else {
        await createStep(
          journeyId,
          stepTitleFormState.title,
          integrationsPayload,
          parentTriggerId,
          evenListenersPayload,
        );
        showSuccess({ header: t('steps.stepCreated') });
      }
      fetchJourneyData();
      const newJourneyContents = await JourneyDataService.refreshJourney(journeyId, setJourneyContents);

      if (!isEdit) {
        const firstTrigger = newJourneyContents?.nodes ?? {};
        const { experimentSteps, hasExperiments } = getExperimentNodes(firstTrigger);

        if (hasExperiments && experimentSteps.find(step => step.parentTriggerId === parentTriggerId)) {
          showExperimentSettingsModal();
        }
      }
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <OverlayPageContainer className="EditStep-container">
      <Page>
        <MediumWrapper>
          <Heading
            title={pageTitle}
            testHook="createEditTriggerHeading"
            crumbs={[
              {
                title: t('journey:back'),
                onClick: () => {
                  goToJourneyView();
                },
              },
            ]}
          />

          <JoNodeTitleForm
            titleValue={stepTitleFormState.title}
            setTitleFormState={setStepTitleFormState}
            label={t('steps.stepNameLabel')}
            wasSubmitButtonClicked={wasSubmitButtonClicked}
          />
          <EditableIntegrationsList
            stageName={stepTitleFormState.title}
            allConnectors={allConnectors}
            integrations={integrationsFormState.integrations}
            onChange={setIntegrationsFormState}
            usedVariables={usedVariables}
            isDefaultConnectorDisplayed={false}
            isSubmitted={wasSubmitButtonClicked}
            type="Journey"
          />
          <hr />
          <Notification className="EditStep-eventsInfo" header={t('eventListener.infoHeader')}>
            <ol className="px-6 py-2">
              <li>{t('eventListener.infoItem0')}</li>
              <li>{t('eventListener.infoItem1')}</li>
              <li>{t('eventListener.infoItem2')}</li>
              <li>{t('eventListener.infoItem3')}</li>
              <p>{t('eventListener.infoFooter')}</p>
            </ol>
          </Notification>
          {showEventsWarning && (
            <Notification kind="warning" className="EditStep-eventsInfo">
              <p>{t('eventListener.partialIntegrationsSupport')}</p>
            </Notification>
          )}
          <EditableEventListenerList
            eventsListeners={eventsListenerFormState.eventsListeners}
            ruleTypesTree={ruleTypesTree}
            usedVariables={usedVariables}
            onChange={setEventsListenerFormState}
          />
          <hr className="EditAudience-footerSeparator" />
          {wasSubmitButtonClicked && !isFormValid && (
            <Notification kind="error" header="">
              <p>
                {!eventsListenerFormState.areEventsListenersValid
                  ? t('eventListener.formInvalid')
                  : t('steps.formInvalid')}
              </p>
            </Notification>
          )}
          <div className="flex items-center justify-end gap-4 pb-6">
            <Btn onClick={goToJourneyView}>{t('common:actions.cancel')}</Btn>
            <Btn color="blue" testHook="saveStepButton" onClick={handleSubmit}>
              {t('common:actions.save')}
            </Btn>
          </div>
        </MediumWrapper>
      </Page>
    </OverlayPageContainer>
  );
};

export default EditStepScreen;
