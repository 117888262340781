import React from 'react';
import { formatNumber } from '~/common';
import { I18NextT } from '~/components/src/Common/types';
import Icons from '~/components/src/Icons';

import { LabelValue } from './LabelValue';
import { ProfilesExited } from './types';
import './styles.scss';

type JourneyStatsProps = {
  t: I18NextT;
  totalEntered: number;
  totalActive: number;
  totalReachedGoals: number;
  profilesExited: ProfilesExited;
};

const JourneyStats = ({
  t,
  totalEntered,
  totalActive,
  totalReachedGoals,
  profilesExited,
}: JourneyStatsProps): React.ReactElement => {
  const { totalProfilesExpired, totalProfilesRemoved, totalProfilesMerged, totalProfilesExitedByCondition } =
    profilesExited;
  return (
    <div className="t-journeyOverview border-b border-gray-200 bg-white p-4">
      <div className="mb-2 flex items-center gap-2 text-gray-600">
        <Icons icon="profiles" className="h-6 w-6" />
        <p className="font-medium ">{t('journey:profiles')}</p>
      </div>
      <LabelValue testHook="totalEntered" label={t('journey:entered')} value={formatNumber(totalEntered)} />
      <LabelValue testHook="totalReached" label={t('journey:reachedGoals')} value={formatNumber(totalReachedGoals)} />
      <LabelValue testHook="totalActive" label={t('journey:active')} value={formatNumber(totalActive)} />
      <div className="my-2 border-b border-gray-400"></div>
      <LabelValue
        testHook="totalExitedByCondition"
        label={t('journey:exitConditions')}
        value={formatNumber(totalProfilesExitedByCondition)}
      />
      <LabelValue testHook="expired" label={t('journey:expired')} value={formatNumber(totalProfilesExpired)} />
      <LabelValue testHook="removed" label={t('journey:optedOut')} value={formatNumber(totalProfilesRemoved)} />
      <LabelValue testHook="merged" label={t('journey:merged')} value={formatNumber(totalProfilesMerged)} />
    </div>
  );
};

export default JourneyStats;
