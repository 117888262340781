import React, { ReactElement } from 'react';
import i18n from '~/i18n';
import { formatNumber } from '~/common';
import { Heading, Tooltip } from '~/components';
import { TotalStats } from '../types';

const JourneyAnalysis = ({ totalStats }: { totalStats: TotalStats }): ReactElement => {
  const { profilesEnteredJourney, profilesActive, totalProfilesReachedGoal, totalProfilesExited } = totalStats;

  const formatValue = (step: number, testHook: string) => {
    const percentage = profilesEnteredJourney && step ? ((step / profilesEnteredJourney) * 100).toFixed(0) : 0;

    return (
      <div className="flex items-center justify-end gap-1">
        <Tooltip tooltip={i18n.t(`journey:journeyStatistics.messages.${testHook}Note`)}>
          <p className={`t-${testHook}`}>{formatNumber(step)}</p>
        </Tooltip>
        <p className={`t-${testHook}Percent text-xs text-blue-400`}>{`${percentage}%`}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Heading kind="h3" title={i18n.t('journey:journeyStatistics.journeyOverview.header')} className="py-0" />
      <table>
        <thead>
          <tr>
            <td className="border p-2 font-medium"></td>
            <td className="border px-3 py-2 text-end font-medium text-gray-600">{i18n.t('journey:entered')}</td>
            <td className="border px-3 py-2 text-end font-medium text-gray-600">
              <Tooltip tooltip={i18n.t('journey:journeyStatistics.messages.totalActiveNote')}>
                <span className="cursor-pointer truncate text-ellipsis text-gray-600">{i18n.t('journey:active')}</span>
              </Tooltip>
            </td>
            <td className="border px-3 py-2 text-end font-medium text-gray-600">
              <Tooltip tooltip={i18n.t('journey:journeyStatistics.messages.totalReachedNote')}>
                <span className="cursor-pointer truncate text-ellipsis text-gray-600">{i18n.t('journey:reached')}</span>
              </Tooltip>
            </td>
            <td className="border px-3 py-2 text-end font-medium text-gray-600">
              <Tooltip tooltip={i18n.t('journey:journeyStatistics.messages.totalExitedNote')}>
                <span className="cursor-pointer truncate text-ellipsis text-gray-600">
                  {i18n.t('common:tableColumns.exited')}
                </span>
              </Tooltip>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-3 py-2 font-medium text-gray-600">{i18n.t('journey:totalProfiles')}</td>
            <td className="t-totalProfilesEntered border bg-white px-3 py-2 text-end">
              {formatNumber(profilesEnteredJourney)}
            </td>
            <td className="border bg-white px-3 py-2">{formatValue(profilesActive, 'totalProfilesActive')}</td>
            <td className="border bg-white px-3 py-2">
              {formatValue(totalProfilesReachedGoal, 'totalProfilesReached')}
            </td>
            <td className="border bg-white px-3 py-2">{formatValue(totalProfilesExited, 'totalProfilesExited')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default JourneyAnalysis;
