import React, { useState, useEffect } from 'react';
import { Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useAPI } from '~/common/ApiHooks';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import BtnIcon from '~/components/src/BtnIcon';
import { I18NextT } from '~/components/src/Common/types';
import { Notification } from '~/components';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { addUserToAdwords, getAdwordsUsersList, getPayloadType } from './service';
import { getError } from './ErrorBlock';
import {
  CreateNewUserType,
  IAddUserPayload,
  AdwordsProps,
  IRemarketingUserListItem,
  IUseApiReturnValue,
  IAdwordsFormValues,
} from './types';

const validations = (t: I18NextT) =>
  Yup.object().shape({
    userList: Yup.object().required(t('connectors:cards.validation.remarketingUser')),
  });

const Adwords = ({ onEdit, connector, openCreateUserModal, hideModal, t, isSubmitted }: AdwordsProps) => {
  const { partnerId } = connector.partnerDetails;

  const [listItems, setListItems] = useState<IRemarketingUserListItem[]>([]);
  const [payloadType, setPayloadType] = useState('');
  const {
    data,
    error,
    isLoading: isListLoading,
  }: IUseApiReturnValue = useAPI(() => getAdwordsUsersList(partnerId), [partnerId]);

  useEffect(() => {
    getPayloadType(partnerId).then(setPayloadType);
  }, [partnerId]);

  const createNewUser: CreateNewUserType = ({ name, description, appId }, setFieldValue) => {
    const payload = {
      name,
      description,
    } as IAddUserPayload;

    if (appId) {
      payload.appId = appId;
    }

    addUserToAdwords(partnerId, payload)
      .then((response: IRemarketingUserListItem) => {
        setListItems([...listItems, response]);
        setFieldValue('userList', response);
        hideModal();
      })
      .catch(() => {
        // Ignore thrown errors
      });
  };

  const isMobileId = payloadType === 'MOBILE_ID';

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {(formProps: FormikProps<IAdwordsFormValues>) => {
        useEffect(() => {
          const userList = data || [];
          setListItems(userList);

          const userListId = formProps.values?.userList?.id;

          if (userListId) {
            const findUserList = userList.find(item => userListId === item?.id);

            if (findUserList) {
              formProps.setFieldValue('userList', findUserList);
            }
          }
        }, [data]);

        const existingUserNames = listItems.map(item => item.name);

        return (
          <>
            <SmallWrapperNoOffset>
              <div className="flex items-center gap-4">
                <Field
                  name="userList"
                  as={FormikSelectField}
                  label={t('connectors.adwords.fieldLabel')}
                  errorText={formProps.touched.userList && formProps.errors.userList}
                  options={listItems}
                  getOptionLabel={(option: IRemarketingUserListItem) => option.name}
                  getOptionValue={(option: IRemarketingUserListItem) => option.id}
                  value={formProps.values.userList}
                  isLoading={isListLoading}
                  testHook="adwordsUserList"
                  className="flex-1"
                />
                <BtnIcon
                  className="mt-3 rounded-full"
                  testHook="addSegment"
                  icon="add"
                  tooltip={t('connectors.adwords.userAdd.iconDesc')}
                  onClick={() =>
                    openCreateUserModal(createNewUser, formProps.setFieldValue, isMobileId, existingUserNames)
                  }
                />
              </div>
            </SmallWrapperNoOffset>
            {error && <Notification kind="error">{getError(error?.status?.error?.errorType, t)}</Notification>}
          </>
        );
      }}
    </ConnectorGenericForm>
  );
};

export default Adwords;
