import React, { useState } from 'react';
import { isNil } from 'lodash';
import moment from 'moment';
import DateRangePicker from '~/components/src/DateRangePicker';
import Icons from '~/components/src/Icons';
import { useParams } from 'react-router-dom';
import i18n from '~/i18n';
import StackedChart from '~/components/src/StackedChart';
import { formatNumber } from '~/common';
import OverlayPageContainer from '~/customer/components/OverlayPageContainer';
import { useAPI } from '~/common/ApiHooks';
import JourneyDataService from '~/customer/journeys/dataService';
import Spinner from '~/components/src/Spinner';
import { MediumWrapper, Panel } from '~/components/src/Containers';
import Heading from '~/components/src/Heading';
import DataExchangeGraph from '~/profiles/components/DataExchangeGraph/DataExchangeGraph';
import { I18NextT } from '~/components/src/Common/types';
import { Tabs, Tab, TabList } from '~/components/src/Tabs';
import { stepStatsSelector } from './selector';

interface StepStatsProps {
  journeyId: string;
  t: I18NextT;
  handleClose: () => void;
}

const ORIGINS = [
  { name: 'DEFAULT', label: i18n.t('journey:chart.default') as string },
  {
    name: 'HISTORIC_PROCESSING',
    label: i18n.t('journey:chart.historicProcessing') as string,
    description: i18n.t('journey:chart.historicProcessingDescription') as string,
  },
] as const;

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const StepStats = ({ journeyId, t, handleClose }: StepStatsProps): React.ReactNode => {
  const { stepId } = useParams() as { stepId: string };

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [filter, setFilter] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });
  const { startDate, endDate } = filter;
  const { data: stepData, error: stepFetchError } = useAPI(() => JourneyDataService.fetchStepData(stepId), [stepId]);

  const { data: hasStartTriggerParent } = useAPI(async () => {
    const journeyTree = await JourneyDataService.fetchJourneyTree(journeyId);
    const startTriggerChildren = journeyTree.nodes.children;
    const hasStartTriggerParent = !!startTriggerChildren.find(step => step.nodeId === stepId);
    return hasStartTriggerParent;
  }, []);

  const { data: dailyStatsResponse, error: stepFetchDailyStatsError } = useAPI(() => {
    if (typeof hasStartTriggerParent === 'boolean') {
      const promises = [
        JourneyDataService.fetchStepDailyStats(
          journeyId,
          stepId,
          startDate.startOf('day').toDate().getTime(),
          endDate.endOf('day').toDate().getTime(),
          'DEFAULT',
          t('orchestration:steps.fetchDailyStatsFailed'),
        ),
      ];

      if (hasStartTriggerParent) {
        promises.push(
          JourneyDataService.fetchStepDailyStats(
            journeyId,
            stepId,
            startDate.startOf('day').toDate().getTime(),
            endDate.endOf('day').toDate().getTime(),
            'HISTORIC_PROCESSING',
            t('orchestration:steps.fetchDailyStatsFailed'),
          ),
        );
      }

      return Promise.all(promises);
    }

    return Promise.resolve([]);
  }, [journeyId, stepId, startDate, endDate, hasStartTriggerParent]);

  const { data: stepTotalStats, error: stepFetchTotalStatsError } = useAPI(
    () => JourneyDataService.fetchStepTotalStats(journeyId, stepId),
    [journeyId, stepId],
  );

  const tooltip = {
    formatter() {
      const header = `<p style="font-size:12px; font-weight:bold; margin: -4px 0 2px 2px;">${this.points[0].key}</p>`;
      const barValues = this.points
        .map(
          (point: any) => `<table style="padding:0">
                <tr>
                    <td style="color:${point.series.color};padding:2px">${point.series.name}: </td>
                    <td style="padding:2px"><b>${formatNumber(point.y)}</b></td>
                </tr>
            </table>`,
        )
        .join('');
      return header + barValues;
    },
    footerFormat: null,
    backgroundColor: '#F1F1F1',
    shared: true,
    useHTML: true,
    crosshairs: true,
    className: 'JourneyView-stepStatisticsChart',
  };

  const stepDailyStats = dailyStatsResponse?.[selectedTabIndex];

  const isDataLoaded = stepData && stepDailyStats && stepTotalStats;

  if (stepFetchError || stepFetchDailyStatsError || stepFetchTotalStatsError) {
    return <p className="t-joStatisticsErrorMessage">{t('common:errors.genericError')}</p>;
  }
  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setFilter({
      startDate,
      endDate,
    });
  };

  return (
    <OverlayPageContainer>
      <MediumWrapper className="mb-4">
        {isDataLoaded ? (
          <>
            <Heading
              title={stepData.name}
              crumbs={[
                {
                  title: t('journey:back'),
                  onClick: () => {
                    handleClose();
                  },
                },
              ]}
              testHook="stepStatsPageHeading"
            >
              <DateRangePicker
                className=""
                testHook="stepStatsDatePicker"
                startDate={startDate}
                endDate={endDate}
                handleDatesChange={handleDatesChange}
              />
            </Heading>
            {!isNil(stepTotalStats.totalProfilesActive) && (
              <div className="flex items-center gap-2">
                <Icons icon="profiles" className="h-8 w-8 text-blue-500" />
                <p className="PageTitleHeader-totalProfiles flex gap-2">
                  {i18n.t('journey:chart.totalProfiles')}{' '}
                  <span className="font-bold">{formatNumber(stepTotalStats.totalProfilesActive)}</span>
                </p>
              </div>
            )}
            {hasStartTriggerParent && (
              <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
                <TabList>
                  {ORIGINS.map(origin => (
                    <Tab key={origin.name} testHook={`${origin.name}-tab`}>
                      {origin.label}
                      {'description' in origin && (
                        <>
                          <span className="ml-2"></span>
                          <Icons icon="info" tooltip={origin.description} className="h-4 w-4 text-gray-500" />
                        </>
                      )}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            )}
            <Panel className="mt-7">
              <div className="JourneyView-statistics--chart-container u-marginTopXl">
                <StackedChart
                  className="t-stepStatisticsChart"
                  data={stepStatsSelector(stepDailyStats, ORIGINS[selectedTabIndex].name)}
                  tooltip={tooltip}
                />
              </div>
            </Panel>
            <DataExchangeGraph integrations={stepData.integrations} sourceId={stepData.stepId} />
          </>
        ) : (
          <div className="JourneyView-statisticsSpinnerContainer">
            <Spinner />
          </div>
        )}
      </MediumWrapper>
    </OverlayPageContainer>
  );
};

export default StepStats;
