import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const getDoubleClickURL = partnerId => `${API_BASE}/remote/partners/doubleclick/${partnerId}/userLists`;

const getDoubleClickUsersList = partnerId =>
  Api.callGet(getDoubleClickURL(partnerId), {
    shouldShowToast: false,
  }).then(parseResponse);

const addUserListInDoubleClick = (parnerId, payload, t) =>
  Api.callPost(getDoubleClickURL(parnerId), payload, {
    toastText: t('audiences:connectors.doubleClick.userListAdd.error'),
    shouldShowToast: true,
  }).then(parseResponse);

export { getDoubleClickUsersList, addUserListInDoubleClick };
