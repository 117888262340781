/**
 * @description Angular module for the customer journey new goal
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form.exitConditionForm
 */
angular.module('webUi.ui.customer.journey.form.exitCondition', [])
    .config(['$stateProvider',function config($stateProvider) {
        $stateProvider
            .state('site.customer.journeys.form.exitConditionNew', {
                url: '/exitConditionNew',
                isReact: true
            });

    }]
    );
