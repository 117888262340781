import React, { Component } from 'react';
import { Field } from 'redux-form';
import Form from '~/components/src/Form/Form';
import FormActions from '~/components/src/Form/ActionsDefault';
import { isRequired, isEmail, defaultMaxInput } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormCheckboxField,
} from '~/components/src/Form/Fields/ReduxFormFields';

export class UserForm extends Component {
  render() {
    const { handleSubmit, submitting, backHref, sites, t, touch } = this.props;
    return (
      <Form noValidate onSubmit={handleSubmit} isSubmitting={submitting}>
        <div>
          <Field
            label={t('userForm.name.label')}
            name="name"
            component={ReduxFormInputField}
            placeholder={t('userForm.name.placeholder')}
            validate={[isRequired, defaultMaxInput]}
            type="text"
          />
          <Field
            label={t('userForm.email.label')}
            name="username"
            component={ReduxFormInputField}
            placeholder={t('userForm.email.placeholder')}
            validate={[isRequired, isEmail, defaultMaxInput]}
            type="text"
          />
          <Field
            label={t('userForm.site')}
            name="site"
            component={ReduxFormSelectField}
            options={sites}
            validate={isRequired}
            touch={touch}
          />
          <Field label={t('userForm.invite')} name="sentInvite" component={ReduxFormCheckboxField} />
        </div>
        <FormActions t={t} submitting={submitting} cancelHref={backHref} />
      </Form>
    );
  }
}

export default UserForm;
