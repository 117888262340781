import React, { useContext, useState } from 'react';
import cx from 'classnames';
import BtnIcon from '~/components/src/BtnIcon';
import { I18NextT } from '~/components/src/Common/types';
import '~/customer/components/styles.scss';

import { ActionsContext } from '../constants';
import { JourneyItem } from './JourneyItem';
import { ButtonType, ItemVariantStats } from './types';
import ItemStats from './ItemStats';
import './styles.scss';

type ExitConditionItemProps = {
  className?: string;
  name: string;
  reached: number;
  id: string;
  variantStats: ItemVariantStats[];
  fetchJourneyData: () => void;
  canEdit: boolean;
  t: I18NextT;
  isDemoMode: boolean;
};

const ExitConditionItem = ({
  className,
  name,
  reached,
  id,
  variantStats = [],
  fetchJourneyData,
  canEdit,
  t,
  isDemoMode = false,
}: ExitConditionItemProps): React.ReactElement => {
  const { goToViewExitConditionScreen, goToEditExitConditionScreen, showDeleteTriggerModal } =
    useContext(ActionsContext);

  const [isExpanded, setIsExpanded] = useState(false);

  const buttons: ButtonType[] = [
    {
      icon: 'view',
      testHook: 'view-exit-condition-button',
      onClick: () => goToViewExitConditionScreen(id),
    },
  ];

  if (canEdit) {
    buttons.push(
      {
        icon: 'edit',
        testHook: 'edit-exit-condition-button',
        onClick: () => {
          goToEditExitConditionScreen(id);
        },
      },
      {
        icon: 'delete',
        testHook: 'delete-exit-condition-button',
        onClick: () => {
          showDeleteTriggerModal({ nodeId: id, label: name }, 'exit', fetchJourneyData);
        },
      },
    );
  }

  let isShowVariantStats = variantStats.length > 0;

  if (variantStats.length === 1 && variantStats[0].profilesReached === reached) {
    isShowVariantStats = false;
  }

  return (
    <JourneyItem
      name={name}
      className={cx('t-journeyExitCondition', className)}
      type="exit"
      onClick={() => setIsExpanded(!isExpanded)}
      isShowVariantStats={isShowVariantStats}
      isExpanded={isExpanded}
      buttons={
        <>
          {!isDemoMode &&
            buttons.map((buttonConfig, buttonIndex) => (
              <BtnIcon
                className="h-5 w-5 bg-transparent p-0 text-gray-400 hover:bg-transparent hover:text-gray-600"
                icon={buttonConfig.icon}
                onClick={buttonConfig.onClick}
                key={buttonIndex}
                testHook={buttonConfig.testHook}
              />
            ))}
        </>
      }
      footer={
        <div className="flex flex-col gap-1 rounded-b-xl bg-gray-50 p-1">
          <ItemStats t={t} testHook="exitConditionStats" label={t('journey:total')} reached={reached} type="exit" />
          {isShowVariantStats && isExpanded && (
            <div className="flex flex-col gap-1">
              {variantStats.map((variant, index) => (
                <ItemStats
                  t={t}
                  key={index}
                  testHook="variantStats"
                  label={variant.name}
                  reached={variant.profilesReached}
                  conversion={variant.conversionRate}
                  hasConversion
                  type="exit"
                />
              ))}
            </div>
          )}
        </div>
      }
    />
  );
};

export default ExitConditionItem;
