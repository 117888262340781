import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import R42EventListener from '~/components/src/R42Icons/R42EventListener';
import R42EventListenerDisabled from '~/components/src/R42Icons/R42EventListenerDisabled';
import i18n from '~/i18n';
import constants from '~/profiles/connectors/form/constants';
import BtnIcon from '../BtnIcon';
import Card from '../Card';
import { R42Cloud } from '../R42Icons';
import ShowMore from '../ShowMore';
import StatusTag from '../StatusTag';
import { components } from '../Table';
import Tooltip from '../Tooltip';
import './styles.scss';

const { Table } = components;

export const INTEGRATION_TYPE = {
  serverToServer: 'SERVER-TO-SERVER',
  r42: 'R42',
  pixelPartner: 'PIXEL-PARTNER',
};

const TableRowProperty = ({ name, value, hidden = false }) => (
  <tr className={cx('ConnectorCard-tableRow', { 'u-hidden': hidden })} key={name + value}>
    <td className="ConnectorCard-tableCell ConnectorCard-tableCell--name">{name}</td>
    <td className="ConnectorCard-tableCell">{value || '-'}</td>
  </tr>
);
const ConsentInfo = ({ consent }) => (
  <div className="my-2">
    <span className="text-gray-600">{i18n.t('partners:form.notification.acceptEUConsent.header')}: </span>
    <span className="text-r42-blue">
      {consent?.label || i18n.t('partners:form.notification.acceptEUConsent.connectorDefault')}
    </span>
  </div>
);

const EventListenerIcon = ({ supportsEventListeners }) =>
  supportsEventListeners ? (
    <Tooltip
      className="ConnectorCard-supportIcon"
      tooltip={i18n.t('orchestration:steps.integrationSupportsEventListeners')}
      placement="bottom"
    >
      <R42EventListener />
    </Tooltip>
  ) : (
    <Tooltip
      className="ConnectorCard-supportIcon"
      tooltip={i18n.t('orchestration:steps.integrationWithoutEventListeners')}
      placement="bottom"
    >
      <R42EventListenerDisabled />
    </Tooltip>
  );

export const ConnectorCardWrapper = ({
  children,
  connector,
  name,
  logoUrl,
  className,
  integration,
  onDelete,
  supportsEventListeners,
  testHook,
}) => (
  <Card className={cx('ConnectorCard', 'u-flex', className, `t-${testHook}`)}>
    {!connector?.activated && <StatusTag text={i18n.t('connectors:deactivated')} />}
    <div className="ConnectorCard-header">
      {logoUrl && (
        <img
          src={logoUrl}
          className={cx('ConnectorCard-logo', { 'ConnectorCard-logo--deactivated': !connector?.activated })}
        />
      )}
      <div className={cx('ConnectorCard-info', { 'ConnectorCard-info--deactivated': !connector?.activated })}>
        <p className="ConnectorCard-info--name">{name}</p>
        {!!connector?.partnerDetails?.partnerNumber && (
          <p className="ConnectorCard-info--number">
            {i18n.t('connectors:partnerNumber', { partnerNumber: connector?.partnerDetails.partnerNumber })}
          </p>
        )}
      </div>
      <div className="flex items-center gap-4">
        {integration && (
          <div>
            <Tooltip className="ConnectorCard-supportIcon" tooltip={integration.message}>
              <integration.Icon size="18" color="#e2b31c"></integration.Icon>
            </Tooltip>
          </div>
        )}
        {typeof supportsEventListeners === 'boolean' && (
          <EventListenerIcon supportsEventListeners={supportsEventListeners} />
        )}
        {onDelete && (
          <BtnIcon icon="delete" onClick={() => onDelete(connector)} tooltip={i18n.t('connectors:delete')} />
        )}
      </div>
    </div>
    {children}
  </Card>
);

const ConnectorCard = ({
  connector,
  name,
  logoUrl,
  properties = [],
  className,
  onEdit,
  onDelete,
  integrationType = INTEGRATION_TYPE.serverToServer,
  supportsEventListeners,
  testHook = 'connectorCard',
  ts = {
    r42PlatformIntegration: 'Relay42 Platform Integration',
    serverToServerIntegration: 'Server-to-Server Integration',
  },
}) => {
  const integrations = {
    [INTEGRATION_TYPE.serverToServer]: {
      message: ts.serverToServerIntegration,
      Icon: R42Cloud,
    },
    [INTEGRATION_TYPE.r42]: {
      message: ts.r42PlatformIntegration,
      Icon: R42Cloud,
    },
  };

  const integration = integrations[integrationType];

  const partnerDetails = connector?.partnerDetails;

  const getConsentDetails = () => {
    if (partnerDetails?.partnerType === constants.partnerTypes.GOOGLE_ADS) {
      return partnerDetails?.consent;
    } else if (partnerDetails?.partnerType === constants.partnerTypes.GOOGLE_CUSTOMER_MATCH) {
      return { label: partnerDetails?.consentStatus };
    }
    return null;
  };

  const showConsent =
    partnerDetails?.partnerType === constants.partnerTypes.GOOGLE_ADS ||
    partnerDetails?.partnerType === constants.partnerTypes.GOOGLE_CUSTOMER_MATCH;

  return (
    <ConnectorCardWrapper
      connector={connector}
      name={name}
      logoUrl={logoUrl}
      integration={integration}
      onEdit={onEdit}
      onDelete={onDelete}
      testHook={testHook}
      className={className}
      supportsEventListeners={supportsEventListeners}
    >
      {properties?.length > 0 && (
        <div className="ConnectorCard-properties">
          <Table isTable={true} className="ConnectorCard-table" stateKey={`connector-${integrationType}`}>
            <tbody>
              <ShowMore
                list={properties}
                renderItem={(prop, index) => <TableRowProperty {...prop} key={index} />}
                btnClassName="ConnectorCard-showMore"
              />
            </tbody>
          </Table>
        </div>
      )}
      {showConsent && <ConsentInfo consent={getConsentDetails()} />}
    </ConnectorCardWrapper>
  );
};

ConnectorCard.propTypes = {
  name: PropTypes.string.isRequired,
  integrationType: PropTypes.oneOf(Object.keys(INTEGRATION_TYPE).map(key => INTEGRATION_TYPE[key])),
  logoUrl: PropTypes.string,
  className: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  testHook: PropTypes.string,
  ts: PropTypes.shape({
    r42PlatformIntegration: PropTypes.string,
    pixelPartnerIntegration: PropTypes.string,
    serverToServerIntegration: PropTypes.string,
  }),
};

export default ConnectorCard;
