import React from 'react';
import i18n from '~/i18n';
import { Notification } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import { useHPContext } from '../context';
import HPDatePicker from './HPDatePicker';
import { getModelHeader } from '../util';
import { Step2Props } from '../types';

export const Step2 = ({
  maxAllowedHistoricProcessingDays,
  maxAllowedSelectionDays,
  range,
  handleHPTrigger,
  setRange,
}: Step2Props): React.ReactElement => {
  const hpContext = useHPContext();
  const { selectedHpOptions, setCurrentStep } = hpContext;

  const showDateRangeSelector = !!selectedHpOptions.find(({ hasOnlyCustomerFacts }) => !hasOnlyCustomerFacts);

  return (
    <div className="flex h-auto w-[640px] flex-col">
      <Notification testHook="hpHeader" kind="information">
        {getModelHeader(selectedHpOptions)}
      </Notification>

      {maxAllowedSelectionDays ? (
        <>
          {showDateRangeSelector && (
            <>
              <HPDatePicker
                range={range}
                setRange={setRange}
                maxSelectionDays={maxAllowedHistoricProcessingDays}
                maxAllowedDays={maxAllowedSelectionDays}
              />
              <Notification kind="information">
                {i18n.t('orchestration:historicProcessing.hpTimeFrameNote', { maxAllowedHistoricProcessingDays })}
              </Notification>
            </>
          )}
          <div className="flex justify-end p-3">
            <ActionButtons
              onConfirm={handleHPTrigger}
              confirmText={i18n.t('orchestration:historicProcessing.triggerHp')}
              declineText={i18n.t('orchestration:historicProcessing.action.chooseHPType')}
              onDecline={() => setCurrentStep(1)}
              testHook="historicProcessionModal-actionButtons"
              paddingClassName={null}
            />
          </div>
        </>
      ) : (
        <Notification className="my-4" kind="error">
          {i18n.t('orchestration:historicProcessing.noDataRetentionPeriod')}
        </Notification>
      )}
    </div>
  );
};
