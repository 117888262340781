import * as StepService from '~/customer/steps/dataService';

export const createStep = async (journeyId, name, integrations, parentTriggerId, variableModifications) =>
  StepService.createStep({
    name,
    journeyId,
    integrations,
    parentTriggerId,
    variableModifications,
  });

export const updateStep = async (journeyId, stepId, name, integrations, variableModifications) =>
  StepService.updateStep({
    journeyId,
    stepId,
    name,
    integrations,
    variableModifications,
  });
