angular.module('webUi.service.ga', ['restangular'])
    .service('GoogleAnalyticsService', ['Restangular', 'TimeService', function GoogleAnalyticsService(Restangular, TimeService) {
        var BASE_URL = 'ga/';

        var callGA = function callGA(toState, pageLoadTimeMillis, isFirstPageLoad) {

            // Calculate page load time for Google Analytics
            // If this is a first page load get time since page load, otherwise use pageLoadTime param
            // Param pageLoadTime is diff between ui-router $stateChangeStart and $stateChangeSuccess events
            if (isFirstPageLoad) {
                var performanceTimeMillis = TimeService.getPerformanceTimeMillis();
                if (performanceTimeMillis > 0) {
                    pageLoadTimeMillis = performanceTimeMillis;
                }
            }

            var gaData = { name: toState.name, url: toState.url, pageLoadTimeMillis: pageLoadTimeMillis };

            // In order to avoid infinite redirect loop, don't post to GA when user is forbidden from accessing a site/module/page (due to lack of permissions
            // or site has Advanced Security, but the user doesn't have 2FA)
            // See: https://synovite.atlassian.net/browse/RP-2814 and https://synovite.atlassian.net/browse/RP-4789
            if (!_.isEqual(toState.name, 'site.error.403') && !_.isEqual(toState.name, 'site.error.noSiteAccess') && !_.isEqual(toState.name, 'site.error.404')) {
                Restangular.one(BASE_URL).post('siteStats', gaData);
            }
        };

        return {
            callGA: callGA
        };
    }]);
