import { Field, Formik } from 'formik';
import React, { ReactElement, useContext } from 'react';
import { buildUrl, useAPI, changeUrl } from '~/common';
import { Heading, Notification } from '~/components';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { FormikInputFieldType } from '~/context/components/APIKeys/types';
import * as Yup from 'yup';
import i18n from '~/i18n';
import Btn from '~/components/src/Btn';
import { useParams } from 'react-router-dom';
import { showSuccess } from '~/notificationCenter';
import EditableEventListenerList from '~/customer/components/EditStep/EditableEventListenerList';
import { getFilter, getRulesDefinitions, createFilter, updateFilter } from './dataService';
import { FilterListType, FilterPayload } from './types';
import { getRulesTreeFromTypes, mapEventToFilter } from './util';
import { FiltersContext } from './FiltersContext';

const FieldLengths = {
  MIN: 2,
  MAX: 255,
} as const;

const makeUniqueFilterValidator = (existingFilters: FilterListType[]) => (inputValue: FormikInputFieldType) => {
  const lowerCaseVariables = existingFilters.map(existingFilter => existingFilter.name.toLowerCase());
  const isUsedAlready = inputValue && lowerCaseVariables.includes(inputValue?.toLowerCase());
  return !isUsedAlready;
};

function FilterEdit(): ReactElement {
  const params = useParams();
  const filterId = params.filterId || '';

  const { data: rulesTree } = useAPI(async () => {
    const ruleDefinitions = await getRulesDefinitions();
    return getRulesTreeFromTypes(ruleDefinitions);
  });

  const { data: filter } = useAPI(() => {
    if (filterId) {
      return getFilter(filterId);
    }
    return Promise.resolve(null);
  }, [filterId]);

  const { filters: existingFilters, refetchFilters } = useContext(FiltersContext);
  const initialValues = { name: filter?.name || '', rules: filter?.rules || [] };

  const formValidations = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(FieldLengths.MIN, i18n.t('validation:validation.minLength', { min: FieldLengths.MIN }))
      .max(FieldLengths.MAX, i18n.t('validation:validation.maxLength', { max: FieldLengths.MAX }))
      .test(
        'filterName-name-is-unique',
        i18n.t('filters:validation.uniqueFilter'),
        makeUniqueFilterValidator(existingFilters.filter(filter => filter.filterId !== filterId)),
      )
      .required(i18n.t('validation:validation.required')),
    rules: Yup.array().min(1),
  });

  const handleSubmit = async ({ name, rules }: { name: string; rules: any[] }) => {
    const mappedRules = mapEventToFilter(rules);
    const payload: FilterPayload = {
      name: name.trim(),
      rules: mappedRules,
    };
    if (filterId) {
      await updateFilter(filterId, payload);
      showSuccess({ header: i18n.t('filters:form.updateSuccess') });
      changeUrl(`profiles/filters/view/${filterId}`);
    } else {
      await createFilter(payload);
      showSuccess({ header: i18n.t('filters:form.createSuccess') });
      changeUrl('profiles/filters');
      refetchFilters();
    }
  };

  return (
    <div className="flex h-full flex-1 flex-col items-center bg-gray-50 pb-6">
      <div className="flex w-2/3 flex-col">
        <Heading
          title={i18n.t(`filters:form.${filterId ? 'edit' : 'create'}`)}
          crumbs={[
            {
              title: i18n.t('filters:back'),
              pathname: buildUrl('profiles/filters'),
            },
          ]}
        />
        <Notification kind="information">
          <p>{i18n.t('filters:form.message')}</p>
        </Notification>
        <div className="mt-4 flex flex-col gap-4">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={formValidations}
            initialValues={initialValues}
            enableReinitialize
            validateOnMount
          >
            {({ handleSubmit, errors, isValid, isSubmitting, touched, values, setFieldValue }) => (
              <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                <label>{i18n.t('filters:form.nameLabel')}</label>
                <Field
                  as={FormikInputField}
                  name="name"
                  className="w-2/5"
                  placeholder={i18n.t('filters:form.namePlaceholder')}
                  errorText={touched.name && errors.name}
                />

                <EditableEventListenerList
                  eventsListeners={values.rules}
                  ruleTypesTree={rulesTree || []}
                  usedVariables={[]}
                  onChange={(events: any) => setFieldValue('rules', events.eventsListeners)}
                  showRulePicker={false}
                  disallowDuplicates={true}
                  actionName={i18n.t('filters:form.addEvent')}
                />
                <div className="mt-2 flex justify-end">
                  <Btn color="blue" type="submit" disabled={!isValid || isSubmitting} testHook="createUpdateFilter">
                    {i18n.t(`filters:form.${filterId ? 'update' : 'create'}`)}
                  </Btn>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default FilterEdit;
