import React, { MouseEvent, ReactElement, useState } from 'react';
import { ColumnInstance } from 'react-table';
import i18n from '~/i18n';
import { StatsType } from '~/customer/pages/JourneyStatsView/types';
import BtnIcon from '~/components/src/BtnIcon';

const COLUMNS_TO_SHOW = [
  i18n.t('journey:journeyStatistics.stepAnalysis.variantName'),
  i18n.t('journey:journeyStatistics.stepAnalysis.stepName'),
];

type FilterButtonType = {
  className?: string;
  testHook?: string;
  disabled?: boolean;
  tooltip?: string;
  allColumns: ColumnInstance<StatsType>[];
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

function FilterButton({ allColumns }: FilterButtonType): ReactElement {
  const [showColumns, setShowColumns] = useState(false);
  const filterColumns = allColumns.filter((column: any) => !COLUMNS_TO_SHOW.includes(column.Header?.props?.children));

  return (
    <div>
      <BtnIcon
        className="!block"
        icon="filter"
        onClick={() => setShowColumns(!showColumns)}
        color={showColumns ? 'blue' : 'gray'}
      />
      {showColumns && (
        <div className="absolute -ml-2 mt-2 max-h-[300px] w-[200px] overflow-y-auto border bg-white py-1">
          {filterColumns.map((column: any) => (
            <div className="pointer bg-white p-2 hover:bg-gray-100" key={column.id}>
              <label className="flex gap-2">
                <input type="checkbox" {...column.getToggleHiddenProps()} />
                <span className="truncate text-ellipsis">{column.Header}</span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default FilterButton;
