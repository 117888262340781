import React, { useContext } from 'react';
import cx from 'classnames';
import { formatNumber } from '~/common/utils/NumberUtil';

import {
  ActionsContext,
  ADD_BUTTON_LINE_LENGTH,
  CARD_WIDTH,
  CARD_HEIGHT,
  HALF_CARD_HEIGHT,
  NODE_PADDING_RIGHT,
} from './constants';

import { TriggerIcon, getIconPositions } from './Icons';

import { HoverRegionUnderCard, Node, TransitionButtonLine } from './Node';
import { RoundExperimentButton, RoundStepButton, RoundStepOrTransitionButton } from './RoundButton';

import { ButtonsPanel, IconButton, ViewIcon, EditIcon, DeleteIcon } from './ButtonsPanel';
import { useNodes } from './JourneyCanvas';
import { actionTypes } from './JOReducer';
import ProfilesCount from './ProfilesCount';
import { demoJourneyId } from '../fake';

const AddStepButtonOnALine = ({ x, y, className, hasError, children }) => (
  <g transform={`translate(${x}, ${y})`} className={className}>
    <line
      className={cx('stroke-gray-500', { 'stroke-red-500': hasError })}
      x1={0}
      y1={16}
      x2={ADD_BUTTON_LINE_LENGTH}
      y2={16}
      strokeDasharray="4 3"
      strokeWidth={2}
    />
    <g transform={`translate(${ADD_BUTTON_LINE_LENGTH}, 0)`} className="JourneyView-rectangularButton">
      {children}
    </g>
  </g>
);

const TransitionButton = ({ x, y, onClick, onMouseEnter, onMouseLeave, className, onDeleteTrigger, hasError }) => (
  <TransitionButtonLine
    x={x}
    y={y}
    onClick={onClick}
    className={className}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onDeleteTrigger={onDeleteTrigger}
    hasError={hasError}
    text="Transition"
  />
);

export const TriggerNode = ({
  journeyId,
  x,
  y,
  data,
  parent,
  fetchJourneyData,
  canEdit,
  hasLinkError,
  hasExperiments,
}) => {
  const {
    goToCreateStepScreen,
    goToEditTriggerScreen,
    goToViewTriggerScreen,
    showDeleteTriggerModal,
    showDeleteTransition,
  } = useContext(ActionsContext);

  const joContextValue = useNodes();
  const isStartTrigger = data.type === 'START_TRIGGER';
  const hasNextStep = data.children.length > 0;
  const showExperimentButton = hasExperiments ? data.children.length > 1 : true;

  const buttons = [
    {
      icon: ViewIcon,
      testHook: 'view-trigger',
      onClick: () => {
        goToViewTriggerScreen(data.nodeId);
      },
    },
  ];
  if (canEdit) {
    buttons.push({
      icon: EditIcon,
      testHook: 'edit-trigger',
      onClick: () => {
        goToEditTriggerScreen(data.nodeId);
      },
    });
  }

  if (!isStartTrigger && canEdit) {
    buttons.push({
      icon: DeleteIcon,
      testHook: 'delete-trigger',
      onClick: () => {
        showDeleteTriggerModal(data, 'trigger', fetchJourneyData);
      },
    });
  }

  const iconPositions = getIconPositions(buttons.length);
  const reachedMaxVariants = data.children.length === 3;

  return (
    <>
      <Node x={x} y={y} Icon={TriggerIcon} data={data} parent={parent} className="JourneyView-triggerNode">
        {!isStartTrigger && (
          <ProfilesCount
            profilesCount={formatNumber(data.profilesCount)}
            experimentWeight={0}
            isVariant={false}
            isWeightValid={true}
            isTrigger={true}
          />
        )}
        <HoverRegionUnderCard />
        {journeyId !== demoJourneyId && (
          <ButtonsPanel x={0} y={CARD_HEIGHT + 16}>
            {buttons.map((buttonConfig, buttonIndex) => (
              <IconButton
                icon={buttonConfig.icon}
                onClick={buttonConfig.onClick}
                x={iconPositions[buttonIndex]}
                key={buttonIndex}
                testHook={buttonConfig.testHook}
              />
            ))}
          </ButtonsPanel>
        )}
      </Node>
      {canEdit && hasNextStep && showExperimentButton && !reachedMaxVariants && (
        <RoundExperimentButton
          x={x + CARD_WIDTH + NODE_PADDING_RIGHT / 2}
          y={y + HALF_CARD_HEIGHT}
          className="t-create-experiment-step-button"
          tooltipText="Add experiment"
          tooltipWidth={200}
          tooltipHeight={36}
          onClick={() => {
            goToCreateStepScreen(data.nodeId);
          }}
        />
      )}
      {canEdit && !hasNextStep && !data.hasTransition && (
        <AddStepButtonOnALine x={x + CARD_WIDTH} y={y + HALF_CARD_HEIGHT - 16} hasError={hasLinkError}>
          {!isStartTrigger ? (
            <RoundStepOrTransitionButton
              className="t-step-transition-button"
              x={16}
              y={16}
              hasError={hasLinkError}
              triggerId={data.nodeId}
            />
          ) : (
            <RoundStepButton
              x={16}
              y={16}
              hasError={hasLinkError}
              onClick={() => {
                goToCreateStepScreen(data.nodeId);
              }}
            />
          )}
        </AddStepButtonOnALine>
      )}
      {data.hasTransition && (
        <TransitionButton
          className={`t-transition-button-${data.nodeId}`}
          hasError={hasLinkError}
          x={x + CARD_WIDTH}
          y={y + HALF_CARD_HEIGHT - 16}
          onClick={() => {
            const dataNodeId = data.nodeId;
            let nodeId = joContextValue?.state.transitionSource;
            if (nodeId === dataNodeId) {
              nodeId = '';
            } else {
              nodeId = dataNodeId;
            }
            return joContextValue?.dispatch({
              type: actionTypes.SET_TRANSITION_SOURCE,
              transitionSource: nodeId,
            });
          }}
          onDeleteTrigger={() => showDeleteTransition(data, fetchJourneyData)}
        />
      )}
    </>
  );
};

export const TriggerNodePlaceholder = ({ x, y, data, parent, hasNodeError }) => {
  const { goToCreateTriggerScreen } = useContext(ActionsContext);
  return (
    <Node
      hasError={hasNodeError}
      Icon={TriggerIcon}
      x={x}
      y={y}
      data={data}
      parent={parent}
      title="Click to add a trigger"
      isStartTrigger
      className="JourneyView-triggerNodePlaceholder"
      onClick={() => {
        goToCreateTriggerScreen();
      }}
    />
  );
};
