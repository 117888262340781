import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { ContextEffect } from '~/common';
import cx from 'classnames';
import { Formik, FieldArray } from 'formik';
import { getIcon } from '~/common/modules/rules/utils';
import { Table, Cell } from '~/components/src/Table/components';
import Icons from '~/components/src/Icons';
import PropertyNameSelect from '~/common/modules/rules/components/RuleCardEdit/Property';
import { canAddPropertyRow } from '~/common/modules/rules/components/RuleCardEdit/utils';
import { getPropertySelectOptions } from '~/common/modules/rules/components/RuleCardEdit/Property/utils';
import StoredVariablesInput from '~/common/modules/rules/components/RuleCardEdit/StoredVariablesInput';
import BtnOutlined from '~/components/src/BtnOutlined';
import BtnIcon from '~/components/src/BtnIcon';
import Link from '~/components/src/Link';
import './styles.scss';

const TheadCell = ({ children, className }) => (
  <div className={`${className} RuleCardEdit-table-head Table-cell Table-cell--header`}>{children}</div>
);

const AddPropertyBtn = ({ formProps, newProperty, disabled, ts }) => (
  <BtnOutlined
    onClick={() => {
      if (!disabled) {
        const filters = formProps.values.filters || [];
        formProps.setFieldValue('filters', [...filters, { ...newProperty, key: Date.now() }]);
        formProps.setFieldValue('hasNewProperty', true);
      }
    }}
    icon="add"
    color="blue"
    testHook="addPropertyButton"
    className="mx-4 my-2 mb-4"
    disabled={disabled}
    size="xs"
  >
    {ts.addProperty}
  </BtnOutlined>
);

const DeleteProperty = ({ arrayHelpers, propertyIndex, t }) => (
  <BtnIcon
    testHook="dDeletePropertyButton"
    icon="close"
    tooltip={t('common:actions.remove')}
    onClick={() => {
      arrayHelpers.remove(propertyIndex);
    }}
  />
);

const EventListenerProperties = ({ formProps, arrayHelpers, propertyTypes, availableVariables, ts, t }) => {
  const propertySelectOptions = getPropertySelectOptions(propertyTypes, formProps.values.filters);
  const canCreateCustomProperty = propertyTypes.some(propertyType => propertyType.dataField === null);

  return (
    <div className="RuleCardEdit-propertyRow">
      {formProps?.values?.filters?.map((property, propertyIndex) => {
        const selectedPropertyName = property.dataField;

        return (
          <div
            key={property.key || property.dataField}
            className={cx('Table-row', 'RuleCardEdit-table-row', 't-ruleCardPropertyRow')}
          >
            <Cell className="RuleCardEdit-propertyNameCell" testHook="propertyNameSelectContainer">
              <PropertyNameSelect
                propertyIndex={propertyIndex}
                selectedPropertyName={selectedPropertyName}
                allSelectedProperties={formProps.values.filters}
                options={propertySelectOptions}
                setFieldValue={formProps.setFieldValue}
                isCreatable={canCreateCustomProperty}
                isClearable={true}
                ts={ts}
                hasNewProperty={formProps.values.hasNewProperty}
              />
            </Cell>
            <Cell className="RuleCardEdit-variablesCell">
              <StoredVariablesInput
                isCreatable={false}
                selectedPropertyName={selectedPropertyName}
                selectedVariable={property.variable}
                propertyIndex={propertyIndex}
                setFieldValue={formProps.setFieldValue}
                availableVariables={availableVariables}
              />
            </Cell>
            <Cell className={cx('RuleCardEdit-deletePropertyContainer', 't-deletePropertyContainer')}>
              <DeleteProperty arrayHelpers={arrayHelpers} propertyIndex={propertyIndex} t={t} />
            </Cell>
          </div>
        );
      })}
    </div>
  );
};

const EventListenerCardEditContent = ({
  onDelete,
  title,
  type,
  typeLabel,
  testHook = 'RuleCardEdit',
  ts,
  t,
  availableVariables,
  newProperty = {},
  formProps = {
    values: {},
    errors: {},
  },
  propertyTypes,
  showRulePicker = true,
  viewOnly,
  url,
}) => {
  const icon = getIcon(type);

  return (
    <div
      className={cx(
        'flex flex-col justify-between rounded-md border border-gray-200 bg-white px-2 ',
        `t-${type}`,
        `t-${testHook}`,
      )}
    >
      <div className="flex items-center p-2">
        <div className="flex w-full items-center gap-2 p-2">
          {icon}
          <div>
            {title && (
              <div className="flex items-center gap-1">
                <p className="text-lg font-medium text-gray-800">{url ? <Link href={url}>{title}</Link> : title}</p>
                <Icons
                  icon="info"
                  className="h-6 w-5 p-0.5 text-gray-400"
                  tooltip={t(`audiences:rules.descriptions.${type}`)}
                />
              </div>
            )}
            {typeLabel && <p className="text-sm text-gray-500">{typeLabel}</p>}
          </div>
        </div>
        {!viewOnly && (
          <BtnIcon icon="delete" onClick={onDelete} tooltip={t('common:actions.delete')} testHook="deleteEventRule" />
        )}
      </div>

      <FieldArray
        name="filters"
        render={arrayHelpers => (
          <>
            {formProps?.values?.filters?.length > 0 && (
              <div className="">
                <Table stateKey="testKey" className="flex flex-col p-2" testHook="ruleCardPropertiesTable">
                  <div className="Table-row RuleCardEdit-tableHeadRow">
                    <TheadCell className="RuleCardEdit-propertyNameCell">{ts.property}</TheadCell>
                    <TheadCell className="RuleCardEdit-variablesCell">{ts.storedVariable}</TheadCell>
                    <TheadCell className="RuleCardEdit-deletePropertyContainer" />
                  </div>
                  <EventListenerProperties
                    formProps={formProps}
                    arrayHelpers={arrayHelpers}
                    propertyTypes={propertyTypes}
                    availableVariables={availableVariables}
                    ts={ts}
                    t={t}
                  />
                </Table>
              </div>
            )}
            {showRulePicker && (
              <AddPropertyBtn
                formProps={formProps}
                newProperty={newProperty}
                disabled={!canAddPropertyRow(propertyTypes, formProps?.values?.filters)}
                ts={ts}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

EventListenerCardEditContent.defaultProps = {
  ts: {
    property: 'Property',
    storedVariable: 'Configured variable',
    addProperty: 'Add a property',
    startTyping: 'Start typing',
    selectProperty: 'Select',
    noOptionMessage: 'No result, it does not support custom values',
  },
};

EventListenerCardEditContent.propTypes = {
  name: PropTypes.string,
  typeLabel: PropTypes.string,
  testHook: PropTypes.string,
  formProps: PropTypes.object,
  filters: PropTypes.array,
  ts: PropTypes.shape({
    property: PropTypes.string.isRequired,
    storedVariable: PropTypes.string.isRequired,
    addProperty: PropTypes.string.isRequired,
    startTyping: PropTypes.string.isRequired,
    selectProperty: PropTypes.string.isRequired,
    noOptionMessage: PropTypes.string.isRequired,
  }),
};

// Pre-save action for ContextEffect
const formTransformations = (formProps, id) => () => {
  const { filters } = formProps.values;
  const transformation = { ruleId: id, filters };
  return {
    ...transformation,
  };
};

const EventListenerEditCard = props => {
  const cardProps = omit(props, ['initialValues', 'onChange']);
  const { onChange, id, filters, isSubmitted } = props;

  const initialValues = {
    filters,
  };

  return (
    <Formik initialValues={initialValues} validateOnChange={false}>
      {formProps => (
        <Fragment>
          <ContextEffect
            onChange={onChange}
            formTransformations={formTransformations(formProps, id)}
            isSubmitted={isSubmitted}
            submitCount={formProps.submitCount}
            setTouched={formProps.setTouched}
            errors={formProps.errors}
            validateOnBlur={true}
            enableReinitialize={true}
          />
          <EventListenerCardEditContent formProps={formProps} {...cardProps} />
        </Fragment>
      )}
    </Formik>
  );
};

export default compose(translate('common'))(EventListenerEditCard);
