import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { Formik, Field } from 'formik';
import i18n from '~/common/formValidation/i18n';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(i18n.t('validation:validation.required')),
});

const initialValues = {
  name: '',
};

const AddSalesforceAudienceForm = ({ hideModal, createNewAudience, setFieldValue, t }) => {
  const handleAddAudience = ({ name }) => {
    createNewAudience(name, setFieldValue);
  };
  return (
    <Formik onSubmit={handleAddAudience} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, errors }) => (
        <>
          <Field
            name="name"
            as={FormikInputField}
            label={t('connectors.salesforce.audienceAdd.nameLabel')}
            placeholder={t('connectors.salesforce.audienceAdd.placeholder')}
            errorText={errors.name}
            autoFocus={true}
          />
          <ActionButtons onConfirm={handleSubmit} onDecline={hideModal} className="mr-0" />
        </>
      )}
    </Formik>
  );
};

AddSalesforceAudienceForm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(translate('audiences'))(AddSalesforceAudienceForm);
