import React, { useContext } from 'react';
import { get } from 'lodash';
import i18n from '~/i18n';
import { formatNumber } from '~/common';

import { ActionsContext, CARD_WIDTH, CARD_HEIGHT, HALF_CARD_HEIGHT, NODE_PADDING_RIGHT } from './constants';

import { StepIcon, getIconPositions, AntennaIcon, ExperimentIcon } from './Icons';

import { HoverRegionUnderCard, ButtonOnALine, Node } from './Node';
import { RoundAddButton } from './RoundButton';
import { ExpandCollapseButton } from './ExpandCollapseButton';

import { ButtonsPanel, IconButton, ViewIcon, EditIcon, StatisticsIcon, DeleteIcon } from './ButtonsPanel';
import { useNodes } from '../components/JourneyCanvas';
import ProfilesCount from './ProfilesCount';
import { demoJourneyId } from '../fake';

const STEP_ACCENT_COLOR = '#715ed0';

const AddTriggerButton = ({ x, y, onClick, className, hasError }) => (
  <ButtonOnALine
    text="Add trigger"
    x={x}
    y={y}
    width={122}
    onClick={onClick}
    className={className}
    hasError={hasError}
    isTrigger={true}
  />
);

export const StepNode = ({
  journeyId,
  x,
  y,
  data,
  parent,
  fetchJourneyData,
  canEdit,
  hasLinkError,
  hasNodeError,
  showExperimentSettingsModal,
  isSumOfVariantsValid,
}) => {
  const {
    goToCreateTriggerScreen,
    goToViewStepScreen,
    goToEditStepScreen,
    showDeleteStepModal,
    goToStepStatisticsScreen,
  } = useContext(ActionsContext);

  const experimentWeight = get(data, 'experimentVariant.weight', null);

  const { handleExpandAndCollapse } = useNodes();

  const hasChildrenTriggers = data.children.length > 0;

  const buttons = [
    {
      icon: ViewIcon,
      testHook: 'view-step',
      onClick: () => {
        goToViewStepScreen(data.nodeId);
      },
    },
    {
      icon: StatisticsIcon,
      testHook: 'step-statistics',
      onClick: () => {
        goToStepStatisticsScreen(data.nodeId);
      },
    },
  ];

  if (canEdit) {
    buttons.push(
      {
        icon: EditIcon,
        testHook: 'edit-step',
        onClick: () => {
          goToEditStepScreen(data.nodeId, parent?.data?.nodeId);
        },
      },
      {
        icon: DeleteIcon,
        testHook: 'delete-step',
        onClick: () => {
          showDeleteStepModal(data, fetchJourneyData, parent?.data?.children.length === 2);
        },
      },
    );
  }
  const iconPositions = getIconPositions(buttons.length);
  const isVariant = parent.children.length > 1;
  const showExpandCollapse = hasChildrenTriggers || data.isChildrenCollapsed;

  return (
    <>
      {canEdit && !data.isChildrenCollapsed && (
        <>
          {hasChildrenTriggers ? (
            <RoundAddButton
              x={x + CARD_WIDTH + NODE_PADDING_RIGHT / 2}
              y={y + HALF_CARD_HEIGHT}
              tooltipText="Add trigger"
              tooltipWidth={200}
              tooltipHeight={36}
              onClick={() => {
                goToCreateTriggerScreen(data.nodeId);
              }}
              className="t-create-trigger-button"
            />
          ) : (
            <AddTriggerButton
              x={x + CARD_WIDTH}
              y={y + HALF_CARD_HEIGHT - 16}
              onClick={() => {
                goToCreateTriggerScreen(data.nodeId);
              }}
              className="t-create-trigger-button"
              hasError={hasLinkError}
            />
          )}
        </>
      )}
      <Node
        accentColor={STEP_ACCENT_COLOR}
        Icon={isVariant ? ExperimentIcon : StepIcon}
        RightIcon={data.hasVariableModifications && AntennaIcon}
        x={x}
        y={y}
        hasError={hasNodeError}
        data={data}
        parent={parent}
        isStep={true}
        isVariant={isVariant}
        isWeightValid={isSumOfVariantsValid}
        className="JourneyView-stepNode"
      >
        <ProfilesCount
          profilesCount={formatNumber(data.profilesCount)}
          experimentWeight={experimentWeight || 0}
          isVariant={isVariant}
          isWeightValid={isSumOfVariantsValid}
          showExperimentSettingsModal={showExperimentSettingsModal}
        />
        {showExpandCollapse && (
          <ExpandCollapseButton
            isCollapsed={data?.isChildrenCollapsed}
            tooltipText={data?.isChildrenCollapsed ? i18n.t('journey:expandNodes') : i18n.t('journey:collapseNodes')}
            className="t-expand-collapse-trigger-button"
            x={CARD_WIDTH}
            y={HALF_CARD_HEIGHT}
            tooltipWidth={200}
            tooltipHeight={36}
            onClick={() => {
              handleExpandAndCollapse(data.nodeId);
            }}
          />
        )}
        <HoverRegionUnderCard />
        {journeyId !== demoJourneyId && (
          <ButtonsPanel x={0} y={CARD_HEIGHT + 16}>
            {buttons.map((buttonConfig, buttonIndex) => (
              <IconButton
                icon={buttonConfig.icon}
                onClick={buttonConfig.onClick}
                x={iconPositions[buttonIndex]}
                key={buttonIndex}
                testHook={buttonConfig.testHook}
              />
            ))}
          </ButtonsPanel>
        )}
      </Node>
    </>
  );
};
