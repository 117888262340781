import React from 'react';
import { CellProps } from 'react-table';
import { getJourneyStats } from '~/customer/journeys/dataService';
import { StatsResponse, StatsType, ParsedJourneyStats } from './types';

export const getColumnTotal = (info: CellProps<StatsType>, accessor: string): number =>
  React.useMemo(() => info.rows.reduce((sum, row) => row.values[accessor] + sum, 0), [info.rows]);

export const getPercent = (reached: number, entered: number): string =>
  entered && reached ? ((reached / entered) * 100).toFixed(0) : '0';

const getAnalytics = (stats: StatsType[]) => {
  stats.map(stat => {
    stat.totalProfilesReached = stat.goals.reduce((sum, goal) => {
      goal.profilesCount = goal.profilesReachedGoal;
      return sum + goal.profilesReachedGoal;
    }, 0);
    stat.totalProfilesExitedCondition = stat.exitConditions.reduce((sum, exitRule) => {
      exitRule.profilesCount = exitRule.profilesExitedByCondition;
      return sum + exitRule.profilesExitedByCondition;
    }, 0);
    return stat;
  });

  return stats;
};

const parseStatsResponse = (response: StatsResponse) => ({
  journeyName: response.journeyName,
  stepAnalysis: getAnalytics(response.stepAnalysis),
  experimentAnalysis: getAnalytics(response.experimentAnalysis),
  totalStats: {
    profilesActive: response.journeyAnalysis?.profilesActive || 0,
    profilesEnteredJourney: response.journeyAnalysis?.profilesEntered || 0,
    totalProfilesReachedGoal: response.journeyAnalysis?.profilesReachedGoals || 0,
    totalProfilesExited: response.journeyAnalysis?.profilesExited || 0,
  },
});

export const getGoalNames = (stats: StatsType[]): Array<string> =>
  stats[0]?.goals.map((goal: { name: string }) => goal.name) || [];

export const getExitConditionNames = (stats: StatsType[]): Array<string> =>
  stats[0]?.exitConditions.map((exitCondition: { name: string }) => exitCondition.name) || [];

export const getJourneyDataAndStats = async (journeyId: string): Promise<ParsedJourneyStats> => {
  const journeyStats = await getJourneyStats(journeyId);
  return parseStatsResponse(journeyStats);
};
