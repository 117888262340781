import { string, object, array } from 'yup';
import { dateValidator } from '~/components/src/DateValidator/validation';
import { transformationTypes } from '../constants';
import { validate } from '../utils';
import { ValidationErrorsByPath, ValueTransformation } from '../transformerTypes';

export const valueTransformationSchema = object({
  transformationType: string(),
  fields: object().when('transformationType', (transformationType, schema) => {
    switch (transformationType) {
      case '':
      case transformationTypes.EPOCH_MILLIS_TO_SECONDS:
      case transformationTypes.EPOCH_SECONDS_TO_MILLIS:
      case transformationTypes.COUNTRY_CODE:
      case transformationTypes.STRING_TO_NUMBER:
      case transformationTypes.STRING_TO_BOOLEAN:
        return schema.shape({});
      case transformationTypes.DATE_FORMAT:
        return schema.shape({
          inputFormat: dateValidator.nullable(),
          outputFormat: dateValidator.nullable(),
        });
      case transformationTypes.DATE_TO_EPOCH_SECONDS:
        return schema.shape({
          inputFormat: string().required(),
        });
      case transformationTypes.EPOCH_SECONDS_TO_DATE:
        return schema.shape({
          dateFormat: string().required(),
          offset: string().matches(/^[+-](((0[0-9]|1[0-7])(:?)[0-5][0-9])|((1[0-8])(:?)0{2}))$/, {
            excludeEmptyString: true,
          }),
        });
      case transformationTypes.HASHING:
        return schema.shape({
          algorithm: string().required(),
        });
      case transformationTypes.STRING_TO_ARRAY:
        return schema.shape({
          delimiter: string().required(),
        });
      case transformationTypes.VALUE_MAPPING:
        return schema.shape({
          valueMappings: array()
            .of(object().shape({ from: string().required(), to: string().required() }))
            .required(),
        });
    }

    return schema;
  }),
});

export const validateValueTransformation = (valueTransformation: ValueTransformation): ValidationErrorsByPath =>
  validate(valueTransformation, valueTransformationSchema);
