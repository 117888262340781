/**
 * @description Angular module for the customer journey new goal
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form.goalForm
 */
angular.module('webUi.ui.customer.journey.form.goal', [])
    .config(['$stateProvider',function config($stateProvider) {
        $stateProvider
            .state('site.customer.journeys.form.goalNew', {
                url: '/goalNew',
                isReact: true
            });

    }]
    );
