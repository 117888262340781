export const mergeGoalAndStat = (stats = {}, goals = []) => {
  const trasformedGoal = goals.map(goal => {
    const reached = stats[goal.nodeId] || 0;
    return Object.assign({}, goal, { reached });
  });
  return trasformedGoal;
};

export const formatPercentage = conversionRate =>
  conversionRate && conversionRate.toLocaleString('en', { style: 'percent', maximumFractionDigits: 1 });

export const getItemWithExperimentStat = (journeyContents, items = []) => {
  const steps = journeyContents?.nodes?.children ?? [];

  return items.map(item => {
    const variantStats = item.stats.variantStats.map(variant => {
      const step = steps.find(step => step?.experimentVariant?.experimentVariantId === variant?.experimentVariantId);
      const variantWeight = step?.experimentVariant?.weight || 0;

      let conversionRate = formatPercentage(0);
      if (variant.profilesEnteredVariant) {
        conversionRate = formatPercentage(variant.profilesReached / variant.profilesEnteredVariant);
      }

      return { ...variant, weight: variantWeight, conversionRate };
    });
    return { ...item, stats: { ...item.stats, variantStats } };
  });
};
