import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field } from 'formik';
import * as Yup from 'yup';
import { translate } from 'react-i18next';
import { useAPI } from '~/common/ApiHooks';
import * as fromModals from '~/modals';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import { Notification } from '~/components';
import { FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import { SmallWrapperNoOffset } from '~/components/src/Containers';
import { ConnectorGenericForm } from '../../ConnectorGenericForm';
import { addAudienceInSalesforce, getSalesforceList } from './service';
import AddSalesforceAudienceForm from './AddSalesforceAudienceForm';

const validations = t =>
  Yup.object().shape({
    salesforceAudience: Yup.object().required(t('connectors:cards.validation.fieldIsRequired')),
  });

const hideModal = () => dispatch => {
  dispatch(fromModals.hideModal());
};

const openAudienceModal = (createNewAudience, setFieldValue) => dispatch => {
  dispatch(
    fromModals.showModal(fromModals.CONTENT_MODAL, {
      title: i18n.t('audiences:connectors.salesforce.audienceAdd.header'),
      confirmText: i18n.t('audiences:connectors.salesforce.audienceAdd.confirmText'),
      content: (
        <AddSalesforceAudienceForm
          hideModal={() => dispatch(hideModal())}
          createNewAudience={createNewAudience}
          setFieldValue={setFieldValue}
        />
      ),
    }),
  );
};

const Salesforce = ({ onEdit, connector, openAudienceModal, hideModal, t, isSubmitted }) => {
  const { partnerId } = connector.partnerDetails;

  const [listItems, setListItems] = useState([]);
  const { data, error, isLoading: isListLoading } = useAPI(() => getSalesforceList(partnerId), [partnerId]);

  useEffect(() => {
    setListItems(data || []);
  }, [data]);

  const createAudience = (name, setFieldValue) => {
    const payload = {
      name,
    };

    addAudienceInSalesforce(partnerId, payload)
      .then(response => {
        setListItems([...listItems, response]);
        setFieldValue('salesforceAudience', response);
        hideModal();
      })
      .catch(() => {});
  };

  return (
    <ConnectorGenericForm onEdit={onEdit} connector={connector} validations={validations(t)} isSubmitted={isSubmitted}>
      {formProps => (
        <>
          <SmallWrapperNoOffset>
            <div className="flex items-center gap-4">
              <Field
                name="salesforceAudience"
                as={FormikSelectField}
                label={t('connectors.salesforce.fieldLabel')}
                errorText={formProps.touched.salesforceAudience && formProps.errors.salesforceAudience}
                testHook="salesforceAudience"
                options={listItems}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.segmentId}
                isLoading={isListLoading}
                className="flex-1"
              />
              <BtnIcon
                className="mt-3 rounded-full"
                icon="add"
                testHook="addSalesforceAudience"
                tooltip={t('connectors.salesforce.audienceAdd.iconDesc')}
                onClick={() => openAudienceModal(createAudience, formProps.setFieldValue)}
              />
            </div>
          </SmallWrapperNoOffset>

          {error && <Notification kind="error">{t('connectors.salesforce.audienceAdd.genericError')}</Notification>}
        </>
      )}
    </ConnectorGenericForm>
  );
};

export default compose(connect(null, { hideModal, openAudienceModal }), translate('audiences'))(Salesforce);
