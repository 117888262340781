import React from 'react';
import ConnectorCard from '~/components/src/ConnectorCard';
import RuleCardView from '~/common/modules/rules/components/RuleCardView';
import OverlayPageContainer from '~/customer/components/OverlayPageContainer';
import { getPropertiesFromConnector } from '~/profiles/connectors';
import { getSiteId } from '~/common';
import { getConnectorImage } from '~/profiles/connectors/connectorUtils';
import { SmallWrapper } from '~/components/src/Containers';
import Heading from '~/components/src/Heading';
import Empty from '~/components/src/Empty';
import BtnIcon from '~/components/src/BtnIcon';
import './styles.scss';

const Integrations = ({ integrations, t }) => {
  const imageUrl = getConnectorImage(getSiteId());

  return (
    <>
      <Heading title={t('orchestration:steps.integrations')} kind="h4" testHook="integrationsHeading" />
      {integrations.map(connector => (
        <ConnectorCard
          key={connector.partnerDetails.partnerId}
          className="u-marginBottomXxl"
          connector={connector}
          name={connector.name}
          logoUrl={imageUrl(connector.partnerDetails)}
          properties={getPropertiesFromConnector(connector)}
          integrationType={connector.integrationType}
          supportsEventListeners={connector.supportsEventListeners}
          t={t}
        />
      ))}
    </>
  );
};

const EventListeners = ({ eventListeners, t }) => (
  <div>
    <Heading title={t('orchestration:steps.eventListeners')} kind="h4" testHook="eventListenersHeading" />
    {eventListeners.map(listener => (
      <RuleCardView
        type={listener.ruleType}
        title={listener.title}
        typeLabel={listener.typeLabel}
        properties={listener.properties}
        timeCondition={null}
        visibleColumns={['property', 'configuredVariable']}
        t={t}
        testHook="eventListenerCard"
        key={listener.variableModificationId}
      />
    ))}
  </div>
);

const ViewStep = ({ name, integrations, eventListeners, hideModal, handleEdit, canEdit, t }) => (
  <OverlayPageContainer>
    <SmallWrapper>
      <Heading
        title={name}
        crumbs={[
          {
            title: t('journey:back'),
            onClick: () => {
              hideModal();
            },
          },
        ]}
      >
        {canEdit && (
          <BtnIcon
            testHook="viewStepActions"
            color="blue"
            onClick={handleEdit}
            icon="edit"
            tooltip={t('common:actions.edit')}
          />
        )}
      </Heading>
      <div>
        {!integrations.length ? (
          <Empty
            header={t('orchestration:steps.noIntegrationsTitle')}
            body={t('orchestration:steps.noIntegrationsMessage')}
          />
        ) : (
          <Integrations integrations={integrations} t={t} />
        )}
      </div>
      {eventListeners.length > 0 && <EventListeners eventListeners={eventListeners} t={t} />}
    </SmallWrapper>
  </OverlayPageContainer>
);

export default ViewStep;
