import React from 'react';
import cx from 'classnames';
import { CARD_WIDTH, CARD_HEIGHT, HALF_CARD_HEIGHT, ADD_BUTTON_LINE_LENGTH } from './constants';
import CardBase from './CardBase';
import { ButtonsPanel, IconButton, DeleteIcon } from './ButtonsPanel';
import { RoundTransitionButton, RoundTriggerButton } from './RoundButton';

const ActionButton = ({
  x,
  y,
  className,
  hasError,
  tooltipText,
  tooltipWidth,
  tooltipHeight,
  isTrigger,
  hasTransition,
  onClick,
}) => (
  <g transform={`translate(${x}, ${y})`}>
    {isTrigger && !hasTransition && (
      <RoundTriggerButton
        x={16}
        y={16}
        className={className}
        onClick={onClick}
        hasError={hasError}
        tooltipText={tooltipText}
        tooltipWidth={tooltipWidth}
        tooltipHeight={tooltipHeight}
      />
    )}
    {hasTransition && (
      <RoundTransitionButton
        x={16}
        y={16}
        className={className}
        hasError={hasError}
        circleClassName={('fill-blue-50 stroke-blue-400', { 'fill-red-50 stroke-red-400': hasError })}
        tooltipText={tooltipText}
        tooltipWidth={tooltipWidth}
        tooltipHeight={tooltipHeight}
      />
    )}
  </g>
);

export const HoverRegionUnderCard = () => (
  <rect className="JourneyView-hoverRegionUnderCard" opacity="0" x="0" y={CARD_HEIGHT} width={CARD_WIDTH} height={16} />
);

export const ButtonOnALine = ({ x, y, text, width, onClick, className, hasError, isTrigger }) => (
  <g transform={`translate(${x}, ${y})`}>
    <line
      className={cx('stroke-gray-500', { 'stroke-red-500': hasError })}
      x1="0"
      y1={16}
      x2={ADD_BUTTON_LINE_LENGTH}
      y2={16}
      strokeDasharray="4 3"
      strokeWidth={2}
    />
    <ActionButton
      x={ADD_BUTTON_LINE_LENGTH}
      y={0}
      onClick={onClick}
      tooltipText={text}
      tooltipWidth={200}
      tooltipHeight={36}
      width={width}
      className={cx('JourneyView-rectangularButton', className)}
      hasError={hasError}
      isTrigger={isTrigger}
    />
  </g>
);

export const TransitionButtonLine = ({
  x,
  y,
  text,
  width,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  onDeleteTrigger,
  hasError,
}) => (
  <g
    transform={`translate(${x}, ${y})`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className={cx(className, 'JourneyView-transitionNode')}
  >
    <line className={cx('stroke-gray-500')} x1="0" y1={16} x2={ADD_BUTTON_LINE_LENGTH} y2={16} />
    <ActionButton
      x={ADD_BUTTON_LINE_LENGTH}
      y={0}
      tooltipWidth={200}
      tooltipHeight={36}
      hasError={hasError}
      width={width}
      className="JourneyView-rectangularButton"
      hasTransition={true}
    />
    <HoverRegionUnderCard />
    <ButtonsPanel x={0} y={72} scale={1} panelWidth={CARD_WIDTH / 1.5}>
      <foreignObject x={10} y={10} width={100} height={80}>
        <p className="t-JourneyView-nodeTitle text-2xl font-medium text-gray-600">{text}</p>
      </foreignObject>
      <IconButton
        icon={DeleteIcon}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onDeleteTrigger();
        }}
        x={CARD_WIDTH / 2}
        y={0}
        testHook="deleteTransition"
        color="gray"
      />
    </ButtonsPanel>
  </g>
);

const Circle = ({ x, y, className }) => (
  <circle cx={x} cy={y} r={6} className={cx('fill-transparent stroke-gray-500', className)} />
);

const NodeTitle = ({ title, width }) => (
  <foreignObject x={56} y={12} width={width} height={80}>
    <p className="t-JourneyView-nodeTitle text-2xl font-medium text-gray-600">{title}</p>
  </foreignObject>
);

export const Node = ({
  x,
  y,
  data,
  className,
  onClick,
  Icon,
  children,
  title = data.label,
  parent,
  hasError,
  RightIcon,
  isStep = false,
  isVariant = false,
  isWeightValid = false,
  isStartTrigger = false,
}) => {
  const hasParent = !!parent;
  const hasChild = data.children.length > 0;
  const hasExperimentChilren = hasChild && data.children[0].experimentBranch !== undefined;
  const isExperimentStep = data.experimentBranch !== undefined;

  const testHookClass = `t-jo-node-${data.nodeId}`;
  const invalidWeight = isVariant && !isWeightValid;

  const nodeTitleWidth = 240;
  const isStartTriggerError = isStartTrigger && hasError;
  return (
    <g
      transform={`translate(${x}, ${y})`}
      className={cx('JourneyView-node', testHookClass, className, {
        'JourneyView-node--hasError': hasError,
      })}
      onClick={onClick}
    >
      {hasChild && (
        <Circle
          x={CARD_WIDTH}
          y={HALF_CARD_HEIGHT}
          className={cx({ 'fill-transparent stroke-emerald-600': hasExperimentChilren })}
        />
      )}
      {hasParent && (
        <Circle
          x={0}
          y={HALF_CARD_HEIGHT}
          className={cx({ 'fill-transparent stroke-emerald-600': isExperimentStep })}
        />
      )}
      <CardBase
        isStep={isStep}
        isVariant={isVariant}
        invalidWeight={invalidWeight}
        isStartTriggerError={isStartTriggerError}
      />
      <Icon x={10} y={8} scale={1.8} className={cx({ 'fill-red-500': invalidWeight || isStartTriggerError })} />
      <NodeTitle title={title} width={nodeTitleWidth} />
      {RightIcon && <RightIcon x={CARD_WIDTH - 30} y={10} scale={1} />}

      {children}
    </g>
  );
};
