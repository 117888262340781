/**
 * @description Angular module for the customer journey trigger
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form
 */
angular.module('webUi.ui.customer.journey.form.trigger', [])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.customer.journeys.form.trigger', {
                url: '/trigger/{triggerId}',
                isReact: true
            })
            .state('site.customer.journeys.form.goal', {
                url : '/goal/{goalId}',
                isReact: true
            })
            .state('site.customer.journeys.form.exitCondition', {
                url : '/exitCondition/{exitConditionId}',
                isReact: true
            });
    }
    ]);
