import React from 'react';
import { Field } from 'redux-form';
import Form from '~/components/src/Form/Form';
import ActionsRow from '~/components/src/Form/ActionsDefault';
import { isRequired, isValidSubnet, defaultMaxInput, defaultMaxText, isPhoneNumber } from '~/common';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormTextAreaField,
  ReduxFormToggleField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { DATE_FORMAT, LOCK_TYPE } from '~/userLockSettings/constants';

const UserFormEdit = ({ initialValues, isUserLocked, handleSubmit, submitting, backHref, clients, t, touch }) => {
  const isMaxAttemptsLock = initialValues.lock.type === LOCK_TYPE.MAX_INVALID_PASSWORD_ATTEMPTS;

  return (
    <Form noValidate onSubmit={handleSubmit} isSubmitting={submitting}>
      <Field
        label={t('userForm.name.label')}
        name="name"
        component={ReduxFormInputField}
        placeholder={t('userForm.name.placeholder')}
        validate={[isRequired, defaultMaxInput]}
        type="text"
      />
      <Field
        label={t('userForm.email.label')}
        name="username"
        component={ReduxFormInputField}
        disabled={true}
        type="text"
      />
      <Field
        label={t('userForm.phone.label')}
        name="phoneNumber"
        component={ReduxFormInputField}
        placeholder={t('userForm.phone.placeholder')}
        validate={[isPhoneNumber, defaultMaxInput]}
        maxLength={24}
        type="text"
      />
      <Field
        label={t('userForm.whitelistedSubnets.label')}
        name="whitelistedSubnets"
        size="default"
        component={ReduxFormTextAreaField}
        placeholder={t('userForm.whitelistedSubnets.placeholder')}
        hint={t('userForm.whitelistedSubnets.hint')}
        validate={[isValidSubnet, defaultMaxText]}
      />
      <Field
        className="my-2"
        label={t('userForm.restrictedSubnets.label')}
        name="restrictedSubnets"
        size="default"
        placeholder={t('userForm.restrictedSubnets.placeholder')}
        hint={t('userForm.restrictedSubnets.hint')}
        validate={[isValidSubnet, defaultMaxText]}
        component={props => {
          // Converting array value to string so textarea can understand it
          const originalValue = props.input.value;
          const value = Array.isArray(originalValue) ? originalValue.join(', ') : originalValue;
          const input = { ...props.input, value };

          return <ReduxFormTextAreaField {...props} input={input} />;
        }}
      />
      <div className="my-4">
        <Field
          label={t(`users:userForm.lock.${isUserLocked ? 'locked' : 'unLocked'}`)}
          name="lock.locked"
          type="checkbox"
          component={ReduxFormToggleField}
          disabled={isMaxAttemptsLock}
        />
        {isUserLocked && (
          <div className="flex flex-col gap-1">
            <Field
              label={t('users:userForm.lock.reason')}
              name="lock.reason"
              type="text"
              disabled={isMaxAttemptsLock || initialValues?.lock?.locked}
              component={ReduxFormInputField}
              validate={isUserLocked ? [isRequired, defaultMaxInput] : [defaultMaxInput]}
            />
            {initialValues?.lock?.lockedBy && (
              <span className="pb-4 text-red-500">
                {t('users:userForm.lock.lockedMessage', {
                  lockedBy: initialValues?.lock?.lockedBy,
                  lockedAt: moment(initialValues?.lock?.lockedAt).format(DATE_FORMAT),
                })}
              </span>
            )}
          </div>
        )}
      </div>
      <Field
        label={t('userForm.client.label')}
        name="clientId"
        component={ReduxFormSelectField}
        placeholder={t('userForm.client.placeholder')}
        options={clients}
        touch={touch}
      />
      <ActionsRow t={t} submitting={submitting} cancelHref={backHref} />
    </Form>
  );
};

export default UserFormEdit;
