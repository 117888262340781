import i18n from 'i18next';
import adminResources from './admin/locales';
import profilesResources from './profiles/locales';
import customerResources from './customer/locales';
import supportResources from './support/locales';
import contextResources from './context/locales';
import userResources from './user/locales';
import contentResources from './content/locales';
import tagManagementResources from './tagManagement/locales';
import validationResources from './common/formValidation/locales';
import workflowResources from './workflows/locales';

const resources = {
  en: {
    ...adminResources.en,
    ...profilesResources.en,
    ...customerResources.en,
    ...contextResources.en,
    ...supportResources.en,
    ...userResources.en,
    ...contentResources.en,
    ...tagManagementResources.en,
    ...validationResources.en,
    ...workflowResources.en,
  },
};

i18n.init({
  initImmediate: false,
  lngs: ['en'],
  lng: 'en',
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
});

export default i18n;
