import { get } from 'lodash';

/**
 * Get All Journeys
 * @param state
 */
export const getJourneys = state => ({
  list: state.customer.journeys.allIds.map(id => state.customer.journeys.byId.data[id]),
  total: state.customer.journeys.byId.total,
});

export const getAllLabels = state => state.customer.journeys.labels.asMutable({ deep: true });

/**
 * Is fetching all journeys currently in progress?
 * @param state
 */
export const isFetchingJourneys = state => get(state, 'customer.journeys.ui.isFetchingAll');

export const getSelectedId = state => get(state, 'customer.journeys.ui.selectedId');

export const isDependantsContainerVisible = state => get(state, 'customer.journeys.ui.dependantsContainerIsVisible');

export const getJourneyById = (state, journeyId) => get(state, `customer.journeys.byId.data[${journeyId}]`);

export const getDependantsInfoById = (state, journeyId) =>
  get(state, `customer.journeys.byId[${journeyId}].dependantsInfo`);

export const getSelectedJourney = (state, journeyId) => {
  const journey = getJourneyById(state, journeyId);
  const journeyDependantsInfo = getDependantsInfoById(state, journeyId);
  return {
    ...journey,
    dependantsInfo: journeyDependantsInfo,
  };
};
