import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { SnapshotRequestType, SnapshotType } from '../types';
import { SnapshotCreateResponse, SnapshotResponse } from './types';
import { sortSnapshotProperties } from './util';

const BASE_PATH = `${API_BASE}/v1/jai`;

export const fetchSnapshotList = (): Promise<SnapshotType[]> =>
  Api.callGet(`${BASE_PATH}/snapshots`).then(parseResponse);

export const fetchSnapshot = (snapshotId: string): Promise<SnapshotResponse> =>
  Api.callGet(`${BASE_PATH}/snapshots/${snapshotId}`).then(parseResponse).then(sortSnapshotProperties);

export const createSnapshot = (payload: SnapshotRequestType): Promise<SnapshotCreateResponse> =>
  Api.callPost(`${BASE_PATH}/snapshots`, payload).then(parseResponse);
