import { changeUrl } from '~/common';

const makeGoToViewTriggerScreen = journeyId => triggerId => {
  changeUrl(`customer/journeys/edit/${journeyId}/trigger/${triggerId}`);
};

const makeGoToCreateTriggerScreen = journeyId => parentStepId => {
  let url = `customer/journeys/edit/${journeyId}/triggerNew`;

  if (parentStepId) {
    url += `?parentStepId=${parentStepId}`;
  }

  changeUrl(url);
};

export const makeGoToEditTriggerScreen = journeyId => triggerId => {
  changeUrl(`customer/journeys/edit/${journeyId}/triggerEdit/${triggerId}`);
};

const makeGoToViewStepScreen = journeyId => stepId => {
  changeUrl(`customer/journeys/edit/${journeyId}/step/${stepId}`);
};

const makeGoToCreateStepScreen = journeyId => parentTriggerId => {
  changeUrl(`customer/journeys/edit/${journeyId}/stepNew/addPartner?parentTriggerId=${parentTriggerId}`);
};

const makeGoToEditStepScreen = journeyId => (stepId, parentTriggerId) => {
  changeUrl(`customer/journeys/edit/${journeyId}/stepEdit/${stepId}?parentTriggerId=${parentTriggerId}`);
};

const makeGoToStepStatisticsScreen = journeyId => stepId => {
  changeUrl(`customer/journeys/edit/${journeyId}/statsView/STEP/${stepId}`);
};

const makeGoToGoalStatisticsScreen = journeyId => goalId => {
  changeUrl(`customer/journeys/edit/${journeyId}/statsView/GOAL/${goalId}`);
};

export const makeGoToJourneyView = journeyId => () => {
  changeUrl(`customer/journeys/edit/${journeyId}`);
};

export const makeGoToJourneyStatsView = journeyId => () => {
  changeUrl(`customer/journeys/edit/${journeyId}/joStatsView`);
};

export const makeGoToViewGoalScreen = journeyId => goalId => {
  changeUrl(`customer/journeys/edit/${journeyId}/goal/${goalId}`);
};
export const makeGoToEditGoalScreen = journeyId => goalId => {
  changeUrl(`customer/journeys/edit/${journeyId}/goalEdit/${goalId}`);
};

export const makeGoToCreateGoalScreen = journeyId => () => {
  changeUrl(`customer/journeys/edit/${journeyId}/goalNew`);
};

export const makeGoToEditExitConditionScreen = journeyId => exitConditionId => {
  changeUrl(`customer/journeys/edit/${journeyId}/exitConditionEdit/${exitConditionId}`);
};

export const makeGoToCreateExitConditionScreen = journeyId => () => {
  changeUrl(`customer/journeys/edit/${journeyId}/exitConditionNew`);
};

export const makeGoToExitConditionStatisticsScreen = journeyId => exitConditionId => {
  changeUrl(`customer/journeys/edit/${journeyId}/statsView/ExitCondition/${exitConditionId}`);
};

export const makeGoToViewExitConditionScreen = journeyId => exitConditionId => {
  changeUrl(`customer/journeys/edit/${journeyId}/exitCondition/${exitConditionId}`);
};

export const makeRedirects = journeyId => ({
  goToViewTriggerScreen: makeGoToViewTriggerScreen(journeyId),
  goToCreateTriggerScreen: makeGoToCreateTriggerScreen(journeyId),
  goToEditTriggerScreen: makeGoToEditTriggerScreen(journeyId),
  goToViewStepScreen: makeGoToViewStepScreen(journeyId),
  goToCreateStepScreen: makeGoToCreateStepScreen(journeyId),
  goToEditStepScreen: makeGoToEditStepScreen(journeyId),
  goToStepStatisticsScreen: makeGoToStepStatisticsScreen(journeyId),
  goToGoalStatisticsScreen: makeGoToGoalStatisticsScreen(journeyId),
  goToJourneyView: makeGoToJourneyView(journeyId),
  goToJourneyStatsView: makeGoToJourneyStatsView(journeyId),
  goToViewGoalScreen: makeGoToViewGoalScreen(journeyId),
  goToEditGoalScreen: makeGoToEditGoalScreen(journeyId),
  goToCreateGoalScreen: makeGoToCreateGoalScreen(journeyId),
  goToEditExitConditionScreen: makeGoToEditExitConditionScreen(journeyId),
  goToCreateExitConditionScreen: makeGoToCreateExitConditionScreen(journeyId),
  goToExitConditionStatisticsScreen: makeGoToExitConditionStatisticsScreen(journeyId),
  goToViewExitConditionScreen: makeGoToViewExitConditionScreen(journeyId),
});
