import React, { ComponentType } from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAPI } from '~/common/ApiHooks';
import withSpinner from '~/components/src/Spinner/withSpinner';
import { getSiteId } from '~/common/SecurityMetaService';
import { getConnectorImage } from '~/profiles/connectors';
import * as StepsService from '~/customer/steps/dataService';
import * as RulesService from '~/common/modules/rules/dataService';
import { useQuery } from '~/customer/components/utils';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';
import constants from '~/profiles/connectors/form/constants';

import { getIntegrations, getUsedVariablesForJO, getVariableModificationsFromStep } from './utils';
import EditStep from './presenter.js';

// TODO: temporary fix to get rid of deprecated WEADAPT partner, BE will remove it from the API
const onlyStepConnectors = (connectors: StepsService.ActivePartnerResponseItem[]) =>
  connectors.filter(
    connector =>
      connector.partnerDetails.partnerType !== constants.partnerTypes.RELAY42_API &&
      connector.partnerDetails.partnerType !== constants.partnerTypes.WEADAPT &&
      // temporary filter to remove ADWORDS from UI, change back when API is updated
      connector.partnerDetails.partnerType !== constants.partnerTypes.ADWORDS,
  );

const { RULE_GROUP_TYPES } = RulesService;

const fetchRuleTypes = () => RulesService.getRulesDefinitions(RULE_GROUP_TYPES.VARIABLE_MODIFICATION);
const fetchAllAvailableConnectors = () => StepsService.getActivePartners();
const fetchStep = (stepId: string) => StepsService.fetchStep(stepId);

const fetchDataForStepScreen = async (stepId: string, parentTriggerId: string, journeyId: string) => {
  const isEdit = !!stepId;
  const fetchVariablesForEventListeners = () =>
    RulesService.getRuleVariablesForEventListeners(journeyId, parentTriggerId);

  const requests = [fetchRuleTypes(), fetchAllAvailableConnectors(), fetchVariablesForEventListeners()];

  if (isEdit) {
    requests.push(fetchStep(stepId));
  }

  const [ruleDefinitions, allConnectors, variables, step] = await Promise.all(requests);

  const transformedAllConnectors = onlyStepConnectors(allConnectors).map(connector => {
    const { partnerDetails } = connector;

    const supportedChangeTypes = connector.supportedChangeTypes || [];
    const supportsEventListeners = supportedChangeTypes.includes('MODIFIED');

    const imageUrl = getConnectorImage(getSiteId())(partnerDetails);

    return {
      activated: connector.activated,
      partnerType: partnerDetails.partnerType,
      partnerName: connector.name,
      partnerId: partnerDetails.partnerId,
      partnerNumber: partnerDetails.partnerNumber,
      image: imageUrl,
      supportsEventListeners,
    };
  });

  const stepName = step?.name || null;
  const integrations = step ? getIntegrations(step) : [];
  const usedVariables = getUsedVariablesForJO(variables);
  const ruleTypesTree = getRulesTreeFromTypes(ruleDefinitions);
  const variableModifications = step?.variableModifications
    ? getVariableModificationsFromStep(step.variableModifications)
    : [];

  return { ruleTypesTree, transformedAllConnectors, integrations, stepName, usedVariables, variableModifications };
};

const withDataFetching = (WrappedComponent: ComponentType<any>) => (props: Record<string, any>) => {
  const query = useQuery();
  const parentTriggerId = query.get('parentTriggerId') as string;

  const params = useParams();
  const stepId = params.stepId || '';

  const { data, isLoading, error } = useAPI(() => fetchDataForStepScreen(stepId, parentTriggerId, props.journeyId));

  const ruleTypesTree = data?.ruleTypesTree;
  const transformedAllConnectors = data?.transformedAllConnectors;
  const transformedIntegrations = data?.integrations;
  const transformedEventListeners = data?.variableModifications;
  const usedVariables = data?.usedVariables;
  const stepName = data?.stepName;

  return (
    <WrappedComponent
      isLoading={isLoading}
      error={error}
      ruleTypesTree={ruleTypesTree}
      allConnectors={transformedAllConnectors}
      integrations={transformedIntegrations}
      eventsListeners={transformedEventListeners}
      parentTriggerId={parentTriggerId}
      stepName={stepName}
      stepId={stepId}
      usedVariables={usedVariables}
      {...props}
    />
  );
};

const enhance = compose(translate('orchestration'), withDataFetching, withSpinner('blankOverlay--lightGray'));

export default enhance(EditStep);
