import React from 'react';
import { translate } from 'react-i18next';
import { Heading } from '~/components';
import { I18NextT } from '~/components/src/Common/types';
import { AudiencesPanel, JOPanel, ConnectorsPanel } from '../panels';

interface InsightsProps {
  canViewAudiences: boolean;
  canViewPartners: boolean;
  hasCustomerJourneyViewPermission: boolean;
  t: I18NextT;
}

// TODO: Workflow in Beta Mode
const isBeta = localStorage.getItem('useBeta') === 'true';

const Insights = ({ canViewPartners, hasCustomerJourneyViewPermission, canViewAudiences, t }: InsightsProps) => (
  <div>
    <Heading className="px-4" title={t('section.insights.header')} kind="h4" />
    <div className="grid grid-cols-1 gap-4 px-4 pb-4 lg:grid-cols-2 2xl:grid-cols-3">
      {canViewAudiences && <AudiencesPanel />}
      {hasCustomerJourneyViewPermission && <JOPanel />}
      {isBeta && hasCustomerJourneyViewPermission && <JOPanel type="AUDIENCE" />}
      {canViewPartners && <ConnectorsPanel />}
    </div>
  </div>
);

export default translate('dashboard')(Insights);
