/* eslint-disable max-len */
import { isEmpty } from 'lodash';

/**
 * Validate RegExp to match a pattern like 192.168.1.0/24
 * @param subnet
 * @returns {boolean}
 */
function validRegExpSubnet(subnet) {
  const IP_REGEXP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/[0-9]{1,2}$/;
  return !_.isNull(subnet.match(IP_REGEXP));
}

function validArraySubnets(subnets) {
  return subnets.every(subnet => validRegExpSubnet(subnet));
}

export const validateSubnet = value => {
  let subnet = value;
  // Undefined or Empty value return true
  if (isEmpty(subnet)) {
    return true;
  }
  // is not an array? transform it
  if (!_.isArray(subnet)) {
    subnet = subnet.replace(/\s/g, '').split(',');
  }
  // Validate array of subnets
  return validArraySubnets(subnet);
};
