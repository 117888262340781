import React from 'react';
import Page from '~/components/src/Page';
import { translate } from 'react-i18next';
import CustomerJourneyContainer from '../journeys/containers/CustomerJourneyContainer';

export const CustomerJourneyViewPage = ({ canEditCustomerJourney, maxJourneyLimit }) => (
  <Page excludeContainer>
    <CustomerJourneyContainer canEditCustomerJourney={canEditCustomerJourney} maxJourneyLimit={maxJourneyLimit} />
  </Page>
);

export default translate('journey')(CustomerJourneyViewPage);
