/**
 * @description Angular main module for Customer Journey -> select a journey > Edit
 * @memberOf webUi.ui.workflows
 * @namespace webUi.ui.workflows.form
 */

angular.module('webUi.ui.workflows.form',
    [
        'webUi.ui.siteState',
        'webUi.ui.workflows.base',
        'webUi.ui.workflows.pages',
    ]).config(['$stateProvider',
    function config($stateProvider) {
        $stateProvider
            .state('site.workflows.form', {
                url: '/edit/{workflowId}?step&nodeId',
                isReact: true
            }).state('site.workflows.view', {
                url: '/view/{workflowId}',
                isReact: true
            });
    }
]);