import { combineReducers } from 'redux';
import * as types from './types';

/**
 * Collecting all domain by IDs for current client
 *
 * @param state
 * @param action
 * @returns {{}}
 */
const byId = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case types.CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_SUCCESS:
      nextState = { ...state };
      action.payload.forEach(item => {
        nextState[item.clientDomainId] = item;
      });
      return nextState;

    case types.CONTEXT_CLIENT_VIEW_SAVE_DOMAINS_SUCCESS:
      return {
        ...state,
        [action.payload.clientDomainId]: action.payload,
      };
    case types.CONTEXT_CLIENT_VIEW_DELETE_DOMAINS_SUCCESS:
      nextState = { ...state };
      delete nextState[action.payload.clientDomainId];
      return nextState;

    default:
      return state;
  }
};
/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
const allIds = (state = [], action) => {
  let nextState;
  switch (action.type) {
    case types.CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_SUCCESS:
      return action.payload.map(item => item.clientDomainId);

    case types.CONTEXT_CLIENT_VIEW_SAVE_DOMAINS_SUCCESS:
      if (state.findIndex(item => item === action.payload.clientDomainId) === -1) {
        return [...state, action.payload.clientDomainId];
      }
      return state;
    case types.CONTEXT_CLIENT_VIEW_DELETE_DOMAINS_SUCCESS:
      nextState = [...state];
      nextState.splice(state.findIndex(item => item === action.payload.clientDomainId), 1);
      return nextState;
    default:
      return state;
  }
};

/**
 * UI State
 * @type {{isFetching: boolean, shouldFetchAll: boolean, error: null}}
 */
const defaultUI = {
  isFetching: false,
  shouldFetchAll: true,
  error: null,
};

const ui = (state = defaultUI, action) => {
  switch (action.type) {
    case types.CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_START:
      return {
        ...state,
        isFetching: true,
        shouldFetchAll: false,
      };
    case types.CONTEXT_CLIENT_VIEW_FETCH_DOMAINS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shouldFetchAll: true,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  allIds,
  ui,
});
