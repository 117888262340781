import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { buildUrl } from '~/common';
import Btn from '~/components/src/Btn';
import Link from '~/components/src/Link';
import Spin from '~/components/src/Spin';
import { UITable } from '~/components/src/UITable';
import i18n from '~/i18n';
import { JourneyDependantsProps } from './Journey/components/types';
import { DependantJourney } from './Journey/types';
import { WORKFLOW_TYPE } from '../constants';

const columnHelper = createColumnHelper<DependantJourney>();

export const WorkflowDependants = ({ list, hideModal }: JourneyDependantsProps) => {
  const getColumns = () => [
    columnHelper.accessor('journeyName', {
      header: () => <div>{i18n.t('dependants:list.journeyHeader')}</div>,
      cell: info => (
        <Link
          className="JourneyDependancy-link"
          href={buildUrl(`workflows/edit/${info.row.original.journeyId}`)}
          onClick={hideModal}
          testHook="journeyLink"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('triggerName', {
      header: () => <div>{i18n.t('dependants:list.triggerHeader')}</div>,
      cell: info => {
        const row = info.row.original;
        const baseUrl = `workflows/edit/${row.journeyId}`;
        const url =
          row.workflowType.name === WORKFLOW_TYPE.Audience
            ? `${baseUrl}?step=1`
            : `${baseUrl}/triggerEdit/${row.triggerId}`;

        return (
          <Link className="JourneyDependancy-link" href={buildUrl(url)} onClick={hideModal} testHook="triggerLink">
            {info.getValue()}
          </Link>
        );
      },
    }),
  ];

  if (!list) {
    return <Spin />;
  }

  return (
    <>
      <UITable
        data={list ?? []}
        testHook="dependantsListTable"
        emptyMessage={i18n.t('workflow:list.empty.header')}
        emptyBody={i18n.t('workflow:list.empty.body')}
        columns={getColumns()}
      />
      <div className="flex justify-end pt-2">
        <Btn onClick={hideModal}>{i18n.t('common:close')}</Btn>
      </div>
    </>
  );
};

export default WorkflowDependants;
