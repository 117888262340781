import i18n from '~/i18n';
import { showError } from '~/notificationCenter';
import { getAppNexusAdvertisers } from '../../connectorFormService';
import connectorFormActionTypes from '../../connectorFormActionTypes';
import { errorMessage } from '../../connectorFormActions';
import constants from '../../constants';

const appNexusActionTypes = {
  FETCH_ADVERTISERS: {
    pending: 'FETCH_ADVERTISERS/pending',
    fulfilled: 'FETCH_ADVERTISERS/fulfilled',
    rejected: 'FETCH_ADVERTISERS/rejected',
  },
  SET_ADVERTISERS: 'SET_ADVERTISERS',
};

const fetchAdvertisers = ({ partner, password, username, partnerId } = {}) => dispatch => {
  dispatch({
    name: appNexusActionTypes.FETCH_ADVERTISERS.pending,
    type: connectorFormActionTypes.APPNEXUS,
  });

  return getAppNexusAdvertisers(partner, { username, password, partnerId })
    .then(payload => {
      dispatch({
        name: appNexusActionTypes.FETCH_ADVERTISERS.fulfilled,
        type: connectorFormActionTypes.APPNEXUS,
      });
      dispatch({
        name: appNexusActionTypes.SET_ADVERTISERS,
        data: payload.data,
        type: connectorFormActionTypes.APPNEXUS,
      });
    })
    .catch(error => {
      dispatch({
        type: connectorFormActionTypes.APPNEXUS,
        name: appNexusActionTypes.FETCH_ADVERTISERS.rejected,
      });
      dispatch({ type: connectorFormActionTypes.CREATE_CONNECTOR.rejected });

      if (error.status && error.status.error.errorType) {
        showError({
          header: i18n.t('partners:form.notification.error'),
          body: errorMessage(constants[partner.partnerType], error.status.error.message),
        });
      }
    });
};

const setAdvertisers = (advertisers = []) => dispatch =>
  dispatch({
    name: appNexusActionTypes.SET_ADVERTISERS,
    data: advertisers,
    type: connectorFormActionTypes.APPNEXUS,
  });

export { appNexusActionTypes, fetchAdvertisers, setAdvertisers };
