import React, { useState } from 'react';
import { Column, useTable, UseTableRowProps } from 'react-table';
import moment from 'moment';
import cx from 'classnames';
import i18n from '~/i18n';
import { Empty, Link, Notification } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import { Panel } from '~/components/src/Containers';
import { buildUrl } from '~/common';
import DeletePopup from '~/components/src/DeletePopup';
import { showSuccess } from '~/notificationCenter';
import { FilterListRowType, FilterListType, FiltersTableProps } from './types';
import { deleteFilter } from './dataService';

const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm:ss';

const filterFilters = (filters: FilterListType[], query: string) =>
  query ? filters.filter(filter => filter.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) : filters;

const EmptyList = () => (
  <Empty className="!top-2" header={i18n.t('filters:list.emptyHeader')} body={i18n.t('filters:list.emptyBody')} />
);

function FiltersTable({
  filtersList,
  filterQuery,
  canEditProfiles,
  refetchFilters,
  setSelectedFilter,
}: FiltersTableProps): JSX.Element {
  const [selectedDeleteFilter, setSelectedDeleteFilter] = useState<FilterListType | null>(null);

  const handleDelete = async (filterId: string) => {
    await deleteFilter(filterId);
    refetchFilters();
    setSelectedDeleteFilter(null);
    showSuccess({ header: i18n.t('filters:form.deleteSuccess') });
  };

  const filters = filterFilters(filtersList, filterQuery);

  const columns: Column<FilterListRowType>[] = React.useMemo(
    () => [
      {
        Header: i18n.t('filters:columns.name'),
        accessor: 'name',
        className: 'w-1/3',
        Cell: ({ row }: { row: UseTableRowProps<FilterListRowType> }) => (
          <Link className="t-filterName w-[30%]" href={buildUrl(`profiles/filters/view/${row.original.filterId}`)}>
            <span className="elipsis truncate">{row.original.name}</span>
          </Link>
        ),
      },
      {
        Header: i18n.t('filters:columns.createdAt'),
        accessor: 'createdAt',
        Cell: ({ row }: { row: UseTableRowProps<FilterListRowType> }) => (
          <div className="flex flex-col">
            <p className="t-filterCreatedDate">{moment(row.values.createdAt).format(dateFormat)}</p>
            <small className="t-filterCreatedTime">{moment(row.values.createdAt).format(timeFormat)}</small>
          </div>
        ),
      },
      {
        Header: i18n.t('filters:columns.modifiedAt'),
        accessor: 'lastModifiedAt',
        Cell: ({ row }: { row: UseTableRowProps<FilterListRowType> }) => (
          <div className="flex flex-col">
            <p className="t-filterModifiedDate">{moment(row.values.lastModifiedAt).format(dateFormat)}</p>
            <small className="t-filterModifiedTime">{moment(row.values.lastModifiedAt).format(timeFormat)}</small>
          </div>
        ),
      },
      {
        Header: i18n.t('filters:columns.dependants'),
        accessor: 'dependants',
        className: 'flex justify-center',
        Cell: ({ row }: { row: UseTableRowProps<FilterListRowType> }) => (
          <div className="flex justify-center">
            {row.values.dependants.length > 0 && (
              <BtnIcon
                color="blue"
                icon="mappingsAndTransformations"
                onClick={() => setSelectedFilter(row.original)}
                testHook="filterDependants"
                tooltip={i18n.t('filters:list.connector')}
              />
            )}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }: { row: UseTableRowProps<FilterListRowType> }) => (
          <div className="flex justify-center">
            {canEditProfiles && (
              <BtnIcon
                icon="delete"
                onClick={() => setSelectedDeleteFilter(row.original)}
                testHook="deleteFilter"
                disabled={row.original.dependants.length > 0}
                tooltip={i18n.t(`filters:list.${row.original.dependants.length > 0 ? 'disableDelete' : 'delete'}`)}
              />
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: filters || [],
    autoResetSortBy: false,
    autoResetFilters: false,
  });

  if (filtersList?.length === 0) {
    return <EmptyList />;
  }

  return (
    <div className="py-4">
      <Panel className="overflow-auto p-0 ">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    className={cx(
                      't-filterTableHead border-b bg-gray-50 px-2 py-3 text-sm font-medium',
                      column.className,
                    )}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="t-filterTableRow border-b last:border-none">
                  {row.cells.map(cell => (
                    <td className="p-2" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Panel>
      {selectedDeleteFilter && (
        <DeletePopup
          title={i18n.t('filters:list.deleteHeader')}
          hidePopup={() => setSelectedDeleteFilter(null)}
          onClick={() => handleDelete(selectedDeleteFilter.filterId)}
        >
          <Notification testHook="filterDeleteDescription">
            {i18n.t('filters:list.deleteBody', {
              name:
                selectedDeleteFilter.name.length > 50
                  ? `${selectedDeleteFilter.name.slice(0, 45)}...`
                  : selectedDeleteFilter.name,
            })}
          </Notification>
        </DeletePopup>
      )}
    </div>
  );
}

export default FiltersTable;
