import * as d3 from 'd3';

import { CARD_WIDTH, CARD_HEIGHT, NODE_PADDING_RIGHT, NODE_PADDING_BOTTOM } from '~/customer/components/constants';

import { isJoDemoMode } from '~/customer/fake/utils';
import demoJourney from '~/customer/fake/demoJourney';

const rotate = position => ({
  ...position,
  x: position?.y,
  y: position?.x,
});

const addProfilesCount = nodes =>
  nodes.map(node => {
    if (node.data.type === 'STEP') {
      return {
        ...node,
        data: {
          ...node.data,
          profilesCount: node.data?.stats?.profilesInStep || 0,
        },
      };
    }
    if (node.data.type === 'TRIGGER') {
      return {
        ...node,
        data: {
          ...node.data,
          profilesCount: node.data?.stats?.profilesEntered || 0,
        },
      };
    }

    return node;
  });

const prepareNodes = (nodes, collapsedNodeIds) => {
  const modifyNodes = nodes => ({
    ...nodes,
    children: collapsedNodeIds.includes(nodes.nodeId)
      ? []
      : nodes.children.map(childNode => {
          if (childNode.children.length) return modifyNodes(childNode);
          return {
            ...childNode,
            children: collapsedNodeIds.includes(childNode.nodeId) ? [] : childNode.children,
            isChildrenCollapsed: collapsedNodeIds.includes(childNode.nodeId),
          };
        }),
    isChildrenCollapsed: collapsedNodeIds.includes(nodes.nodeId),
  });

  return modifyNodes(nodes);
};

export const convertForDisplay = (journeyContents, journeyId, collapsedNodeIds) => {
  if (isJoDemoMode() && journeyId === 'demoJourney') {
    journeyContents = demoJourney;
  }

  const modifiedNodes = prepareNodes(journeyContents.nodes, collapsedNodeIds);
  const hierarchy = d3.hierarchy(modifiedNodes);
  const nodeSourcesToLink = hierarchy.descendants().filter(({ data }) => data.hasTransition && data.type === 'TRIGGER');
  const nodeTargetsToLink = hierarchy.descendants().filter(({ data }) => data.hasTransition && data.type === 'STEP');

  const newLinks = nodeSourcesToLink.map(target => {
    const source = nodeTargetsToLink?.filter(source => source?.data?.nodeId === target?.data?.transitionNodeId);
    return {
      source: source[0],
      target,
      _source: source[0], // backup source
      _target: target, // backup target
      isTransition: true,
    };
  });

  const treeLayout = d3
    .tree()
    .nodeSize([CARD_HEIGHT + NODE_PADDING_BOTTOM, CARD_WIDTH + NODE_PADDING_RIGHT])
    .separation(() => 1);

  const treeWithPositions = treeLayout(hierarchy);

  const nodes = treeWithPositions.descendants();
  const links = treeWithPositions.links();

  links.push(...newLinks);

  // D3 returns a vertical tree. Rotate it to be horizontal.
  const rotatedNodes = addProfilesCount(nodes.map(rotate));
  const rotatedLinks = links.map(link => ({
    source: rotate(link.source),
    target: rotate(link.target),
    isTransition: link.source && link.target && link.isTransition ? link.isTransition : false,
  }));

  return {
    nodes: rotatedNodes,
    links: rotatedLinks,
  };
};
