import React, { CSSProperties, useContext } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';
import Notification from '~/components/src/Notification';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import { menuPortalStyle } from '~/tagManagement/urlBuilder/utils';
import { EXPIRY_OPTIONS } from '~/workflows/constants';
import { updateWorkflow } from '~/workflows/dataService';
import { TOption } from '~/workflows/types';
import { JourneyContext } from './JourneyContext';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(i18n.t('validation:validation.required')),
  expiryTime: Yup.number()
    .min(1, i18n.t('workflow:journey.journeyModal.validations.expiryTime'))
    .required(i18n.t('validation:validation.required')),
});

const EditJourney = ({ hideModal }: { hideModal: () => void }) => {
  const { journeyData, refreshJourney } = useContext(JourneyContext);
  if (!journeyData) return <Spin />;

  const { name, expiryTime, journeyId } = journeyData;

  const initialValues = {
    name,
    expiryTime: expiryTime ? expiryTime.substring(0, expiryTime.length - 1) : 90,
    timeUnit: expiryTime ? expiryTime[expiryTime.length - 1] : 'd',
  };

  const handleSubmit = async (values: any) => {
    const payload = {
      name: values.name,
      expiryTime: `${values.expiryTime}${values.timeUnit}`,
      description: '',
    };

    await updateWorkflow(journeyId, payload);
    hideModal();
    refreshJourney();
    showSuccess({
      header: i18n.t('workflow:journey.journeyModal.successToast'),
    });
  };

  return (
    <div className="EditJourney">
      <Formik onSubmit={handleSubmit} validationSchema={validationSchema} initialValues={initialValues}>
        {({ handleSubmit, setFieldValue, errors, isValid, isSubmitting }) => (
          <>
            <Notification kind="information">
              <span>{i18n.t('workflow:journey.journeyModal.expiryTip')}</span>
            </Notification>
            <Field
              name="name"
              as={FormikInputField}
              label={i18n.t('workflow:journey.journeyModal.placeholders.name')}
              placeholder={i18n.t('connectors:common.commonField.pleaseProvideValue')}
              errorText={errors.name}
              autoFocus={true}
            />
            <label className="py-2">{i18n.t('workflow:journey.journeyModal.placeholders.expiryTime')}</label>
            <div className="flex items-center gap-2">
              <Field
                name="expiryTime"
                as={FormikInputField}
                errorText={errors.expiryTime}
                placeholder={i18n.t('connectors:common.commonField.pleaseProvideValue')}
                min={1}
                type="number"
                className="w-1/2"
              />
              <Field
                name="timeUnit"
                as={FormikSelectField}
                className="w-full"
                options={EXPIRY_OPTIONS}
                customSetFieldValue={(name: string, option: TOption) => setFieldValue(name, option.value)}
                menuPosition="fixed"
                elementStyles={{
                  menuPortal: (provided: CSSProperties) => menuPortalStyle(provided),
                }}
              />
            </div>

            <ActionButtons
              className="mr-0 py-2"
              onConfirm={handleSubmit}
              onDecline={hideModal}
              isConfirmEnabled={isValid && !isSubmitting}
              testHook="editJourneyButtons"
              confirmText={i18n.t('workflow:journey.journeyModal.update')}
              declineText={i18n.t('workflow:journey.journeyModal.cancel')}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default EditJourney;
