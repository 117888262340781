import { changeUrl, normalizeValue } from '~/common';
import ContextUsersService from '../dataService.js';
import { subnetsToString, normalizeSubnets } from './helper';
import * as types from '../types';
import * as selectors from '../selectors';

/**
 * Fetch user
 */
export const fetchUser = userId => dispatch => {
  dispatch({
    type: types.USER_FETCH_START,
  });

  return ContextUsersService.getUser(userId)
    .then((payload = {}) =>
      dispatch({
        type: types.USER_FETCH_SUCCESS,
        payload: {
          ...payload,
          clientId: payload.clientPojo && payload.clientPojo.clientId,
          restrictedSubnets: subnetsToString(payload.restrictedSubnets),
          whitelistedSubnets: subnetsToString(payload.whitelistedSubnets),
        },
      }),
    )
    .catch(error => dispatch({ type: types.USER_FETCH_FAIL, error }));
};

/**
 * Fetch user only if not fetched yet
 */
export const fetchUserIfNeeded = userId => (dispatch, getState) => {
  if (selectors.shouldFetchUser(getState(), userId)) {
    return dispatch(fetchUser(userId));
  }
  return Promise.resolve();
};

/**
 * Save new user
 * @param user
 */
export const saveUser = user => dispatch => {
  const model = {
    ...user,
    defaultSiteId: user.site.id,
    name: normalizeValue(user.name),
    username: normalizeValue(user.username),
  };

  dispatch({
    type: types.USER_SAVE_START,
    payload: model,
  });

  // Save User on the backend
  return ContextUsersService.addUser(model)
    .then(data => {
      dispatch({
        type: types.USER_SAVE_SUCCESS,
        payload: {
          ...model,
          userId: data,
        },
      });

      // Go to the message view
      changeUrl(`context/users/view/${data}`);
    })
    .catch(error => dispatch({ type: types.USER_SAVE_FAIL, error }));
};

/**
 * Edit existing user
 * @param values
 */
export const editUser = values => dispatch => {
  const { lock, clientId, name, phoneNumber, restrictedSubnets, userId, whitelistedSubnets } = values;
  const model = {
    lock,
    clientId,
    name: normalizeValue(name),
    phoneNumber,
    restrictedSubnets: normalizeSubnets(restrictedSubnets),
    userId,
    whitelistedSubnets: normalizeSubnets(whitelistedSubnets),
  };

  dispatch({
    type: types.USER_EDIT_START,
    payload: model,
  });

  // Update User on the backend
  return ContextUsersService.editUser(model)
    .then((payload = {}) => {
      dispatch({
        type: types.USER_EDIT_SUCCESS,
        payload: {
          ...payload,
          clientId: payload.clientPojo && payload.clientPojo.clientId,
        },
      });

      // Go to the message view
      changeUrl(`context/users/view/${payload.userId}`);
    })
    .catch(error => dispatch({ type: types.USER_EDIT_FAIL, error }));
};

/**
 * Toggle Password Invalidation
 */
export const togglePasswordInvalidation = () => ({
  type: types.TOGGLE_PASSWORD_INVALIDATION,
});

/**
 * Request Password Reset for a user
 * @param userId
 */
export const requestPasswordReset = userId => (dispatch, getState) => {
  const model = {
    userId,
    invalidateOldCredentials: selectors.shouldInvalidatePassword(getState()),
  };

  dispatch({
    type: types.REQUEST_NEW_PASSWORD_START,
  });

  return ContextUsersService.requestPasswordReset(model)
    .then(() =>
      dispatch({
        type: types.REQUEST_NEW_PASSWORD_SUCCESS,
        payload: model,
      }),
    )
    .catch(error => dispatch({ type: types.REQUEST_NEW_PASSWORD_FAIL, error }));
};
