/**
 * @description Angular module for the customer journey trigger
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form
 */
angular.module('webUi.ui.customer.journey.form.joStatsView', [])
    .config(['$stateProvider',
        function config($stateProvider) {
            $stateProvider
                .state('site.customer.journeys.form.joStatsView', {
                    url: '/joStatsView',
                    isReact: true
                });
        }
    ]
    );
