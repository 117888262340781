import React from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import { ReduxFormInputField, ReduxFormSensitiveField } from '~/components/src/Form/Fields/ReduxFormFields';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import FormHeader from '../formHeader/FormHeader';
import constants from '../../constants';

const SalesforceForm = props => {
  const { t, canUpdate, values, partner } = props;

  return (
    <FormSection name={constants.partnerTypes.SALESFORCE}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.cookieSyncMessage')}
        partnerNumber={values.partnerNumber || partner.partnerNumber}
      />
      <ContainerFormSection>
        <Field
          label={t('form.formFields.user')}
          name="user"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />

        <FieldWithEditMode
          label={t('form.formFields.password')}
          name="password"
          component={ReduxFormSensitiveField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          isNeedActivateEditMode={canUpdate}
          validate={isRequired}
          type="text"
        />

        <Field
          label={t('form.formFields.apiKey')}
          name="apiKey"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />

        <Field
          label={t('form.formFields.organizationUuid')}
          name="organizationUuid"
          component={ReduxFormInputField}
          placeholder={t('form.formFields.inputPlaceHolder')}
          validate={isRequired}
          type="text"
        />
      </ContainerFormSection>
    </FormSection>
  );
};

export default SalesforceForm;
