import { connect } from 'react-redux';
import { fetchUserIdTypes, redirectToAuthorizationUrl, fetchPartnerInformation } from './doubleClickOfflineActions';
import {
  fetchingUserIdTypes,
  getUserIdTypes,
  fetchingPartnerInformation,
  getPartnerInformation,
} from './doubleClickOfflineSelector';
import DoubleClickOfflineForm from './DoubleClickOfflineForm';

const mapStateToProps = state => ({
  fetchingUserIdTypes: fetchingUserIdTypes(state),
  userIdTypes: getUserIdTypes(state),
  fetchingPartnerInformation: fetchingPartnerInformation(state),
  partnerInformation: getPartnerInformation(state),
});

const mapDispatchToProps = {
  fetchUserIdTypes,
  redirectToAuthorizationUrl,
  fetchPartnerInformation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoubleClickOfflineForm);
