export const JOURNEYS_FETCH_START = 'app/customer/journeys/JOURNEYS_FETCH_START';
export const JOURNEYS_FETCH_SUCCESS = 'app/customer/journeys/JOURNEYS_FETCH_SUCCESS';
export const JOURNEYS_FETCH_FAIL = 'app/customer/journeys/JOURNEYS_FETCH_FAIL';

export const JOURNEY_LABELS_UPDATE_START = 'app/customer/journeys/JOURNEY_LABELS_UPDATE_START';
export const JOURNEY_LABELS_UPDATE_SUCCESS = 'app/customer/journeys/JOURNEY_LABELS_UPDATE_SUCCESS';
export const JOURNEY_LABELS_UPDATE_FAIL = 'app/customer/journeys/JOURNEY_LABELS_UPDATE_FAIL';

export const JOURNEY_LABELS_FETCH_START = 'app/customer/journeys/JOURNEY_LABELS_FETCH_START';
export const JOURNEY_LABELS_FETCH_SUCCESS = 'app/customer/journeys/JOURNEY_LABELS_FETCH_SUCCESS';
export const JOURNEY_LABELS_FETCH_FAIL = 'app/customer/journeys/JOURNEY_LABELS_FETCH_FAIL';

export const JOURNEYS_DELETE_START = 'app/customer/journeys/JOURNEYS_DELETE_START';
export const JOURNEYS_DELETE_SUCCESS = 'app/customer/journeys/JOURNEYS_DELETE_SUCCESS';

export const SHOW_DEPENDANTS_CONTAINER = 'app/customer/journeys/SHOW_DEPENDANTS_CONTAINER';
export const HIDE_DEPENDANTS_CONTAINER = 'app/customer/journeys/HIDE_DEPENDANTS_CONTAINER';

export const JOURNEY_DEPENDANTS_INFO_FETCH_SUCCESS =
  'app/customer/journeys/dependencies/JOURNEY_DEPENDANTS_INFO_FETCH_SUCCESS';
