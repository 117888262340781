import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import ProfileConnectors from '~/profiles/connectors/dataService';
import { IAddUserPayload } from './types';

const getAdwordsUsersListURL = (partnerId: string) => `${API_BASE}/remote/partners/adwords/${partnerId}/userLists`;

const getAdwordsUsersList = (partnerId: string) =>
  Api.callGet(getAdwordsUsersListURL(partnerId), { shouldShowToast: false, shouldHandleCommonErrors: false }).then(
    parseResponse,
  );

const addUserToAdwords = (partnerId: string, payload: IAddUserPayload) =>
  Api.callPost(getAdwordsUsersListURL(partnerId), payload, { shouldShowToast: true }).then(parseResponse);

const getPayloadType = (partnerId: string) =>
  ProfileConnectors.getConnectorDetail(partnerId)
  .then(connector => connector?.configuration?.payloadType?.name);

export { addUserToAdwords, getAdwordsUsersList, getPayloadType };
