import React from 'react';
import { translate } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Field, withFormik } from 'formik';
import { ContextEffect } from '~/common';
import i18n from '~/i18n';
import { FormikInputField, FormikTextAreaField } from '~/components/src/Form/Fields/FormikFields';
import './AudienceGeneralInfoForm.scss';

const editAudienceSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, i18n.t('audiences:edit.validationsErrors.noName'))
    .max(255, i18n.t('audiences:edit.validationsErrors.longName'))
    .required('Required'),
  description: Yup.string()
    .nullable()
    .max(1000, i18n.t('audiences:edit.validationsErrors.longDescription')),
});

const formTransformations = ({ isValid, values }) => () => ({
  isValid,
  generalForm: values,
});

const AudienceGeneralInfoForm = ({
  t,
  onChange,
  isNew,
  isValid,
  values,
  dirty,
  touched,
  errors,
  isSubmitted,
  submitCount,
  setTouched,
}) => (
  <div className="AudienceGeneralInfoForm">
    <Form>
      <ContextEffect
        onChange={onChange}
        formTransformations={formTransformations({ isValid, values, dirty }, isNew)}
        isSubmitted={isSubmitted}
        submitCount={submitCount}
        setTouched={setTouched}
        errors={errors}
      />
      <Field as={FormikInputField} name="name" label={t('edit.name')} errorText={touched.name && errors.name} />
      <Field
        as={FormikTextAreaField}
        name="description"
        label={t('edit.description')}
        errorText={touched.description && errors.description}
      />
    </Form>
  </div>
);

const GeneralDetailsFormWrapper = withFormik({
  mapPropsToValues: ({ audience }) => ({
    name: audience !== null ? audience.name : '',
    description: audience !== null ? audience.description : '',
  }),
  validationSchema: editAudienceSchema,
  validateOnMount: true,
  validateOnBlur: true,
})(AudienceGeneralInfoForm);

export default translate('audiences')(GeneralDetailsFormWrapper);
