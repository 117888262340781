import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { buildUrl, useAPI } from '~/common';
import { getAllRuleVariables } from '~/common/modules/rules/dataService';
import { Link, PickerView } from '~/components';
import BtnIcon from '~/components/src/BtnIcon';
import Icons from '~/components/src/Icons';
import i18n from '~/i18n';
import { HP_STATUS, WORKFLOW_STATUS, WORKFLOW_TYPE } from '~/workflows/constants';
import { fetchDependencies, validateJourney } from '~/workflows/dataService';
import TriggerHistoricProcesingButton from '~/workflows/form/HistoricProcessing/components/TriggerHistoricProcesingButton';
import { HPWorkflowTypes } from '~/workflows/form/HistoricProcessing/constants';
import TriggerHP from '~/workflows/form/HistoricProcessing/TriggerHP';
import ViewScheduledHP from '~/workflows/form/HistoricProcessing/ViewScheduledHP';
import { JourneyValidationError } from '~/workflows/form/Journey/types';
import PublishButton from '~/workflows/form/Publish/PublishButton';
import PublishModal from '~/workflows/form/Publish/PublishModal';
import ValidationModal from '~/workflows/form/Publish/ValidationModal';
import WorkflowDependants from '~/workflows/form/WorkflowDependants';
import WorkflowStatusTag from '~/workflows/form/WorkflowStatusTag';
import { THeaderProps } from '~/workflows/types';
import { formatExpiryTime } from '~/workflows/util';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import CopyID from '../CopyID';

const Header = ({
  workflowId,
  treeData,
  hasGoals,
  hasExits,
  audienceData,
  canEditPublishButton,
  refetchAudience,
}: THeaderProps) => {
  const { navigate, hasWorkflowAudienceEdit: canEdit } = useContext(WorklfowContext);

  const { data: variables } = useAPI(() => getAllRuleVariables());
  const { data: dependants } = useAPI(async () => fetchDependencies(workflowId));

  const [showDependantJourneys, setShowDependantJourneys] = useState(false);
  const [journeyErrors, setJourneyErrors] = useState<JourneyValidationError[]>([]);
  const [showValidationErrorsModal, setShowValidationErrorsModal] = useState(false);
  const [showJourneyPublishModal, setShowJourneyPublishModal] = useState(false);
  const [showHistoricProcessingModal, setShowHistoricProcessingModal] = useState(false);
  const [showHistoricProcessRunningModal, setShowHistoricProcessRunningModal] = useState(false);

  const { state, historicProcessing, name, expiryTime, workflowType } = audienceData;

  const dashboardLink = buildUrl('workflows/dashboard');
  const isHpRunning = historicProcessing?.state?.name === HP_STATUS.RUNNING;
  const isHpScheduled = historicProcessing?.state?.name === HP_STATUS.SCHEDULED;

  const statusText =
    state.name === WORKFLOW_STATUS.PUBLISHED
      ? i18n.t('workflow:orchestration.publish.publishedLabel')
      : i18n.t('workflow:orchestration.publish.unpublishedChangesLabel');

  const handleValidate = async () => {
    const validationResponse = await validateJourney(workflowId);
    if (validationResponse.length) {
      setJourneyErrors(validationResponse);
      setShowValidationErrorsModal(true);
    } else {
      setShowJourneyPublishModal(true);
    }
  };

  return (
    <>
      <div className="sticky top-0 z-[1] flex items-center justify-between gap-2 border-b bg-white px-3 py-2 text-2xl">
        <div className="flex gap-2">
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <RouterLink to={dashboardLink} className="PageTitleHeader-titleContainer--link">
                <Icons
                  icon="back"
                  tooltip={i18n.t('workflow:orchestration.header.backToJourneyList')}
                  className="h-6 w-6 text-gray-400"
                />
              </RouterLink>
              <Icons className="h-6 w-6 text-blue-600" icon="audience" />
              <p className="text-xl">{name}</p>
            </div>
            <CopyID className="ml-8" id={workflowId} testHook="copyAudienceId" />
            <div className="ml-8 mt-1 flex items-center gap-1 text-sm">
              <WorkflowStatusTag status={state.name} text={statusText} />
              <div className="flex items-center gap-1">
                <Icons icon="schedule" className="h-4 w-4" />
                {formatExpiryTime(expiryTime)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <BtnIcon
            icon="statistics"
            tooltip={i18n.t('workflow:overview.actions.stats')}
            onClick={() => navigate(buildUrl(`workflows/edit/${workflowId}/stats`))}
            testHook="statistics"
          />
          <TriggerHistoricProcesingButton
            hpJobData={historicProcessing}
            eligible={!!historicProcessing?.eligible}
            isHpRunning={isHpRunning}
            isHpScheduled={isHpScheduled}
            canEdit={canEdit}
            workflowType={HPWorkflowTypes.Audience}
            setShowHistoricProcessingModal={setShowHistoricProcessingModal}
            setShowHistoricProcessRunningModal={setShowHistoricProcessRunningModal}
          />
          <BtnIcon
            icon="edit"
            color="gray"
            testHook="edit"
            disabled={!canEdit}
            onClick={() => navigate(buildUrl(`workflows/edit/${workflowId}`))}
            tooltip={i18n.t('workflow:overview.actions.edit')}
          />
          <PublishButton
            canEdit={canEditPublishButton}
            state={state}
            validateJourney={handleValidate}
            isHpRunningOrScheduled={isHpRunning || isHpScheduled}
          />
        </div>
      </div>
      {dependants && dependants.length > 0 && (
        <div className="flex h-12 items-center justify-center bg-amber-500">
          <Icons className="mx-2 h-6 w-6 text-white" icon="overlap" />
          <p className="text-white">
            {i18n.t('workflow:orchestration.header.overlapInformation', { type: workflowType.label })}
          </p>
          <Link className="JourneyOverlap-infoBarLink" onClick={() => setShowDependantJourneys(true)}>
            {i18n.t('workflow:orchestration.header.overlapDependencyLink')}
          </Link>
        </div>
      )}
      {showDependantJourneys && (
        <PickerView
          className="!h-auto min-h-[200px] !w-1/3 min-w-[400px]"
          pickerTitle={i18n.t('workflow:journey.dependencyModal.title', { type: workflowType.label })}
          handlePickerVisibility={() => setShowDependantJourneys(false)}
        >
          <WorkflowDependants list={dependants || []} hideModal={() => setShowDependantJourneys(false)} />
        </PickerView>
      )}
      {showValidationErrorsModal && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:overview.publish.title')}
          handlePickerVisibility={() => setShowValidationErrorsModal(false)}
        >
          <ValidationModal
            journeyName={name || ''}
            validations={journeyErrors}
            variables={variables}
            journeyTree={treeData}
            workflowType={WORKFLOW_TYPE.Audience}
            workflowId={workflowId}
            hideModal={() => setShowValidationErrorsModal(false)}
            setShowJourneyErrors={setShowValidationErrorsModal}
          />
        </PickerView>
      )}
      {showJourneyPublishModal && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:overview.publish.title')}
          handlePickerVisibility={() => setShowJourneyPublishModal(false)}
        >
          <PublishModal
            journeyName={name || ''}
            isHpRunningOrScheduled={isHpRunning || isHpScheduled}
            isLatestVersion={false}
            journeyId={workflowId || ''}
            refreshJourney={() => refetchAudience()}
            hideModal={() => setShowJourneyPublishModal(false)}
            workflowType={WORKFLOW_TYPE.Audience}
          />
        </PickerView>
      )}
      {showHistoricProcessingModal && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:orchestration.tooltips.triggerHistoricProcessing')}
          handlePickerVisibility={() => setShowHistoricProcessingModal(false)}
        >
          <TriggerHP
            hasGoals={hasGoals}
            hasExits={hasExits}
            journeyId={workflowId}
            workflowType={HPWorkflowTypes.Audience}
            fetchJourneyData={refetchAudience}
            hideModal={() => setShowHistoricProcessingModal(false)}
          />
        </PickerView>
      )}
      {showHistoricProcessRunningModal && (
        <PickerView
          className="!h-auto min-h-[200px] !w-auto min-w-[400px]"
          pickerTitle={i18n.t('workflow:orchestration.tooltips.scheduledHistoricProcessing')}
          handlePickerVisibility={() => setShowHistoricProcessRunningModal(false)}
        >
          <ViewScheduledHP
            hasGoals={hasGoals}
            hasExits={hasExits}
            hpJobId={historicProcessing.jobId || ''}
            journeyId={workflowId}
            workflowType={HPWorkflowTypes.Audience}
            fetchJourneyData={refetchAudience}
            hideModal={() => setShowHistoricProcessRunningModal(false)}
          />
        </PickerView>
      )}
    </>
  );
};

export default Header;
