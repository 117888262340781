/* eslint-disable no-prototype-builtins */
angular.module('webUi.service.permission', [
    'restangular',
    'ui.bootstrap',
    'webUi.service.tagmanagement'
])

    .factory('PermissionService', ['Restangular', '$uibModal', 'TagmanagementService', 'SecurityService', '$q',
    /**
     *
     * @function PermissionService
     *
     * @param {Restangular} Restangular
     * @param {$uibModal} $uibModal
     * @param {TagmanagementService} TagmanagementService
     * @returns {PermissionService}
     * @memberOf webUi.service.permission
     */
        function PermissionService(Restangular, $uibModal, TagmanagementService, SecurityService, $q) {

            var BASE_PATH = 'permission/';
            // TODO
            var getAudienceNavName = function() {
                if(localStorage.getItem('useBeta') === 'true'){
                    return'Orchestration';
                } 
                return 'Workflow';
            };

            var ALL_LEFTNAV_ITEMS = [
                {name: 'Dashboard', id: 'r42-dashboard', siteState: 'site.dashboard', keycode: 'd'},
                {name: 'Tags', id: 'r42-tagmanagement', siteState: 'site.tagmanagement', requiredRoles: ['TAG_VIEW', 'URL_BUILDER'], requiredModules: ['TAG_MANAGEMENT'], keycode: 't'},
                {name: 'Data', id: 'r42-profiles', siteState: 'site.profiles', requiredRoles: ['PROFILES_VIEW','DATA_ACCESS', 'isSupportEngineer', 'ADSERVING_VIEW'], requiredModules: ['DATA_MANAGEMENT'], keycode: 'p'},
                {name: 'Audiences', id: 'r42-audiences', siteState: 'site.audiences', linkState: 'site.audiences.segments.dashboard', requiredRoles: ['AUDIENCES_VIEW', 'isSupportEngineer'], requiredModules: ['AUDIENCES'], keycode: 'k'},
                {name: 'AI', id: 'r42-ai', siteState: 'site.ai', requiredRoles: ['JOURNEY_AI_ACCESS'], requiredModules: ['RECOMMENDER'], keycode: 'a'},
                {name: 'Orchestration', id: 'r42-journeys', siteState: 'site.customer', requiredRoles: ['CUSTOMER_JOURNEY_VIEW', 'CUSTOMER_JOURNEY_EDIT', 'isSupportEngineer', 'isContextAdmin'], requiredModules: ['CUSTOMER_JOURNEY'], keycode: 'j'},
                {name: getAudienceNavName(), id: 'r42-workflows', siteState: 'site.workflows', requiredRoles: ['WORKFLOW_VIEW', 'WORKFLOW_AUDIENCE_EDIT', 'WORKFLOW_JOURNEY_EDIT', 'isSupportEngineer', 'isContextAdmin'], requiredModules: ['CUSTOMER_JOURNEY'], keycode: 'w'},
                {name: 'Content', id: 'r42-content', siteState: 'site.content', requiredRoles: ['ADSERVING_VIEW'], requiredModules: ['AD_SERVING'], keycode: 'c'},
            ];

            var ALL_RIGHTNAV_ITEMS = [
                {name: 'Admin', id: 'r42-admin', siteState: 'site.admin', requiredRoles: ['SITEADMIN', 'isSupportEngineer'], keycode: 'a'}
            ];

            var ALL_SUBNAV_ITEMS = {
                'site.dashboard': [
                    {name: 'Dashboard', id: 'r42-Dashboard-overview', siteState: 'site.dashboard', linkState: 'site.dashboard', keycode: 'd'}
                ],
                'site.tagmanagement': [
                    {name: 'Stats', id: 'r42-tagmanagement-stats', siteState: 'site.tagmanagement.stats', linkState: 'site.tagmanagement.stats', keycode: 'd'},
                    {name: 'Pages', id: 'r42-tagmanagement-tags', siteState: 'site.tagmanagement.tags', linkState: 'site.tagmanagement.tags.list({"location":"' + TagmanagementService.GLOBAL_PATH + '"})', keycode: 't'},
                    {name: 'Experiments', id: 'r42-tagmanagement-experiments', siteState: 'site.tagmanagement.experiments', linkState: 'site.tagmanagement.experiments.dashboard', keycode: 'e'},
                    {name: 'Privacy', id: 'r42-tagmanagement-cookiesettings', siteState: 'site.tagmanagement.cookiePermission', linkState: 'site.tagmanagement.cookiePermission.list', keycode: 'c'},
                    {name: 'Publishing', id: 'r42-tagmanagement-publishing', siteState: 'site.tagmanagement.publishing', linkState: 'site.tagmanagement.publishing', keycode: 'p'},
                    {name: 'Error Reporting', id: 'r42-tagmanagement-errorreporting', siteState: 'site.tagmanagement.errorReporting', linkState: 'site.tagmanagement.errorReporting.view', keycode: 'r', requiredRoles: ['TAG_EDIT', 'isSupportEngineer']},
                    {name: 'Counters', id: 'r42-tagmanagement-counters', siteState: 'site.tagmanagement.tagCounters', linkState: 'site.tagmanagement.tagCounters.list', keycode: 'x'},
                    {name: 'Beacons', id: 'r42-tagmanagement-beacons', siteState: 'site.tagmanagement.beacons', requiredRoles: ['TAG_VIEW'], linkState: 'site.tagmanagement.beacons', keycode: 'b'},
                    {name: 'Url Builder', id: 'r42-tagmanagement-urlbuilder', siteState: 'site.tagmanagement.urlbuilder', linkState: 'site.tagmanagement.urlbuilder.campaign.dashboard', keycode: 'u', requiredRoles: ['URL_BUILDER'], requiredModules: ['URL_BUILDER']},
                    {name: 'Indexed Paths', id: 'r42-tagmanagement-newpath', siteState: 'site.tagmanagement.newPaths', linkState: 'site.tagmanagement.newPaths.list({"location":"' + TagmanagementService.GLOBAL_PATH + '"})', keycode: 'i'}
                ],
                'site.profiles': [
                    {name: 'Data Partners', id: 'r42-profiles-dataCollectors', siteState: 'site.profiles.thirdPartyDataCollectors', linkState: 'site.profiles.thirdPartyDataCollectors.dashboard', keycode: 't', requiredRoles: ['PROFILES_VIEW', 'isSupportEngineer'], requiredModules: ['DATA_COLLECTORS']},
                    {name: 'Connectors', id: 'r42-profiles-connectors', siteState: 'site.profiles.partners', linkState: 'site.profiles.partners.list', keycode: 'p', requiredRoles: ['PROFILES_VIEW', 'DATA_ACCESS', 'isSupportEngineer']},
                    {name: 'Variables', id: 'r42-profiles-variables', siteState: 'site.profiles.variables', linkState: 'site.profiles.variables.view', keycode: 'v'},
                    {name: 'Engagements', id: 'r42-profiles-engagements', siteState: 'site.profiles.engagements', linkState: 'site.profiles.engagements.dashboard', keycode: 'e'},
                    {name: 'Customer Facts', id: 'r42-profiles-externalfacts', siteState: 'site.profiles.externalFacts', linkState: 'site.profiles.externalFacts.dashboard', keycode: 'f'},
                    {name: 'Exports', id: 'r42-profiles-export', siteState: 'site.profiles.export', linkState: 'site.profiles.export', keycode: 'e', requiredRoles: ['DATA_ACCESS','isSupportEngineer']},
                    {name: 'Profiles', id: 'r42-profiles-customerData', siteState: 'site.profiles.customerData', linkState: 'site.profiles.customerData', keycode: 'e', requiredRoles: ['DATA_ACCESS']},
                    {name: 'Filters', id: 'r42-profiles-filters', siteState: 'site.profiles.filters', linkState: 'site.profiles.filters.dashboard', keycode: 'e', requiredRoles: ['DATA_ACCESS', 'isSupportEngineer']},
                    {name: 'Transformations', id: 'r42-profiles-transformations', siteState: 'site.profiles.transformations', linkState: 'site.profiles.transformations.dashboard', keycode: 'e', requiredRoles: ['DATA_ACCESS', 'isSupportEngineer']}  
                ],
                'site.audiences': [
                    {name: 'Audiences', id: 'r42-audiences-selections', siteState: 'site.audiences', linkState: 'site.audiences.segments.dashboard', keycode: 's', requiredRoles: ['AUDIENCES_VIEW']},
                ],
                'site.ai': [
                    {name: 'Snapshots', id: 'r42-ai-snapshots', siteState: 'site.ai.snapshots', linkState: 'site.ai.snapshots.list', keycode: 's', requiredRoles: ['JOURNEY_AI_ACCESS']},
                    {name: 'Models', id: 'r42-ai-models', siteState: 'site.ai.models', linkState: 'site.ai.models.list', keycode: 'm', requiredRoles: ['JOURNEY_AI_ACCESS']},
                ],
                'site.content': [
                    {name: 'External', id: 'r42-content-external', siteState: 'site.content.campaigns', linkState: 'site.content.campaigns.dashboard', keycode: 'e'},
                    // TODO: UI-2095 Replace when migration of external is completed
                    {name: 'External New', id: 'r42-content-external2', siteState: 'site.content.campaigns2', linkState: 'site.content.campaigns2.dashboard', keycode: 'e', isDev: true},
                    {name: 'Media Libraries', id: 'r42-content-medialibraries', siteState: 'site.content.medialibraries', linkState: 'site.content.medialibraries.dashboard', keycode: 'm'},
                    {name: 'Data Feeds', id: 'r42-content-datafeeds', siteState: 'site.content.datafeeds', linkState: 'site.content.datafeeds.dashboard', keycode: 'd'}
                ],
                'site.customer': [
                    {name: 'Journey', id: 'r42-customer-journeys', siteState: 'site.customer.journeys.dashboard', linkState: 'site.customer.journeys.dashboard', keycode: 'j', requiredRoles: ['CUSTOMER_JOURNEY_VIEW', 'CUSTOMER_JOURNEY_EDIT', 'isSupportEngineer', 'isContextAdmin'], requiredModules: ['CUSTOMER_JOURNEY']}
                ],
                'site.workflows': [
                    {name: 'Workflow', id: 'r42-workflows-dashboard', siteState: 'site.workflows.dashboard', linkState: 'site.workflows.dashboard', keycode: 'w', requiredRoles: ['WORKFLOW_VIEW', 'WORKFLOW_AUDIENCE_EDIT', 'WORKFLOW_JOURNEY_EDIT', 'isSupportEngineer', 'isContextAdmin'], requiredModules: ['CUSTOMER_JOURNEY']}
                ],
                'site.test': [
                    {name: 'Validation', id: 'r42-test-validation', siteState: 'site.test.validation', linkState: 'site.test.validation.dashboard', requiredRoles: ['isSupportEngineer']}
                ],
                'site.admin': [
                    {name: 'Users', id: 'r42-admin-users', siteState: 'site.admin.users', linkState: 'site.admin.users', keycode: 'u'},
                    {name: 'Site Security', id: 'r42-admin-siteSecurity', siteState: 'site.admin.siteSecurity', linkState: 'site.admin.siteSecurity', keycode: 's'},
                    {name: 'Environments', id: 'r42-admin-environments', siteState: 'site.admin.environments', linkState: 'site.admin.environments', keycode: 'e'},
                    {name: 'Url Builder', id: 'r42-admin-urlbuilder', siteState: 'site.admin.urlBuilder', linkState: 'site.admin.urlBuilder.view', keycode: 'b', requiredModules: ['URL_BUILDER']},
                    {
                        name: 'Connectors',
                        id: 'r42-admin-connectors',
                        siteState: 'site.admin.connectors',
                        linkState: 'site.admin.connectors',
                        requiredRoles: ['isSupportEngineer'],
                        keycode: 'p'
                    },
                    {name: 'Profile Identities', id: 'r42-admin-profileIdentities', siteState: 'site.admin.identities', linkState: 'site.admin.identities', keycode: 'i', requiredRoles: ['isSupportEngineer']},
                    {name: 'Imports', id: 'r42-admin-imports', siteState: 'site.admin.imports', linkState: 'site.admin.imports', keycode: 'i', requiredRoles: ['isSupportEngineer']}
                ],
                'site.support': [
                    {name: 'Tag Templates', id: 'r42-support-tagtemplates', siteState: 'site.support.tagtemplates', linkState: 'site.support.tagtemplates', keycode: 't', requiredRoles: ['isSupportEngineer']},
                    {name: 'Cookie Permission Templates', id: 'r42-support-cookiepermissiontemplates', siteState: 'site.support.cookiepermissionforms', linkState: 'site.support.cookiepermissionforms.list', keycode: 'c', requiredRoles: ['isSupportEngineer'] },
                    {name: 'Messages', id: 'r42-support-messages', siteState: 'site.support.messages', linkState: 'site.support.messages.list', keycode: 'm', requiredRoles: ['isSupportEngineer']},
                ],
                'site.context': [
                    {name: 'Clients', id: 'r42-context-clients', siteState: 'site.context.clients', linkState: 'site.context.clients.list', keycode: 'c', requiredRoles: ['isContextAdmin', 'isSupportEngineer']},
                    {name: 'Sites', id: 'r42-context-sites', siteState: 'site.context.sites', linkState: 'site.context.sites.list', keycode: 's', requiredRoles: ['isContextAdmin', 'isSupportEngineer']},
                    {name: 'Users', id: 'r42-context-users', siteState: 'site.context.users', linkState: 'site.context.users.list', keycode: 'u', requiredRoles: ['isContextAdmin', 'isSupportEngineer']},
                    {name: 'Basescripts', id: 'r42-context-basescripts', siteState: 'site.context.basescripts', linkState: 'site.context.basescripts.list', keycode: 'b', requiredRoles: ['isContextAdmin', 'isSupportEngineer']},
                ]
            };

            /**
    /**
     * Filter the top navigation items based on permissions and roles required.
     *
     * @param securityContext
     * @returns {Object: {left, right}
     * @private
     */
            function getAvailableNavItems(securityContext) {

                function hasRolesAndModules(item, securityContext) {
                    return hasRequiredRoles(item.requiredRoles, securityContext) &&
                    hasRequiredModules(item.requiredModules, securityContext);
                }

                function filterNavItems(navIt, secContext, availableSubNavIt){
                    var filteredNavItems = _.filter(navIt, function (navItem) {
                        var subItems = availableSubNavIt[navItem.siteState];

                        // if the user doesn't have permission to view it, filter out the nav item
                        if (!hasRolesAndModules(navItem, secContext)) {
                            return false;
                        }
                        if (subItems?.length > 0) {
                            //if we're allowed to see it, attach a link state from the first child that has the required roles
                            // see https://synovite.atlassian.net/browse/RP-1879
                            var hasAvailableItems = (_.where(subItems, { 'hasRequiredRoles' : true}).length > 0);
                            if (!hasAvailableItems) {
                                // No subitems with correct permissions; hide the module for now
                                return false;
                            }
                            var firstItem = _.find(subItems, { 'hasRequiredRoles' : true});
                            navItem.linkState = firstItem.linkState;
                        }
                        return true;
                    });
                    return filteredNavItems;
                }

                /**
         * Filter the sub-navigation items based on permissions and modules required
         *
         * @param securityContext -the security context
         * @returns {*}
         * @private
         */
                function getAvailableSubNavItems(securityContext) {
                    var isBeta = localStorage.getItem('useBeta') === 'true';
                    var isDev = localStorage.getItem('usePre') === 'true';
                    var filterSubItems = function (subItems) {

                        // Filter out items belonging to modules that are not available
                        var filteredSubItems =  _.filter(subItems, function (subItem) {
                            return hasRequiredModules(subItem.requiredModules, securityContext);
                        });
                        // Augment the subitems with a flag indicating whether the requirements for viewing the item have been met
                        _.forEach(filteredSubItems, function(item) {
                            item.hasRequiredRoles = hasRequiredRoles(item.requiredRoles, securityContext);
                        });
                        return filteredSubItems;
                    };

                    var filteredSubNavItems = {};

                    for (var navItemState in ALL_SUBNAV_ITEMS) {
                        if (ALL_SUBNAV_ITEMS.hasOwnProperty(navItemState)) {
                            var subNavItemsToFilter = ALL_SUBNAV_ITEMS[navItemState];
                            if (!isBeta) {
                                subNavItemsToFilter = subNavItemsToFilter.filter(item => !item.isBeta);
                            }
                            if (!isDev) {
                                subNavItemsToFilter = subNavItemsToFilter.filter(item => !item.isDev);
                            }
                            filteredSubNavItems[navItemState] = filterSubItems(subNavItemsToFilter, securityContext);
                        }
                    }
                    return filteredSubNavItems;
                }

                var availableSubNavItems = getAvailableSubNavItems(securityContext);

                return {
                    right: filterNavItems(ALL_RIGHTNAV_ITEMS, securityContext, availableSubNavItems),
                    left: filterNavItems(ALL_LEFTNAV_ITEMS, securityContext, availableSubNavItems),
                    sub: availableSubNavItems
                };
            }

            /**
	 * Retrieves an array of all available sites sorted by name
	 * @param {SecurityContext} securityContext
	 * @returns {Array} an array of the available sites
	 */
            function getAvailableSites(securityContext){
                var availableSites = _.toArray(_.where(securityContext.getSites(), {'deleted': false, 'interactiveDemo': false}));
                return _.sortBy(availableSites, site => site.name.toLowerCase(), 'name');
            }

            function isAllowedToGoToNavState (state, securityContext){

                var flattenNavItems = function flattenNavItems (leftNav, rightNav, subNav){
                    var siteStates = {};
                    _.forEach(leftNav.concat(rightNav), function (item) {
                        siteStates[item.siteState] = {
                            name: item.name
                        };
                    });
                    _.forEach(subNav, function (sub, parent) {
                        if ( !siteStates[parent] ){
                            // parent not allowed, so its children should not be present
                            return;
                        }
                        _.forEach(sub, function (item) {
                            siteStates[item.siteState] = {
                                name: item.name
                            };
                        });
                    });
                    return siteStates;
                };

                function getClosestParentState (forState, flattenedNavStates){
                    if ( !forState || !forState.name ){
                        return undefined;
                    }
                    var stateName = forState.name;
                    //in the rare cases when the state we want to go to is one of the nav states
                    var supposedState = flattenedNavStates[stateName];
                    if ( !_.isUndefined(supposedState) ){
                        return stateName;
                    }
                    var splits = stateName.split('.');
                    var closestMatchedState;
                    var currentSplit = '';
                    _.forEach(splits, function (split) {
                        if (currentSplit === '') {
                            currentSplit = split;
                        } else {
                            currentSplit += ('.' + split);
                        }
                        if ( flattenedNavStates[currentSplit] ){
                            closestMatchedState = currentSplit;
                        }
                    });
                    return closestMatchedState;
                }

                var availableNavItems = getAvailableNavItems(securityContext);

                var flattened = flattenNavItems(ALL_LEFTNAV_ITEMS, ALL_RIGHTNAV_ITEMS, ALL_SUBNAV_ITEMS);
                var flattenedAvailable = flattenNavItems(availableNavItems.left, availableNavItems.right, availableNavItems.sub);
                var closestMatchedState = getClosestParentState(state, flattened);

                //if the state we go to is not a child state of any of the nav states, we are allowed to go to it, also
                //if the available states contains the closestMattchedState
                return !closestMatchedState || !!flattenedAvailable[closestMatchedState];
            }

            /**
         * Test that the user has the required roles
         * Required roles can be in the following formats:
         *      'ADSERVING_VIEW'
         *      true if user has ADSERVING_VIEW
         *
         *      ['ADSERVING_VIEW']
         *      true if user has ADSERVING_VIEW
         *
         *      ['ADSERVING_VIEW', 'DATA_MANAGEMENT']
         *      true if user has (ADSERVING_VIEW || DATA_MANAGEMENT)
         *
         *      [ ['TAG_VIEW', 'ADSERVING_VIEW'], 'DATA_MANAGEMENT' ]
         *      true if user has (TAG_VIEW && ADSERVING_VIEW ) || DATA_MANAGEMENT
         *
         * @param requiredRoles
         * @param securityContext - the function to call to check the existence of the permission
         * @returns {*}
         */
            function hasRequiredRoles(requiredRoles, securityContext) {

                if (!requiredRoles) {
                    //no required roles defined, assume there is no restriction based on role
                    return true;
                }

                /**
         * If at least one of the required roles or group of roles is satisfied,
         * then the user has the correct roles to view
         */
                if (_.isArray(requiredRoles)) {
                    for (var i = 0; i < requiredRoles.length; i++) {
                        // second level should have all roles
                        if (hasAllRoles(requiredRoles[i], securityContext)) {
                            return true;
                        }
                    }

                    return false;

                } else {
                    return hasPermission(requiredRoles, securityContext);
                }
            }


            function hasAllRoles(roles, securityContext) {
                if(!roles) {
                    return true;
                }
                if(typeof roles === 'string') {
                    return hasPermission(roles, securityContext);
                } else if(_.isArray(roles)) {
                    for (var i = 0; i < roles.length; i++) {
                        if (!hasPermission(roles[i], securityContext)) {
                            return false;
                        }
                    }
                }
                return true;
            }

            function hasPermission(requiredRole, securityContext) {
                if(requiredRole === 'isSupportEngineer') {
                    return securityContext.isSupportEngineer();
                } else if(requiredRole === 'isContextAdmin') {
                    return securityContext.isContextAdmin();
                    /** if only one string role defined, check the permission */
                } else if (typeof requiredRole === 'string') {
                    return securityContext.hasPermission(requiredRole);
                } else {
                    return false;
                }
            }

            /**
             * Returns true if site has ALL required modules
             * @param requiredModules
             * @param securityContext
             * @returns {*}
             */
            function hasRequiredModules(requiredModules, securityContext) {
                if (!requiredModules) {
                    //no required modules defined, assume there is no restriction based on site module
                    return true;
                } else if (typeof requiredModules === 'string') {
                    // if only one site module defined in string form, check the existence of the module
                    return securityContext.hasModule(requiredModules);
                } else if (_.isArray(requiredModules)) {
                    //check that site has all required modules defined in array
                    for (var i = 0; i < requiredModules.length; i++) {
                        if (!securityContext.hasModule(requiredModules[i])) {
                            return false;
                        }
                    }
                }
                return true;
            }

            /**
     * Returns an object with all role requirements. For instance "EDIT" roles require "VIEW" roles.
     * E.g "TAG_EDIT" requires "TAG_VIEW", "TAG_PUBLISH" requires both "TAG_EDIT" and "TAG_VIEW"
     * @type type
     */
            function getRoleRequirements() {
                return {
                    TAG_EDIT: {
                        requires: ['TAG_VIEW']
                    },
                    TAG_PUBLISH: {
                        requires: ['TAG_EDIT', 'TAG_VIEW']
                    },
                    ADSERVING_VIEW: {
                        requires: ['PROFILES_VIEW']
                    },
                    ADSERVING_EDIT: {
                        requires: ['ADSERVING_VIEW', 'PROFILES_VIEW']
                    },
                    AUDIENCES_VIEW: {
                        requires: ['PROFILES_VIEW, ADSERVING_VIEW, DATA_ACCESS']
                    },
                    PROFILES_EDIT: {
                        requires: ['PROFILES_VIEW']
                    },
                    CUSTOMER_JOURNEY_VIEW: {
                        requires: ['PROFILES_VIEW']
                    },
                    CUSTOMER_JOURNEY_EDIT: {
                        requires: ['CUSTOMER_JOURNEY_VIEW', 'PROFILES_VIEW', 'PROFILES_EDIT']
                    },
                    API_PROFILES_EDIT: {
                        requires: ['API_PROFILES_VIEW'],
                        apiPermission: true
                    },
                    API_DATAFEED_EDIT: {
                        requires: ['API_DATAFEED_VIEW'],
                        apiPermission: true
                    },
                    API_CUSTOMER_JOURNEY_EDIT: {
                        requires: ['API_CUSTOMER_JOURNEY_VIEW'],
                        apiPermission: true
                    }
                };
            }

            /**
     * Toggles the selected by the user role and updates the chosen roles object. If the selected by the user role has requirements, it automatically
     * selects all required roles. For example: if the user chooses "TAG_ADMIN", it would add to the "chosenRoles" also "TAG_VIEW" and "TAG_EDIT"
     * @param {type} selectedRoleName
     * @param {type} selectedRoleNameValue
     * @param {type} chosenRoles
     * @returns {Boolean}
     */
            function handleRelatedPermissions(selectedRoleName, selectedRoleNameValue, chosenRoles) {
                // Get the roles and their requirements
                var roleRequirements = getRoleRequirements();
                // Find the requirement for the selected role
                var requirement = roleRequirements[selectedRoleName];
                if (selectedRoleNameValue && !_.isUndefined(requirement)) {
                    _.forEach(requirement.requires, function (req) {
                        if (requirement.apiPermission) {
                            chosenRoles.apiPermissions[req] = true;
                        } else {
                            chosenRoles[req] = true;
                        }
                    });
                }
                if (!selectedRoleNameValue) {
                    var roleRequiredFor = [];
                    _.forEach(roleRequirements, function (req, requiredRoleName) {
                        _.forEach(req.requires, function (rol) {
                            if (rol === selectedRoleName) {
                                roleRequiredFor.push(requiredRoleName);
                            }
                        });
                    });
                    _.forEach(roleRequiredFor, function (roleToDisable) {
                        var requirement = roleRequirements[roleToDisable];
                        if (requirement && requirement.apiPermission) {
                            chosenRoles.apiPermissions[roleToDisable] = false;
                        } else {
                            chosenRoles[roleToDisable] = false;
                        }
                    });
                }
                return chosenRoles;
            }

            /**
     * Checks whether object properties/array or properties contains the API_USER permission
     * @param {type} permissions
     * @returns {unresolved}
     */
            function hasApiUserPermission(permissions) {
                if (_.isObject(permissions)) {
                    return _.has(permissions, 'API_USER');
                } else if (_.isArray(permissions)) {
                    return _.contains(permissions, 'API_USER');
                }
            }

            /**
     * Checks the users for API permissions. More specifically looks for the API_USER permission.
     * Augments the user and add a "hasApiPermisisons" flag to the object
     * @param {type} user
     * @returns {unresolved}
     */
            function hasApiPermissions(user) {
                if (_.isArray(user)) {
                    _.forEach(user, function (usr) {
                        usr.hasApiPermissions = (hasApiUserPermission(usr.permissions) || false);
                    });
                } else {
                    user.hasApiPermissions = (hasApiUserPermission(user.permissions) || false);
                }
                return user;
            }


            function editUsersPermissions(webuiPermissionGroups, allApiPermissions, user, site, users) {
                var promisedUsersPermissions = $q.defer();

                var permissionGroups = {
                    webuiPermissionGroups: webuiPermissionGroups,
                    allApiPermissions: allApiPermissions
                };

                //Open modal to Edit permissions for the user
                var d = openEditAccessModal(user, site, permissionGroups, user.permissions);

                //When the user finish to edit this permissions and close the modal update the permissions for the user
                d.result.then(function(newPermissions) {
                    if(newPermissions) {
                        /* save modified permissions */
                        saveUserPermissionsForSite(user.userId, site.siteId, newPermissions).then(function() {
                            //When save the new permissions get the new siteContext and update the users with the hasApiPermissions new Data
                            SecurityService.getSiteContext(site.siteId).then(function(siteContext){
                                users = hasApiPermissions(_.values(siteContext.users));
                                promisedUsersPermissions.resolve(users);
                            });
                        });
                    }
                });
                return promisedUsersPermissions.promise;
            }

            /**
         * Takes a map of permissionGroup to permissions array and the available modules and returns the permissions grouped by siteModule.
         * [{
         *   "key":{"name":"TAG_MANAGEMENT","label":"Tag Management"},
         *   "value":[{"name":"TAG_VIEW","label":"View Tags"},{"name":"TAG_EDIT","label":"Edit Tags"},{"name":"TAG_PUBLISH","label":"Publish Tags"}]
         *  }, {
         *   "key":{"name":"DATA_MANAGEMENT","label":"Data"},
         *   "value":[]
         *  }, {
         *   "key":{"name":"AD_SERVING","label":"Content Serving"},
         *   "value":[{"name":"ADSERVING_VIEW","label":"View Ads"},{"name":"ADSERVING_EDIT","label":"Edit and publish ContentSegmentsService.js"}]
         *  }, {
         *   "key":{"name":"GENERAL","label":"General"},
         *   "value":[{"name":"SITEADMIN","label":"Account Admin"}]
         *  }]
         *
         * @param sitePermissionsMap - map of permissionGroup to permissions array, output of SecurityService.getSitePermissionsMap(), like the example.
         * @param siteModules - result of securityContext.getAvailableModules()
         */
            function parsePermissionGroups(sitePermissionsMap, siteModules){
            //example: ["TAG_MANAGEMENT", "AD_SERVING", "DATA_MANAGEMENT"]
                var _siteAvailableModules = _.pluck(siteModules, 'name');
                _siteAvailableModules.push('GENERAL');

                //example:
                var _sitePermissionsMap = Restangular.stripRestangular(sitePermissionsMap);

                //return
                var _permissionGroups = [];
                // Filter _sitePermissionsMap based on _siteAvailableModules for current site.
                _permissionGroups = _.filter(_sitePermissionsMap, function(sitePermission) {
                    return _.contains(_siteAvailableModules, sitePermission.module.name);
                });
                return _permissionGroups;
            }
            /**
         * Transform the permissionGroups map into an array
         */
            function collectPermissions(permissionGroups) {
                var permissions = [];
                _.forEach(permissionGroups, function(groupKey, idx) {
                    permissions = permissions.concat(permissionGroups[idx].permissions);
                });
                return permissions;
            }

            /**
         * Checks whether it should display a specific permission. For example api permissions are not displayed until they are toggled
         * @param {type} showApiPermissionsOnly
         * @param {type} permission
         * @returns {Boolean}
         */
            function shouldDisplayPermission(showApiPermissionsOnly, permission) {
            // When displaying only the api permissions, return true if the selected permisson is api.
            // When displaying only the webui permissions, return true if the permission is not an api permission

                if (showApiPermissionsOnly) {
                    return permission.apiPermission;
                } else {
                    return !permission.apiPermission;
                }
            }

            /**
         *
         * @param {type} permissionGroups
         * @returns {Object} { webuiPermissionGroups: [], apiPermissionGroups: [], allApiPermissions: [] }
         */
            function collectWebuiAndApiPermissions(permissionGroups) {
                var allPermissionGroups = {
                    webuiPermissionGroups:[],
                    apiPermissionGroups: [],
                    allApiPermissions: []
                };

                _.forEach(permissionGroups, function (group) {
                    var webuiEntry = {
                        module: group.module,
                        permissions: []
                    };
                    var apiEntry = {
                        module: group.module,
                        permissions: []
                    };
                    _.forEach(group.permissions, function (val) {
                        if (!val.apiPermission) {
                            webuiEntry.permissions.push(val.permission);
                        } else {
                            allPermissionGroups.allApiPermissions.push(val.permission);
                            apiEntry.permissions.push(val.permission);
                        }
                    });
                    allPermissionGroups.webuiPermissionGroups.push(webuiEntry);
                    allPermissionGroups.apiPermissionGroups.push(apiEntry);
                });
                return allPermissionGroups;
            }

            /**
         * Open the edit permissions for user modal window
         */
            function openEditAccessModal(user, site, permissionGroups, givenPermissions) {
                return $uibModal.open({
                    backdrop : true,
                    keyboard : true,
                    backdropClick : true,
                    templateUrl : 'ui/shared/editUserPermissionsForSite/editPermissionsModal.tpl.html',
                    controller : 'EditUserPermissionsForSiteController',
                    resolve : {
                        dialogsModel : function() {
                            return {
                            /* some properties to send to modal*/
                                permissionGroups : permissionGroups,
                                givenPermissions: givenPermissions,
                                user: user,
                                site: site
                            };
                        }
                    }
                });
            }
            /**
         * Open the unlink user from site modal window
         */
            function openUnlinkUserModal(user, site) {
                return $uibModal.open({
                    backdrop : true,
                    keyboard : true,
                    backdropClick : true,
                    templateUrl : 'ui/shared/removeUserAccessForSite/removeAccessModal.tpl.html',
                    controller : 'RemoveUserAccessForSiteController',
                    resolve : {
                        dialogsModel : function() {
                            return {
                                user: user,
                                site: site
                            };
                        }
                    }
                });
            }

            /**
         * Save given user permissions for the siteId
         */
            function saveUserPermissionsForSite(userId, siteId, givenPermissions) {
                return Restangular.all(BASE_PATH + 'updateSitePermissions')
                    .post({
                        userId: userId,
                        siteId : siteId,
                        permissions : givenPermissions
                    });
            }

            /**
         * Removes access for the userId to the given site
         */
            function removeAccess(userId, siteId) {
                return Restangular.one(BASE_PATH + 'users/' + userId + '/sites/' + siteId).remove();
            }




            return {
                BASE_PATH: BASE_PATH,
                removeAccess: removeAccess,
                saveUserPermissionsForSite: saveUserPermissionsForSite,
                openUnlinkUserModal: openUnlinkUserModal,
                openEditAccessModal: openEditAccessModal,
                collectWebuiAndApiPermissions: collectWebuiAndApiPermissions,
                shouldDisplayPermission: shouldDisplayPermission,
                collectPermissions: collectPermissions,
                parsePermissionGroups: parsePermissionGroups,
                hasRequiredRoles: hasRequiredRoles,
                hasRequiredModules: hasRequiredModules,
                getAvailableSites: getAvailableSites,
                getAvailableNavItems: getAvailableNavItems,
                isAllowedToGoToNavState: isAllowedToGoToNavState,
                getRoleRequirements: getRoleRequirements,
                handleRelatedPermissions: handleRelatedPermissions,
                hasApiUserPermission: hasApiUserPermission,
                hasApiPermissions: hasApiPermissions,
                editUsersPermissions: editUsersPermissions
            };
        }]);

