import { FILTER_CLAZZ } from './components/RuleCardEdit/Filter/constants';

export const RULE_FETCH_START = 'app/rules/RULE_FETCH_START';
export const RULE_FETCH_FAIL = 'app/rules/RULE_FETCH_FAIL';
export const RULE_FETCH_SUCCESS = 'app/rules/RULE_FETCH_SUCCESS';
export const RULE_VARIABLES_FETCH_START = 'app/rules/RULE_VARIABLES_FETCH_START';
export const RULE_VARIABLES_FETCH_FAIL = 'app/rules/RULE_VARIABLES_FETCH_FAIL';
export const RULE_VARIABLES_FETCH_SUCCESS = 'app/rules/RULE_VARIABLES_FETCH_SUCCESS';
export const RULE_VARIABLES_REFETCH_START = 'app/rules/RULE_VARIABLES_REFETCH_START';
export const RULE_VARIABLES_REFETCH_FAIL = 'app/rules/RULE_VARIABLES_REFETCH_FAIL';
export const RULE_VARIABLES_REFETCH_SUCCESS = 'app/rules/RULE_VARIABLES_REFETCH_SUCCESS';

export const RulesTypes = {
  Engagement: 'EngagementRule',
  Conversion: 'ConversionRule',
  ExternalFact: 'CustomRule',
  AiFact: 'AIFactRule',
  Experiment: 'ExperimentRule',
  Visit: 'VisitRule',
  ReusableAudience: 'ReusableSelectionRule',
  FixedTime: 'FixedTimeRule',
  JourneyOverlap: 'JourneyOverlapRule',
  BannerView: 'BannerViewRule',
  DeleteMergedData: 'DeleteMergedDataRule',
  DeleteProfile: 'DeleteProfileRule',
  DeleteProfileMapping: 'DeleteProfileMappingRule',
  PageView: 'PageViewRule',
  Sync: 'SyncRule',
  ThirdPartySync: 'ThirdPartySyncRule',
  UserPreferences: 'UserPreferencesRule',
} as const;

export const RulesTypeNames = {
  ENGAGEMENT: 'EngagementRule',
  CONVERSION: 'ConversionRule',
  EXTERNAL: 'CustomRule',
  AI: 'AIFactRule',
  EXPERIMENT: 'ExperimentRule',
  VISIT: 'VisitRule',
  REUSABLE_SELECTION: 'ReusableSelectionRule',
  FIXED_WAIT_TIME: 'FixedTimeRule',
  JOURNEY_OVERLAP: 'JourneyOverlapRule',
  BANNER_VIEW: 'BannerViewRule',
  DELETE_MERGED_DATA: 'DeleteMergedDataRule',
  DELETE_PROFILE: 'DeleteProfileRule',
  DELETE_PROFILE_MAPPING: 'DeleteProfileMappingRule',
  PAGE_VIEW: 'PageViewRule',
  SYNC: 'SyncRule',
  THIRD_PARTY_SYNC: 'ThirdPartySyncRule',
  USER_PREFERENCES: 'UserPreferencesRule',
} as const;

export type FilterValueClassName = keyof typeof FILTER_CLAZZ;
