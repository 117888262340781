// Define app-templates module
angular.module('app-templates', []);
angular.module('component-templates', []);

// Collect all templates
function requireAll(context) {
    context.keys().forEach(context);
}

requireAll(require.context('./app', true, /\.tpl\.html$/));
