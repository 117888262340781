import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAPI } from '~/common/ApiHooks';
import withSpinner from '~/components/src/Spinner/withSpinner';

import { transformCriteria } from '~/profiles/audiences/selectors';
import { addNamesFromRuleDefinitionsToPath } from '~/profiles/audiences/audienceUtils.js';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';

import * as RulesService from '~/common/modules/rules/dataService';
import * as TriggersService from '~/customer/triggers/dataService';
import { useQuery } from '~/customer/components/utils';
import { RulesTypes } from '~/common/modules/rules/types';
import { withRouter } from '~/common/withRouter';

import { removeItemsNotAvailableInOrchestration } from './utils';
import EditTrigger from './presenter.js';

const fetchRuleTypes = () => RulesService.getRulesDefinitions();

const fetchAllVariables = () => RulesService.getAllRuleVariables();

const fetchTriggerIfEdit = triggerId => {
  const isEdit = !!triggerId;

  if (isEdit) {
    return TriggersService.fetchTrigger(triggerId);
  }

  return Promise.resolve(null);
};

const fetchRuleTypesAndTrigger = (triggerId, excludeRules = {}) =>
  Promise.all([fetchRuleTypes(), fetchTriggerIfEdit(triggerId)]).then(([ruleDefinitions, trigger]) => {
    let transformedTrigger = null;
    if (trigger) {
      transformedTrigger = addNamesFromRuleDefinitionsToPath(ruleDefinitions, 'criteria')(trigger);
      transformedTrigger = {
        ...transformedTrigger,
        criteria: transformCriteria(transformedTrigger.criteria),
      };
    }
    return [
      removeItemsNotAvailableInOrchestration(getRulesTreeFromTypes(ruleDefinitions, excludeRules)),
      transformedTrigger,
    ];
  });

const useAllVariables = () => {
  const [allVariables, setAllVariables] = useState(null);

  const { data, isLoading, error } = useAPI(() => fetchAllVariables());

  useEffect(() => {
    if (data !== null) {
      setAllVariables(data);
    }
  }, [data]);

  const refetchAllVariables = async () => {
    const refetchedAllVariables = await fetchAllVariables();
    setAllVariables(refetchedAllVariables);
  };

  return {
    allVariables,
    isLoading: isLoading || allVariables === null,
    error,
    refetchAllVariables,
  };
};

const withDataFetching = WrappedComponent => props => {
  const { triggerId } = useParams();
  const query = useQuery();
  const parentStepId = query.get('parentStepId');
  const isJourneyCondition = props.type === 'goal' || props.type === 'exitCondition';

  const isStartTrigger =
    (!triggerId && !parentStepId && !isJourneyCondition) || props.journeyContents?.nodes?.nodeId === triggerId;

  let excludeRules = {};

  if (isJourneyCondition || isStartTrigger) {
    excludeRules = { ...excludeRules, [RulesTypes.FixedTime]: true };
  }

  if (isJourneyCondition || !isStartTrigger) {
    excludeRules = { ...excludeRules, [RulesTypes.JourneyOverlap]: true };
  }

  const ruleTypesAndTriggerRequest = useAPI(() => fetchRuleTypesAndTrigger(triggerId, excludeRules));
  const allVariablesRequest = useAllVariables();

  const ruleTypesTree = ruleTypesAndTriggerRequest.data?.[0];
  const trigger = ruleTypesAndTriggerRequest.data?.[1];
  const { allVariables, refetchAllVariables } = allVariablesRequest;

  return (
    <WrappedComponent
      isLoading={ruleTypesAndTriggerRequest.isLoading || allVariablesRequest.isLoading}
      error={ruleTypesAndTriggerRequest.error || allVariablesRequest.error}
      ruleTypesTree={ruleTypesTree}
      allVariables={allVariables}
      trigger={trigger}
      refetchAllVariables={refetchAllVariables}
      isJourneyCondition={isJourneyCondition}
      {...props}
    />
  );
};

const enhance = compose(
  withRouter,
  translate('orchestration'),
  withDataFetching,
  withSpinner('blankOverlay--lightGray'),
);

export default enhance(EditTrigger);
