import React, { ReactNode } from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired, useAPI } from '~/common';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormCheckboxField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Spin from '~/components/src/Spin';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';
import { TAirshipProps } from './types';
import { fetchBaseUrls } from './dataService';

function AirshipForm({ t, values, partner, canUpdate, touch }: TAirshipProps): ReactNode {
  const partnerNumber = values.partnerNumber || partner.partnerNumber;
  const { data: baseUrls = [], isLoading: isBaseUrlsLoading } = useAPI(() => fetchBaseUrls(), []);

  if (isBaseUrlsLoading) {
    return <Spin />;
  }
  return (
    <FormSection name={constants.partnerTypes.AIRSHIP}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={partnerNumber}
      />
      <ContainerFormSection>
        <>
          <Field
            label={t('form.formFields.baseURL')}
            name="baseUrl"
            component={ReduxFormSelectField}
            options={baseUrls}
            validate={isRequired}
            touch={touch}
          />
          <FieldWithEditMode
            label={t('form.formFields.appKey')}
            name="appKey"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.token')}
            name="token"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          {!canUpdate && (
            <>
              <Field
                label={t('form.formFields.advancedSettings')}
                name="advancedSettings"
                component={ReduxFormCheckboxField}
                disabled={canUpdate}
              />
              {values.advancedSettings && (
                <Field
                  label={t('form.formFields.partnerNumber')}
                  name="partnerNumber"
                  component={ReduxFormInputField}
                  validate={isRequired}
                  type="text"
                  disabled={canUpdate}
                />
              )}
            </>
          )}
        </>
      </ContainerFormSection>
    </FormSection>
  );
}

export default AirshipForm;
