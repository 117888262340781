import React, { useState, createContext, useContext, useReducer } from 'react';

import Zoom from '~/customer/components/Zoom';
import Nodes from '~/customer/components/Nodes';
import Links from '~/customer/components/Links';
import { getExperimentNodes } from '~/customer/components/utils';
import { convertForDisplay } from './utils';
import { reducer, initialState } from '../JOReducer';

import './styles.scss';

const NodesContext = createContext();

export function useNodes() {
  return useContext(NodesContext);
}

const CanvasSvgWrap = ({ children }) => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {children}
  </svg>
);

const JourneyCanvas = ({
  journeyContents,
  journeyId,
  fetchJourneyData,
  canEdit,
  nodesErrors,
  startTriggerError,
  showExperimentSettingsModal,
  isSumOfVariantsValid,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [collapsedNodeIds, setCollapsedNodeIds] = useState([]);
  const { nodes, links } = convertForDisplay(journeyContents, journeyId, collapsedNodeIds);
  const { hasExperiments } = getExperimentNodes(journeyContents.nodes);
  const JOContextValue = { state, dispatch };

  const handleExpandAndCollapse = nodeId => {
    if (collapsedNodeIds.includes(nodeId)) {
      setCollapsedNodeIds(collapsedNodeIds.filter(id => id !== nodeId));
    } else {
      setCollapsedNodeIds([...collapsedNodeIds, nodeId]);
    }
  };

  return (
    <NodesContext.Provider value={{ handleExpandAndCollapse, ...JOContextValue }}>
      <div className="JourneyView-canvas">
        <CanvasSvgWrap>
          <Zoom nodes={nodes}>
            <Links links={links} nodesErrors={nodesErrors} />
            <Nodes
              journeyId={journeyId}
              showExperimentSettingsModal={showExperimentSettingsModal}
              nodes={nodes}
              fetchJourneyData={fetchJourneyData}
              canEdit={canEdit}
              nodesErrors={nodesErrors}
              startTriggerError={startTriggerError}
              isSumOfVariantsValid={isSumOfVariantsValid}
              hasExperiments={hasExperiments}
            />
          </Zoom>
        </CanvasSvgWrap>
      </div>
    </NodesContext.Provider>
  );
};

export default JourneyCanvas;
