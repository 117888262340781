import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter';
import * as types from './actionTypes';
import * as Api from './dataService';

/**
 * Fetch journeys
 */
export const fetchJourneys = page => dispatch => {
  dispatch({
    type: types.JOURNEYS_FETCH_START,
  });

  return Api.fetchJourneys(page)
    .then(journeys => {
      dispatch({
        type: types.JOURNEYS_FETCH_SUCCESS,
        payload: journeys,
      });
    })
    .catch(error => dispatch({ type: types.JOURNEYS_FETCH_FAIL, error }));
};

export const fetchJourneyLabels = () => dispatch => {
  dispatch({
    type: types.JOURNEY_LABELS_FETCH_START,
  });

  return Api.fetchJourneyLabels()
    .then(labels => {
      dispatch({
        type: types.JOURNEY_LABELS_FETCH_SUCCESS,
        payload: labels,
      });
    })
    .catch(error => dispatch({ type: types.JOURNEY_LABELS_FETCH_FAIL, error }));
};

export const updateJourneyLabels = (labels, journeyId) => dispatch => {
  dispatch({
    type: types.JOURNEY_LABELS_UPDATE_START,
    payload: { id: journeyId },
  });

  return Api.updateJourneyLabels(journeyId, labels)
    .then(labels => {
      dispatch(fetchJourneyLabels());
      dispatch({
        type: types.JOURNEY_LABELS_UPDATE_SUCCESS,
        payload: { id: journeyId, labels },
      });
    })
    .catch(error => dispatch({ type: types.JOURNEY_LABELS_UPDATE_FAIL, error, payload: { id: journeyId } }));
};

/**
 * Delete label
 * @param labelId
 */
export const deleteLabel = labelId => dispatch =>
  Api.deleteLabel(labelId).then(() => {
    showSuccess({ body: i18n.t('workflow:label.api.deleted') });
    dispatch(fetchJourneyLabels());
  });

/**
 * Delete journey
 * @param journeyId
 */
export const deleteJourney = journeyId => dispatch => {
  dispatch({
    type: types.JOURNEYS_DELETE_START,
  });

  return Api.deleteJourney(journeyId)
    .then(() => {
      showSuccess({ header: i18n.t('journey:deleteModal.successToast') });
      dispatch({
        type: types.JOURNEYS_DELETE_SUCCESS,
        payload: journeyId,
      });
    })
    .catch(() => {});
};

export const fetchDependantsInfo = journeyId => dispatch =>
  Api.getDependantJourneys(journeyId).then(dependantsInfo => {
    dispatch({
      type: types.JOURNEY_DEPENDANTS_INFO_FETCH_SUCCESS,
      payload: { id: journeyId, dependantsInfo },
    });
  });

export const showDependantsContainer = journeyId => dispatch => {
  dispatch(fetchDependantsInfo(journeyId));
  dispatch({
    type: types.SHOW_DEPENDANTS_CONTAINER,
    payload: {
      journeyId,
    },
  });
};

export const hideDependantsContainer = () => dispatch => {
  dispatch({
    type: types.HIDE_DEPENDANTS_CONTAINER,
  });
};
