import React, { ReactNode } from 'react';
import { Field, FormSection } from 'redux-form';
import { isRequired } from '~/common';
import { I18NextT } from '~/components/src/Common/types';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormCheckboxField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';
import { PartnerFormProps } from '../../types';

interface AirshipProps {
  t: I18NextT;
  values: {
    name: string;
    partnerNumber: number;
    token: string;
    appKey: string;
    advancedSettings: boolean;
  };
  partner: PartnerFormProps;
  canUpdate: boolean;
}

function AirshipForm({ t, values, partner, canUpdate }: AirshipProps): ReactNode {
  const partnerNumber = values.partnerNumber || partner.partnerNumber;
  return (
    <FormSection name={constants.partnerTypes.AIRSHIP}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={partnerNumber}
      />
      <ContainerFormSection>
        <>
          <FieldWithEditMode
            label={t('form.formFields.appKey')}
            name="appKey"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.token')}
            name="token"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          {!canUpdate && (
            <>
              <Field
                label={t('form.formFields.advancedSettings')}
                name="advancedSettings"
                component={ReduxFormCheckboxField}
                disabled={canUpdate}
              />
              {values.advancedSettings && (
                <Field
                  label={t('form.formFields.partnerNumber')}
                  name="partnerNumber"
                  component={ReduxFormInputField}
                  validate={isRequired}
                  type="text"
                  disabled={canUpdate}
                />
              )}
            </>
          )}
        </>
      </ContainerFormSection>
    </FormSection>
  );
}

export default AirshipForm;
