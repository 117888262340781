import React from 'react';
import { get } from 'lodash';
import { TriggerNodePlaceholder, TriggerNode } from './TriggerNode';
import { StepNode } from './StepNode';

import CardBase from './CardBase';

import './styles.scss';

const getNodeComponentByType = nodeType => {
  const componentsByNodeType = {
    START_TRIGGER: TriggerNode,
    TRIGGER: TriggerNode,
    STEP: StepNode,
  };

  return componentsByNodeType[nodeType] || TriggerNodePlaceholder;
};

const FilterDefinitions = () => (
  <defs>
    <clipPath id="jo-clip-outside-node">
      <CardBase />
    </clipPath>
    <filter id="jo-card-shadow" height="1.4" width="1.3">
      <feDropShadow dx="1" dy="3" stdDeviation="3" floodColor="#c2c2c2" floodOpacity="0.5" />
    </filter>
  </defs>
);

const Nodes = ({
  journeyId,
  nodes,
  fetchJourneyData,
  canEdit,
  startTriggerError,
  nodesErrors = {},
  showExperimentSettingsModal,
  isSumOfVariantsValid,
  hasExperiments,
}) => (
  <>
    <FilterDefinitions />
    {nodes.map(({ x, y, data, parent }) => {
      const NodeComponent = getNodeComponentByType(data.type);
      const isStartTriggerError = !data.type && startTriggerError;
      const nodeErrors = nodesErrors[data.nodeId];
      const hasNodeError = isStartTriggerError || get(nodeErrors, 'DATA_ERROR.length', 0) > 0;
      const hasButtonError = get(nodeErrors, 'STRUCTURE_ERROR.length', 0) > 0;

      return (
        <NodeComponent
          journeyId={journeyId}
          showExperimentSettingsModal={showExperimentSettingsModal}
          hasNodeError={hasNodeError}
          hasLinkError={hasButtonError}
          key={data.nodeId}
          x={x}
          y={y}
          data={data}
          parent={parent}
          fetchJourneyData={fetchJourneyData}
          canEdit={canEdit}
          isSumOfVariantsValid={isSumOfVariantsValid}
          hasExperiments={hasExperiments}
        />
      );
    })}
  </>
);
export default Nodes;
