import React, { useState } from 'react';
import { useQuery } from '~/customer/components/utils';
import EditableRulesList from '~/common/modules/rules/components/EditableRulesList';
import { getOverlapJourneysCandidates } from '~/customer/journeys/dataService';
import { joinCriteriaVariables } from '~/profiles/pages/AudienceEdit/utils';
import JoNodeTitleForm from '~/customer/components/JoNodeTitleForm';
import OverlayPageContainer from '~/customer/components/OverlayPageContainer';
import Heading from '~/components/src/Heading';
import { RulesTypes } from '~/common/modules/rules/types';
import Btn from '~/components/src/Btn';
import Notification from '~/components/src/Notification';
import { getTitleAndSubmitHandler } from './utils';
import './styles.scss';

const EditTriggerScreen = ({
  ruleTypesTree,
  allVariables,
  trigger,
  journeyId,
  setJourneyContents,
  goToJourneyView,
  refetchAllVariables,
  t,
  type = 'trigger',
  fetchJourneyData,
  isUsedInJourneys,
  isJourneyCondition = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const triggerId = trigger && trigger.triggerId;
  const isEdit = !!triggerId;

  const [triggerTitleFormState, setTriggerTitleFormState] = useState({
    isValid: isEdit,
    title: isEdit ? trigger.name : '',
  });

  const [criteriaFormState, setCriteriaFormState] = useState({
    isValid: isEdit,
    criteria: isEdit ? trigger.criteria : [],
  });

  const isFormValid = triggerTitleFormState.isValid && criteriaFormState.isValid;

  const [wasSubmitButtonClicked, setWasSubmitButtonClicked] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const editableCriteria = joinCriteriaVariables(criteriaFormState.criteria, allVariables);

  const query = useQuery();
  const parentStepId = query.get('parentStepId');

  const { pageTitle, submitHandler } = getTitleAndSubmitHandler(triggerId, parentStepId, t, type);

  let formProps = null;
  const bindFormikForm = _formProps => {
    formProps = _formProps;
  };

  const handleSubmit = async () => {
    await formProps?.validateForm();

    if (!wasSubmitButtonClicked) {
      setWasSubmitButtonClicked(true);
    }

    const canSubmit = formProps?.isValid && isFormValid && !isSubmitting;

    if (!canSubmit) {
      return;
    }

    setIsSubmitting(true);

    submitHandler({
      trigger,
      triggerId,
      journeyId,
      parentStepId,
      triggerTitleFormState,
      criteriaFormState,
      setJourneyContents,
      t,
      type,
      fetchJourneyData,
    }).catch(() => {
      setIsSubmitting(false);
    });
  };

  const isGoal = type === 'goal';
  const isExitCondition = type === 'exitCondition';

  const alreadySelectedOverlapJourneyIds = criteriaFormState.criteria
    .flat()
    .filter(rule => rule.type === RulesTypes.JourneyOverlap)
    .map(rule => rule?.dependantJourneyId);

  const isOverlapRulesLimitReached = alreadySelectedOverlapJourneyIds.length >= 5;

  const ruleTypes = ruleTypesTree?.map(rule => {
    if (rule.type === RulesTypes.JourneyOverlap) {
      return {
        ...rule,
        isDisabled: isOverlapRulesLimitReached,
        subtitle: isOverlapRulesLimitReached
          ? t('audiences:rules.descriptions.JourneyOverlapRuleLimitReached', { limit: 5 })
          : rule.subtitle,
        subMenu: null,
        customSubMenu: {
          fetchData: () =>
            getOverlapJourneysCandidates(journeyId).then(data =>
              data
                .filter(item => !alreadySelectedOverlapJourneyIds.includes(item.journeyId))
                .map(item => ({
                  title: item.journeyName,
                  ruleDefinitionId: rule.ruleDefinitionId,
                  availableFilters: [],
                  type: RulesTypes.JourneyOverlap,
                  key: item.journeyId,
                  extra: {
                    dependantJourneyId: item.journeyId,
                  },
                })),
            ),
        },
      };
    }
    return rule;
  });

  const testHookClass = isEdit ? `t-jo-edit-trigger-screen-${triggerId}` : `t-jo-create-trigger-screen-${parentStepId}`;
  const getNodeLabel = () => {
    if (isGoal) return t('goals.goalName');
    if (isExitCondition) return t('exits.exitConditionName');
    return t('triggers.triggerName');
  };

  const getNotificationError = () => {
    if (isGoal) return t('goals.formInvalid');
    if (isExitCondition) return t('exits.formInvalid');
    return t('triggers.formInvalid');
  };

  return (
    <OverlayPageContainer className={`EditTrigger-container  ${testHookClass}`}>
      <div className="flex h-full w-full justify-center bg-gray-50">
        <div className="w-full px-16">
          <Heading
            title={pageTitle}
            crumbs={[
              {
                title: t('journey:back'),
                onClick: () => {
                  goToJourneyView();
                },
              },
            ]}
          />
          <JoNodeTitleForm
            titleValue={triggerTitleFormState.title}
            setTitleFormState={setTriggerTitleFormState}
            isEdit={isEdit}
            label={getNodeLabel()}
            wasSubmitButtonClicked={wasSubmitButtonClicked}
          />
          <EditableRulesList
            criteria={editableCriteria}
            ruleTypesTree={ruleTypes}
            onChange={setCriteriaFormState}
            fetchAllVariables={refetchAllVariables}
            isJourneyCondition={isJourneyCondition}
            isSubmitted={wasSubmitButtonClicked}
            isUsedInJourneys={isUsedInJourneys}
            bindFormikForm={bindFormikForm}
            isJoTrigger
          />
          <hr className="EditAudience-footerSeparator" />
          {wasSubmitButtonClicked && !isFormValid && (
            <Notification kind="error" header="">
              <p>{getNotificationError()}</p>
            </Notification>
          )}
          <div className="flex items-center justify-end gap-4 pb-6">
            <Btn onClick={goToJourneyView}>{t('common:actions.cancel')}</Btn>
            <Btn color="blue" testHook="saveTriggerButton" onClick={handleSubmit}>
              {t('common:actions.save')}
            </Btn>
          </div>
        </div>
      </div>
    </OverlayPageContainer>
  );
};

export default EditTriggerScreen;
