import React from 'react';
import i18n from '~/i18n';
import { UITable } from '~/components/src/UITable';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { formatNumber } from '~/common';
import { TLabel, TWorkflow, TWorkflowTableProps } from '~/workflows/types';
import UsageIcons from '~/profiles/components/UsageIcons';
import LabelSelect from '~/components/src/LabelSelect';
import { DATE_FORMAT, TIME_FORMAT } from '~/workflows/constants';
import StatusIndicator from './StatusIndicator';
import HPStatus from './HPStatus';
import WorkflowActions from './WorkflowActions';

const columnHelper = createColumnHelper<TWorkflow>();

const getColumns = (
  labels: TLabel[],
  onDependantIconClick: (workflow: TWorkflow) => void,
  updateWorkflowLabels: (workflowId: string, labels: string[]) => void,
  refetchWorkflows: () => void,
  onDeleteLabel: (labelId: string) => void,
) => [
  columnHelper.accessor('name', {
    header: () => <div>{i18n.t('workflow:table.header.name')}</div>,
    cell: info => <StatusIndicator workflow={info?.row?.original} />,
    size: 200,
  }),
  columnHelper.accessor('workflowType', {
    header: () => <div className="text-center">{i18n.t('workflow:table.header.workFlowtype')}</div>,
    cell: ({ row }) => <div className="text-center">{row?.original?.workflowType?.label ?? '-'}</div>,
    size: 100,
  }),
  columnHelper.accessor('labels', {
    header: () => <div>{i18n.t('workflow:table.header.labels')}</div>,
    cell: ({ row }) => (
      <div>
        <LabelSelect
          options={labels}
          labels={row?.original.labels}
          updateJourneyLabels={updateWorkflowLabels}
          journeyId={row?.original?.journeyId}
          onDeleteLabel={onDeleteLabel}
          testHook="workFlowLabelSelect"
        />
      </div>
    ),
    size: 300,
  }),
  columnHelper.accessor('profilesEntered', {
    header: () => <div className="text-center">{i18n.t('workflow:table.header.entered')}</div>,
    cell: info => <div className="text-center">{formatNumber(info.getValue())}</div>,
    size: 100,
  }),
  columnHelper.accessor('activeProfiles', {
    header: () => <div className="text-center">{i18n.t('workflow:table.header.active')}</div>,
    cell: info => <div className="text-center">{formatNumber(info.getValue())}</div>,
    size: 100,
  }),
  columnHelper.accessor('profilesExited', {
    header: () => <div className="text-center">{i18n.t('workflow:table.header.exited')}</div>,
    cell: info => <div className="text-center">{formatNumber(info.getValue())}</div>,
    size: 100,
  }),
  columnHelper.accessor('goalsReached', {
    header: () => <div className="text-center">{i18n.t('workflow:table.header.reached')}</div>,
    cell: info => <div className="text-center">{formatNumber(info.getValue())}</div>,
    size: 100,
  }),
  columnHelper.accessor('historicProcessing', {
    header: () => <div className="text-center">{i18n.t('workflow:table.header.status')}</div>,
    cell: ({ row }) => <HPStatus hpData={row?.original?.historicProcessing} />,
    size: 100,
  }),
  columnHelper.accessor('isUsedInJourneys', {
    header: () => <div className="text-center">{i18n.t('workflow:table.header.usedIn')}</div>,
    cell: ({ row }) => {
      const { journeyId: workflowId, isUsedInJourneys } = row?.original;

      return (
        <div className="flex justify-center">
          <UsageIcons
            dependantTypes={isUsedInJourneys ? ['JOURNEY'] : []}
            elementUsedIn={['JOURNEY']}
            elementId={workflowId}
            onDependantIconClick={() => onDependantIconClick(row?.original)}
          />
        </div>
      );
    },
    size: 100,
  }),
  columnHelper.accessor('lastModified', {
    header: () => <div className="text-center">{i18n.t('workflow:table.header.lastEdit')}</div>,
    cell: info => (
      <div className="text-center">
        <span className="text-sm">{moment(info.getValue()).format(DATE_FORMAT)} </span>{' '}
        <span className="text-xs text-r42-blue">{moment(info.getValue()).format(TIME_FORMAT)}</span>
      </div>
    ),
    size: 100,
  }),
  columnHelper.accessor('actions', {
    id: 'actions',
    header: () => <div className="text-center">{i18n.t('workflow:table.header.actions')}</div>,
    cell: ({ row }) => <WorkflowActions workflow={row.original} refetchWorkflows={refetchWorkflows} />,
    size: 100,
  }),
];

const WorkflowTable = ({
  workflows,
  page,
  labels,
  setPage,
  onDependantIconClick,
  updateWorkflowLabels,
  refetchWorkflows,
  onDeleteLabel,
  hasFilter,
}: TWorkflowTableProps) => {
  const { content, totalPages } = workflows;

  return (
    <UITable
      enablePagination
      totalPages={totalPages}
      page={page}
      onPaginate={setPage}
      data={content ?? []}
      stickyHeader={false}
      testHook="workflowsTable"
      emptyMessage={i18n.t('workflow:list.empty.header')}
      emptyBody={i18n.t(`workflow:list.empty.${hasFilter ? 'filter' : 'body'}`)}
      columns={getColumns(labels, onDependantIconClick, updateWorkflowLabels, refetchWorkflows, onDeleteLabel)}
      emptyClassName="!top-4"
    />
  );
};

export default WorkflowTable;
