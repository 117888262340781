import moment from 'moment';
import i18n from '~/i18n';
import { StepDailyStats, StepDailyStatsOrigin, TriggerDailyStatsItem } from '~/customer/journeys/types';
import { StepStatsHighchartsData, GoalStatsHighchartsData } from './types';

const stepStatsSelector = (stats: StepDailyStats[], origin: StepDailyStatsOrigin): StepStatsHighchartsData => {
  const entered: number[] = [];
  const exited: number[] = [];
  const nextStep: number[] = [];
  const reachedGoal: number[] = [];
  const timestamp: string[] = [];

  stats.forEach(stat => {
    entered.push(stat.profilesAdded);
    exited.push(stat.profilesReachedExit + stat.profilesRemoved + stat.profilesMerged + stat.profilesExitedByCondition);
    nextStep.push(stat.profilesMovedToNextStep);
    reachedGoal.push(stat.profilesReachedGoal);
    timestamp.push(moment(stat.key.timestamp).format('D. MMM'));
  });

  return {
    series: [
      {
        name: i18n.t('journey:chart.entered'),
        data: entered,
        stack: 'entered',
        color: '#417505',
      },
      ...(origin === 'HISTORIC_PROCESSING'
        ? []
        : [
            {
              name: i18n.t('journey:chart.exitedJourney'),
              data: exited,
              stack: 'exited',
              color: '#0054b0',
            },
            {
              name: i18n.t('journey:chart.enteredNextStep'),
              data: nextStep,
              stack: 'nextStep',
              color: '#715ed0',
            },
            {
              name: i18n.t('journey:chart.reachedGoal'),
              data: reachedGoal,
              stack: 'reachedGoal',
              color: '#ffa638',
            },
          ]),
    ],
    xAxis: timestamp,
    noDataMessage: i18n.t('journey:chart.emptyMessage'),
  };
};

const goalStatsSelector = (stats: TriggerDailyStatsItem[]): GoalStatsHighchartsData => {
  const keys: string[] = [];
  const colors = ['#1fb25f', '#715ed0', '#0054b0'];
  const legends: Record<string, number[]> = {};
  const timestamp: string[] = [];

  stats.forEach(stat => {
    timestamp.push(moment(stat.dayTimestamp).format('D. MMM'));
    if (stat.variantStats.length === 0) {
      if (!keys.includes('Default')) {
        keys.push('Default');
        legends.Default = [stat.profilesReachedGoal];
      } else {
        legends.Default.push(stat.profilesReachedGoal);
      }
    } else {
      stat.variantStats.forEach(variant => {
        if (!keys.includes(variant.name)) {
          keys.push(variant.name);
          legends[variant.name] = [variant.profilesReached];
        } else {
          legends[variant.name].push(variant.profilesReached);
        }
      });
    }
  });

  const series = keys.map((key, index) => ({
    name: `Reached goal: ${keys[index]}`,
    data: legends[key],
    stack: index,
    color: colors[index],
  }));

  return {
    series,
    xAxis: timestamp,
    noDataMessage: i18n.t('journey:chart.emptyMessage'),
  };
};

export { stepStatsSelector, goalStatsSelector };
