import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'react-redux-composable-list';

const withSelectables = (configuration = {}) => Enhanced => {
  class WithSelectables extends Component {
    componentDidMount() {
      const { onSelectItems } = this.props;
      onSelectItems(configuration.ids || []);
    }

    render() {
      return <Enhanced isSelectable={this.props.isSelectable} {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch, { isSelectable, stateKey }) => ({
    onSelectItems: isSelectable
      ? bindActionCreators(ids => actionCreators.doSelectItems(stateKey, ids, true), dispatch)
      : () => {},
  });

  return connect(
    null,
    mapDispatchToProps,
  )(WithSelectables);
};

export default withSelectables;
