import { RulesTypes } from '~/common/modules/rules/types';
import { FILTER_CLAZZ } from '~/common/modules/rules/components/RuleCardEdit/Filter/constants';
import { RULE_DEFAULT_TIME_PERIOD, RulesMap } from '~/common/modules/rules/selectors';
import _ from 'lodash';

const fallbackFilterValue = {
  clazz: FILTER_CLAZZ.STRING,
  value: null,
};

const addFallbackValues = properties =>
  // Backend requires us to send non-empty values for some fields
  properties.map(property => ({
    ...property,
    filterValue: property.filterValue || fallbackFilterValue,
    profileVarId: property.profileVarId || null,
    minListSize: property.minListSize || 1,
  }));

const removeEmptyProperties = properties => properties.filter(property => property.dataField !== undefined);

export const shapeRuleFilters = (filters = []) => addFallbackValues(removeEmptyProperties(filters));

// Converts criteria from
export const shapeCriteriaRequest = criteria =>
  criteria.map(ruleSet => ({
    rules: ruleSet.map(rule => {
      const newRule = {
        name: rule.title,
        clazz: rule.type,
        ruleDefinitionId: rule.ruleDefinitionId,
        ruleId: rule.ruleId,
      };

      if (rule.type === RulesTypes.FixedTime) {
        return { ...newRule, waitTime: rule.waitTime };
      }

      if (rule.type === RulesTypes.JourneyOverlap) {
        return { ...newRule, negation: rule.negation, dependantJourneyId: rule.dependantJourneyId };
      }

      if (
        [RulesTypes.ExternalFact, RulesTypes.Experiment, RulesTypes.ReusableAudience, RulesTypes.AiFact].includes(
          rule.type,
        )
      ) {
        // These rules don't really have timeCondition, but backend requires us to send it anyway
        newRule.timeCondition = RULE_DEFAULT_TIME_PERIOD;
      }

      return {
        ...newRule,
        negation: rule.negation,
        filters: shapeRuleFilters(rule.filters),
        timeCondition: newRule.timeCondition || rule.timeCondition,
      };
    }),
  }));

export const shapeIntegrationsRequest = integrations =>
  integrations.map(integration => {
    const data = integration?.partnerDetails?.data;
    if (!data || _.isEmpty(data)) return integration.partnerDetails;

    // Info: Remove data properties with empty keys
    const newData = {};
    Object.keys(data).forEach(key => {
      if (key.trim() !== '') {
        newData[key] = data[key];
      }
    });

    return { ...integration?.partnerDetails, data: newData };
  });

export const shapeEventListeners = listeners =>
  listeners.map(listener => ({
    ruleDefinitionId: listener.ruleDefinitionId,
    ruleType: { name: RulesMap[listener.type].ruleTypeName, label: listener.typeLabel },
    properties: listener.filters.map(filter => ({
      propertyName: filter.dataField,
      variableId: filter.profileVarId,
      variableName: filter.profileVarName,
    })),
  }));
