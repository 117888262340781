import React from 'react';
import cx from 'classnames';
import i18n from '~/i18n';
import Btn from '~/components/src/Btn';
import Icons from '~/components/src/Icons';
import BtnOutlined from '~/components/src/BtnOutlined';
import './style.scss';

function Filter({ tableInstance, toggleFiltersPanel }) {
  const { columns, rows, state, setAllFilters } = tableInstance;

  return (
    <div className="fixed right-0 top-0 z-10 h-full bg-white pt-12 drop-shadow-md">
      <div className={cx('relative flex h-full flex-col gap-2')}>
        <div className={cx('flex items-center justify-between border-b border-gray-100 px-2 py-4')}>
          <div
            className={cx('flex items-center gap-2', {
              'text-blue-500': state.filters.length,
              'text-gray-500': !state.filters.length,
            })}
          >
            <Icons icon="filter" className="h-6 w-6" />
            {i18n.t('common:filters')}
            {state.filters.length > 0 && ':'}
            {state.filters.length > 0 && <span className="font-medium">{state.filters.length}</span>}
          </div>

          {i18n.t('common:rows', { row: rows.length })}
        </div>
        <div className="t-filterContent mb-28 overflow-y-auto px-4">
          {columns.map(column => (column.canFilter ? column.render('Filter') : null))}
        </div>
        <div className="fixed bottom-12 flex w-full items-center gap-2 border-t border-gray-100 bg-white p-4">
          <Btn color="gray" onClick={toggleFiltersPanel} testHook="jo-filterClose">
            {i18n.t('common:close')}
          </Btn>
          {!!state.filters.length && (
            <BtnOutlined testHook="clearFilter" onClick={() => setAllFilters([])}>
              {i18n.t('common:clearFilters')}
            </BtnOutlined>
          )}
        </div>
      </div>
    </div>
  );
}

export default Filter;
