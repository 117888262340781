import React from 'react';
import { translate } from 'react-i18next';
import cx from 'classnames';
import Btn from '~/components/src/Btn';
import Select, { components } from 'react-select';
import { I18NextT } from '~/components/src/Common/types';
import '~/customer/components/styles.scss';
import i18n from '~/i18n';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
import { isJoDemoMode } from '~/customer/fake/utils';
import { demoJourneyId } from '~/customer/fake';

import ExitConditionItem from './ExitConditionItem';
import GoalItem from './GoalItem';
import JourneyStats from './JourneyStats';
import { ExitCondition, Goal, ProfilesExited } from './types';
import './styles.scss';
import { JourneyVersion, JourneyVersionOption } from '../JOList/types';

const demoJourneyVersionId = '160335e4-c7bf-4068-83ae-f68137b47e0d';

type JourneyRightPanelProps = {
  journeyId: string;
  goals: Goal[];
  exitConditions: ExitCondition[];
  totalEntered: number;
  totalActive: number;
  totalReachedGoals: number;
  profilesExited: ProfilesExited;
  goToCreateGoalScreen: () => void;
  goToCreateExitConditionScreen: () => void;
  fetchJourneyData: () => void;
  handleVersionChange: () => void;
  currentVersion: JourneyVersionOption;
  canEdit: boolean;
  goalError?: string;
  exitConditionError?: string;
  className: string;
  journeyVersions?: JourneyVersion[];
  t: I18NextT;
};

const CustomVersionOption = ({ data, ...props }: any) => (
  <components.Option key={props.id} {...props}>
    <p className={twMerge(cx('font-medium text-gray-600', { 'text-white': props.isSelected }))}>
      {data?.label}
      {data?.isLatest && (
        <span className="mx-1 rounded-full bg-amber-400 px-1 py-0.5 text-xs">
          {i18n.t('orchestration:version.latest')}
        </span>
      )}
    </p>
    <p className={twMerge(cx('text-sm text-gray-500', { 'text-gray-200': props.isSelected }))}>{data.description}</p>
    <p className={twMerge(cx('py-1 text-xs text-gray-600', { 'text-gray-200': props.isSelected }))}>
      <span className={twMerge(cx('text-gray-400', { 'text-gray-300': props.isSelected }))}>
        {i18n.t('orchestration:version.publishedOn')}:{' '}
      </span>
      {moment(data.lastModified).format('DD MMM yyyy')}
    </p>
  </components.Option>
);

const JourneyVersionComponent = ({
  journeyVersions,
  currentVersion,
  handleVersionChange,
}: {
  journeyVersions?: JourneyVersion[];
  currentVersion: JourneyVersionOption;
  handleVersionChange: () => void;
}) => (
  <div className="flex flex-col gap-1 border-b border-gray-200 bg-gray-50 p-4">
    <label>{i18n.t('orchestration:version.label')}</label>
    <Select
      name="version"
      id="version"
      options={journeyVersions}
      className="w-full"
      onChange={handleVersionChange}
      label={i18n.t('orchestration:version.name')}
      components={{ Option: CustomVersionOption }}
      value={currentVersion}
    />
    {!currentVersion?.isLatest && (
      <small className="mt-1 text-amber-600">{i18n.t('orchestration:version.previousVersionMessage')}</small>
    )}
  </div>
);

const JourneyRightPanel = ({
  journeyId,
  goals,
  exitConditions,
  totalEntered = 0,
  totalActive = 0,
  totalReachedGoals = 0,
  profilesExited,
  goToCreateGoalScreen,
  goToCreateExitConditionScreen,
  fetchJourneyData,
  canEdit,
  goalError,
  exitConditionError,
  className,
  journeyVersions,
  handleVersionChange,
  currentVersion,
  t,
}: JourneyRightPanelProps) => {
  const isDemo = localStorage.getItem('useDemo') === 'true';
  const showVersion = journeyId === demoJourneyVersionId;
  const _isJoDemoMode = isJoDemoMode() && journeyId === demoJourneyId;

  return (
    <div
      className={cx('t-journeyRightPanel border-gray=200 flex h-full w-1/6 flex-col border-l bg-gray-50', className)}
    >
      {isDemo && showVersion && (
        <JourneyVersionComponent
          journeyVersions={journeyVersions}
          handleVersionChange={handleVersionChange}
          currentVersion={currentVersion}
        />
      )}
      <JourneyStats
        totalEntered={totalEntered}
        totalActive={totalActive}
        totalReachedGoals={totalReachedGoals}
        profilesExited={profilesExited}
        t={t}
      />
      <div className="grid grid-flow-col grid-rows-1 gap-2  p-4">
        <Btn
          className={cx('w-full', {
            't-addGoal-error border-red-400 !bg-red-400 text-red-50 !ring-red-300': goalError,
          })}
          icon="goal"
          color="amber"
          size="sm"
          onClick={goToCreateGoalScreen}
          disabled={!canEdit}
          testHook="addGoal"
          tooltip={!canEdit ? t('orchestration:permissions.notEnoughPermissions') : undefined}
        >
          {t('orchestration:goals.addGoalButton')}
        </Btn>
        <Btn
          className={cx('w-full', {
            't-addExitCondition-error border-red-400 !bg-red-400 text-red-50 !ring-red-300': exitConditionError,
          })}
          icon="exited"
          color="red"
          size="sm"
          onClick={goToCreateExitConditionScreen}
          disabled={!canEdit}
          testHook="addExitCondition"
          tooltip={!canEdit ? t('orchestration:permissions.notEnoughPermissions') : undefined}
        >
          {t('orchestration:exits.addExitButton')}
        </Btn>
      </div>
      <div className="flex h-full flex-col gap-4 overflow-y-auto p-4">
        {goals?.map(goal => (
          <GoalItem
            key={goal.nodeId}
            name={goal.label}
            reached={goal.stats.profilesReachedGoal}
            variantStats={goal.stats.variantStats}
            id={goal.nodeId}
            canEdit={canEdit}
            fetchJourneyData={fetchJourneyData}
            t={t}
            isDemoMode={_isJoDemoMode}
          />
        ))}
        {exitConditions?.map(exitCondition => (
          <ExitConditionItem
            key={exitCondition.nodeId}
            name={exitCondition.label}
            reached={exitCondition.stats.profilesExitedByCondition}
            variantStats={exitCondition.stats.variantStats}
            id={exitCondition.nodeId}
            canEdit={canEdit}
            fetchJourneyData={fetchJourneyData}
            t={t}
            isDemoMode={_isJoDemoMode}
          />
        ))}
      </div>
    </div>
  );
};

export default translate()(JourneyRightPanel);
