/**
 * @description Angular module for the customer journey trigger edit
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form.exitConditionEdit
 */
angular.module('webUi.ui.customer.journey.form.exitConditionEdit',
    [
        'webUi.ui.customer.journey.form',
        'webUi.service',
    ])

    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.customer.journeys.form.exitConditionEdit', {
                url: '/exitConditionEdit/{goalId}',
                isReact: true
            });
    }]);