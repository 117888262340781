import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators, selectors } from 'react-redux-composable-list';
import RowSelectable from './presenter';
import { select } from '../../helper/services';
import { tableContextWrapper } from '../context';

const isSelectableRow = (isSelectable, id) => isSelectable && !(id === undefined || id === null);

const mapStateToProps = (state, { stateKey, id, isSelectable = false, preselected = [], unselectables = [] }) => {
  const hasSelectableRow = isSelectableRow(isSelectable, id);

  const isSelected = hasSelectableRow ? selectors.getIsSelected(state, stateKey, id) : false;

  const selectState = hasSelectableRow ? select.getSelectState(id, isSelected, preselected, unselectables) : null;

  return {
    isSelectable,
    selectState,
  };
};

const mapDispatchToProps = (dispatch, { stateKey, isSelectable, id }) => ({
  onSelect: isSelectableRow(isSelectable, id)
    ? bindActionCreators(() => actionCreators.doSelectItem(stateKey, id), dispatch)
    : () => {},
});

export default tableContextWrapper(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RowSelectable),
);
