import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { buildUrl } from '~/common';
import * as selectors from '~/support/messages/selectors';
import { fetchMessagesIfNeeded } from '~/support/messages/actions';
import Spinner from '~/components/src/Spinner';
import MessageList from '~/support/components/MessagesList';

class MessagesContainer extends Component {
  componentDidMount() {
    this.props.fetchMessagesIfNeeded();
  }

  render() {
    const { isFetching, ...props } = this.props;
    return (
      <div>
        <MessageList {...props} />
        <Spinner isActive={isFetching} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: selectors.isFetchingMessages(state),
  list: selectors.getMessages(state),
  stateKey: 'SUPPORT/MESSAGES_LIST',
  clientUrl: item => buildUrl(`context/clients/view/${item.clientId}`),
  messageUrl: item => buildUrl(`support/messages/view/${item.messageId}`),
});

const mapDispatchToProps = dispatch => ({
  fetchMessagesIfNeeded: () => dispatch(fetchMessagesIfNeeded()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  translate('messages'),
)(MessagesContainer);
