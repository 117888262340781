import React from 'react';
import CardMessage from '~/components/src/CardMessage';
import { I18NextT } from '~/components/src/Common/types';

const errorTypes = [
  'AdwordsUserListUnrecoverableException',
  'AdwordsRecoverableException',
  'AdwordsAuthenticationRecoverableException',
  'AdwordsAuthorizationRecoverableException',
];

export const getError = (errorType: string, t: I18NextT) => {
  switch (errorType) {
    case errorTypes[2]:
      return (
        <p>
          <span className="font-medium">{t('connectors.adwords.usersList.authErrorHeader')}</span>
          <ol className="mt-2 px-4">
            <li>{t('connectors.adwords.usersList.authenticationCheck1')}</li>
            <li>{t('connectors.adwords.usersList.authenticationCheck2')}</li>
            <li>{t('connectors.adwords.usersList.authenticationCheck3')}</li>
          </ol>
        </p>
      );
    case errorTypes[3]:
      return (
        <p>
          <span className="font-medium">{t('connectors.adwords.usersList.authErrorHeader')}</span>
          <ol className="mt-2 px-4">
            <li>{t('connectors.adwords.usersList.authorizationCheck1')}</li>
            <li>{t('connectors.adwords.usersList.authorizationCheck2')}</li>
          </ol>
        </p>
      );
    case errorTypes[0]:
    case errorTypes[1]:
    default:
      return <p>{t('connectors.adwords.usersList.genericError')}</p>;
  }
};
