/**
 * @description Angular module for the customer journey step
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form
 */
angular.module('webUi.ui.customer.journey.form.step', [])
    .config(['$stateProvider',
        function config($stateProvider) {
            $stateProvider
                .state('site.customer.journeys.form.step', {
                    url: '/step/{stepId}',
                    isReact: true
                });
        }
    ]
    );
