import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import * as fromModals from '~/modals';
import BtnIcon from '~/components/src/BtnIcon';
import EditJourney from './EditJourney';
import './styles.scss';

const EditJourneyTrigger = ({ name, expiryTime, journeyId, fetchJourneyData, onJourneyEditClick, canEdit, t }) => (
  <BtnIcon
    icon="setting"
    testHook="journeySettings"
    onClick={() => onJourneyEditClick(name, expiryTime, journeyId, fetchJourneyData)}
    tooltip={t(`orchestration:${canEdit ? 'tooltips.journeySettings' : 'permissions.notEnoughPermissions'}`)}
    disabled={!canEdit}
  />
);

const hideModal = () => dispatch => {
  dispatch(fromModals.hideModal());
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onJourneyEditClick: (name, expiryTime, journeyId, fetchJourneyData) => {
    dispatch(
      fromModals.showModal(fromModals.CONTENT_MODAL, {
        title: ownProps.t('journey:journeyModal.title'),
        content: (
          <EditJourney
            name={name}
            expiryTime={expiryTime}
            journeyId={journeyId}
            hideModal={() => dispatch(hideModal())}
            fetchJourneyData={fetchJourneyData}
          />
        ),
      }),
    );
  },
});

export default compose(translate(), connect(null, mapDispatchToProps))(EditJourneyTrigger);
