import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { noop } from 'lodash';

import { getAngularService } from 'ReactAngular/react.service';
import { getItemWithExperimentStat } from '~/customer/pages/JourneyView/utils';
import { withRouter } from '~/common/withRouter';
import { compose } from 'recompose';

import { useAPI } from '~/common/ApiHooks';
import i18n from '~/i18n';
import { ActionsContext } from '~/customer/components/constants';

import Page from '~/components/src/Page/';
import JourneyDataService from '~/customer/journeys/dataService';
import * as RulesDataService from '~/common/modules/rules/dataService';

import JourneyHeader from '~/customer/components/JourneyHeader/JourneyHeader';
import JourneyCanvas from '~/customer/components/JourneyCanvas';
import JourneyRightPanel from '~/customer/components/JourneyRightPanel';

import ViewTriggerScreen from '~/customer/components/ViewTrigger';
import ViewStepScreen from '~/customer/components/ViewStep';

import EditTriggerScreen from '~/customer/components/EditTrigger';
import EditStepScreen from '~/customer/components/EditStep';
import GoalStats from '~/customer/components/GoalStats';
import StepStats from '~/customer/pages/JourneyView/StepStats';

import { getExperimentNodes } from '~/customer/components/utils';
import { makeRedirects } from './redirects';
import { makeModals } from './modalActions';

import './styles.scss';

const useJourney = (journeyId, versionId) => {
  const SecurityService = getAngularService(document, 'SecurityService');

  const [canEditJourney, setEditPermissions] = useState(null);
  const [journeyContents, setJourneyContents] = useState(null);
  const [journeyData, setJourneyData] = useState(null);
  const [journeyVersions, setJourneyVersions] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(journeyVersions ? journeyVersions[0] : null);
  const [journeyErrors, setJourneyErrors] = useState([]);
  const [showJourneyErrors, setShowJourneyErrors] = useState(false);

  SecurityService.getSecurityContext().then(context => {
    setEditPermissions(context.hasPermission('CUSTOMER_JOURNEY_EDIT'));
  });

  const { data, error } = useAPI(() => JourneyDataService.fetchJourneyTree(journeyId, versionId), [journeyId]);

  const { data: joData, error: journeyDataError } = useAPI(
    () => JourneyDataService.fetchJourneyData(journeyId),
    [journeyId],
  );

  const { data: dependantJourneys, error: dependantJourneysError } = useAPI(
    () => JourneyDataService.getDependantJourneys(journeyId),
    [journeyId],
  );

  const { data: variablesResponse } = useAPI(() => RulesDataService.getAllRuleVariables(), [journeyId]);
  const variables = variablesResponse || [];

  const setVersions = data => {
    const journeyVersions = data?.versions?.map(version => ({
      ...version,
      label: version.name,
      value: version.versionId,
    }));
    setJourneyVersions(journeyVersions);
    setCurrentVersion(journeyVersions ? journeyVersions[0] : null);
  };

  const fetchJourneyData = async () => {
    const joData = await JourneyDataService.fetchJourneyData(journeyId);
    const journeyErrorsResponse = await JourneyDataService.validateJourney(journeyId);

    setJourneyData(joData);
    setVersions(joData);
    setJourneyErrors(journeyErrorsResponse.data);
  };

  const fetchJourneyTree = async versionId => {
    const treeData = await JourneyDataService.fetchJourneyTree(journeyId, versionId);

    setJourneyContents(treeData);
  };

  useEffect(() => {
    setJourneyContents(data);
    setJourneyData(joData);
    setVersions(joData);
  }, [data, joData]);
  return [
    journeyContents,
    setJourneyContents,
    error,
    journeyData,
    journeyDataError,
    fetchJourneyData,
    fetchJourneyTree,
    canEditJourney,
    journeyErrors,
    showJourneyErrors,
    setShowJourneyErrors,
    dependantJourneys,
    dependantJourneysError,
    variables,
    journeyVersions,
    currentVersion,
    setCurrentVersion,
  ];
};

const JourneyViewPage = ({ params, dispatch }) => {
  const { journeyId, versionId } = params;

  const [
    journeyContents,
    setJourneyContents,
    journeyFetchError,
    journeyData,
    journeyDataError,
    fetchJourneyData,
    fetchJourneyTree,
    canEditJourney,
    journeyErrors,
    showJourneyErrors,
    setShowJourneyErrors,
    dependantJourneys,
    dependantJourneysError,
    variables,
    journeyVersions,
    currentVersion,
    setCurrentVersion,
  ] = useJourney(journeyId, versionId);

  if (journeyFetchError || journeyDataError || dependantJourneysError) {
    return null;
  }

  const isLoading = !journeyContents || !journeyData;

  const {
    goToViewTriggerScreen,
    goToCreateTriggerScreen,
    goToEditTriggerScreen,
    goToViewStepScreen,
    goToCreateStepScreen,
    goToEditStepScreen,
    goToJourneyView,
    goToJourneyStatsView,
    goToStepStatisticsScreen,
    goToGoalStatisticsScreen,
    goToViewGoalScreen,
    goToEditGoalScreen,
    goToEditExitConditionScreen,
    goToCreateGoalScreen,
    goToCreateExitConditionScreen,
    goToExitConditionStatisticsScreen,
    goToViewExitConditionScreen,
  } = makeRedirects(journeyId);

  const {
    showDeleteTriggerModal,
    showDeleteTransition,
    showDeleteStepModal,
    showJourneyValidationModal,
    showJourneyPublishModal,
    showExperimentSettingsModal,
    showDependantJourneysModal,
    showTransitionModal,
    showHistoricProcessingModal,
    showHistoricProcessRunningModal,
  } = makeModals(journeyId, dispatch, setJourneyContents, journeyContents, fetchJourneyData, dependantJourneys);

  const actions = {
    goToJourneyView,
    goToJourneyStatsView,
    goToViewTriggerScreen,
    goToCreateTriggerScreen,
    goToEditTriggerScreen,
    goToViewStepScreen,
    goToCreateStepScreen,
    goToEditStepScreen,
    goToStepStatisticsScreen,
    goToGoalStatisticsScreen,
    showDeleteStepModal,
    showDeleteTriggerModal,
    showDeleteTransition,
    showTransitionModal,
    goToViewGoalScreen,
    goToEditGoalScreen,
    goToEditExitConditionScreen,
    goToCreateGoalScreen,
    goToCreateExitConditionScreen,
    goToExitConditionStatisticsScreen,
    goToViewExitConditionScreen,
  };

  const DATA_ERROR = 'DATA_ERROR';
  const STRUCTURE_ERROR = 'STRUCTURE_ERROR';
  let goalError = null;
  let startTriggerError = null;
  let nodesErrors = [];

  if (showJourneyErrors) {
    goalError = journeyErrors.find(error => error.errorCode === 'JOURNEY_HAS_NO_GOALS');
    startTriggerError = journeyErrors.find(error => error.errorCode === 'JOURNEY_HAS_NO_START_TRIGGER');
    nodesErrors = journeyErrors
      .filter(error => error.nodeId || error.stepId)
      .reduce((errorsMap, error) => {
        const { errorType, errorCode } = error;
        const nodeId = error.stepId || error.nodeId;

        if (!errorsMap[nodeId]) {
          errorsMap[nodeId] = {
            [DATA_ERROR]: [],
            [STRUCTURE_ERROR]: [],
            other: [],
          };
        }

        if ([DATA_ERROR, STRUCTURE_ERROR].includes(errorType)) {
          errorsMap[nodeId][errorType].push(errorCode);
        } else {
          errorsMap[nodeId].other.push(errorCode);
        }
        return errorsMap;
      }, {});
  }

  let isSumOfVariantsValid = true;
  let hasStepsAfterFirstTrigger = false;
  const { experimentSteps } = getExperimentNodes(journeyContents?.nodes || []);

  const handleVersionChange = selectedOption => {
    setCurrentVersion(selectedOption);
    fetchJourneyTree(selectedOption?.versionId);
  };

  if (experimentSteps.length > 0) {
    hasStepsAfterFirstTrigger = true;
    const weightOfVariants = experimentSteps.reduce((acc, step) => acc + (step?.experimentVariant?.weight || 0), 0);
    isSumOfVariantsValid = weightOfVariants === 100;
  }
  const isUsedInJourneys = dependantJourneys?.length !== 0;

  return (
    <Page isReady={!isLoading} className="Page JourneyView-page overflow-hidden" excludeContainer={true}>
      <div className="absolute left-0 flex h-full w-full overflow-hidden">
        <ActionsContext.Provider value={actions}>
          <div className="flex flex-1 flex-col">
            <JourneyHeader
              canEdit={canEditJourney}
              onPublishClick={noop}
              name={journeyData?.name}
              expiryTime={journeyData?.expiryTime}
              historicProcessing={journeyData?.historicProcessing}
              journeyId={journeyId}
              state={journeyData?.state}
              fetchJourneyData={fetchJourneyData}
              showJourneyValidationModal={showJourneyValidationModal}
              showJourneyPublishModal={showJourneyPublishModal}
              setShowJourneyErrors={setShowJourneyErrors}
              showExperimentSettingsModal={showExperimentSettingsModal}
              showDependantJourneysModal={showDependantJourneysModal}
              showHistoricProcessingModal={showHistoricProcessingModal}
              showHistoricProcessRunningModal={showHistoricProcessRunningModal}
              hasStepsAfterFirstTrigger={hasStepsAfterFirstTrigger}
              isUsedInJourneys={isUsedInJourneys}
              variables={variables}
              journeyContents={journeyContents}
              goToJourneyStatsView={goToJourneyStatsView}
              currentVersion={currentVersion}
            />

            <JourneyCanvas
              nodesErrors={nodesErrors}
              startTriggerError={startTriggerError}
              journeyContents={journeyContents}
              fetchJourneyData={fetchJourneyData}
              canEdit={canEditJourney}
              setJourneyContents={setJourneyContents}
              journeyId={journeyId}
              showExperimentSettingsModal={showExperimentSettingsModal}
              isSumOfVariantsValid={isSumOfVariantsValid}
            />
          </div>
          {journeyContents && (
            <JourneyRightPanel
              journeyId={journeyId}
              goalError={goalError}
              totalEntered={journeyContents.profilesEnteredJourney}
              totalActive={journeyContents.profilesActive}
              totalReachedGoals={journeyContents.totalProfilesReachedGoal}
              goals={getItemWithExperimentStat(journeyContents, journeyContents.goals)}
              exitConditions={getItemWithExperimentStat(journeyContents, journeyContents.exitConditions)}
              profilesExited={journeyContents.profilesExited}
              goToCreateGoalScreen={goToCreateGoalScreen}
              goToCreateExitConditionScreen={goToCreateExitConditionScreen}
              canEdit={canEditJourney}
              fetchJourneyData={fetchJourneyData}
              currentVersion={currentVersion}
              handleVersionChange={handleVersionChange}
              journeyVersions={journeyVersions}
            />
          )}
        </ActionsContext.Provider>
      </div>

      <Routes>
        <Route
          path="triggerNew"
          element={
            <EditTriggerScreen
              journeyId={journeyId}
              journeyContents={journeyContents}
              setJourneyContents={setJourneyContents}
              goToJourneyView={goToJourneyView}
              fetchJourneyData={fetchJourneyData}
              isUsedInJourneys={isUsedInJourneys}
              type="trigger"
            />
          }
        />

        <Route
          path="triggerEdit/:triggerId"
          element={
            <EditTriggerScreen
              journeyId={journeyId}
              journeyContents={journeyContents}
              setJourneyContents={setJourneyContents}
              goToJourneyView={goToJourneyView}
              fetchJourneyData={fetchJourneyData}
              isUsedInJourneys={isUsedInJourneys}
              type="trigger"
            />
          }
        />

        <Route
          path="stepNew/addPartner"
          element={
            <EditStepScreen
              journeyId={journeyId}
              setJourneyContents={setJourneyContents}
              goToJourneyView={goToJourneyView}
              fetchJourneyData={fetchJourneyData}
              showExperimentSettingsModal={showExperimentSettingsModal}
            />
          }
        />

        <Route
          path="stepEdit/:stepId"
          element={
            <EditStepScreen
              journeyId={journeyId}
              setJourneyContents={setJourneyContents}
              goToJourneyView={goToJourneyView}
              fetchJourneyData={fetchJourneyData}
            />
          }
        />

        <Route
          path="trigger/:triggerId"
          element={
            <ViewTriggerScreen
              goToEditTriggerScreen={goToEditTriggerScreen}
              handleDecline={() => goToJourneyView()}
              canEdit={canEditJourney}
            />
          }
        />

        <Route
          path="step/:stepId"
          element={
            <ViewStepScreen
              nodes={journeyContents?.nodes}
              hideModal={() => goToJourneyView()}
              goToEditStepScreen={goToEditStepScreen}
              canEdit={canEditJourney}
            />
          }
        />

        <Route
          path="statsView/STEP/:stepId"
          element={
            <StepStats journeyId={journeyId} t={i18n.t.bind(i18n)} handleClose={() => goToJourneyView(journeyId)} />
          }
        />

        <Route
          path="statsView/GOAL/:goalId"
          element={
            <GoalStats
              journeyContents={journeyContents}
              journeyId={journeyId}
              t={i18n.t.bind(i18n)}
              handleDecline={() => goToJourneyView(journeyId)}
            />
          }
        />
        <Route
          path="statsView/ExitCondition/:exitConditionId"
          element={
            <GoalStats
              journeyContents={journeyContents}
              journeyId={journeyId}
              t={i18n.t.bind(i18n)}
              handleDecline={() => goToJourneyView(journeyId)}
            />
          }
        />
        <Route
          path="goal/:goalId"
          element={
            <ViewTriggerScreen
              goToEditGoalScreen={goToEditGoalScreen}
              handleDecline={() => goToJourneyView()}
              type="goal"
              canEdit={canEditJourney}
            />
          }
        ></Route>
        <Route
          path="exitCondition/:exitConditionId"
          element={
            <ViewTriggerScreen
              goToEditExitConditionScreen={goToEditExitConditionScreen}
              handleDecline={() => goToJourneyView()}
              type="goal"
              canEdit={canEditJourney}
            />
          }
        ></Route>
        <Route
          path="goalNew"
          element={
            <EditTriggerScreen
              journeyId={journeyId}
              setJourneyContents={setJourneyContents}
              goToJourneyView={goToJourneyView}
              type="goal"
              fetchJourneyData={fetchJourneyData}
            />
          }
        />
        <Route
          path="exitConditionNew"
          element={
            <EditTriggerScreen
              journeyId={journeyId}
              setJourneyContents={setJourneyContents}
              goToJourneyView={goToJourneyView}
              type="exitCondition"
              fetchJourneyData={fetchJourneyData}
            />
          }
        />
        <Route
          path="goalEdit/:triggerId"
          element={
            <EditTriggerScreen
              journeyId={journeyId}
              setJourneyContents={setJourneyContents}
              goToJourneyView={goToJourneyView}
              type="goal"
              fetchJourneyData={fetchJourneyData}
            />
          }
        />
        <Route
          path="exitConditionEdit/:triggerId"
          element={
            <EditTriggerScreen
              journeyId={journeyId}
              setJourneyContents={setJourneyContents}
              goToJourneyView={goToJourneyView}
              type="exitCondition"
              fetchJourneyData={fetchJourneyData}
            />
          }
        />
      </Routes>
    </Page>
  );
};

export default compose(withRouter, connect())(JourneyViewPage);
