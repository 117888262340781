export type JOStateType = {
  transitionSource: string;
};

export interface Action {
  type: string;
  [payloadProperty: string]: string;
}

const actionTypes = {
  SET_TRANSITION_SOURCE: 'SET_TRANSITION_SOURCE',
};

const initialState = {
  transitionSource: '',
};

function reducer(state: JOStateType, action: Action): JOStateType {
  switch (action.type) {
    case actionTypes.SET_TRANSITION_SOURCE:
      return {
        ...state,
        transitionSource: action.transitionSource,
      };

    default:
      return state;
  }
}

export { actionTypes, initialState, reducer };
