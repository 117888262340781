/**
 * @description Angular main module for Workflow Module, including journeys, in the future other modules related
 * @memberOf webUi
 * @namespace webUi.ui.workflows
 */
angular.module('webUi.ui.workflows',
    [
        'webUi.ui.workflows.dashboard',
        'webUi.ui.workflows.form'
    ]
);
