import React, { useContext, useState } from 'react';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import { TWorkflow } from '~/workflows/types';
import { WORKFLOW_TYPE } from '~/workflows/constants';
import { WorklfowContext } from '~/workflows/WorklfowContext';
import DeletePopup from '~/components/src/DeletePopup';
import { Notification } from '~/components';
import { deleteWorkflow } from '~/workflows/dataService';
import { showSuccess } from '~/notificationCenter';
import { buildUrl } from '~/common';

const WorkflowActions = ({
  workflow,
  refetchWorkflows,
}: {
  workflow: TWorkflow;
  refetchWorkflows: () => void;
}): JSX.Element => {
  const { hasWorkflowJourneyEdit, hasWorkflowAudienceEdit, navigate } = useContext(WorklfowContext);
  const { journeyId, workflowType, name } = workflow;

  const hasPermissions =
    workflowType?.name === WORKFLOW_TYPE.Audience ? hasWorkflowAudienceEdit : hasWorkflowJourneyEdit;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleWorkflowEdit = () => {
    if (workflowType?.name === WORKFLOW_TYPE.Audience) navigate(buildUrl(`workflows/edit/${journeyId}`));
    else navigate(buildUrl(`workflows/edit/${journeyId}`));
  };

  const handleWorkflowStats = () => {
    navigate(buildUrl(`workflows/edit/${journeyId}/stats`));
  };

  const handleDeleteWorkflow = async () => {
    await deleteWorkflow(workflow.journeyId);
    showSuccess({ header: i18n.t('workflow:notifications.delete.success') });
    setShowDeleteDialog(false);
    refetchWorkflows();
  };

  return (
    <div className="flex justify-end gap-1">
      <BtnIcon
        icon="edit"
        disabled={!hasPermissions}
        onClick={() => handleWorkflowEdit()}
        tooltip={i18n.t('workflow:common.actions.edit')}
        testHook="editWorkFlow"
      />
      <BtnIcon
        icon="statistics"
        tooltip={i18n.t('workflow:common.actions.statistics')}
        onClick={() => handleWorkflowStats()}
        testHook="workflowStatistics"
      />
      <BtnIcon
        icon="delete"
        disabled={!hasPermissions}
        onClick={() => setShowDeleteDialog(true)}
        tooltip={i18n.t('workflow:common.actions.delete')}
        testHook="deleteWorkflow"
      />
      {showDeleteDialog && (
        <DeletePopup
          title={i18n.t('workflow:messages.delete.header')}
          hidePopup={() => setShowDeleteDialog(false)}
          onClick={() => handleDeleteWorkflow()}
        >
          <Notification>{i18n.t('workflow:messages.delete.message', { name })}</Notification>
        </DeletePopup>
      )}
    </div>
  );
};

export default WorkflowActions;
