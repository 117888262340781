import React from 'react';
import Link from '../Link';
import { components } from '../Table';

const { HeaderCell, Sort } = components;

export const textSort = prop => item => `${item[prop]}`.toLowerCase();
export const numberSort = prop => item => item[prop];
export const dateSort = prop => item => item[prop];
export const boolSort = prop => item => item[prop] ? 1 : 0;

const SORTS_ASC_DESC = {
  ASC: <Link>&#8593;</Link>,
  DESC: <Link>&#8595;</Link>,
};

const SortableHeader = ({ width = 0, sortKey, title, sortFn = textSort(sortKey), className = '', ...props }) => {
  const styles = width ? { width } : {};

  return (
    <HeaderCell style={styles} {...props} className={className}>
      <Sort sortKey={sortKey} sortFn={sortFn} suffix={SORTS_ASC_DESC}>
        {title}
      </Sort>
    </HeaderCell>
  );
};

export default SortableHeader;
