import React, { Component, useEffect } from 'react';
import { getAngularService } from 'ReactAngular/react.service';
import { Route, Routes } from 'react-router-dom';

import PrivateRoute from '../../components/src/PrivateRoute/index';
import JourneysPage from './Journeys';
import JourneyViewPage from './JourneyView';

import './styles.scss';
import JourneyStatsView from './JourneyStatsView/JourneyStatsView';

const CUSTOMER_JOURNEY_VIEW = 'CUSTOMER_JOURNEY_VIEW';
const CUSTOMER_JOURNEY_EDIT = 'CUSTOMER_JOURNEY_EDIT';

const GoToExperimentDemo = () => {
  useEffect(() => {
    localStorage.setItem('useJoDemoMode', true);
    window.location.replace('/');
  }, []);
  return null;
};

class CustomerPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      canViewCustomerJourney: false,
      canEditCustomerJourney: false,
      maxJourneyLimit: null,
    };
  }

  componentDidMount() {
    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext().then(context => {
      const { currentSiteId, sites } = context;
      const maxJourneyLimit = sites[currentSiteId]?.maxJourneyLimit;

      this.setState({
        isReady: true,
        canViewCustomerJourney: context.hasPermission(CUSTOMER_JOURNEY_VIEW),
        canEditCustomerJourney: context.hasPermission(CUSTOMER_JOURNEY_EDIT),
        maxJourneyLimit,
      });
    });
  }

  render() {
    const { isReady, canViewCustomerJourney, canEditCustomerJourney, maxJourneyLimit } = this.state;

    return (
      isReady && (
        <div className="Customer">
          <Routes>
            <Route
              path="journeys/joDemoMode"
              element={<PrivateRoute authenticated={canViewCustomerJourney} component={GoToExperimentDemo} />}
            />
            <Route
              path="journeys/dashboard"
              element={
                <PrivateRoute
                  authenticated={canViewCustomerJourney}
                  canEditCustomerJourney={canEditCustomerJourney}
                  maxJourneyLimit={maxJourneyLimit}
                  component={JourneysPage}
                />
              }
            />
            <Route
              path="journeys/edit/:journeyId/joStatsView"
              element={<PrivateRoute authenticated={canViewCustomerJourney} component={JourneyStatsView} />}
            />
            <Route
              path="journeys/edit/:journeyId/*"
              element={<PrivateRoute authenticated={canViewCustomerJourney} component={JourneyViewPage} />}
            />
          </Routes>
        </div>
      )
    );
  }
}

export default CustomerPages;
