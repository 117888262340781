import React, { useState } from 'react';
import { Column, useTable, UseTableRowProps } from 'react-table';
import fileSize from 'filesize';
import cx from 'classnames';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Link from '~/components/src/Link';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import Icons from '~/components/src/Icons';
import { getSiteId } from '~/common/SecurityMetaService';
import ModalWindow from '~/components/src/ModalWindow/ModalWindow';
import DeleteModal from '~/components/src/ModalWindow/DeleteModal';
import Notification from '~/components/src/Notification';
import { getMediaLibraryOrFolderUrl, makeUniqueNameValidator } from './utils';
import { MediaItem } from './types';

interface MediaLibraryContentTableProps {
  createFolder: (name: string) => Promise<void>;
  deleteFileOrFolder: (fullPath: string, isFolder: boolean) => Promise<void>;
  hasAdservingEdit: boolean;
  isFolder: boolean;
  isNewFolderFormVisible: boolean;
  mediaFiles: MediaItem[];
  mediaLibraryId: string;
  mediaLibraryName: string;
  setIsNewFolderFormVisible: (isVisible: boolean) => void;
}

const IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'webp', 'gif'];

const MEDIA_FILES_CELL_TEST_HOOKS: Record<string, string> = {
  name: 't-nameCell',
  size: 't-sizeCell',
  path: 't-actionsCell',
};

interface CreateFolderFormProps {
  createFolder: (name: string) => Promise<void>;
  existingMediaFileNames: string[];
  setIsNewFolderFormVisible: (isVisible: boolean) => void;
}

const CreateFolderForm = ({
  createFolder,
  existingMediaFileNames,
  setIsNewFolderFormVisible,
}: CreateFolderFormProps) => (
  <tr className="border-b border-b-[#eaeaea] last:border-0">
    <td className="flex pt-5 pb-2 first:pl-7 last:pr-7">
      <Icons className="mt-[10px] mr-3 h-6 w-6 text-r42-blue" icon="folderFilled" />
      <Formik
        initialValues={{ name: '' }}
        onSubmit={values => {
          createFolder(values.name);
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .required(i18n.t('validation:validation.required'))
            .matches(/^[^?%*:|"<>.#\\]+$/, i18n.t('mediaLibraries:specialCharsAreNotAllowed'))
            .test(
              'media-file-name-is-unique',
              i18n.t('mediaLibraries:fileNameTakenAlready'),
              makeUniqueNameValidator(existingMediaFileNames),
            ),
        })}
      >
        {({ handleSubmit, errors, isValid }) => (
          <Form className="flex items-start gap-2">
            <Field
              as={FormikInputField}
              autoFocus
              className="w-96"
              errorText={errors.name}
              name="name"
              placeholder={i18n.t('mediaLibraries:folderName')}
              testHook="newFolderNameInput"
            />
            <BtnIcon
              className="mt-2"
              color="gray"
              icon="close"
              onClick={() => {
                setIsNewFolderFormVisible(false);
              }}
              testHook="newFolderCancelButton"
              tooltip={i18n.t('common:actions.cancel')}
            />
            <BtnIcon
              className="mt-2"
              color="blue"
              disabled={!isValid}
              icon="done"
              onClick={() => {
                handleSubmit();
              }}
              testHook="newFolderSumbitButton"
              tooltip={i18n.t('common:actions.create')}
            />
          </Form>
        )}
      </Formik>
    </td>
  </tr>
);

interface DeleteMediaItemModalProps {
  deleteFileOrFolder: (fullPath: string, isFolder: boolean) => Promise<void>;
  deleteModalMediaItem: MediaItem;
  setDeleteModalItemPath: (mediaItem: MediaItem | null) => void;
}

const DeleteMediaItemModal = ({
  deleteFileOrFolder,
  deleteModalMediaItem,
  setDeleteModalItemPath,
}: DeleteMediaItemModalProps) => (
  <DeleteModal
    closeModal={() => {
      setDeleteModalItemPath(null);
    }}
    onDelete={async () => {
      setDeleteModalItemPath(null);
      await deleteFileOrFolder(deleteModalMediaItem.fullPath, deleteModalMediaItem.folder);
    }}
    title={i18n.t('mediaLibraries:deleteModalHeader')}
  >
    {i18n.t('mediaLibraries:deleteAreYouSure', { name: deleteModalMediaItem.name })}
  </DeleteModal>
);

interface PreviewModalProps {
  previewModalUrl: string;
  setPreviewModalUrl: (url: string) => void;
}

const PreviewModal = ({ setPreviewModalUrl, previewModalUrl }: PreviewModalProps) => (
  <ModalWindow
    closeModal={() => {
      setPreviewModalUrl('');
    }}
    title={i18n.t('mediaLibraries:preview')}
  >
    {IMAGE_FILE_EXTENSIONS.includes(previewModalUrl.split('.').reverse()[0]) ? (
      <img src={previewModalUrl} className="max-h-full max-w-full overflow-auto object-contain" />
    ) : (
      <>
        <Notification className="mb-4">
          {i18n.t('mediaLibraries:noPreviewAvailable')}{' '}
          <a target="_blank" className="text-r42-blue" href={previewModalUrl}>
            {i18n.t('mediaLibraries:downloadIt')}
          </a>
          .
        </Notification>
      </>
    )}
  </ModalWindow>
);

const getActionsComponent =
  (hasAdservingEdit: boolean, mediaLibraryId: string, setDeleteModalMediaItem: (mediaItem: MediaItem) => void) =>
  ({ row }: { row: UseTableRowProps<MediaItem> }) =>
    (
      <div className={cx('flex gap-2', { 'pl-10': row.original.folder })}>
        {!row.original.folder && (
          <a
            href={`/assets/${getSiteId()}/download/medialibraries/${mediaLibraryId}?filepath=${row.original.path}`}
            target="_blank"
          >
            <BtnIcon
              color="gray"
              icon="download"
              testHook="downloadFileButton"
              tooltip={i18n.t('common:actions.download')}
            />
          </a>
        )}
        <BtnIcon
          color="gray"
          disabled={!hasAdservingEdit}
          icon="delete"
          onClick={async () => {
            setDeleteModalMediaItem(row.original);
          }}
          testHook={row.original.folder ? 'deleteFolderButton' : 'deleteFileButton'}
          tooltip={
            hasAdservingEdit ? i18n.t('common:actions.delete') : i18n.t('mediaLibraries:deleteMediaFileNoPermissions')
          }
        />
      </div>
    );

const MediaLibraryContentTable = ({
  createFolder,
  deleteFileOrFolder,
  hasAdservingEdit,
  isFolder,
  isNewFolderFormVisible,
  mediaFiles,
  mediaLibraryId,
  mediaLibraryName,
  setIsNewFolderFormVisible,
}: MediaLibraryContentTableProps): JSX.Element => {
  const [previewModalUrl, setPreviewModalUrl] = useState('');
  const [deleteModalMediaItem, setDeleteModalMediaItem] = useState<MediaItem | null>(null);

  const columns: Column<MediaItem>[] = React.useMemo(
    () => [
      {
        accessor: 'name',
        Cell: ({ row }: { row: UseTableRowProps<MediaItem> }) =>
          row.original.folder ? (
            <>
              <Icons className="mr-3 h-6 w-6 align-middle text-r42-blue" icon="folderFilled" />
              <Link
                href={getMediaLibraryOrFolderUrl(mediaLibraryId, [mediaLibraryName, ...row.original.path.split('/')])}
                wrapperClassName="w-[80%] overflow-hidden text-ellipsis align-middle"
              >
                {row.original.name}
              </Link>
            </>
          ) : (
            <>
              <Icons className="mr-3 h-6 w-6 align-middle text-r42-blue" icon="file" />
              <Link
                onClick={() => {
                  setPreviewModalUrl(
                    `/assets/${getSiteId()}/download/medialibraries/${mediaLibraryId}?filepath=${row.original.path}`,
                  );
                }}
                wrapperClassName="w-[80%] overflow-hidden text-ellipsis align-middle"
              >
                {row.original.name}
              </Link>
            </>
          ),
        Header: i18n.t('common:name'),
      },
      {
        accessor: 'size',
        Cell: ({ row }: { row: UseTableRowProps<MediaItem> }) =>
          row.original.folder ? null : fileSize(row.original.size),
        Header: i18n.t('mediaLibraries:size'),
      },
      {
        accessor: 'path',
        Cell: getActionsComponent(hasAdservingEdit, mediaLibraryId, setDeleteModalMediaItem),
        Header: i18n.t('mediaLibraries:actions'),
      },
    ],
    [deleteModalMediaItem, mediaLibraryId, hasAdservingEdit],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: mediaFiles,
    autoResetSortBy: false,
  });

  const existingMediaFileNames = mediaFiles.map(mediaFile => mediaFile.name);

  if (!mediaFiles.length) {
    return (
      <>
        <Notification kind="information" testHook="noMediaFiles">
          {isFolder ? i18n.t('mediaLibraries:folderEmpty') : i18n.t('mediaLibraries:mlEmpty')}{' '}
          {i18n.t('mediaLibraries:useButtonsToUploadAndCreate')}
        </Notification>
        {isNewFolderFormVisible && (
          <div className="rounded border border-gray-300 bg-white pl-8 pr-8">
            <table>
              <tbody>
                <CreateFolderForm
                  createFolder={createFolder}
                  existingMediaFileNames={existingMediaFileNames}
                  setIsNewFolderFormVisible={setIsNewFolderFormVisible}
                />
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="rounded border border-gray-300 bg-white pl-8 pr-8 pt-2 pb-2">
        <table {...getTableProps()} className="w-full table-fixed">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    className={cx(
                      'select-none border-b border-b-[#eaeaea] pt-5 pb-5 font-medium first:pl-7 last:pr-7',
                      { 'w-[150px]': ['size', 'path'].includes(column.id) },
                    )}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isNewFolderFormVisible && (
              <CreateFolderForm
                createFolder={createFolder}
                existingMediaFileNames={existingMediaFileNames}
                setIsNewFolderFormVisible={setIsNewFolderFormVisible}
              />
            )}
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={cx('t-mediaFilesItem border-b border-b-[#eaeaea] last:border-0', {
                    't-folder': row.original.folder,
                    't-file': !row.original.folder,
                  })}
                  key={row.original.fullPath}
                >
                  {row.cells.map(cell => (
                    <td
                      className={cx('pt-3 pb-3 first:pl-7 last:pr-7', {
                        [MEDIA_FILES_CELL_TEST_HOOKS[cell.column.id]]: MEDIA_FILES_CELL_TEST_HOOKS[cell.column.id],
                        'whitespace-nowrap': cell.column.id === 'name',
                      })}
                      key={cell.column.id}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {previewModalUrl && <PreviewModal previewModalUrl={previewModalUrl} setPreviewModalUrl={setPreviewModalUrl} />}
      {deleteModalMediaItem && (
        <DeleteMediaItemModal
          deleteFileOrFolder={deleteFileOrFolder}
          deleteModalMediaItem={deleteModalMediaItem}
          setDeleteModalItemPath={setDeleteModalMediaItem}
        />
      )}
    </>
  );
};

export default MediaLibraryContentTable;
