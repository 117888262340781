/**
 * @description Angular module for the customer journey trigger edit
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form.triggerEdit
 */
angular.module('webUi.ui.customer.journey.form.triggerEdit', [])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.customer.journeys.form.triggerEdit', {
                url: '/triggerEdit/{triggerId}',
                isReact: true
            });
    }]);
