import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import { Formik, Field } from 'formik';
import i18n from '~/common/formValidation/i18n';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validation:validation.required')),
  customerFileSource: Yup.string().required(i18n.t('validation:validation.required')),
});

const initialValues = {
  name: '',
  description: '',
  optOutUrl: '',
  customerFileSource: '',
};

const customerFileSourceOptions = [
  {
    value: 'PARTNER_PROVIDED_ONLY',
    label: i18n.t('audiences:connectors.facebook.audienceAdd.customerFileSource.partnerOnly'),
  },
  {
    value: 'USER_PROVIDED_ONLY',
    label: i18n.t('audiences:connectors.facebook.audienceAdd.customerFileSource.userOnly'),
  },
  {
    value: 'BOTH_USER_AND_PARTNER_PROVIDED',
    label: i18n.t('audiences:connectors.facebook.audienceAdd.customerFileSource.partnerAndUser'),
  },
];

const AddFacebookAudienceForm = ({ hideModal, createNewAudience, setFieldValue, t }) => {
  const handleAddAudience = ({ name, description, optOutUrl, customerFileSource }) => {
    createNewAudience(name, description, optOutUrl, customerFileSource, setFieldValue);
  };
  return (
    <Formik onSubmit={handleAddAudience} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit, errors, setFieldValue }) => (
        <>
          <Field
            name="name"
            as={FormikInputField}
            label={t('connectors.facebook.audienceAdd.nameLabel')}
            placeholder={t('connectors.facebook.audienceAdd.placeholder')}
            autoFocus={true}
            errorText={errors.name}
          />
          <Field
            name="description"
            as={FormikInputField}
            label={t('connectors.facebook.audienceAdd.descriptionLabel')}
            placeholder={t('connectors.facebook.audienceAdd.placeholder')}
            errorText={errors.description}
          />
          <Field
            name="customerFileSource"
            as={FormikSelectField}
            options={customerFileSourceOptions}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
            errorText={errors.customerFileSource}
            isClearable={true}
            label={t('connectors.facebook.audienceAdd.customerFileSourceLabel')}
            customSetFieldValue={(name, option) => {
              setFieldValue(name, option.value);
            }}
          />
          <Field
            name="optOutUrl"
            as={FormikInputField}
            label={t('connectors.facebook.audienceAdd.optOutUrlLabel')}
            placeholder={t('connectors.facebook.audienceAdd.placeholder')}
            errorText={errors.optOutUrl}
          />
          <ActionButtons
            onConfirm={handleSubmit}
            onDecline={hideModal}
            testHook="addFacebookAudienceFormButtons"
            className="mr-0"
          />
        </>
      )}
    </Formik>
  );
};

AddFacebookAudienceForm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default compose(translate('audiences'))(AddFacebookAudienceForm);
