/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { get, max, includes } from 'lodash';

/**
 * Get basescript by id
 * @param state
 * @param id
 */
export const getBasescriptById = (state, id) => state.context.basescripts.byId[id];

/**
 * Get all other basescripts
 * @param state
 * @param id
 */
export const getOtherBasescripts = (state, id) =>
  state.context.basescripts.allIds
    .filter(currentId => id !== currentId)
    .map(currentId => state.context.basescripts.byId[currentId]);

/**
 * Get All Basescripts
 * @param state
 */
export const getBasescripts = state => state.context.basescripts.allIds.map(id => state.context.basescripts.byId[id]);

/**
 * Normalize the basescript information
 * @param basescript
 */
export const getBasecriptInfo = basescript => {
  if (!basescript.modules) {
    basescript.modules = [];
  }
  if (basescript) {
    const basescriptInfo = {};
    basescriptInfo.basescriptId = basescript.basescriptId;
    basescriptInfo.prependScript = basescript.prependScript;
    basescriptInfo.version = basescript.version;
    basescriptInfo.cacheExpiryTimeSeconds = basescript.cacheExpiryTimeSeconds;
    basescript.modules.forEach(module => (basescriptInfo[module.basescriptModule.name] = module.version));
    return basescriptInfo;
  }
  return null;
};

/**
 * Get the basescripts in format needed for displaying in tables
 * @param state
 */
export const getBasecriptsTableInfo = state => getBasescripts(state).map(basescript => getBasecriptInfo(basescript));

/**
 * Should fetch all basescripts?
 * @param state
 */
export const shouldFetchBasescripts = state => get(state, 'context.basescripts.ui.shouldFetchBasescripts');

/**
 * Is fetching all basescripts currently in progress?
 * @param state
 */
export const isFetchingBasescripts = state => get(state, 'context.basescripts.ui.isFetchingBasescripts');

/**
 * Get All Basescript modules
 * @param state
 */
export const getBasescriptModules = state => state.context.basescripts.basescriptModules;

const getBasescriptModulesForSelect = state => {
  const basescriptModules = getBasescriptModules(state);
  if (!basescriptModules) {
    return [];
  }
  const basescriptModulesTransformed = [];
  basescriptModules.forEach(basescriptModule => {
    basescriptModulesTransformed.push({
      version: max(basescriptModule.possibleVersions),
      basescriptModule: {
        name: basescriptModule.module.name,
        label: basescriptModule.module.label,
      },
    });
  });
  return basescriptModulesTransformed;
};

/**
 * Returns a basescript prepopulated with default values
 * @param state
 */
export const getDefaultValues = state => {
  const modules = getBasescriptModulesForSelect(state);
  // if modules are not ready return null for initial values to avoid issues in refresh
  if (!modules.length) {
    return null;
  }
  return {
    version: '',
    prependScript: '',
    modules: getBasescriptModulesForSelect(state),
    cacheExpiryTimeSeconds: 14400,
  };
};

/**
 * Should fetch all basescriptModules?
 * @param state
 */
export const shouldFetchBasescriptModules = state => get(state, 'context.basescripts.ui.shouldFetchBasescriptModules');

/**
 * Is fetching all basescriptModules currently in progress?
 * @param state
 */
export const isFetchingBasescriptModules = state => get(state, 'context.basescripts.ui.isFetchingBasescriptModules');

export const getBasescriptsFilter = state => get(state, 'context.basescripts.ui.basescriptsFilter');

export const getSitesFilter = state => get(state, 'context.basescripts.ui.sitesFilter');

export const getLinkSitesToBasescriptFilter = state => get(state, 'context.basescripts.ui.linkSitesToBasescriptFilter');

const findBasescriptForSite = (state, siteId) =>
  getBasescripts(state).find(basescript =>
    includes(basescript.linkedToSites.map(linkedToSite => linkedToSite.siteId), siteId),
  );

/**
 * Get the site ids selected to use the basescript
 * @param state
 * @param basescriptId
 */
export const getSitesSelectedToUseBasescript = (state, basescriptId) =>
  state.context.basescripts.useByBasescriptId[basescriptId] || [];

/**
 * Get information for the sites and the corresponding basescripts
 * @param state
 * @param sites
 * @param basescriptId
 */
export const getSitesBasescriptTableInfo = (state, sites, basescriptId) => {
  const sitesSelectedForUsage = getSitesSelectedToUseBasescript(state, basescriptId);
  return sites.map(site => {
    const basescript = findBasescriptForSite(state, site.siteId);
    return {
      siteId: site.siteId,
      siteName: site.name,
      basescriptId: basescript ? basescript.basescriptId : null,
      basescriptVersion: basescript ? basescript.version : null,
      markedToUseBasescript: includes(sitesSelectedForUsage, site.siteId),
    };
  });
};
