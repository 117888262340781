import React from 'react';
import { useAPI } from '~/common/ApiHooks';
import api from '~/customer/journeys/dataService';
import Notification from '~/components/src/Notification';
import i18n from '~/i18n';

interface TransitionModalContentProps {
  hideModal: () => void;
  journeyId: string;
  refresh: () => Promise<void>;
  triggerId: string;
}

const TransitionModalContent = ({
  hideModal,
  journeyId,
  refresh,
  triggerId,
}: TransitionModalContentProps): JSX.Element => {
  const { data, isLoading } = useAPI(() => api.fetchAllowedTransitions(journeyId, triggerId));

  const allowedTransitionTargets = data || [];

  return (
    <div className="flex !max-h-[500px] min-h-[500px] flex-col">
      {!isLoading &&
        (allowedTransitionTargets.length ? (
          <Notification kind="information">
            {i18n.t('orchestration:common.modal.notAllStepsAreAvailableForTransition')}
          </Notification>
        ) : (
          <Notification>{i18n.t('orchestration:common.modal.noStepsAvailableForTransition')}</Notification>
        ))}
      <div className="my-2 flex flex-col gap-2 overflow-y-auto">
        {allowedTransitionTargets.map(step => (
          <div
            key={step.stepId}
            className="t-stepName cursor-pointer border border-transparent pr-2 pl-2 pt-1 pb-1 hover:rounded hover:border-gray-800"
            onClick={() => {
              api.addTransition(journeyId, triggerId, step.stepId).then(() => {
                refresh();
                hideModal();
              });
            }}
          >
            <span className="text-lg text-gray-600 hover:text-gray-800">{step.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransitionModalContent;
