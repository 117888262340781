import React, { ReactElement } from 'react';
import i18n from '~/i18n';
import Icons from '~/components/src/Icons';
import BtnIcon from '~/components/src/BtnIcon';
import { changeUrl } from '~/common';
import { Notification, Tooltip } from '~/components';
import { getConnectorLogoUrl } from '~/profiles/helpers/connectorsHelper';
import { FilterDependentType, FilterListType } from './types';

type FilterDependencyProps = {
  selectedFilter: FilterListType | null;
  handleClose: () => void;
};

function FilterDependencyList({ selectedFilter, handleClose }: FilterDependencyProps): ReactElement {
  if (!selectedFilter) return <></>;

  return (
    <div className="fixed left-0 top-16 flex h-full w-full justify-end bg-gray-400/50" onClick={() => handleClose()}>
      <div
        className="h-full w-1/3 overflow-y-auto border-l border-gray-200 bg-white"
        onClick={e => e.stopPropagation()}
      >
        <div className="sticky top-0">
          <div className="flex items-center justify-between border-b border-gray-200 bg-white p-6">
            <div className="flex flex-col gap-2">
              <p className="text-gray-400">{i18n.t('filters:dependencies.title')}</p>
              <p className="t-filterDependencyName text-xl font-medium text-gray-600">{selectedFilter.name}</p>
            </div>
            <BtnIcon
              icon="close"
              className="h-6 w-6"
              tooltip={i18n.t('filters:dependencies.close')}
              onClick={() => handleClose()}
              testHook="closeDependentsPanel"
            />
          </div>
          <div className="sticky flex items-center gap-2 bg-gray-100 p-6">
            <Icons icon="connector" className="h-6 w-6 rounded bg-gray-50 text-gray-600" />
            <p className="font-medium text-gray-600">{i18n.t('filters:dependencies.partners')}</p>
          </div>
        </div>

        <div>
          {selectedFilter.dependants.length === 0 && (
            <Notification kind="information">{i18n.t('filters:dependencies.noPartners')}</Notification>
          )}
          {selectedFilter.dependants.map((dependent: FilterDependentType) => (
            <div
              className="t-filterDependant flex items-center gap-2 border-b border-gray-100 px-4 py-2 hover:bg-gray-50"
              key={dependent.id}
              onClick={() => changeUrl(`profiles/partners/list/eventStats/${dependent.id}`)}
            >
              <img
                alt={dependent.name}
                className="!h-10 !w-10"
                src={getConnectorLogoUrl(dependent.id, dependent.partnerType, true) || ''}
              />
              <div className="flex cursor-pointer flex-col gap-1 ">
                <Tooltip tooltip={i18n.t('filters:messages.viewConnector')}>
                  <p className="t-partnerName text-gray-600">{dependent.name}</p>
                  <p className="t-partnerType text-xs text-gray-400">{dependent.partnerType}</p>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FilterDependencyList;
