import React, { useMemo } from 'react';
import cx from 'classnames';
import { matchSorter } from 'match-sorter';
import moment from 'moment';
import i18n from '~/i18n';
import { formatNumber } from '~/common';
import { components } from '~/components/src/Table';
import { getDate, getTime } from '~/common/utils/DateUtil';
import UsageIcons from '~/profiles/components/UsageIcons';
import { DATE_FORMAT, HP_STATUS, PUBLISHED } from '~/customer/components/constants';
import SearchElement from '~/components/src/Form/Elements/SearchElement';
import FieldWrapper from '~/components/src/Form/Fields/FieldWrapper';
import Icons from '~/components/src/Icons';
import { SelectColumnFilter, NumberRangeColumnFilter, DateColumnFilter, MultiSelectColumnFilter } from '~/components';
import { Row, UseFiltersColumnProps, Column, UseTableRowProps } from 'react-table';
import icons from '~/components/src/Icons/icons';
import LabelSelect from '~/components/src/LabelSelect';
import { PropsForColumns, TableJourney, FilteringFunction, Journey, HistoricProcessing } from './types';

const { RowActions } = components;

const fuzzyTextFilterFn: FilteringFunction<TableJourney> = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, { keys: [(row: Row<TableJourney>) => row.values[id]] });

fuzzyTextFilterFn.autoRemove = val => !val;

const dateBetweenFilterFn: FilteringFunction<TableJourney> = (rows, id, filterValues) => {
  const sd = moment(filterValues[0]);
  const ed = moment(filterValues[1]);

  return rows.filter(r => moment(r.values[id]).isBetween(sd, ed));
};

dateBetweenFilterFn.autoRemove = val => !val;

const textFilterFn: FilteringFunction<TableJourney> = (rows, id, filterValue) =>
  rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
  });

interface DefaultColumnFilterProps {
  column: UseFiltersColumnProps<TableJourney>;
}

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }: DefaultColumnFilterProps) {
  const count = preFilteredRows.length;

  return (
    <FieldWrapper label={i18n.t('common:tableColumns.journeyName')}>
      <SearchElement
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} journeys...`}
      />
    </FieldWrapper>
  );
}

const getHPIconData = (hpStatus: string): { icon: keyof typeof icons; iconClass: string } => {
  switch (hpStatus.toUpperCase()) {
    case HP_STATUS.FAILED:
      return { icon: 'tagError', iconClass: 'text-red-500' };
    case HP_STATUS.SUCCESSFUL:
      return { icon: 'done', iconClass: 'text-green-500' };
    case HP_STATUS.RUNNING:
      return { icon: 'hourglass', iconClass: 'text-yellow-600' };
    case HP_STATUS.SCHEDULED:
      return { icon: 'schedule', iconClass: 'text-yellow-600' };
    case HP_STATUS.CANCELLING:
      return { icon: 'retract', iconClass: 'text-yellow-600' };
    case HP_STATUS.CANCELLED:
      return { icon: 'success', iconClass: 'text-yellow-500' };
    case HP_STATUS.CANCELLATION_FAILED:
      return { icon: 'unpublished', iconClass: 'text-red-600' };
    default:
      return { icon: 'minus', iconClass: 'text-gray-200' };
  }
};

const HPStatus = ({ hpData }: { hpData: HistoricProcessing }) => {
  const iconData = getHPIconData(hpData?.state?.name || '');
  const tooltip = (
    <>
      {i18n.t('journey:common.hpStatus')}: <b>{hpData?.state?.label}</b>
      <br />
      {i18n.t('journey:common.hpLastRun')}: {moment(hpData?.triggeredOn).format(DATE_FORMAT)}
    </>
  );

  return (
    <div className="mr-4 text-end">
      <Icons
        icon={iconData.icon}
        tooltip={hpData?.state?.label ? tooltip : ''}
        className={cx('t-hpStatus', iconData.iconClass, 'h-10 w-10 rounded p-2')}
      />
    </div>
  );
};

const NumberCell = (val?: number) => <p className="JOList-row--number">{formatNumber(val || 0)}</p>;

const columns = (props: PropsForColumns): Column<TableJourney>[] => {
  const { itemUrl, onDependantIconClick, onDeleteClick, labels, updateJourneyLabels, onDeleteLabel } = props;

  return useMemo(
    () => [
      {
        Header: i18n.t('common:tableColumns.journeyName'),
        accessor: 'journeyName',
        colspan: 6,
        width: 300,
        Filter: DefaultColumnFilter,
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => (
          <div className="StatusIndicator">
            <span className={`StatusIndicator--${row?.original?.status === PUBLISHED ? 'published' : 'unpublished'}`} />

            <a className="Link" href={itemUrl(row?.original?.journeyId)}>
              {row?.values.journeyName}
            </a>
          </div>
        ),
      },
      {
        Header: i18n.t('common:tableColumns.status'),
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: i18n.t('common:tableColumns.labels'),
        accessor: 'labels',
        disableSortBy: true,
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        colspan: 6,
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => (
          <LabelSelect
            options={labels}
            labels={row?.values.labels}
            updateJourneyLabels={updateJourneyLabels}
            journeyId={row?.original?.journeyId}
            isUpdatingLabels={row?.original?.isUpdatingLabels}
            onDeleteLabel={onDeleteLabel}
            testHook="journeyLabelSelect"
          />
        ),
        width: 450,
      },
      {
        Header: () => <span className="JOList-row--number">{i18n.t('common:tableColumns.entered')}</span>,
        accessor: 'entered',
        className: 'JOList-cell--textEnd',
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => NumberCell(row?.values?.entered),
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        colspan: 2,
      },
      {
        Header: <span className="JOList-row--number">{i18n.t('common:tableColumns.active')}</span>,
        accessor: 'active',
        className: 'JOList-cell--textEnd',
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => NumberCell(row?.values?.active),
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        colspan: 2,
      },
      {
        Header: <span className="JOList-row--number">{i18n.t('common:tableColumns.exited')}</span>,
        accessor: 'exited',
        className: 'JOList-cell--textEnd',
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => NumberCell(row?.values?.exited),
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        colspan: 2,
      },
      {
        Header: <span className="JOList-row--number">{i18n.t('common:tableColumns.reached')}</span>,
        accessor: 'reached',
        className: 'JOList-cell--textEnd',
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => NumberCell(row?.values?.reached),
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        colspan: 2,
      },
      {
        Header: <p className="JOList-cell--textEnd">{i18n.t('common:tableColumns.historicProcessingStatus')}</p>,
        accessor: 'historicProcessingStatus',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
        colspan: 2,
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => (
          <HPStatus hpData={row?.original?.historicProcessing}></HPStatus>
        ),
      },
      {
        Header: () => <p className="JOList-row--textCenter">{i18n.t('common:tableColumns.usedIn')}</p>,
        accessor: 'usedIn',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => (
          <div className="JOList-row--usage">
            <UsageIcons
              dependantTypes={row.values.usedIn ? ['JOURNEY'] : []}
              elementUsedIn={['JOURNEY']}
              elementId={row?.original?.journeyId}
              onDependantIconClick={onDependantIconClick}
            />
          </div>
        ),
        colspan: 2,
      },
      {
        Header: i18n.t('common:tableColumns.lastEdit'),
        accessor: 'lastEdit',
        colspan: 2,
        Filter: DateColumnFilter,
        filter: 'dateBetween',
        Cell: ({ row }: { row: UseTableRowProps<TableJourney> }) => (
          <span className="JOList-row--lastEdit">
            <span className="JOList-row--lastEdit-date">{getDate(row.values.lastEdit)}</span>
            <span className="JOList-row--lastEdit-time">{getTime(row.values.lastEdit)}</span>
          </span>
        ),
      },
      {
        accessor: 'actions',
        width: 100,
        disableSortBy: true,
        disableFilters: true,
        colspan: 1,
        Cell: ({ row }: any) => {
          const actions = [
            {
              name: i18n.t('common:actions.statistics'),
              tooltip: i18n.t('common:actions.statistics'),
              isDisabled: false,
              onClick: () => props.redirectToStatisticsPage(row?.original?.journeyId),
              testHook: 'statistics',
            },
            {
              name: i18n.t('common:actions.edit'),
              tooltip: i18n.t('common:actions.edit'),
              isDisabled: false,
              onClick: () => props.redirectToEditPage(row?.original?.journeyId),
              testHook: 'edit',
            },
            {
              name: i18n.t('common:actions.delete'),
              tooltip: i18n.t('common:actions.delete'),
              isDisabled: !props.canEditCustomerJourney,
              onClick: () => onDeleteClick(row?.original, i18n.t.bind(i18n)),
              testHook: 'delete',
              type: 'delete',
            },
          ];
          return (
            <div className="JOList-cell--textEnd">
              <RowActions actions={actions} />
            </div>
          );
        },
      },
    ],
    [labels],
  );
};

const data = (list: Journey[]): TableJourney[] =>
  React.useMemo(
    () =>
      list
        ?.map(journey => ({
          journeyName: journey.name,
          status: journey?.state?.label,
          historicProcessing: journey?.historicProcessing || {},
          historicProcessingStatus: journey?.historicProcessing?.state?.label || '-',
          lastEdit: journey.lastModified,
          usedIn: journey.isUsedInJourneys,
          journeyId: journey.journeyId,
          entered: journey.profilesEntered,
          active: journey.activeProfiles,
          exited: journey.profilesExited,
          reached: journey.goalsReached,
          labels: journey.labels,
          isUpdatingLabels: journey.isUpdatingLabels,
        }))
        .concat([]),
    [list],
  );

export { columns, data, fuzzyTextFilterFn, SelectColumnFilter, dateBetweenFilterFn, textFilterFn };
