import React, { useContext, useEffect, useState } from 'react';
import { FormikErrors, FormikHelpers, useFormikContext } from 'formik';
import cx from 'classnames';
import { get, set } from 'lodash';
import i18n from '~/i18n';
import PickerView from '~/components/src/PickerView';
import InputElement from '~/components/src/Form/Elements/InputElement';
import SelectElement from '~/components/src/Form/Elements/SelectElement';
import DateValidator from '~/components/src/DateValidator';
import BtnIcon from '~/components/src/BtnIcon';
import Btn from '~/components/src/Btn';
import { TransformationContext } from '../context';
import {
  OptionType,
  ValueTransformationProps,
  DateFormatValueTransformation,
  DateToEpochSecondsValueTransformation,
  EpochSecondsToDateValueTransformation,
  HashingValueTransformation,
  StringToArrayValueTransformation,
  ValueMappingValueTransformation,
  ValueTransformationType,
} from './types';
import { TransformationPayload } from '../types';
import { VALUEMAPPING_DEFAULT_OPTION, hashingOptions } from '../core/constants';

const DateFormat = ({
  valueTransformation,
  valueTransformationPath,
  isReadOnly,
  errors,
  setFieldValue,
}: {
  valueTransformation: DateFormatValueTransformation;
  valueTransformationPath: string;
  isReadOnly: boolean;
  errors: FormikErrors<TransformationPayload>;
  setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'];
}) => {
  const [isEventPickerVisible, setIsEventPickerVisible] = useState(false);
  const [fieldName, setFieldName] = useState<string | null>(null);
  const handleDateValidationPicker = (fName: string) => {
    setIsEventPickerVisible(true);
    setFieldName(fName);
  };
  return (
    <>
      <div className="flex items-center px-1">
        <InputElement
          id={`${valueTransformationPath}.inputFormat`}
          value={valueTransformation.inputFormat}
          hasError={!!get(errors, `${valueTransformationPath}.inputFormat`)}
          error={get(errors, `${valueTransformationPath}.inputFormat`)}
          placeholder={i18n.t('transformations:form.valueMappings.inputFormat')}
          onChange={event => setFieldValue(`${valueTransformationPath}.inputFormat`, event.target.value)}
          enableAction
          buttonIcon="calendar"
          buttonTestHook="inputCalendarButton"
          onToggleButton={() => handleDateValidationPicker('inputFormat')}
          testHook="inputFormatInputElement"
          disabled={isReadOnly}
        />
      </div>
      <div className="flex items-center px-1">
        <InputElement
          id={`${valueTransformationPath}.outputFormat`}
          value={valueTransformation.outputFormat}
          placeholder={i18n.t('transformations:form.valueMappings.outputFormat')}
          hasError={!!get(errors, `${valueTransformationPath}.outputFormat`)}
          error={get(errors, `${valueTransformationPath}.outputFormat`)}
          onChange={event => setFieldValue(`${valueTransformationPath}.outputFormat`, event.target.value)}
          enableAction
          buttonIcon="calendar"
          buttonTestHook="outputCalendarButton"
          onToggleButton={() => handleDateValidationPicker('outputFormat')}
          testHook="outputFormatInputElement"
          disabled={isReadOnly}
        />
      </div>
      {isEventPickerVisible && !isReadOnly && (
        <PickerView
          className="mt-2 p-4"
          handlePickerVisibility={setIsEventPickerVisible}
          pickerTitle={i18n.t('forms:dateFormat.controls.label')}
        >
          <DateValidator
            initialValue={valueTransformation[fieldName || '']}
            onChange={value => {
              setFieldValue(`${valueTransformationPath}.${fieldName}`, value);
              setIsEventPickerVisible(false);
            }}
          />
        </PickerView>
      )}
    </>
  );
};

const DateToEpochSeconds = ({
  valueTransformation,
  valueTransformationPath,
  isReadOnly,
  errors,
  setFieldValue,
}: {
  valueTransformation: DateToEpochSecondsValueTransformation;
  valueTransformationPath: string;
  isReadOnly: boolean;
  errors: FormikErrors<TransformationPayload>;
  setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'];
}) => (
  <div className="flex items-center px-1">
    <InputElement
      id={`${valueTransformationPath}.inputFormat`}
      value={valueTransformation.inputFormat}
      hasError={!!get(errors, `${valueTransformationPath}.inputFormat`)}
      error={get(errors, `${valueTransformationPath}.inputFormat`)}
      placeholder={i18n.t('transformations:form.valueMappings.format')}
      onChange={event => setFieldValue(`${valueTransformationPath}.inputFormat`, event.target.value)}
      disabled={isReadOnly}
    />
  </div>
);

const EpochSecondsToDate = ({
  valueTransformation,
  valueTransformationPath,
  isReadOnly,
  errors,
  setFieldValue,
}: {
  valueTransformation: EpochSecondsToDateValueTransformation;
  valueTransformationPath: string;
  isReadOnly: boolean;
  errors: FormikErrors<TransformationPayload>;
  setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'];
}) => (
  <>
    <div className="flex items-center px-1">
      <InputElement
        id={`${valueTransformationPath}.dateFormat`}
        value={valueTransformation.dateFormat}
        hasError={!!get(errors, `${valueTransformationPath}.dateFormat`)}
        error={get(errors, `${valueTransformationPath}.dateFormat`)}
        placeholder={i18n.t('transformations:form.valueMappings.format')}
        onChange={event => setFieldValue(`${valueTransformationPath}.dateFormat`, event.target.value)}
        disabled={isReadOnly}
      />
    </div>
    <div className="flex items-center px-1">
      <InputElement
        id={`${valueTransformationPath}.offset`}
        value={valueTransformation.offset}
        hasError={!!get(errors, `${valueTransformationPath}.offset`)}
        error={get(errors, `${valueTransformationPath}.offset`)}
        placeholder={i18n.t('transformations:form.valueMappings.offset')}
        onChange={event => setFieldValue(`${valueTransformationPath}.offset`, event.target.value)}
        disabled={isReadOnly}
      />
    </div>
  </>
);

const Hashing = ({
  valueTransformation,
  valueTransformationPath,
  isReadOnly,
  errors,
  setFieldValue,
}: {
  valueTransformation: HashingValueTransformation;
  valueTransformationPath: string;
  isReadOnly: boolean;
  errors: FormikErrors<TransformationPayload>;
  setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'];
}) => (
  <div className="flex w-[50%] items-center px-1">
    <SelectElement
      className="w-full"
      name={`${valueTransformationPath}.algorithm`}
      value={valueTransformation.algorithm}
      placeholder={i18n.t('transformations:form.valueMappings.algorithm')}
      hasError={!!get(errors, `${valueTransformationPath}.algorithm`)}
      error={get(errors, `${valueTransformationPath}.algorithm`)}
      options={hashingOptions}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.value}
      onChange={(selectedOption: OptionType) =>
        setFieldValue(`${valueTransformationPath}.algorithm`, selectedOption.value)
      }
      disabled={isReadOnly}
      menuPosition="fixed"
    />
  </div>
);

const StringToArray = ({
  valueTransformation,
  valueTransformationPath,
  isReadOnly,
  errors,
  setFieldValue,
}: {
  valueTransformation: StringToArrayValueTransformation;
  valueTransformationPath: string;
  isReadOnly: boolean;
  errors: FormikErrors<TransformationPayload>;
  setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'];
}) => (
  <div className="flex items-center px-1">
    <InputElement
      id={`${valueTransformationPath}.delimiter`}
      value={valueTransformation.delimiter}
      hasError={!!get(errors, `${valueTransformationPath}.delimiter`)}
      error={get(errors, `${valueTransformationPath}.delimiter`)}
      placeholder={i18n.t('transformations:form.valueMappings.delimiter')}
      onChange={event => setFieldValue(`${valueTransformationPath}.delimiter`, event.target.value)}
      disabled={isReadOnly}
    />
  </div>
);

const ValueMapping = ({
  valueTransformation,
  valueTransformationPath,
  isReadOnly,
  errors,
  setFieldValue,
}: {
  valueTransformation: ValueMappingValueTransformation;
  valueTransformationPath: string;
  isReadOnly: boolean;
  errors: FormikErrors<TransformationPayload>;
  setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'];
}) => {
  const [enableDefaultMapping, setEnableDefaultMapping] = useState(!!valueTransformation?.defaultMapping?.type);
  useEffect(() => {
    if (!valueTransformation.valueMappings || valueTransformation.valueMappings?.length === 0) {
      setFieldValue(`${valueTransformationPath}.valueMappings`, [
        {
          from: '',
          to: '',
        },
      ]);
    }
  }, []);
  return (
    <div>
      {valueTransformation?.valueMappings?.map(({ from, to }, valueMappingIndex) => (
        <div className="flex gap-2 py-4" key={valueMappingIndex}>
          <div className="flex items-center px-1">
            <InputElement
              id={`${valueTransformationPath}.valueMappings[${valueMappingIndex}].from`}
              value={from}
              hasError={!!get(errors, `${valueTransformationPath}.valueMappings[${valueMappingIndex}].from`)}
              error={get(errors, `${valueTransformationPath}.valueMappings[${valueMappingIndex}].from`)}
              placeholder={i18n.t('transformations:form.valueMappings.from')}
              onChange={event => {
                const valueMappings = valueTransformation.valueMappings || [];
                set(valueMappings, `[${valueMappingIndex}].from`, event.target.value);
                setFieldValue(`${valueTransformationPath}.valueMappings`, valueMappings);
              }}
              disabled={isReadOnly}
            />
          </div>
          <div className="flex items-center px-1">
            <InputElement
              id={`${valueTransformationPath}.valueMappings[${valueMappingIndex}].to`}
              value={to}
              hasError={!!get(errors, `${valueTransformationPath}.valueMappings[${valueMappingIndex}].to`)}
              error={get(errors, `${valueTransformationPath}.valueMappings[${valueMappingIndex}].to`)}
              placeholder={i18n.t('transformations:form.valueMappings.to')}
              onChange={event => {
                const valueMappings = valueTransformation.valueMappings || [];
                set(valueMappings, `[${valueMappingIndex}].to`, event.target.value);
                setFieldValue(`${valueTransformationPath}.valueMappings`, valueMappings);
              }}
              disabled={isReadOnly}
            />
          </div>
          <div
            className={cx('flex items-center', {
              invisible: valueTransformation.valueMappings.length <= 1,
            })}
          >
            {!isReadOnly && (
              <BtnIcon
                icon="delete"
                tooltip={i18n.t('transformations:tooltips.delete')}
                onClick={() => {
                  const valueMappings = valueTransformation.valueMappings || [];
                  valueMappings.splice(valueMappingIndex, 1);
                  setFieldValue(`${valueTransformationPath}.valueMappings`, valueMappings);
                }}
              />
            )}
          </div>
        </div>
      ))}
      {enableDefaultMapping && (
        <div className="my-4 flex w-full flex-col gap-2 rounded-md border border-gray-200 bg-gray-50 p-2">
          <div className="flex justify-between">
            <label>{i18n.t('transformations:form.default')}</label>
            <BtnIcon
              className="h-6 w-6 bg-transparent"
              icon="close"
              tooltip={i18n.t('transformations:tooltips.deleteDefaultMapping')}
              onClick={() => {
                setEnableDefaultMapping(false);
                setFieldValue(`${valueTransformationPath}.defaultMapping`, undefined);
              }}
            />
          </div>
          <div className="flex gap-2">
            <div className="flex-1">
              <SelectElement
                id={`${valueTransformationPath}.defaultMapping.type`}
                defaultValue={VALUEMAPPING_DEFAULT_OPTION[0]}
                options={VALUEMAPPING_DEFAULT_OPTION}
                value={valueTransformation?.defaultMapping?.type}
                onChange={(mapping: { label: string; value: string }) => {
                  setFieldValue(`${valueTransformationPath}.defaultMapping.type`, mapping.value);
                  if (mapping.value === VALUEMAPPING_DEFAULT_OPTION[1].value) {
                    setFieldValue(
                      `${valueTransformationPath}.defaultMapping.value`,
                      valueTransformation?.defaultMapping?.value || '',
                    );
                  }
                }}
                disabled={isReadOnly}
              />
            </div>
            {valueTransformation?.defaultMapping?.type === VALUEMAPPING_DEFAULT_OPTION[1].value && (
              <InputElement
                id={`${valueTransformationPath}.defaultMapping.value`}
                value={valueTransformation?.defaultMapping?.value}
                hasError={!!get(errors, `${valueTransformationPath}.defaultMapping.value`)}
                error={get(errors, `${valueTransformationPath}.defaultMapping.value`)}
                placeholder={i18n.t('transformations:form.valueMappings.to')}
                onChange={event => {
                  setFieldValue(`${valueTransformationPath}.defaultMapping.value`, event.target.value);
                }}
                disabled={isReadOnly}
              />
            )}
          </div>
        </div>
      )}
      {!isReadOnly && (
        <div className="flex gap-2 px-1 py-2">
          <Btn
            size="xs"
            icon="add"
            color="blue"
            testHook="addValueMapping"
            onClick={() => {
              const valueMappings = valueTransformation.valueMappings || [];
              valueMappings.push({
                from: '',
                to: '',
              });

              setFieldValue(`${valueTransformationPath}.valueMappings`, valueMappings);
            }}
          >
            Add custom value mapping
          </Btn>
          {!enableDefaultMapping && (
            <Btn
              size="xs"
              icon="add"
              testHook="adddefaultValueMapping"
              onClick={() => {
                setEnableDefaultMapping(true);
                setFieldValue(`${valueTransformationPath}.defaultMapping.type`, VALUEMAPPING_DEFAULT_OPTION[0].value);
                setFieldValue(`${valueTransformationPath}.defaultMapping.value`, '');
              }}
            >
              {i18n.t('transformations:form.valueMappings.default')}
            </Btn>
          )}
        </div>
      )}
    </div>
  );
};

const getRenderedElement = (
  valueTransformation: ValueTransformationType,
  valueTransformationPath: string,
  isReadOnly: boolean,
  errors: FormikErrors<TransformationPayload>,
  setFieldValue: FormikHelpers<TransformationPayload>['setFieldValue'],
) => {
  switch (valueTransformation.transformationType) {
    case 'DATE_FORMAT':
      return (
        <DateFormat
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          isReadOnly={isReadOnly}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      );
    case 'DATE_TO_EPOCH_SECONDS':
      return (
        <DateToEpochSeconds
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          isReadOnly={isReadOnly}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      );
    case 'EPOCH_SECONDS_TO_DATE':
      return (
        <EpochSecondsToDate
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          isReadOnly={isReadOnly}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      );
    case 'HASHING':
      return (
        <Hashing
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          isReadOnly={isReadOnly}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      );

    case 'STRING_TO_ARRAY':
      return (
        <StringToArray
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          isReadOnly={isReadOnly}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      );

    case 'VALUE_MAPPING':
      return (
        <ValueMapping
          valueTransformation={valueTransformation}
          valueTransformationPath={valueTransformationPath}
          isReadOnly={isReadOnly}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      );
  }

  return null;
};

const ValueTransformation = ({
  valueTransformation,
  remove,
  valueTransformationPath,
  index,
  isReadOnly,
}: ValueTransformationProps): JSX.Element => {
  const form = useFormikContext();
  const { setFieldValue, errors } = form;

  const valueTransformationElement = getRenderedElement(
    valueTransformation,
    valueTransformationPath,
    isReadOnly,
    errors,
    setFieldValue,
  );
  const { valTransforms } = useContext(TransformationContext);

  return (
    <div className="flex items-center py-3">
      <div className="w-[5%] text-center">{index + 1}</div>
      <div className="ml-2 w-[30%] flex-1 items-center">
        <SelectElement
          id={`${valueTransformationPath}.transformationType`}
          className="w-full"
          value={valueTransformation.transformationType}
          options={valTransforms || []}
          getOptionValue={option => option.transformationType}
          getOptionLabel={option => option.title}
          disabled={isReadOnly}
          onChange={selectedOption =>
            setFieldValue(`${valueTransformationPath}.transformationType`, selectedOption.transformationType)
          }
          menuPosition="fixed"
        />
      </div>
      <div className="flex flex-[2] items-center pl-2">{valueTransformationElement}</div>
      {!isReadOnly && (
        <BtnIcon
          className={cx('h-6 w-6')}
          icon="close"
          tooltip={i18n.t('transformations:tooltips.delete')}
          onClick={remove}
        />
      )}
    </div>
  );
};

export default ValueTransformation;
