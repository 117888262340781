import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { useAPI } from '~/common';
import { truncateString } from '~/common/utils/StringUtils';
import { DateRangePicker, StackedChart, Tooltip } from '~/components';
import { Panel } from '~/components/src/Containers';
import Icons from '~/components/src/Icons';
import Spin from '~/components/src/Spin';
import { Tab, TabList, Tabs } from '~/components/src/Tabs';
import DataExchangeGraph from '~/profiles/components/DataExchangeGraph/DataExchangeGraph';
import { fetchStepData, fetchStepStats } from '~/workflows/dataService';
import { TStepData } from '~/workflows/types';
import { ORIGIN_TYPES, VARIANT_STATS_TABS } from '../constants';
import { mapStepStats } from '../util';

const defaultDate = {
  startDate: moment().subtract(6, 'days').startOf('day'),
  endDate: moment().endOf('day'),
};

const VariantNodes = ({
  allSteps,
  selectedStep,
  setSelectedStep,
}: {
  allSteps: TStepData[];
  selectedStep: string;
  setSelectedStep: (id: string) => void;
}) => (
  <div className="flex">
    {allSteps?.map(item => (
      <div
        key={item.nodeId}
        className={twMerge(
          cx('t-experimentStep mb-5 mr-2 h-14 w-32 cursor-pointer flex-col rounded-lg border border-gray-300 p-2', {
            'border-blue-500': selectedStep === item.nodeId,
          }),
        )}
        onClick={() => setSelectedStep(item.nodeId)}
      >
        <div className="flex justify-between">
          <Tooltip tooltip={item.label}>
            <span
              className={twMerge(
                cx('text-gray-600', {
                  'text-blue-600': selectedStep === item.nodeId,
                }),
              )}
            >
              {truncateString(item.label, 12)}
            </span>
          </Tooltip>
        </div>
        <div className="t-profilesActive flex items-center gap-2">
          <span className="text-sm text-gray-400">Profiles:</span>
          {item.profilesActive}
        </div>
      </div>
    ))}
  </div>
);

const ConnectorStats = ({ workflowId, allSteps }: { workflowId: string; allSteps: TStepData[] }) => {
  const [selectedStep, setSelectedStep] = useState(allSteps[0].nodeId);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [dateRange, setDateRange] = useState({
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
  });

  const { startDate, endDate } = dateRange;

  const origin = selectedTabIndex ? ORIGIN_TYPES.HISTORIC_PROCESSING : undefined;

  const { data: stepStats, isLoading: isStepStatsLoading } = useAPI(() => {
    if (selectedStep)
      return fetchStepStats(
        workflowId,
        selectedStep,
        startDate.startOf('day').toDate().getTime(),
        endDate.endOf('day').toDate().getTime(),
        origin,
      );
    return Promise.resolve(null);
  }, [workflowId, selectedStep, selectedTabIndex, startDate, endDate]);

  const { data: stepDetails, isLoading: isStepDetailsLoading } = useAPI(() => {
    if (selectedStep) return fetchStepData(selectedStep);
    return Promise.resolve(null);
  }, [workflowId, selectedStep]);

  const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setDateRange({
      startDate,
      endDate,
    });
  };

  return (
    <div className="flex flex-col gap-2 pt-4">
      {allSteps && allSteps.length > 1 && (
        <VariantNodes allSteps={allSteps || []} selectedStep={selectedStep} setSelectedStep={setSelectedStep} />
      )}

      <div>
        <div className="absolute right-4 mt-7">
          <DateRangePicker
            testHook="stepStatsDatePicker"
            startDate={startDate}
            endDate={endDate}
            handleDatesChange={handleDatesChange}
          />
        </div>
        <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
          <TabList>
            {VARIANT_STATS_TABS.map(origin => (
              <Tab key={origin.name} testHook={`${origin.name}-tab`}>
                <span className="flex items-center gap-2">
                  {origin.label}
                  {origin?.description && (
                    <Icons icon="info" tooltip={origin.description} className="h-4 w-4 text-gray-500" />
                  )}
                </span>
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Panel className="mt-7">
          {isStepStatsLoading ? (
            <Spin />
          ) : (
            <StackedChart
              hideGenealInfo
              className="t-stepStatisticsChart"
              data={mapStepStats(stepStats || [], origin)}
            />
          )}
        </Panel>
      </div>

      <div className="">
        {isStepDetailsLoading || !stepDetails ? (
          <Spin />
        ) : (
          <DataExchangeGraph
            integrations={stepDetails.integrations}
            sourceId={stepDetails.stepId}
            type="orchestration"
          />
        )}
      </div>
    </div>
  );
};

export default ConnectorStats;
