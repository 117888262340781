import Api, { API_BASE, parseResponse } from '~/common/ApiService';
import { changeUrl } from '~/common';
import i18n from '~/i18n';
import {
  addFakeDataForJourney,
  getFakeStepStats,
  getFakeTotalStatsForStep,
  getFakeTriggerData,
  makeFake,
  getFakeVersions,
  getFakeTrees,
  getFakeGoalStats,
  demoJourneyListData,
  demoJourneydetails,
  getFakeJourneyStats,
  demoJourneyId,
} from '~/customer/fake';
import { JourneyContents } from '~/customer/components/JourneyHeader/types';
import { Journey, JourneyMeta, JourneyVersion, Label } from '~/customer/components/JOList/types';
import { FallbackJson, WebRestResponse } from '~/common/types';
import {
  CreateGoalPayload,
  CreateStartTriggerPayload,
  CreateTriggerPayload,
  DependantJourney,
  JourneyValidationError,
  OverlapCandidate,
  Step,
  StepDailyStats,
  StepDailyStatsOrigin,
  TotalStepStats,
  Trigger,
  GoalStats,
  GoalDailyStats,
  UpdateExperimentsPayload,
} from './types';
import treeMock from './treeMock';
import treeMockFedX from './treeMockFedX';
import { StatsResponse } from '../pages/JourneyStatsView/types';
import { HPJobDetails, HPReprocessingModeResponse, TriggerHpPayloadType } from '../pages/HistoricProcessing/types';
import { isJoDemoMode } from '../fake/utils';
import demoJourney from '../fake/demoJourney';
import demoGoalStats from '../fake/demoGoalStats';
import demoJourneyStats from '../fake/demoJourneyStats';
import demoGoalGraph from '../fake/demoGoalGraph';

const BASE_PATH = `${API_BASE}/customer/journeys`;
const NEW_BASE_PATH = `${API_BASE}/journeyorchestration`;

export const JO_PAGINATION_DEFAULT_SIZE = 100;

export const fetchJourneys = (page = 0): Promise<Journey[]> =>
  Api.callGet(`${NEW_BASE_PATH}/journeys?page=${page}&size=${JO_PAGINATION_DEFAULT_SIZE}`)
    .then(parseResponse)
    .then(data => {
      const content = isJoDemoMode() ? [demoJourneyListData, ...data.content] : data.content;
      return { ...data, content };
    });

export const fetchJourneyLabels = (): Promise<Label[]> => Api.callGet(`${API_BASE}/labels`).then(parseResponse);

export const updateJourneyLabels = (journeyId: string, payload: string[]): Promise<Label[]> =>
  Api.callPut(`${NEW_BASE_PATH}/${journeyId}/labels`, payload).then(parseResponse);

export const deleteLabel = (labelId: string): Promise<string> =>
  Api.callDelete(`${API_BASE}/labels/${labelId}`).then(parseResponse);

export const fetchJourneyVersions = (journeyId: string): Promise<JourneyVersion[]> => {
  const versions = getFakeVersions(journeyId);
  return Promise.resolve(versions);
};

export const fetchJourneyData = (journeyId: string): Promise<JourneyMeta> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoJourneydetails);
  return Api.callGet(`${BASE_PATH}/${journeyId}`).then(response => {
    const { data } = response;
    const versions = getFakeVersions(journeyId);
    return { ...data, versions };
  });
};

export const fetchJourneyTree = (journeyId: string, versionId?: string): Promise<JourneyContents> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoJourney);
  return Api.callGet(`${NEW_BASE_PATH}/${journeyId}/tree${versionId ? `?versionId=${versionId}` : ''}`)
    .then(response => {
      if (journeyId === 'f6c541ed-267d-4800-a8ba-cba6774803c8') {
        return treeMock.data;
      } else if (journeyId === '24d9088b-a357-4c09-b6bd-fb672b186b40') {
        return treeMockFedX.data;
      } else if (journeyId === '160335e4-c7bf-4068-83ae-f68137b47e0d' && versionId) {
        return getFakeTrees(journeyId, versionId);
      }
      return response.data;
    })
    .then(makeFake((response: JourneyContents) => addFakeDataForJourney(journeyId, response)));
};

const fetchStepData = (stepId: string): Promise<Step> =>
  Api.callGet(`${BASE_PATH}/steps/${stepId}`).then(parseResponse);

const fetchStepDailyStats = (
  journeyId: string,
  stepId: string,
  startDate: number,
  endDate: number,
  origin: StepDailyStatsOrigin = 'DEFAULT',
  errorMessage: string,
): Promise<StepDailyStats[]> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoJourneyStats);
  return Api.callGet(
    `${BASE_PATH}/${journeyId}/dailyStats/${stepId}?rangeFrom=${startDate}&rangeTo=${endDate}&origin=${origin}`,
    {
      toastText: errorMessage,
    },
  )
    .then(parseResponse)
    .then(makeFake(() => getFakeStepStats(journeyId, stepId, origin)));
};

const fetchStepTotalStats = (journeyId: string, stepId: string): Promise<TotalStepStats> =>
  Api.callGet(`${BASE_PATH}/${journeyId}/totalStats/${stepId}`)
    .then(parseResponse)
    .then(makeFake(() => getFakeTotalStatsForStep(journeyId, stepId)));

const fetchGoalData = (triggerId: string): Promise<Trigger> =>
  Api.callGet(`${BASE_PATH}/triggers/${triggerId}`).then(parseResponse);

export const getJourneyStats = (journeyId: string): Promise<StatsResponse> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoJourneyStats);
  return Api.callGet(`${NEW_BASE_PATH}/${journeyId}/goals/stats`)
    .then(parseResponse)
    .then(makeFake((response: StatsResponse) => getFakeJourneyStats(response)));
};

const fetchGoalDailyStats = (
  journeyId: string,
  goalId: string,
  startDate: number,
  endDate: number,
): Promise<GoalDailyStats> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoGoalGraph);
  return Api.callGet(`${NEW_BASE_PATH}/${journeyId}/goal/${goalId}/stats?rangeFrom=${startDate}&rangeTo=${endDate}`)
    .then(parseResponse)
    .then(makeFake((response: GoalDailyStats) => getFakeTriggerData(goalId, startDate, endDate, response)));
};

const fetchGoalStats = (journeyId: string, goalId: string): Promise<GoalStats> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve(demoGoalStats);
  return Api.callGet(`${NEW_BASE_PATH}/${journeyId}/goals/${goalId}/stats`)
    .then(parseResponse)
    .then(makeFake((response: GoalStats) => getFakeGoalStats(goalId, response)));
};

export const refreshJourney = (
  journeyId: string,
  setJourneyContents: (journeyContents: JourneyContents) => void,
): Promise<JourneyContents> =>
  fetchJourneyTree(journeyId).then(journeyContents => {
    setJourneyContents(journeyContents);
    changeUrl(`customer/journeys/edit/${journeyId}`);
    return journeyContents;
  });

export const deleteJourney = (journeyId: string): Promise<FallbackJson> =>
  Api.callDelete(`${BASE_PATH}/${journeyId}`) as Promise<FallbackJson>;

export const createStartTrigger = (payload: CreateStartTriggerPayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}/triggers`, payload).then(parseResponse);

export const createTrigger = (payload: CreateTriggerPayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}/triggers`, payload).then(parseResponse);

export const deleteTrigger = (journeyId: string, triggerId: string): Promise<string> =>
  Api.callDelete(`${BASE_PATH}/${journeyId}/triggers/${triggerId}`).then(parseResponse);

export const deleteStep = (journeyId: string, stepId: string): Promise<string> =>
  Api.callDelete(`${BASE_PATH}/${journeyId}/steps/${stepId}`).then(parseResponse);

export const createGoal = (payload: CreateGoalPayload): Promise<string> =>
  Api.callPost(`${BASE_PATH}/goals`, payload).then(parseResponse);

export const createExitCondition = (journeyId: string, payload: CreateGoalPayload): Promise<string> =>
  Api.callPost(`${NEW_BASE_PATH}/journeys/${journeyId}/exitconditions`, payload).then(parseResponse);

export const validateJourney = (journeyId: string): Promise<WebRestResponse<JourneyValidationError[]>> =>
  Api.callGet(`${BASE_PATH}/${journeyId}/validate`) as Promise<WebRestResponse<JourneyValidationError[]>>;

export const publishJourney = (journeyId: string): Promise<FallbackJson> =>
  Api.callPost(`${BASE_PATH}/publish`, { journeyId }) as Promise<FallbackJson>;

export const updateExperiments = (payload: UpdateExperimentsPayload): Promise<FallbackJson> =>
  Api.callPut(`${NEW_BASE_PATH}/experimentvariants`, payload) as Promise<FallbackJson>;

export const getDependantJourneys = (journeyId: string): Promise<DependantJourney[]> => {
  if (isJoDemoMode() && journeyId === demoJourneyId) return Promise.resolve([]);
  return Api.callGet(`${NEW_BASE_PATH}/${journeyId}/dependants`, {
    toastText: i18n.t('orchestration:overlap.dependantsApiError'),
  }).then(parseResponse);
};

export const getOverlapJourneysCandidates = (journeyId: string): Promise<OverlapCandidate[]> =>
  Api.callGet(`${NEW_BASE_PATH}/${journeyId}/overlapCandidates`, {
    toastText: i18n.t('orchestration:overlap.overlapCandidatesApiError'),
  }).then(parseResponse);

export const getHistoricProcessingModes = (journeyId: string): Promise<HPReprocessingModeResponse> =>
  Api.callGet(`${NEW_BASE_PATH}/${journeyId}/historicprocessing/reprocessingModes`).then(parseResponse);

export const triggerHistoricProcessing = (journeyId: string, payload: TriggerHpPayloadType): Promise<FallbackJson> =>
  Api.callPost(`${NEW_BASE_PATH}/${journeyId}/historicprocessing`, payload, {
    shouldShowToast: false,
  }) as Promise<FallbackJson>;

export const getHistoricProcessingDetails = (journeyId: string, jobId: string): Promise<HPJobDetails> =>
  Api.callGet(`${NEW_BASE_PATH}/${journeyId}/historicprocessing/${jobId}`).then(parseResponse);

export const stopHistoricProcessingJob = (journeyId: string, jobId: string): Promise<FallbackJson> =>
  Api.callPost(
    `${NEW_BASE_PATH}/${journeyId}/historicprocessing/${jobId}/cancel`,
    {},
    {
      shouldShowToast: false,
    },
  ) as Promise<FallbackJson>;

export const fetchAllowedTransitions = (
  journeyId: string,
  triggerId: string,
): Promise<{ stepId: string; name: string }[]> =>
  Api.callGet(`${NEW_BASE_PATH}/${journeyId}/triggers/${triggerId}/nextSteps`).then(parseResponse);

export const addTransition = (journeyId: string, triggerId: string, stepId: string) =>
  Api.callPost(`${NEW_BASE_PATH}/journeys/${journeyId}/triggers/${triggerId}/link`, { stepId });

export const deleteTransition = (journeyId: string, triggerId: string): Promise<string> =>
  Api.callDelete(`${NEW_BASE_PATH}/journeys/${journeyId}/triggers/${triggerId}/link`).then(parseResponse);

export default {
  fetchJourneys,
  fetchJourneyData,
  fetchJourneyTree,
  refreshJourney,
  deleteJourney,
  deleteTransition,
  createStartTrigger,
  createTrigger,
  deleteTrigger,
  deleteStep,
  fetchStepData,
  fetchStepDailyStats,
  fetchStepTotalStats,
  fetchGoalData,
  fetchGoalStats,
  fetchGoalDailyStats,
  fetchJourneyVersions,
  fetchAllowedTransitions,
  addTransition,
  createGoal,
  validateJourney,
  publishJourney,
  updateExperiments,
  getDependantJourneys,
  getOverlapJourneysCandidates,
  triggerHistoricProcessing,
  createExitCondition,
};
