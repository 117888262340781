import React from 'react';
import * as d3 from 'd3';
import { CONTENT_MODAL } from '~/modals/types';
import { showModal, showDeleteModal, hideModal as defaultHideModal } from '~/modals/actions';
import JourneyDataService from '~/customer/journeys/dataService';
import { makeGoToJourneyView } from '~/customer/pages/JourneyView/redirects';
import i18n from '~/i18n';
import EditExperiment from '~/customer/components/EditExperiment';
import { showSuccess } from '~/notificationCenter/actions';
import OverlapJourneyList from '~/customer/components/OverlapJourneyList';
import { getExperimentNodes } from '~/customer/components/utils';
import ValidationModal from './ValidationModal';
import PublishModal from './PublishModal';
import TransitionModalContent from './TransitionModalContent';
import ViewScheduledHP from '../HistoricProcessing/ViewScheduledHP';
import TriggerHP from '../HistoricProcessing/TriggerHP';

const hideModalAndGoToJourneyView = journeyId => () => dispatch => {
  dispatch(defaultHideModal());

  const goToJourneyView = makeGoToJourneyView(journeyId);
  goToJourneyView();
};

const getModalMessage = (stepName, data, showVariantDeletionMessage = false) => {
  const childNodes = d3
    .hierarchy(data)
    .descendants()
    .map(node => node.data.label);

  return (
    <div className="DeleteModal">
      <p>{i18n.t('orchestration:common.modal.confirmationDelete', { name: stepName })}</p>

      {showVariantDeletionMessage && <p>{i18n.t('orchestration:common.modal.variantsDeleteMessage')}</p>}

      {childNodes.length > 1 && (
        <>
          <span>{i18n.t('orchestration:common.modal.confirmNodeDeletion')}</span>
          <p className="DeleteModal-list--header">{i18n.t('orchestration:common.modal.itemsDelete')}</p>
          <ul className="DeleteModal-list">
            {childNodes.map((node, index) => (
              <li key={index}>{node}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export const makeHideModal = dispatch => () => {
  dispatch(defaultHideModal());
};

export const makeShowDeleteTriggerModal =
  (journeyId, dispatch, setJourneyContents) =>
  (data, type = 'trigger', fetchJourneyData) => {
    const { nodeId: triggerId, label: triggerName } = data;
    const showSuccessMessage = type => showSuccess({ body: i18n.t(`orchestration:common.modal.${type}Deleted`) });
    const action = showDeleteModal({
      title: i18n.t(`orchestration:common.modal.delete${type}`),
      message: getModalMessage(triggerName, data),
      onConfirm: () =>
        JourneyDataService.deleteTrigger(journeyId, triggerId).then(() => {
          fetchJourneyData();
          JourneyDataService.refreshJourney(journeyId, setJourneyContents);
          showSuccessMessage(type);
        }),
      hideModal: hideModalAndGoToJourneyView(journeyId),
      className: `t-jo-delete-trigger-modal-${triggerId}`,
    });

    dispatch(action);
  };

export const makeShowDeleteTansitionModal = (journeyId, dispatch, setJourneyContents) => (data, fetchJourneyData) => {
  const { nodeId: triggerId, label: triggerName } = data;
  const showSuccessMessage = () => showSuccess({ body: i18n.t('orchestration:transitions.transitionDeleted') });
  const action = showDeleteModal({
    title: i18n.t('orchestration:transitions.deleteTransition'),
    message: getModalMessage(triggerName, data),
    onConfirm: () =>
      JourneyDataService.deleteTransition(journeyId, triggerId).then(() => {
        fetchJourneyData();
        JourneyDataService.refreshJourney(journeyId, setJourneyContents);
        showSuccessMessage();
      }),
    hideModal: hideModalAndGoToJourneyView(journeyId),
    className: 't-delete-transition-button',
  });

  dispatch(action);
};

export const makeShowExperimentSettingsModal = (journeyId, dispatch, setJourneyContents, fetchJourneyData) => () => {
  const hideModal = makeHideModal(dispatch);
  const action = showModal(CONTENT_MODAL, {
    title: i18n.t('journey:experimentModal.title'),
    content: (
      <EditExperiment
        setJourneyContents={setJourneyContents}
        journeyId={journeyId}
        hideModal={hideModal}
        fetchJourneyData={fetchJourneyData}
      />
    ),
  });

  dispatch(action);
};

export const makeShowDependantJourneysModal = (dispatch, dependantJourneys) => () => {
  const hideModal = makeHideModal(dispatch);
  const action = showModal(CONTENT_MODAL, {
    title: i18n.t('journey:dependencyModal.title'),
    content: (
      <OverlapJourneyList
        stateKey="PROFILES/DEPENDANTS/JOURNEY"
        list={dependantJourneys}
        t={i18n.t.bind(i18n)}
        hideModal={hideModal}
      />
    ),
  });

  dispatch(action);
};

export const makeShowDeleteStepModal =
  (journeyId, dispatch, setJourneyContents, journeyContents) =>
  (data, fetchJourneyData, showVariantDeletionMessage) => {
    const { nodeId: stepId, label: stepName } = data;
    const hideModal = makeHideModal(dispatch);
    const action = showDeleteModal({
      title: i18n.t('orchestration:steps.deleteStep'),
      message: getModalMessage(stepName, data, showVariantDeletionMessage),
      isHideAfterConfirm: false,
      onConfirm: async () => {
        await JourneyDataService.deleteStep(journeyId, stepId);
        fetchJourneyData();
        await JourneyDataService.refreshJourney(journeyId, setJourneyContents);
        showSuccess({ body: i18n.t('orchestration:steps.stepDeleted') });
        hideModal();
        const { experimentSteps } = getExperimentNodes(journeyContents.nodes);
        const isRemovedStepIsVariant = experimentSteps.some(step => step.nodeId === stepId);

        if (isRemovedStepIsVariant) {
          if (experimentSteps.length > 2) {
            makeShowExperimentSettingsModal(journeyId, dispatch, setJourneyContents, fetchJourneyData)();
          } else if (experimentSteps.length === 2) {
            const experimentVariant = experimentSteps.find(step => step.nodeId !== stepId)?.experimentVariant;
            await JourneyDataService.updateExperiments({
              experimentVariantConfig: [
                {
                  name: experimentVariant?.name,
                  experimentVariantId: experimentVariant?.experimentVariantId,
                  weight: 100,
                },
              ],
            });
            JourneyDataService.refreshJourney(journeyId, setJourneyContents);
          }
        }
      },
      hideModal: hideModalAndGoToJourneyView(journeyId),
      className: `t-jo-delete-step-modal-${stepId}`,
    });

    dispatch(action);
  };

export const makeShowJourneyValidationModal =
  (journeyId, dispatch) =>
  (journeyName, validations, fetchJourneyData, setShowJourneyErrors, variables, journeyContents) => {
    const hideModal = makeHideModal(dispatch);

    const action = showModal(CONTENT_MODAL, {
      title: i18n.t('orchestration:publish.title'),
      content: (
        <ValidationModal
          journeyName={journeyName}
          validations={validations}
          variables={variables}
          journeyContents={journeyContents}
        />
      ),
      hideModal: hideModalAndGoToJourneyView(journeyId),
      confirmText: i18n.t('orchestration:validations.showErrorsOnCanvas'),
      testHook: 'validateJourney',
      onConfirm: () => {
        hideModal();
        fetchJourneyData();
        setShowJourneyErrors(true);
      },
    });

    dispatch(action);
  };

export const makeShowJourneyPublishModal =
  (journeyId, dispatch, setJourneyContents) =>
  (journeyName, fetchJourneyData, isHpRunningOrScheduled, isLatestVersion) => {
    const hideModal = makeHideModal(dispatch);

    const action = showModal(CONTENT_MODAL, {
      title: i18n.t('orchestration:publish.title'),
      content: (
        <PublishModal
          journeyName={journeyName}
          isHpRunningOrScheduled={isHpRunningOrScheduled}
          isLatestVersion={isLatestVersion}
        />
      ),
      hideModal: hideModalAndGoToJourneyView(journeyId),
      confirmText: i18n.t('orchestration:publish.publish'),
      onConfirm: () =>
        JourneyDataService.publishJourney(journeyId).then(() => {
          showSuccess({ header: i18n.t('orchestration:publish.published', { name: journeyName }) });
          hideModal();
          fetchJourneyData();
          return JourneyDataService.refreshJourney(journeyId, setJourneyContents);
        }),
      testHook: 'publishJourney',
    });

    dispatch(action);
  };

export const makeShowHistoricProcessingModal = (journeyId, journeyContents, dispatch) => fetchJourneyData => {
  const hideModal = makeHideModal(dispatch);

  const action = showModal(CONTENT_MODAL, {
    title: i18n.t('orchestration:tooltips.triggerHistoricProcessing'),
    content: (
      <TriggerHP
        fetchJourneyData={fetchJourneyData}
        journeyContents={journeyContents}
        hideModal={hideModal}
        journeyId={journeyId}
      />
    ),
    hideModal: hideModalAndGoToJourneyView(journeyId),
    testHook: 'triggerHistoricProcessingModal',
  });

  dispatch(action);
};

export const makeShowHistoricProcessRunningModal =
  (journeyId, journeyContents, dispatch) => (hpJobId, fetchJourneyData) => {
    const hideModal = makeHideModal(dispatch);

    const action = showModal(CONTENT_MODAL, {
      title: i18n.t('orchestration:tooltips.scheduledHistoricProcessing'),
      content: (
        <ViewScheduledHP
          journeyContents={journeyContents}
          hpJobId={hpJobId}
          journeyId={journeyId}
          fetchJourneyData={fetchJourneyData}
          hideModal={hideModal}
        />
      ),
      hideModal: hideModalAndGoToJourneyView(journeyId),
      testHook: 'scheduledHistoricProcessing',
    });

    dispatch(action);
  };

export const makeShowTransitionModal = (journeyId, dispatch, setJourneyContents) => triggerId => {
  const action = showModal(CONTENT_MODAL, {
    content: (
      <TransitionModalContent
        hideModal={makeHideModal(dispatch)}
        journeyId={journeyId}
        refresh={() => JourneyDataService.refreshJourney(journeyId, setJourneyContents)}
        triggerId={triggerId}
      />
    ),
    className: 'w-[500px] max-w-[500px] m-auto',
    hideModal: hideModalAndGoToJourneyView(journeyId),
    title: i18n.t('orchestration:common.modal.pickStep'),
  });

  dispatch(action);
};

export const makeModals = (
  journeyId,
  dispatch,
  setJourneyContents,
  journeyContents,
  fetchJourneyData,
  dependantJourneys,
) => ({
  showDeleteTriggerModal: makeShowDeleteTriggerModal(journeyId, dispatch, setJourneyContents),
  showDeleteTransition: makeShowDeleteTansitionModal(journeyId, dispatch, setJourneyContents),
  showDeleteStepModal: makeShowDeleteStepModal(journeyId, dispatch, setJourneyContents, journeyContents),
  showJourneyValidationModal: makeShowJourneyValidationModal(journeyId, dispatch),
  showJourneyPublishModal: makeShowJourneyPublishModal(journeyId, dispatch, setJourneyContents),
  showExperimentSettingsModal: makeShowExperimentSettingsModal(
    journeyId,
    dispatch,
    setJourneyContents,
    fetchJourneyData,
  ),
  showDependantJourneysModal: makeShowDependantJourneysModal(dispatch, dependantJourneys),
  showTransitionModal: makeShowTransitionModal(journeyId, dispatch, setJourneyContents),
  showHistoricProcessingModal: makeShowHistoricProcessingModal(journeyId, journeyContents, dispatch),
  showHistoricProcessRunningModal: makeShowHistoricProcessRunningModal(journeyId, journeyContents, dispatch),
  hideModal: makeHideModal(dispatch),
});
