import React, { useContext, useState } from 'react';
import cx from 'classnames';
import BtnIcon from '~/components/src/BtnIcon';
import { I18NextT } from '~/components/src/Common/types';
import '~/customer/components/styles.scss';

import { ActionsContext } from '../constants';
import { JourneyItem } from './JourneyItem';
import { ButtonType, ItemVariantStats } from './types';
import ItemStats from './ItemStats';
import './styles.scss';

type GoalItemProps = {
  className?: string;
  name: string;
  reached: number;
  id: string;
  variantStats: ItemVariantStats[];
  fetchJourneyData: () => void;
  canEdit: boolean;
  t: I18NextT;
  isDemoMode: boolean;
};

const GoalItem = ({
  className,
  name,
  reached,
  id,
  variantStats = [],
  fetchJourneyData,
  canEdit,
  t,
  isDemoMode = false,
}: GoalItemProps): React.ReactElement => {
  const { goToGoalStatisticsScreen, goToViewGoalScreen, goToEditGoalScreen, showDeleteTriggerModal } =
    useContext(ActionsContext);

  const [isExpanded, setIsExpanded] = useState(false);

  let buttons: ButtonType[] = [
    {
      icon: 'view',
      testHook: 'view-goal-button',
      onClick: () => goToViewGoalScreen(id),
    },
    {
      icon: 'statistics',
      testHook: 'statistic-goal-button',
      onClick: () => goToGoalStatisticsScreen(id),
    },
  ];

  if (canEdit) {
    buttons.push(
      {
        icon: 'edit',
        testHook: 'edit-goal-button',
        onClick: () => {
          goToEditGoalScreen(id);
        },
      },
      {
        icon: 'delete',
        testHook: 'delete-trigger-button',
        onClick: () => {
          showDeleteTriggerModal({ nodeId: id, label: name }, 'goal', fetchJourneyData);
        },
      },
    );
  }

  if (isDemoMode) buttons = buttons.filter(btn => btn.icon === 'statistics');

  let isShowVariantStats = variantStats.length > 0;

  if (variantStats.length === 1 && variantStats[0].profilesReached === reached) {
    isShowVariantStats = false;
  }

  return (
    <JourneyItem
      name={name}
      className={cx('t-journeyGoal', className)}
      type="goal"
      onClick={() => setIsExpanded(!isExpanded)}
      isShowVariantStats={isShowVariantStats}
      isExpanded={isExpanded}
      buttons={
        <>
          {buttons.map((buttonConfig, buttonIndex) => (
            <BtnIcon
              className="h-5 w-5 bg-transparent p-0 text-gray-400 hover:bg-transparent hover:text-gray-600"
              icon={buttonConfig.icon}
              onClick={buttonConfig.onClick}
              key={buttonIndex}
              testHook={buttonConfig.testHook}
            />
          ))}
        </>
      }
      footer={
        <div className="flex flex-col gap-1 rounded-b-xl bg-gray-50 p-1">
          <ItemStats t={t} testHook="goalStats" label={t('journey:total')} reached={reached} type="goal" />
          {isShowVariantStats && isExpanded && (
            <div className="flex flex-col gap-1">
              {variantStats.map((variant, index) => (
                <ItemStats
                  key={index}
                  t={t}
                  testHook="variantStats"
                  label={variant.name}
                  reached={variant.profilesReached}
                  conversion={variant.conversionRate}
                  hasConversion
                  type="goal"
                />
              ))}
            </div>
          )}
        </div>
      }
    />
  );
};

export default GoalItem;
