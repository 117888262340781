import React, { useContext } from 'react';
import { Field, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAPI } from '~/common';
import EditableRulesList from '~/common/modules/rules/components/EditableRulesList';
import { getRulesDefinitions } from '~/common/modules/rules/dataService';
import { RulesTypes } from '~/common/modules/rules/types';
import { Page } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import Heading from '~/components/src/Heading';
import Notification from '~/components/src/Notification';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { joinCriteriaVariables } from '~/profiles/pages/AudienceEdit/utils';
import { NodeTypes } from '~/workflows/constants';
import { useAllVariables } from '~/workflows/hooks';
import { TRule } from '~/workflows/types';
import { useQuery } from '~/workflows/util';
import { fetchJourneyOverlaps } from '../../utils';
import { JourneyContext } from '../JourneyContext';
import { fetchRuleTypesAndTrigger, fetchTriggerIfEdit, getTitleAndSubmitHandler } from './utils';

const EditTrigger = ({
  type = NodeTypes.TRIGGER,
  isUsedInJourneys = false,
}: {
  type?: keyof typeof NodeTypes;
  isUsedInJourneys?: boolean;
}) => {
  const { triggerId } = useParams();
  const query = useQuery();
  const parentStepId = query.get('parentStepId');
  const isJourneyCondition = type === NodeTypes.GOAL || type === NodeTypes.EXIT_BY_CONDITION;

  const { journeyData, journeyTree, actions, refreshJourney } = useContext(JourneyContext);
  const { goToJourneyView } = actions;
  const journeyId = journeyData?.journeyId || '';

  const isStartTrigger =
    (type === NodeTypes.TRIGGER && !triggerId && !parentStepId) || journeyTree?.nodes?.nodeId === triggerId;

  const { data: ruleDefinitions, isLoading: ruleDefinitionsLoading } = useAPI(() =>
    getRulesDefinitions(isStartTrigger ? NodeTypes.START_TRIGGER : type),
  );
  const { data: triggerData, isLoading: triggerDataLoading } = useAPI(() => fetchTriggerIfEdit(triggerId));

  const { allVariables, refetchAllVariables, isLoading: isVariablesLoading } = useAllVariables();

  if (isVariablesLoading || ruleDefinitionsLoading || triggerDataLoading) return <Spin />;

  const mappedRuleTypesAndTrigger = fetchRuleTypesAndTrigger(ruleDefinitions, triggerData);

  const ruleTypesTree = mappedRuleTypesAndTrigger[0];
  const trigger = mappedRuleTypesAndTrigger[1];

  const isEdit = !!triggerId;

  const mapCriteria = (criteria: any) => joinCriteriaVariables(criteria || [], allVariables || []);

  const { pageTitle, submitHandler } = getTitleAndSubmitHandler(triggerId, parentStepId, type);

  const initialValues = {
    title: isEdit ? triggerData.name : '',
    criteria: mapCriteria(trigger.criteria),
    criteriaValid: isEdit,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(i18n.t('validation:validation.required')),
    criteria: Yup.array(),
  });

  const handleTriggerSubmit = async (triggerData: any) => {
    const canSubmit = triggerData.criteriaValid;
    if (!canSubmit) {
      return;
    }

    await submitHandler({
      journeyId,
      triggerId,
      triggerData,
      parentStepId,
      type,
    });

    refreshJourney();
    await goToJourneyView();
  };

  const isGoal = type === NodeTypes.GOAL;
  const isExitCondition = type === NodeTypes.EXIT_BY_CONDITION;

  const alreadySelectedOverlapJourneyIds = trigger.criteria
    .flat()
    .filter((rule: TRule) => rule.type === RulesTypes.JourneyOverlap)
    .map((rule: TRule) => rule?.dependantJourneyId);

  const isOverlapRulesLimitReached = alreadySelectedOverlapJourneyIds.length >= 5;

  const ruleTypes = ruleTypesTree?.map((rule: TRule) => {
    if (rule.type === RulesTypes.JourneyOverlap) {
      return {
        ...rule,
        isDisabled: isOverlapRulesLimitReached,
        subtitle: isOverlapRulesLimitReached
          ? i18n.t('audiences:rules.descriptions.JourneyOverlapRuleLimitReached', { limit: 5 })
          : rule.subtitle,
        subMenu: null,
        customSubMenu: {
          fetchData: () => fetchJourneyOverlaps(rule, journeyId, alreadySelectedOverlapJourneyIds),
        },
      };
    }
    return rule;
  });

  const testHookClass = isEdit ? `t-jo-edit-trigger-screen-${triggerId}` : `t-jo-create-trigger-screen-${parentStepId}`;

  const getNodeLabel = () => {
    if (isGoal) return i18n.t('workflow:orchestration.goals.goalName');
    if (isExitCondition) return i18n.t('workflow:orchestration.exits.exitConditionName');
    return i18n.t('workflow:orchestration.triggers.triggerName');
  };

  const getNotificationError = () => {
    if (isGoal) return i18n.t('workflow:orchestration.goals.formInvalid');
    if (isExitCondition) return i18n.t('workflow:orchestration.exits.formInvalid');
    return i18n.t('workflow:orchestration.triggers.formInvalid');
  };

  return (
    <Page className={testHookClass}>
      <Heading
        title={pageTitle}
        crumbs={[
          {
            title: i18n.t('workflow:journey.back'),
            onClick: () => goToJourneyView(),
          },
        ]}
      />
      <Formik
        onSubmit={handleTriggerSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting, isValid, touched, errors }) => (
          <div>
            <div className="JourneyView-nodeTitleField">
              <Field
                as={FormikInputField}
                name="title"
                label={getNodeLabel()}
                errorText={touched.title && errors.title}
                autoFocus={true}
              />
            </div>

            <EditableRulesList
              criteria={values.criteria}
              ruleTypesTree={ruleTypes}
              onChange={(criteriaState: any) => {
                setFieldValue('criteria', mapCriteria(criteriaState.criteria));
                setFieldValue('criteriaValid', criteriaState.isValid);
              }}
              fetchAllVariables={refetchAllVariables}
              isJourneyCondition={isJourneyCondition}
              isSubmitted={isSubmitting}
              isUsedInJourneys={isUsedInJourneys}
              isJoTrigger
            />
            <hr className="EditAudience-footerSeparator" />
            {touched && !isValid && (
              <Notification kind="error" header="">
                <p>{getNotificationError()}</p>
              </Notification>
            )}
            <div className="flex items-center justify-end gap-4 pb-6">
              <ActionButtons
                onConfirm={handleSubmit}
                onDecline={() => goToJourneyView()}
                testHook="EditTrigger"
                className="mr-0"
              />
            </div>
          </div>
        )}
      </Formik>
    </Page>
  );
};

export default EditTrigger;
