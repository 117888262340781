import React from 'react';

const PageTitleHeader = ({ handleIcon, title, className = '' }) => (
  <div className={className}>
    <div className="PageTitleHeader flex items-center gap-2">
      {handleIcon}
      <div className="PageTitleHeader-titleContainer">
        <p className="PageTitleHeader-titleContainer--title">{title}</p>
      </div>
    </div>
  </div>
);

export default PageTitleHeader;
