import { compose } from 'redux';

import {
  flattenCriteria,
  addNamesFromRuleDefinitions,
  addVariablesToCriteria,
  addPropertiesToCriteria,
  addTitleAndTypeLabel,
  addNamesToJourneyOverlapRules,
} from '~/profiles/audiences/audienceUtils';

const onlyFieldsUsedInTriggerView = criteria =>
  criteria.map(ruleSet =>
    ruleSet.map(rule => ({
      type: rule?.type,
      ruleType: rule?.ruleType,
      ruleId: rule?.ruleId,
      title: rule?.title,
      typeLabel: rule?.typeLabel,
      timeCondition: rule?.timeCondition,
      waitTime: rule?.waitTime,
      properties: rule?.properties,
      negation: rule?.negation,
    })),
  );

export const prepareDataForViewTrigger = (trigger, ruleDefinitions, variables) => {
  const criteria = compose(
    onlyFieldsUsedInTriggerView,
    addTitleAndTypeLabel,
    addPropertiesToCriteria,
    flattenCriteria,
    addVariablesToCriteria(variables),
    addNamesToJourneyOverlapRules,
    addNamesFromRuleDefinitions(ruleDefinitions),
  )(trigger.criteria);

  return {
    triggerId: trigger.triggerId,
    title: trigger.name,
    criteria,
  };
};
