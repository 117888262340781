angular.module('webUi.ui.tagmanagement.tags.conditions.journey')
    .controller('TagmanagementTagFormJourneyConditionController', ['$window', '$scope', 'JourneyService', 'TagFormJourneyConditionService', 'CONDITION_TYPES_CONSTANTS',
        function TagmanagementTagFormJourneyConditionController($window, $scope, JourneyService, ConditionService, TYPES) {
            var conditionClazz;
            var vm = this;
            // TODO:Workflow in Beta Mode
            var isBeta = localStorage.getItem('useBeta') === 'true';

            function init () {
                conditionClazz = ConditionService.getClassName();

                vm.data = {
                    journeys: [],
                    filteredJourneys: [],
                    journeyTypes: ConditionService.getJourneyTypes(),
                    orchestrationTypes: isBeta ? ['JOURNEY','AUDIENCE'] : ['JOURNEY'],
                    steps: [],
                    stepTypes: ConditionService.getStepTypes(),
                };

                vm.callbacks = {
                    onJourneyChanged: onJourneyChanged,
                    onJourneyTypeChanged: onJourneyTypeChanged,
                    onStepTypeChanged: onStepTypeChanged,
                    onOrchestrationTypeChanged: onOrchestrationTypeChanged
                };

                vm.ui = {
                    isStepDisabled: true,
                    isStepSelectDisabled: false,
                    showError: false
                };

                

                // Set external model
                $scope.state.formCondition = angular.copy($scope.getCondition(conditionClazz)) ||
                    ConditionService.getEmptyExternalModel();

                // Transform internal model to external before submitting
                $scope.state.beforeSubmit = function () {
                    const formData = vm.model;
                    if (formData.journeyId && formData.journeyType) {
                        $scope.state.formCondition = Object.assign(
                            {},
                            $scope.state.formCondition,
                            ConditionService.transformData(formData)
                        );
                        return true;
                    } else {
                        vm.ui.showError = true;
                        return false;
                    }
                };

                // Transform external model to internal
                vm.model = ConditionService.initModel($scope.state.formCondition);

                // Set the right UI flags
                onOrchestrationTypeChanged(false);
                onJourneyTypeChanged();
                onStepTypeChanged();

                // Get steps if editing rule
                if (vm.model.journeyId) {
                    getSteps(vm.model.journeyId);
                }
            }

            // Reset steps when journey changed
            function onJourneyChanged () {
                // Reset step select
                vm.data.steps = [];
                vm.model.stepId = null;

                // Update step state
                onJourneyTypeChanged();

                // Get all steps for new Journey
                getSteps(vm.model.journeyId);
            }

            function onJourneyTypeChanged () {
                vm.ui.isStepDisabled = !vm.model.journeyId || vm.model.journeyType === TYPES.NOT_IN;
            }

            function onOrchestrationTypeChanged (resetFields = true) {
                vm.ui.showError = false;

                if (resetFields) {
                    vm.model.journeyId = null;
                    vm.model.stepId = null;
                    vm.model.stepType = TYPES.IN_ANY;
                    onStepTypeChanged();
                }
    
                // load journeys
                JourneyService.getJourneysList(vm.model.orchestrationType)
                    .then((journeys) => {
                        vm.data.journeys = journeys;
                    });
            }

            function onStepTypeChanged () {
                const isInAny = vm.model.stepType === TYPES.IN_ANY;
                vm.ui.isStepSelectDisabled = isInAny;
                if (isInAny) {
                    // Reset step
                    vm.model.stepId = null;
                }
            }

            // Get all steps for a given journey
            function getSteps (journeyId) {
                ConditionService.getJourneySteps(journeyId)
                    .then((steps) => {
                        vm.data.steps = steps;
                    });
            }

            /**
             * Init controller
             */
            init();
        }
    ]);
