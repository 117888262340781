import React, { useContext } from 'react';
import { Field, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAPI } from '~/common';
import EditableIntegrationsList from '~/common/modules/connectors/components/EditableConnectorsList';
import {
  getRulesDefinitions,
  getRuleVariablesForEventListeners,
  RULE_GROUP_TYPES,
} from '~/common/modules/rules/dataService';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';
import ActionButtons from '~/components/src/ActionButtons';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import Heading from '~/components/src/Heading';
import Notification from '~/components/src/Notification';
import Page from '~/components/src/Page';
import Spin from '~/components/src/Spin';
import i18n from '~/i18n';
import { showSuccess } from '~/notificationCenter/actions';
import { shapeEventListeners, shapeIntegrationsRequest } from '~/profiles/audiences/utils';
import { createStep, fetchStepData, getActivePartners, updateStep } from '~/workflows/dataService';
import { useQuery } from '~/workflows/util';
import { JourneyContext } from '../JourneyContext';
import EditableEventListenerList from './components/EditableEventListenerList';
import { getIntegrations, getUsedVariablesForJO, mappedTransformedConnectors, mapVariableModifications } from './utils';
import { StepForm } from '../types';
import { setLocalStorageValue } from '../JourneyCanvas/utils';

const EditStep = ({ journeyId }: { journeyId: string }) => {
  const { stepId } = useParams();
  const query = useQuery();
  const parentTriggerId = query.get('parentTriggerId') || '';
  const { actions, refreshJourney } = useContext(JourneyContext);

  const { goToJourneyView } = actions;

  const isEdit = !!stepId;

  const { data: ruleVariables, isLoading: variablesLoading } = useAPI(() =>
    getRuleVariablesForEventListeners(journeyId, parentTriggerId),
  );
  const { data: ruleDefinitions, isLoading: rulesLoading } = useAPI(() =>
    getRulesDefinitions(RULE_GROUP_TYPES.VARIABLE_MODIFICATION),
  );
  const { data: allConnectors, isLoading: connectorListLoading } = useAPI(() => getActivePartners());
  const { data: step, isLoading: stepDataLoading } = useAPI(() => {
    if (stepId) return fetchStepData(stepId);
    return Promise.resolve({} as any);
  });

  if (variablesLoading || rulesLoading || connectorListLoading || stepDataLoading) return <Spin />;

  const transformedAllConnectors = mappedTransformedConnectors(allConnectors || []);
  const integrations = step.integrations ? getIntegrations(step.integrations) : [];
  const usedVariables = getUsedVariablesForJO(ruleVariables || []);
  const ruleTypesTree = getRulesTreeFromTypes(ruleDefinitions);
  const variableModifications = step?.variableModifications ? mapVariableModifications(step.variableModifications) : [];

  const showEventsWarning = (values: StepForm) =>
    values?.variableModifications?.length > 0 &&
    !values?.integrations?.every(integration => integration?.supportsEventListeners);

  const pageTitle = i18n.t(`workflow:orchestration.steps.${isEdit ? 'editStep' : 'createStep'}`);

  const handleStepEdit = async (values: StepForm) => {
    const canSubmit = values.areIntegrationsValid && values.areEventsListenersValid;

    if (!canSubmit) {
      return;
    }

    const integrations = shapeIntegrationsRequest(values.integrations);
    const variableModifications = shapeEventListeners(values.variableModifications);

    const payload = {
      name: values.name,
      integrations,
      variableModifications,
      journeyId,
    };

    if (isEdit) {
      await updateStep({ ...payload, stepId });
      showSuccess({ header: i18n.t('workflow:orchestration.steps.stepSaved') });
      refreshJourney();
      goToJourneyView();
    } else {
      await createStep({ ...payload, parentTriggerId });
      showSuccess({ header: i18n.t('workflow:orchestration.steps.stepCreated') });

      setLocalStorageValue(`${journeyId}_stepParentTriggerId`, parentTriggerId);
      refreshJourney();
      goToJourneyView();
    }
  };

  const initialValues = {
    name: step.name || '',
    journeyId,
    integrations,
    variableModifications,
    areIntegrationsValid: true,
    areEventsListenersValid: true,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t('validation:validation.required')),
    integrations: Yup.array(),
    variableModifications: Yup.array(),
  });

  return (
    <Page>
      <Heading
        title={pageTitle}
        testHook="createEditStep"
        crumbs={[
          {
            title: i18n.t('workflow:journey.back'),
            onClick: () => goToJourneyView(),
          },
        ]}
      />
      <Formik
        onSubmit={handleStepEdit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange
      >
        {({ submitCount, values, handleSubmit, setFieldValue, isValid, touched, errors }) => (
          <div>
            <div className="JourneyView-nodeTitleField">
              <Field
                as={FormikInputField}
                name="name"
                label={i18n.t('workflow:orchestration.steps.stepNameLabel')}
                errorText={touched.name && errors.name}
                autoFocus={true}
              />
            </div>
            <EditableIntegrationsList
              stageName={values.name}
              allConnectors={transformedAllConnectors}
              integrations={values.integrations}
              onChange={(integrationsData: any) => {
                setFieldValue('integrations', integrationsData.integrations);
                setFieldValue('areIntegrationsValid', integrationsData.areIntegrationsValid);
              }}
              usedVariables={usedVariables}
              isDefaultConnectorDisplayed={false}
              isSubmitted={!!submitCount}
              type="Journey"
            />
            <hr />
            <Notification
              className="EditStep-eventsInfo"
              header={i18n.t('workflow:orchestration.eventListener.infoHeader')}
            >
              <ol className="px-6 py-2">
                <li>{i18n.t('workflow:orchestration.eventListener.infoItem0')}</li>
                <li>{i18n.t('workflow:orchestration.eventListener.infoItem1')}</li>
                <li>{i18n.t('workflow:orchestration.eventListener.infoItem2')}</li>
                <li>{i18n.t('workflow:orchestration.eventListener.infoItem3')}</li>
                <p>{i18n.t('workflow:orchestration.eventListener.infoFooter')}</p>
              </ol>
            </Notification>
            {showEventsWarning(values) && (
              <Notification kind="warning" className="EditStep-eventsInfo">
                <p>{i18n.t('workflow:orchestration.eventListener.partialIntegrationsSupport')}</p>
              </Notification>
            )}
            <EditableEventListenerList
              eventsListeners={values.variableModifications}
              ruleTypesTree={ruleTypesTree}
              usedVariables={usedVariables}
              onChange={(events: any) => {
                setFieldValue('variableModifications', events.eventsListeners);
                setFieldValue('areEventsListenersValid', events.areEventsListenersValid);
              }}
            />
            <hr className="EditAudience-footerSeparator" />
            {!(isValid && values.areIntegrationsValid && values.areEventsListenersValid) && (
              <Notification kind="error" header="">
                <p>
                  {!values.areEventsListenersValid
                    ? i18n.t('workflow:orchestration.eventListener.formInvalid')
                    : i18n.t('workflow:orchestration.steps.formInvalid')}
                </p>
              </Notification>
            )}
            <div className="flex items-center justify-end gap-4 pb-6">
              <ActionButtons
                onConfirm={handleSubmit}
                onDecline={() => goToJourneyView()}
                testHook="EditStep"
                className="mr-0"
              />
            </div>
          </div>
        )}
      </Formik>
    </Page>
  );
};

export default EditStep;
