import { shapeCriteriaRequest } from '~/profiles/audiences/utils';
import { showSuccess } from '~/notificationCenter/actions';

import JourneyDataService from '~/customer/journeys/dataService';
import * as TriggersService from '~/customer/triggers/dataService';

import { RulesTypes } from '~/common/modules/rules/types';

const handleCreateStartTrigger = ({ journeyId, setJourneyContents, criteriaFormState, triggerTitleFormState, t }) => {
  const payload = {
    type: 'START_TRIGGER',
    name: triggerTitleFormState.title,
    journeyId,
    criteria: shapeCriteriaRequest(criteriaFormState.criteria),
  };

  return JourneyDataService.createStartTrigger(payload).then(() => {
    showSuccess({ header: t('triggers.triggerCreated') });
    JourneyDataService.refreshJourney(journeyId, setJourneyContents);
  });
};

const handleCreateTriggerAfterStep = ({
  journeyId,
  parentStepId,
  setJourneyContents,
  criteriaFormState,
  triggerTitleFormState,
  t,
  fetchJourneyData,
}) => {
  const payload = {
    type: 'TRIGGER',
    name: triggerTitleFormState.title,
    journeyId,
    parentStepId,
    criteria: shapeCriteriaRequest(criteriaFormState.criteria),
  };

  return JourneyDataService.createTrigger(payload).then(() => {
    showSuccess({ header: t('triggers.triggerCreated') });
    fetchJourneyData();
    return JourneyDataService.refreshJourney(journeyId, setJourneyContents);
  });
};

const handleCreateGoal = ({
  journeyId,
  setJourneyContents,
  criteriaFormState,
  triggerTitleFormState,
  t,
  fetchJourneyData,
}) => {
  const payload = {
    name: triggerTitleFormState.title,
    journeyId,
    criteria: shapeCriteriaRequest(criteriaFormState.criteria),
  };

  return JourneyDataService.createGoal(payload).then(() => {
    showSuccess({ header: t('goals.goalCreated') });
    fetchJourneyData();
    return JourneyDataService.refreshJourney(journeyId, setJourneyContents);
  });
};

const handleCreateExitCondition = ({
  journeyId,
  setJourneyContents,
  criteriaFormState,
  triggerTitleFormState,
  t,
  fetchJourneyData,
}) => {
  const payload = {
    name: triggerTitleFormState.title,
    journeyId,
    criteria: shapeCriteriaRequest(criteriaFormState.criteria),
  };

  return JourneyDataService.createExitCondition(journeyId, payload).then(() => {
    showSuccess({ header: t('exits.exitConditionCreated') });
    fetchJourneyData();
    return JourneyDataService.refreshJourney(journeyId, setJourneyContents);
  });
};

const handleUpdateTrigger = ({
  journeyId,
  triggerId,
  triggerTitleFormState,
  criteriaFormState,
  setJourneyContents,
  trigger,
  t,
  type = 'trigger',
  fetchJourneyData,
}) => {
  const isGoal = type === 'goal';
  const isExitCondition = type === 'exitCondition';
  const requests = [];

  let successHeader = t('triggers.triggerSaved');
  if (isGoal) successHeader = t('goals.goalSaved');
  if (isExitCondition) successHeader = t('exits.exitConditionSaved');

  const updateCriteriaPayload = {
    journeyId,
    criteria: shapeCriteriaRequest(criteriaFormState.criteria),
  };

  requests.push(TriggersService.updateCriteria(triggerId, updateCriteriaPayload));

  const initialTitle = trigger.name;
  const currentTitle = triggerTitleFormState.title;

  const hasTitleChanged = initialTitle !== currentTitle;

  if (hasTitleChanged) {
    const updateTitlePayload = { name: currentTitle };
    requests.push(TriggersService.updateTitle(triggerId, updateTitlePayload));
  }

  return Promise.all(requests).then(() => {
    showSuccess({ header: successHeader });
    fetchJourneyData();
    return JourneyDataService.refreshJourney(journeyId, setJourneyContents);
  });
};

export const getTitleAndSubmitHandler = (triggerId, parentStepId, t, type) => {
  const isGoal = type === 'goal';
  const isExitCondition = type === 'exitCondition';

  if (!triggerId && !parentStepId && !isGoal && !isExitCondition) {
    return {
      pageTitle: t('triggers.createStartTrigger'),
      submitHandler: handleCreateStartTrigger,
    };
  }

  if (!triggerId && parentStepId && !isGoal && !isExitCondition) {
    return {
      pageTitle: t('triggers.createTrigger'),
      submitHandler: handleCreateTriggerAfterStep,
    };
  }
  if (triggerId && !isGoal && !isExitCondition) {
    return {
      pageTitle: t('triggers.editTrigger'),
      submitHandler: handleUpdateTrigger,
    };
  }
  if (triggerId && !isExitCondition && isGoal) {
    return {
      pageTitle: t('goals.editGoal'),
      submitHandler: handleUpdateTrigger,
    };
  }
  if (triggerId && isExitCondition && !isGoal) {
    return {
      pageTitle: t('exits.editExitCondition'),
      submitHandler: handleUpdateTrigger,
    };
  }
  if (isGoal && !isExitCondition) {
    return {
      pageTitle: t('goals.createGoal'),
      submitHandler: handleCreateGoal,
    };
  }

  return {
    pageTitle: t('exits.createExitCondition'),
    submitHandler: handleCreateExitCondition,
  };
};

export const removeItemsNotAvailableInOrchestration = ruleTypesTree =>
  ruleTypesTree.filter(
    typesGroup => typesGroup.type !== RulesTypes.ReusableAudience && typesGroup.type !== RulesTypes.Experiment,
  );
