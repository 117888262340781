import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormikInputField, FormikDateField } from '~/components/src/Form/Fields/FormikFields';
import ActionButtons from '~/components/src/ActionButtons';
import i18n from '~/i18n';
import moment, { Moment } from 'moment';
import { getAngularService } from 'ReactAngular/react.service';
import { Notification } from '~/components';

type NewSnapshotProps = {
  hideModal: () => void;
  createSnapshot: (values: any) => void;
};

const today = moment();
const initialValues = {
  snapshotName: '',
  startTs: null,
  endTs: null,
};

const validationSchema = (startDate: Moment) =>
  Yup.object().shape({
    snapshotName: Yup.string().trim().required(i18n.t('validation:validation.required')),
    startTs: Yup.date()
      .nullable()
      .min(
        startDate.toISOString(),
        i18n.t('validation:validation.startTimeIsAfter', { date: startDate.format('DD/MM/YYYY') }),
      )
      .max(today.toISOString(), i18n.t('validation:validation.startTimeIsInFuture'))
      .required(i18n.t('validation:validation.required')),
    endTs: Yup.date()
      .nullable()
      .min(Yup.ref('startTs'), i18n.t('validation:validation.endTimeisAfterStartTime'))
      .max(today.toISOString(), i18n.t('validation:validation.endTimeIsInFuture'))
      .required(i18n.t('validation:validation.required')),
  });

function NewSnapshot({ hideModal, createSnapshot }: NewSnapshotProps): JSX.Element {
  const SecurityService = getAngularService(document, 'SecurityService');
  const [startDate, setStartDate] = useState<Moment>(today);

  useEffect(() => {
    SecurityService.getSecurityContext().then((context: any) => {
      const { currentSiteId, sites } = context;
      const dataRetentionMonths = sites[currentSiteId]?.dataRetentionMonths || 0;
      const startDate = dataRetentionMonths
        ? moment().subtract(dataRetentionMonths, 'months')
        : moment().subtract(1, 'days');
      setStartDate(startDate);
    });
  }, []);

  const valid = (current: Moment) => current.isBefore(today) && current.isAfter(startDate);

  return (
    <div>
      <Formik onSubmit={createSnapshot} validationSchema={validationSchema(startDate)} initialValues={initialValues}>
        {({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="snapshotName"
              as={FormikInputField}
              label={i18n.t('ai:new.name')}
              placeholder={i18n.t('ai:new.placeholder')}
              errorText={errors.snapshotName}
              autoFocus={true}
            />
            <Field
              name="startTs"
              as={FormikDateField}
              label={i18n.t('ai:new.startFrame')}
              errorText={errors.startTs}
              className="SnapshotForm-date"
              isValidDate={valid}
            />
            <Field
              name="endTs"
              as={FormikDateField}
              label={i18n.t('ai:new.endFrame')}
              errorText={errors.endTs}
              className="SnapshotForm-date"
              isValidDate={valid}
            />
            <Notification kind="information" className="SnapshotForm-message">
              <p>{i18n.t('ai:snapshotsPage.new.dataRetention')}</p>
            </Notification>
            <ActionButtons
              onConfirm={handleSubmit}
              onDecline={hideModal}
              className="m-0 mt-4"
              confirmText="Create"
              testHook="saveSnapshot"
            />
          </form>
        )}
      </Formik>
    </div>
  );
}

export default NewSnapshot;
