import React from 'react';
import { Formik, Field } from 'formik';
import cx from 'classnames';
import { translate } from 'react-i18next';
import { useAPI } from '~/common/ApiHooks';
import { isRequired } from '~/common';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { Notification } from '~/components';
import Spinner from '~/components/src/Spinner';
import JourneyDataService from '~/customer/journeys/dataService';
import ActionButtons from '~/components/src/ActionButtons';
import { showSuccess } from '~/notificationCenter';
import './styles.scss';
import { getExperimentNodes } from '../utils';

const validateWeight = (allValues, variantId, t) => value => {
  if (value === '') {
    return t('experimentModal.validations.notEmpty');
  }
  if (Number(value) + 0 !== Number(value)) {
    return t('experimentModal.validations.isNumber');
  }
  if (Number(value) < 0 || Number(value) > 100) {
    return t('experimentModal.validations.isPositive');
  }

  return undefined;
};

const hasError = (formProps, index, fieldName) => {
  const touched = formProps.touched.weights;
  const errors = formProps.errors.weights;
  if (!touched || !errors) {
    return false;
  }
  return touched[index] && touched[index][fieldName] && errors[index] && errors[index][fieldName];
};

const hasWeightError = values =>
  Object.values(values?.weights).reduce((acc, variant) => acc + (variant.weight || 0), 0) !== 100;

const hasUniqueNameError = values => {
  const names = Object.values(values?.weights)?.map(({ name }) => name.toLowerCase()) || [];

  return names.length !== new Set(names).size;
};

const hasValidationsError = values => hasWeightError(values) || hasUniqueNameError(values);

const EditExperiment = ({ hideModal, t, setJourneyContents, journeyId, fetchJourneyData }) => {
  const { data: journeyContents } = useAPI(() => JourneyDataService.fetchJourneyTree(journeyId));
  const { experimentSteps } = getExperimentNodes(journeyContents?.nodes || []);

  const initialValue = experimentSteps
    .map(step => ({
      ...(step?.experimentVariant ?? {}),
    }))
    .reduce(
      (acc, val) => ({
        ...acc,
        [val.experimentVariantId]: { ...val },
      }),
      {},
    );

  const onSubmit = async ({ weights }) => {
    const payload = Object.values(weights);

    await JourneyDataService.updateExperiments({ experimentVariantConfig: payload });
    hideModal();
    fetchJourneyData();
    JourneyDataService.refreshJourney(journeyId, setJourneyContents);
    showSuccess({
      header: t('journeyModal.successToast'),
    });
  };

  return (
    <div className="EditExperiment">
      {!journeyContents ? (
        <Spinner className="EditExperiment-spinner" />
      ) : (
        <Formik initialValues={{ weights: initialValue }} onSubmit={onSubmit}>
          {formProps => (
            <form className="EditExperiment-form" onSubmit={formProps.handleSubmit}>
              <Notification kind="information" header="" className="">
                <span>{t('experimentModal.weightTip')}</span>
              </Notification>

              {experimentSteps.map(step => {
                const experimentVariantId = step?.experimentVariant?.experimentVariantId;
                return (
                  <div key={experimentVariantId}>
                    <div className="EditExperiment-propertyRow" key={experimentVariantId}>
                      <div className="EditExperiment-propertyRow-variantName">
                        <Field
                          name={`weights[${experimentVariantId}].name`}
                          as={FormikInputField}
                          label={step.label}
                          hintText={t('experimentModal.hintText.variantName')}
                          validate={isRequired}
                          className={cx(`${experimentVariantId}-variantName`, {
                            'is-error': hasError(formProps, experimentVariantId, 'name'),
                          })}
                          placeholder={t('experimentModal.placeholders.name')}
                        />
                      </div>
                      <div className="EditExperiment-propertyRow-weight">
                        <Field
                          type="number"
                          as={FormikInputField}
                          hintText={t('experimentModal.hintText.weight')}
                          validate={validateWeight(formProps.values.weights, experimentVariantId, t)}
                          name={`weights[${experimentVariantId}].weight`}
                          placeholder={t('experimentModal.placeholders.weight')}
                          className={cx(`${experimentVariantId}-weight`, {
                            'is-error': hasError(formProps, experimentVariantId, 'weight'),
                          })}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              {hasWeightError(formProps?.values) && (
                <Notification testHook="experimentError" kind="error" className="mt-4">
                  <span>{t('experimentModal.weightError')}</span>
                </Notification>
              )}

              {hasUniqueNameError(formProps?.values) && (
                <Notification testHook="uniqueNameError" kind="error" className="mt-4">
                  <span>{t('experimentModal.uniqueNameError')}</span>
                </Notification>
              )}
              <ActionButtons
                className="mr-0"
                onConfirm={formProps.handleSubmit}
                onDecline={hideModal}
                confirmText={t('experimentModal.update')}
                declineText={t('experimentModal.cancel')}
                isConfirmEnabled={formProps.isValid && !hasValidationsError(formProps?.values)}
                testHook="editExperiment"
              />
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default translate('journey')(EditExperiment);
