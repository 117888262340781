export const getMandatoryProperties = availableFilters => {
  const mandatoryFilters = availableFilters.filter(({ mandatory }) => mandatory);
  return mandatoryFilters.map(({ dataField, availableConstraints, restrictedValues }) => ({
    dataField,
    // Filters with "mandatory": true have defaultConstraint value in "availableConstraints" instead of "defaultConstraint"
    defaultConstraint: availableConstraints[0],
    constraint: availableConstraints[0],
    filterValue: restrictedValues[0],
  }));
};
