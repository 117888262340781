import React from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import Icons from '~/components/src/Icons';
import { Tooltip } from '~/components';
import { HpOptionIds } from '../constants';
import { useHPContext } from '../context';
import { HpOptionsType } from '../types';

export const HpOptionSelector = ({ disabled = false }: { disabled?: boolean }): React.ReactElement => {
  const hpContext = useHPContext();

  const { selectedHpOptions, setSelectedHpOptions, reprocessingModes, hasExits, hasGoals } = hpContext;

  function handleOptions(isOptionSelected: boolean, option: HpOptionsType) {
    if (isOptionSelected) {
      setSelectedHpOptions(selectedHpOptions.filter(({ id }) => id !== option.id));
    } else {
      setSelectedHpOptions([...selectedHpOptions, option]);
    }
  }

  return (
    <div className="flex gap-2">
      {reprocessingModes?.map((option, index) => {
        const isOptionSelected = selectedHpOptions.some(({ id }) => id === option.id) || false;
        const isOptionDisabled =
          (option.id === HpOptionIds.REEVALUATE_EXIT_CONDITIONS && !hasExits) ||
          (option.id === HpOptionIds.REEVALUATE_GOALS && !hasGoals);

        return (
          <Tooltip key={`option-${index}`} tooltip={isOptionDisabled ? option.disabledTooltip : ''}>
            <div
              className={twMerge(
                cx(
                  `t-${option.id} relative flex flex-1 cursor-pointer flex-col items-center gap-4 rounded border border-gray-200 p-4 hover:border-blue-400`,
                  {
                    'border-blue-500 bg-blue-50': isOptionSelected,
                    'cursor-not-allowed border-gray-100 bg-gray-100 hover:border-gray-100': isOptionDisabled,
                    'cursor-default': disabled,
                  },
                ),
              )}
              onClick={() => (!disabled && !isOptionDisabled ? handleOptions(isOptionSelected, option) : null)}
            >
              <Icons
                icon={isOptionSelected ? 'done' : 'blank'}
                className={twMerge(
                  cx('absolute right-1 top-1 h-4 w-4 rounded-full border p-0.5', {
                    'border-blue-500 bg-blue-500 text-white': isOptionSelected,
                    'border-gray-300 bg-gray-200 text-white': isOptionDisabled,
                  }),
                )}
              />

              <div className={cx('flex items-center gap-2', `text-${option.color}-500`)}>
                <Icons icon={option.icon} className={cx('h-8 w-8 rounded-full border p-1.5')} />
                <p className="font-medium">{option.title}</p>
              </div>
              <p className="text-center text-gray-600">{option.description}</p>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
