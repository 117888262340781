import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import ActionButtons from '~/components/src/ActionButtons';
import { hideModal as defaultHideModalAction } from '../actions';
import ModalWrapper from './ModalWrapper';
import ModalHeader from './ModalHeader';

import './styles.scss';

const DeleteModal = ({ title, message, onConfirm, hideModal, className, isHideAfterConfirm = true }) => {
  const handleConfirm = isConfirmed => () => {
    if (isConfirmed) {
      onConfirm().then(() => {
        if (isHideAfterConfirm) hideModal();
      });
    } else {
      hideModal();
    }
  };

  return (
    <ModalWrapper className={cx('DeleteModal-content', className)}>
      <ModalHeader title={title} onCloseClick={handleConfirm(false)} />
      <div className="Modal-body p-4">{message}</div>
      <ActionButtons
        onDecline={handleConfirm(false)}
        onDelete={handleConfirm(true)}
        testHook="deleteModalActions"
        className="pr-4 pb-4 pt-4"
      />
    </ModalWrapper>
  );
};

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default connect(null, (dispatch, ownProps) => {
  const customHideModalAction = ownProps.hideModal;
  const hideModalAction = customHideModalAction || defaultHideModalAction;
  return { hideModal: () => dispatch(hideModalAction()) };
})(DeleteModal);
