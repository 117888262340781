import Api, { API_BASE } from '~/common/ApiService';
import i18n from '~/i18n';
import { PartnerDetailsBase } from '~/profiles/types';
import { VariableModilficationProperty } from '~/customer/journeys/types';

const BASE_PATH = `${API_BASE}/customer/journeys/steps`;

type ChangeType = 'NEW' | 'MODIFIED' | 'REMOVED';

export interface ActivePartnerResponseItem {
  name: string;
  variant: null;
  partnerDetails: PartnerDetailsBase;
  supportedChangeTypes: ChangeType[];
  activated: boolean;
}

export interface VariableModilficationPayloadItem {
  ruleDefinitionId: string;
  ruleType: { name: string; label: string };
  properties: VariableModilficationProperty[];
}

interface StepPayload {
  name: string;
  journeyId: string;
  integrations: PartnerDetailsBase[];
  parentTriggerId: string;
  variableModifications: VariableModilficationPayloadItem[];
}

export const fetchStep = (stepId: string) => Api.callGet(`${BASE_PATH}/${stepId}`).then(response => response.data);

export const getActivePartners = () =>
  Api.callGet(`${API_BASE}/journeyorchestration/partners`).then(
    response => response.data as ActivePartnerResponseItem[],
  );

export const getPreviousTrigger = (stepId: string) =>
  Api.callGet(`${BASE_PATH}/${stepId}/previousTrigger`).then(response => response.data);

export const createStep = (payload: StepPayload) =>
  Api.callPost(`${BASE_PATH}`, payload, {
    toastText: i18n.t('orchestration:steps.createStepRequestFailed'),
  });

export const updateStep = (payload: StepPayload) =>
  Api.callPut(`${BASE_PATH}`, payload, {
    toastText: i18n.t('orchestration:steps.updateStepRequestFailed'),
  });
