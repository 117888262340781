export default {
  CLANG: 'clang',
  CLANG_BATCH: 'clang batch',
  DOUBLE_CLICK: 'doubleclick',
  AMNET: 'amnet',
  EXACT_TARGET: 'exacttarget',
  FACEBOOK: 'facebook',
  DOUBLE_CLICK_OFFLINE: 'doubleclick offline',
  DOUBLE_CLICK_OFFLINE_API_PARAM: 'doubleClickOffline',
  TWITTER_ID: 'TWITTER_ID',
  HASHED_PHONE: 'HASHED_PHONE',
  HASHED_ID: 'HASHED_ID',
  HASHED_EMAIL: 'HASHED_EMAIL',
  HASHED_MOBILE_ADVERTISER_ID: 'HASHED_MOBILE_ADVERTISER_ID',
  STANDARD: 'STANDARD',
  OAUTH: 'OAUTH',
  WEADAPT: 'weadapt',
  TURN: 'turn',
  SELLIGENT: 'selligent',
  MEDIA_MATH: 'mediamath',
  MOB_PRO: 'mobpro',
  PLATFORM_161: 'platform161',
  ADFORM: 'adform',
  OPTIMIZELY: 'optimizely',
  RELAY42_API: 'relay42 streaming api',
  ADWORDS: 'adwords',
  GOOGLE_ADS: 'googleAds',
  YAHOO: 'yahoo',
  LINKEDIN: 'linkedin',
  GOOGLE_CUSTOMER_MATCH: 'googlecustomermatch',
  refreshToken: 'refreshToken',
  TRIPOLIS: 'tripolis',
  APPNEXUS: 'appnexus',
  ADNUNTIUS: 'adnuntius',
  CUSTOM: 'custom',
  KINESIS: 'kinesis',
  GOOGLE_PUBSUB: 'Google Pub/Sub',
  MAILCHIMP: 'mailchimp',
  SALESFORCE: 'salesforce',
  TRADEDESK: 'tradedesk',
  ADFORM_PARTNER_NUMBER: 13,
  ORACLE_RESPONSYS_PARTNER_NUMBER: 27,
  FIRST_PARTY_ID: 'FIRST_PARTY_ID',
  CUSTOMER_ID: 'CUSTOMER_ID',
  DIY: 'DIY',
  Filter: 'Filter',
  Transformation: 'Transformation',
  CLANG_PARTNER_NUMBERS: {
    HASHED_EMAIL: 1012,
    CLANG_ID: 1010,
  },
  CLANG_BATCH_PARTNER_NUMBER: {
    HASHED_EMAIL: 1022,
    CLANG_ID: 1020,
  },
  ADWORDS_PARTNER_NUMBERS: {
    HASHED_EMAIL: 7001,
    HASHED_PHONE: 7004,
    CRM_ID: 2001,
    MOBILE_ID: 6001,
  },
  DOUBLE_CLICK_PARTNER_NUMBERS: {
    MOBILE: 6001,
    WEB: 7,
    CRM_ID: 2001,
  },
  TWITTER_PARTNER_NUMBERS: {
    HASHED_EMAIL: 7001,
    TWITTER_ID: 7011,
  },
  AMAZON_DSP_PARTNER_NUMBERS: {
    AMAZON_COOKIE: 28,
    MOBILE_ADVERTISER_ID: 6001,
    EXTERNAL_USER_ID: 31,
  },
  partnerTypes: {
    CLANG: 'CLANG',
    CLANG_BATCH: 'CLANG_BATCH',
    DOUBLE_CLICK: 'DOUBLE_CLICK',
    DOUBLE_CLICK_OFFLINE: 'DOUBLE_CLICK_OFFLINE',
    AMNET: 'AMNET',
    EXACT_TARGET: 'EXACT_TARGET',
    FACEBOOK: 'FACEBOOK',
    FACEBOOK_CONVERSIONS: 'FACEBOOK_CONVERSIONS',
    WEADAPT: 'WEADAPT',
    TURN: 'TURN',
    SELLIGENT: 'SELLIGENT',
    MEDIA_MATH: 'MEDIA_MATH',
    MOB_PRO: 'MOB_PRO',
    CLICK_DISTRICT: 'CLICK_DISTRICT',
    ADFORM: 'ADFORM',
    OPTIMIZELY: 'OPTIMIZELY',
    RELAY42_API: 'RELAY42_API',
    ADWORDS: 'ADWORDS',
    GOOGLE_ADS: 'GOOGLE_ADS',
    TRIPOLIS: 'TRIPOLIS',
    APPNEXUS: 'APPNEXUS',
    ADNUNTIUS: 'ADNUNTIUS',
    CUSTOM: 'CUSTOM',
    KINESIS: 'KINESIS',
    GOOGLE_PUBSUB: 'GOOGLE_PUBSUB',
    MAILCHIMP: 'MAILCHIMP',
    ADOBE: 'ADOBE',
    SALESFORCE: 'SALESFORCE',
    TRADEDESK: 'TRADEDESK',
    TWITTER: 'TWITTER',
    AZURE_EVENTHUB: 'AZURE_EVENTHUB',
    MARKETO: 'MARKETO',
    AIRSHIP: 'AIRSHIP',
    ORACLE_RESPONSYS: 'ORACLE_RESPONSYS',
    DIY: 'DIY',
    AMAZON_DSP: 'AMAZON_DSP',
    YAHOO: 'YAHOO',
    LINKEDIN: 'LINKEDIN',
    GOOGLE_CUSTOMER_MATCH: 'GOOGLE_CUSTOMER_MATCH',
  },
  modes: {
    CREATE_SERVER: 'CREATE_SERVER',
    CREATE_EVENT: 'CREATE_EVENT',
    EDIT_SERVER: 'EDIT_SERVER',
    EDIT_EVENT: 'EDIT_EVENT',
  },
  errorStackColors: [
    '#D50000',
    '#F4511E',
    '#FF1744',
    '#FF5252',
    '#FF8A80',
    '#F44336',
    '#E57373',
    '#F06292',
    '#F50057',
    '#FF6D00',
  ],
  // Grey shades
  infoStackColors: ['#91A3B0', '#848482', '#98AFC7', '#A9A9A9', '#696969', '#808080'],
};
