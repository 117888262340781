import { uniq } from 'lodash';

const removeCustomTypes = propertyTypes => propertyTypes.filter(propertyType => propertyType.dataField !== null);

const removeUnfinishedFields = configuredProperties =>
  configuredProperties.filter(configuredProperty => configuredProperty.dataField !== undefined);

export const getPropertySelectOptions = (propertyTypes, configuredProperties = []) => {
  // "Property" select options are:
  // available property types + all custom properties created by user
  const nonCustomTypes = removeCustomTypes(propertyTypes).map(nonCustomType => nonCustomType.dataField);

  const allUsedTypes = removeUnfinishedFields(configuredProperties).map(dataField => dataField.dataField);

  const uniqueTypes = uniq([...nonCustomTypes, ...allUsedTypes]);

  return uniqueTypes.map(propertyTypeName => ({
    value: propertyTypeName,
    label: propertyTypeName,
  }));
};
