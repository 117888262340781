import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import { ContextEffect } from '~/common';

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('Required'),
});

const getOnChangeArgument = ({ isValid, values }) => ({
  isValid,
  title: values.title,
});

const JoNodeTitleForm = ({ titleValue, setTitleFormState, label, wasSubmitButtonClicked }) => {
  const initialValues = {
    title: titleValue,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {({ errors }) => (
        <div className="JourneyView-nodeTitleField">
          <ContextEffect onChange={setTitleFormState} formTransformations={getOnChangeArgument} />
          <Field
            as={FormikInputField}
            name="title"
            label={label}
            errorText={wasSubmitButtonClicked && errors.title}
            autoFocus={true}
          />
        </div>
      )}
    </Formik>
  );
};

export default JoNodeTitleForm;
