function requireAll(context) {
    context.keys().forEach(context);
}

require('./libs');

require('./templates');

// Require CSS files
require('./less-libs.less');
require('./theme-base.less');
require('./less/main.less');
require('./client/components/src/Common/global.scss');
require('./client/components/src/Common/utils/index.scss');
require('./main.css');

// Collect all angular modules
requireAll(require.context('./common', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./app/react', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./app/ui', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./app/service', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./app/filter', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./app/directive', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./app/component', true, /^(?!.*\.spec\.js$).*\.js$/));

require('./app/app');
require('./client/index.js');

requireAll(require.context('./components', true, /\.less$/));
requireAll(require.context('./app', true, /\.less$/));

// Require assets
requireAll(require.context('./assets', true, /\.(png|jpg|svg|gif)$/));
requireAll(require.context('./assets', true, /\.(eot|ttf|woff|woff2)(\?v=\d+\.\d+\.\d+)?$/));
