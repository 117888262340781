import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import BtnIcon from '~/components/src/BtnIcon';
import './styles.scss';

export const EditableRowControls = ({ onHandleAdd, onHandleCancel, isAddButtonEnabled = true, className }) => {
  const addButton = onHandleAdd ? (
    <BtnIcon icon="done" testHook="acceptButton" disabled={!isAddButtonEnabled} onClick={() => onHandleAdd()} />
  ) : (
    <BtnIcon icon="done" testHook="acceptButton" disabled={!isAddButtonEnabled} type="submit" />
  );
  return (
    <div className={cx('right-0 flex items-center gap-4', className)}>
      {addButton}
      <BtnIcon icon="close" onClick={() => onHandleCancel()} testHook="cancelButton" />
    </div>
  );
};

EditableRowControls.propTypes = {
  onHandleAdd: PropTypes.func,
  onHandleCancel: PropTypes.func,
};

export default EditableRowControls;
