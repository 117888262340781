import Api, { API_BASE } from '~/common/ApiService';
import i18n from '~/i18n';

const BASE_PATH = `${API_BASE}/customer/journeys/triggers`;

export const fetchTrigger = triggerId => Api.callGet(`${BASE_PATH}/${triggerId}`).then(response => response.data);

export const updateTitle = (triggerId, payload) =>
  Api.callPost(`${BASE_PATH}/${triggerId}/updateName`, payload, {
    toastText: i18n.t('orchestration:triggers.updateTitleRequestFailed'),
  });

export const updateCriteria = (triggerId, payload) =>
  Api.callPost(`${BASE_PATH}/${triggerId}/updateCriteria`, payload, {
    toastText: i18n.t('orchestration:triggers.updateCriteriaRequestFailed'),
  });
