import React, { useState } from 'react';

import { Field, Formik, FormikErrors } from 'formik';

import i18n from '~/i18n';
import { useAPI } from '~/common';
import Spin from '~/components/src/Spin';
import { Notification } from '~/components';
import ActionButtons from '~/components/src/ActionButtons';
import scrollIntoView from '~/common/utils/scrollIntoView';
import { fetchSnapshot } from '~/ai/Snapshots/dataService';
import { SnapshotProperty, SnapshotType } from '~/ai/types';
import { SnapshotStatus } from '~/ai/Snapshots/SnapshotTable';
import { FormikInputField, FormikSelectField } from '~/components/src/Form/Fields/FormikFields';

import ModelAdvanceSettings from './ModelAdvanceSettings';
import { Model, ModelFormProps, OptionType } from '../types';
import PredictionsForm from '../predictions/PredictionsForm';
import ModelPredictiveProperties from './ModelPredictiveProperties';
import { mapSnapshot, modelInitialValues, modelValidationSchema } from '../util';
import { fetchModelById, fetchModelConfig, fetchSnapshots } from '../dataService';

import '../styles.scss';

const getErrorMessages = (
  isPropertiesLoading: boolean,
  snapshotProperties: SnapshotProperty[],
  errors: FormikErrors<Model>,
) => {
  if (isPropertiesLoading) return null;
  if (typeof errors.snapshotId === 'string' && snapshotProperties.length === 0)
    return i18n.t('ai:validations.noSnapshotsSelected');
  if (snapshotProperties.length === 0) return i18n.t('ai:validations.noSnapshotsProperties');
  if (typeof errors.predictions === 'string') return errors.predictions;
  return null;
};

const ModelForm = ({ modelId, isEdit, goToModelsList, handleCreateModel }: ModelFormProps): React.ReactElement => {
  const [selectedSnapshotId, setSelectedSnapshotId] = useState<string>();
  const { data: modelConfig } = useAPI(() => fetchModelConfig());

  // TODO: Filter logic can be removed post MVP
  // const { data:snapshots } = useAPI(() => fetchSnapshots());
  const { data: snapshots } = useAPI(async () => {
    const data = await fetchSnapshots();
    return data
      ?.filter((snapshot: SnapshotType) => snapshot?.status === SnapshotStatus.FINISHED)
      .map((snapshot: SnapshotType) => ({ label: snapshot?.snapshotName, value: snapshot.snapshotId }));
  });

  const { data: modelData, isLoading: isModelDataLoading } = useAPI(async () => {
    if (modelId) {
      const data = await fetchModelById(modelId);
      setSelectedSnapshotId(data?.snapshotId || '');
      return data;
    }
    return Promise.resolve(null);
  }, [modelId]);

  const { data: snapshotPropertiesResp, isLoading: isPropertiesLoading } = useAPI(async () => {
    if (selectedSnapshotId) {
      const data = await fetchSnapshot(selectedSnapshotId);
      return mapSnapshot(data);
    }
    return Promise.resolve([]);
  }, [selectedSnapshotId]);

  const snapshotProperties = snapshotPropertiesResp || [];

  const initialValues = modelData || modelInitialValues;
  const validationSchema = modelValidationSchema(modelData?.maxPredictionDays, modelConfig?.maxInputsForAIModel);

  if (isEdit && isModelDataLoading) return <Spin />;

  return (
    <div>
      <Formik
        onSubmit={handleCreateModel}
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
      >
        {({ handleSubmit, errors, values, touched, setFieldValue }) => {
          const errorMessage = getErrorMessages(isPropertiesLoading, snapshotProperties, errors);
          return (
            <form onSubmit={handleSubmit}>
              <div className="Models-form1">
                <Field
                  name="modelName"
                  as={FormikInputField}
                  label={i18n.t('ai:new.name')}
                  placeholder={i18n.t('ai:new.placeholder')}
                  errorText={errors?.modelName}
                  autoFocus={true}
                />
                <Field
                  name="snapshotId"
                  as={FormikSelectField}
                  label={i18n.t('ai:new.snapshot')}
                  errorText={errors.snapshotId}
                  getOptionLabel={(option: { label: string; value: string }) => option.label}
                  getOptionValue={(option: { label: string; value: string }) => option.value}
                  customSetFieldValue={(name: string, option: OptionType) => {
                    setSelectedSnapshotId(option.value);
                    setFieldValue(name, option.value);
                  }}
                  options={snapshots || []}
                />
              </div>
              {isEdit && <Notification kind="warning">{i18n.t('ai:messages.snapshotChangeWarning')}</Notification>}
              <div>
                {snapshotProperties?.length > 0 && (
                  <PredictionsForm predictions={values.predictions} errors={errors} properties={snapshotProperties} />
                )}
                <br />
                {touched.snapshotId && errorMessage && <Notification kind="error">{errorMessage}</Notification>}
              </div>
              {snapshotProperties.length > 0 && (
                <>
                  <hr className="py-4" />
                  <ModelPredictiveProperties snapshotProperties={snapshotProperties} values={values} errors={errors} />
                  <hr className="py-4" />
                  <ModelAdvanceSettings values={values} errors={errors} />
                </>
              )}
              <ActionButtons
                confirmText={i18n.t(`ai:new.${isEdit ? 'update' : 'create'}`)}
                onConfirm={values => {
                  const err = Object.keys(errors);
                  if (err.length) {
                    if (errors?.predictions && Array.isArray(errors?.predictions)) {
                      const predictionIndex = errors.predictions.findIndex(ep => ep !== undefined);
                      scrollIntoView(`[id*="predictions.${predictionIndex}"]`);
                    } else scrollIntoView(`input[name=${err[0]}]`);
                  }
                  handleSubmit(values);
                }}
                onDecline={() => goToModelsList()}
                className="mr-0"
                testHook="saveModel"
              />
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ModelForm;
