import React, { ReactElement, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { buildUrl, changeUrl, useAPI } from '~/common';
import { Heading, Notification } from '~/components';
import i18n from '~/i18n';
import BtnIcon from '~/components/src/BtnIcon';
import Spin from '~/components/src/Spin';
import EditableEventListenerList from '~/customer/components/EditStep/EditableEventListenerList';
import { getConnectorLogoUrl } from '~/profiles/helpers/connectorsHelper';
import { getFilter } from './dataService';
import { FilterDependentType } from './types';
import { FiltersContext } from './FiltersContext';

function FilterView(): ReactElement {
  const params = useParams();
  const filterId = params.filterId || '';
  const { canEditProfiles } = useContext(FiltersContext);

  const { data: filter, isLoading } = useAPI(() => getFilter(filterId));

  if (isLoading) return <Spin />;

  return (
    <div className="flex h-full flex-1 flex-col items-center bg-gray-50">
      <div className="w-2/3">
        <Heading
          testHook="filterHeader"
          title={filter?.name || i18n.t('filters:list.header')}
          crumbs={[
            {
              title: i18n.t('filters:back'),
              pathname: buildUrl('profiles/filters'),
            },
          ]}
          truncateHeader
        >
          {canEditProfiles && (
            <BtnIcon
              onClick={() => changeUrl(`profiles/filters/edit/${filterId}`)}
              testHook="editFilter"
              color="blue"
              icon="edit"
              tooltip={i18n.t('filters:tooltips.edit')}
            />
          )}
        </Heading>
        <div className="flex w-full flex-col gap-2">
          <p className="text-xl text-blue-600">{i18n.t('filters:form.events')}</p>
          {filter?.rules.length ? (
            <EditableEventListenerList
              eventsListeners={filter?.rules}
              showRulePicker={false}
              viewOnly={true}
              onChange
            />
          ) : (
            <Notification kind="information">
              <p> {i18n.t('filters:messages.noEvents')}</p>
            </Notification>
          )}
        </div>
        <div className="mt-8 flex w-full flex-col gap-2">
          <p className="text-xl text-green-600">{i18n.t('filters:dependencies.title')}</p>
          {filter?.dependants?.length ? (
            filter?.dependants.map((dependant: FilterDependentType) => (
              <div
                className="t-filterDependants flex h-16 cursor-pointer items-center gap-4 rounded border border-gray-300 bg-white px-4 py-1 hover:bg-gray-50"
                onClick={() => changeUrl(`profiles/partners/editEvent/${dependant.id}`)}
              >
                <img
                  alt={dependant.name}
                  className="!w-10"
                  src={getConnectorLogoUrl(dependant.id, dependant.partnerType, true) || ''}
                />
                <div>
                  <p className="text-lg font-medium text-gray-800">{dependant.name}</p>
                </div>
              </div>
            ))
          ) : (
            <Notification kind="information">
              <p>{i18n.t('filters:messages.noDependencies')}</p>
            </Notification>
          )}
        </div>
      </div>
    </div>
  );
}

export default FilterView;
