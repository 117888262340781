import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '~/components/src/Link';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import Spinner from '~/components/src/Spinner';
import PageTitleHeader from '~/customer/components/PageTitleHeader/index.js';
import JourneyDataService from '~/customer/journeys/dataService';
import { buildUrl } from '~/common';
import BtnIcon from '~/components/src/BtnIcon';
import i18n from '~/i18n';
import Btn from '~/components/src/Btn';
import Icons from '~/components/src/Icons';
import icons from '~/components/src/Icons/icons';
import EditExperimentTrigger from '../EditExperiment/EditExperimentTrigger';
import EditJourneyTrigger from '../EditJourney/EditJourneyTrigger';
import { HP_STATUS } from '../constants';
import {
  JourneyHeaderProps,
  TriggerHistoricProcesingButtonProps,
  JourneyNode,
  EmptyJourneyContents,
  PublishButtonProps,
  JOStatusTagProps,
  VersionStatusProps,
} from './types';
import './styles.scss';

const JO_STATUS = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
};

const formatJourneyExpiryTime = (expiryTime: string) => {
  const numberValue = expiryTime.substring(0, expiryTime.length - 1);
  const timeValue = expiryTime[expiryTime.length - 1];
  switch (timeValue) {
    case 'd':
      return `${numberValue} Days`;
    case 'm':
      return `${numberValue} Minutes`;
    case 'h':
      return `${numberValue} Hours`;
    case 'w':
      return `${numberValue} Weeks`;
  }
  return expiryTime;
};

const TriggerHistoricProcesingButton = ({
  hpJobData,
  eligible,
  isHpRunning,
  isHpScheduled,
  canEdit,
  journeyId,
  fetchJourneyData,
  showHistoricProcessingModal,
  showHistoricProcessRunningModal,
}: TriggerHistoricProcesingButtonProps): JSX.Element => {
  const isHpCancelling = hpJobData.state?.name === HP_STATUS.CANCELLING;
  const isHpCancelFailed = hpJobData.state?.name === HP_STATUS.CANCELLATION_FAILED;

  let historicProcessingTooltip = '';

  if (isHpRunning || isHpScheduled) {
    historicProcessingTooltip = i18n.t('orchestration:tooltips.journeyQueuedForHistoricProcessing');
  } else if (isHpCancelling) {
    historicProcessingTooltip = i18n.t('orchestration:tooltips.hpJobCancelling');
  } else if (isHpCancelFailed) {
    historicProcessingTooltip = i18n.t('orchestration:tooltips.hpCancelFailed');
  } else if (eligible) {
    historicProcessingTooltip = i18n.t('orchestration:tooltips.triggerHistoricProcessing');
  } else {
    historicProcessingTooltip = i18n.t('orchestration:tooltips.publishStartTriggerChangeFirst');
  }

  let icon: keyof typeof icons = 'historicProcessing';
  if (isHpRunning) icon = 'hourglass';
  if (isHpScheduled) icon = 'schedule';
  if (isHpCancelling) icon = 'retract';
  if (isHpCancelFailed) icon = 'unpublished';

  if (isHpRunning || isHpScheduled || isHpCancelFailed) {
    return (
      <BtnIcon
        icon={icon}
        color={isHpCancelFailed ? 'red' : 'amber'}
        onClick={() => {
          if (journeyId) showHistoricProcessRunningModal(hpJobData.jobId, fetchJourneyData);
        }}
        testHook={`triggerHpButton-${icon}Icon`}
        tooltip={i18n.t('orchestration:tooltips.journeyQueuedForHistoricProcessing')}
      />
    );
  }

  return (
    <>
      <BtnIcon
        disabled={!canEdit || !eligible}
        icon={icon}
        onClick={() => {
          if (journeyId) showHistoricProcessingModal(fetchJourneyData);
        }}
        testHook={`triggerHpButton-${icon}Icon`}
        tooltip={canEdit ? historicProcessingTooltip : i18n.t('orchestration:permissions.notEnoughPermissions')}
      />
    </>
  );
};

const PublishButton = ({
  isDemo,
  isLatestVersion,
  canEdit,
  state,
  validateJourney,
  isHpRunningOrScheduled,
}: PublishButtonProps) => {
  function getTooltip() {
    if (!canEdit && state.name !== JO_STATUS.PUBLISHED) return i18n.t('orchestration:permissions.notEnoughPermissions');
    else if (isDemo && !isLatestVersion) {
      return 'You are tyring to publish old version';
    }
    return '';
  }

  function shouldDisable() {
    if (isDemo && !isLatestVersion) {
      return false;
    } else if (state.name === JO_STATUS.PUBLISHED || !canEdit) {
      return true;
    }
    return false;
  }
  return (
    <Btn
      color="blue"
      onClick={() => {
        validateJourney(isHpRunningOrScheduled);
      }}
      testHook="journey-publish"
      disabled={shouldDisable()}
      tooltip={getTooltip()}
    >
      {i18n.t('orchestration:publish.publish')}
    </Btn>
  );
};

const JOStatusTag = ({ status, text }: JOStatusTagProps) => (
  <div
    className={twMerge(
      cx('rounded-xl border border-green-100 bg-green-50 px-2 font-medium text-green-600', {
        'border-amber-100 bg-amber-50 text-amber-600': status !== JO_STATUS.PUBLISHED,
      }),
    )}
  >
    <span className="t-journeyStatusText">{text}</span>
  </div>
);

const VersionStatus = ({ isLatestVersion, state, text }: VersionStatusProps) => {
  if (isLatestVersion) {
    return <JOStatusTag status={state?.name} text={text} />;
  }
  return (
    <>
      <JOStatusTag status="UNPUBLISHED" text={i18n.t('orchestration:publish.unpublishedLabel')} />
      <JOStatusTag status="UNPUBLISHED" text={i18n.t('orchestration:version.previousVersionMessage')} />
    </>
  );
};

const JourneyHeader = ({
  name,
  expiryTime,
  historicProcessing,
  journeyId,
  state,
  fetchJourneyData,
  showJourneyValidationModal,
  showJourneyPublishModal,
  setShowJourneyErrors,
  canEdit,
  showExperimentSettingsModal,
  showDependantJourneysModal,
  goToJourneyStatsView,
  hasStepsAfterFirstTrigger,
  isUsedInJourneys,
  variables,
  journeyContents,
  showHistoricProcessingModal,
  showHistoricProcessRunningModal,
  currentVersion,
}: JourneyHeaderProps): React.ReactNode => {
  if (!name || !expiryTime) {
    return <Spinner />;
  }

  const [hasExperiment, setHasExperiment] = useState(false);

  function getExperimentNode(currentNode: JourneyNode | EmptyJourneyContents['nodes']): boolean {
    if (currentNode.type === 'STEP' && currentNode.experimentVariant !== null) {
      return true;
    }
    return currentNode.children.some(child => getExperimentNode(child));
  }

  useEffect(() => {
    setHasExperiment(getExperimentNode(journeyContents.nodes));
  }, [journeyContents]);

  const isLatestVersion = currentVersion?.isLatest;

  const validateJourney = (isHpRunningOrScheduled: boolean) => {
    JourneyDataService.validateJourney(journeyId).then(validationResponse => {
      if (validationResponse.data.length) {
        showJourneyValidationModal(
          name,
          validationResponse.data,
          fetchJourneyData,
          setShowJourneyErrors,
          variables,
          journeyContents,
        );
      } else {
        showJourneyPublishModal(name, fetchJourneyData, isHpRunningOrScheduled, isLatestVersion);
      }
    });
  };

  const journeyStatusText =
    state.name === JO_STATUS.PUBLISHED
      ? i18n.t('orchestration:publish.publishedLabel')
      : i18n.t('orchestration:publish.unpublishedChangesLabel');

  const journeyListPageUrl = buildUrl('customer/journeys/dashboard');

  const isDemo = localStorage.getItem('useDemo') === 'true';

  const isHpRunning = historicProcessing.state?.name === HP_STATUS.RUNNING;
  const isHpScheduled = historicProcessing.state?.name === HP_STATUS.SCHEDULED;

  return (
    <>
      <div className="flex border-b border-gray-200 bg-white pb-4 pl-4 pr-4 pt-4">
        <div className="flex-1">
          <PageTitleHeader
            title={name}
            handleIcon={
              <>
                <RouterLink to={journeyListPageUrl} className="PageTitleHeader-titleContainer--link">
                  <Icons
                    icon="back"
                    tooltip={i18n.t('orchestration:header.backToJourneyList')}
                    className="h-6 w-6 text-gray-400"
                  />
                </RouterLink>
                <Icons icon="journey" className="h-6 w-6 text-blue-600" />
              </>
            }
            className="JourneyHeader-pageTitleHeader"
          />
          <div className="ml-8 mt-2 flex items-center gap-1 text-sm">
            {!isDemo ? (
              <JOStatusTag status={state.name} text={journeyStatusText} />
            ) : (
              <VersionStatus state={state} text={journeyStatusText} isLatestVersion={currentVersion?.isLatest} />
            )}
            <div className="flex items-center gap-1">
              <Icons icon="schedule" className="h-4 w-4" />
              {formatJourneyExpiryTime(expiryTime)}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-6">
          <BtnIcon
            icon="statistics"
            className=""
            onClick={() => goToJourneyStatsView()}
            tooltip={i18n.t('orchestration:tooltips.journeyStats')}
            testHook="journeyStats"
          />
          <EditJourneyTrigger
            name={name}
            expiryTime={expiryTime}
            journeyId={journeyId}
            fetchJourneyData={fetchJourneyData}
            canEdit={canEdit}
          />
          {hasStepsAfterFirstTrigger && hasExperiment && (
            <EditExperimentTrigger canEdit={canEdit} showExperimentSettingsModal={showExperimentSettingsModal} />
          )}
          <TriggerHistoricProcesingButton
            hpJobData={historicProcessing}
            eligible={historicProcessing.eligible}
            fetchJourneyData={fetchJourneyData}
            isHpRunning={isHpRunning}
            isHpScheduled={isHpScheduled}
            canEdit={canEdit}
            journeyId={journeyId}
            showHistoricProcessingModal={showHistoricProcessingModal}
            showHistoricProcessRunningModal={showHistoricProcessRunningModal}
          />
          <PublishButton
            isDemo={isDemo}
            isLatestVersion={isLatestVersion}
            canEdit={canEdit}
            state={state}
            validateJourney={validateJourney}
            isHpRunningOrScheduled={isHpRunning || isHpScheduled}
          />
        </div>
      </div>
      {isUsedInJourneys && (
        <div className="flex h-12 items-center justify-center bg-amber-500">
          <Icons className="mx-2 h-6 w-6 text-white" icon="overlap" />
          <p className="text-white">{i18n.t('orchestration:header.overlapInformation')}</p>
          <Link className="JourneyOverlap-infoBarLink" onClick={showDependantJourneysModal}>
            {i18n.t('orchestration:header.overlapDependencyLink')}
          </Link>
        </div>
      )}
    </>
  );
};

export default JourneyHeader;
