import React from 'react';
import i18n from '~/i18n';
import Notification from '~/components/src/Notification';
import Icons from '~/components/src/Icons';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInputField } from '~/components/src/Form/Fields/FormikFields';
import './styles.scss';

interface PublishModalProps {
  journeyName: string;
  isHpRunningOrScheduled: boolean;
  isLatestVersion: boolean;
}

const VersionInput = () => {
  const setVersionName = (values: any) => {
    console.log({ values });
  };

  const initialValues = { versionName: '' };

  const validationSchema = Yup.object().shape({
    versionName: Yup.string().trim().required(i18n.t('validation:validation.required')),
  });

  return (
    <>
      <Formik
        onSubmit={setVersionName}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ handleSubmit, errors }) => (
          <form className="mt-8 flex items-center gap-2" onSubmit={handleSubmit}>
            <Field
              className="w-full"
              name="versionName"
              id="versionName"
              as={FormikInputField}
              placeholder={i18n.t('dataFeeds:form.placeholder')}
              label={i18n.t('orchestration:version.name')}
              errorText={errors?.versionName}
              hintText={i18n.t('orchestration:version.nameHint')}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

const PublishModal = ({ journeyName, isHpRunningOrScheduled, isLatestVersion }: PublishModalProps): React.ReactNode => {
  const isDemo = localStorage.getItem('useDemo') === 'true';
  return (
    <div className="JourneyModal">
      <p className="t-publishMessage text-xl font-medium text-gray-600">{i18n.t('orchestration:publish.message')}</p>
      <p className="JourneyModal-title my-2 rounded bg-blue-50 p-2 text-xl text-blue-500">
        <Icons icon="journey" className="mr-2 h-6 w-6" /> {journeyName}
      </p>
      <p className="t-publishInfo text-sm text-gray-400">{i18n.t('orchestration:publish.info')}</p>
      {isHpRunningOrScheduled && (
        <Notification kind="information" className="mt-4" testHook="hpIsRunningMessage">
          {i18n.t('orchestration:publish.hpIsRunning')}
        </Notification>
      )}
      {isDemo && (
        <>
          <VersionInput />
          {!isLatestVersion && (
            <Notification kind="warning" className="mt-4" testHook="oldVersionMessage">
              {i18n.t('orchestration:version.publishPreviousVersionMessage')}
            </Notification>
          )}
        </>
      )}
    </div>
  );
};

export default PublishModal;
