import React, { useContext } from 'react';
import { useAPI } from '~/common';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';
import Spin from '~/components/src/Spin';
import { transformCriteria } from '~/profiles/audiences';
import { addNamesFromRuleDefinitionsToPath } from '~/profiles/audiences/audienceUtils';
import { RULE_GROUP_TYPES } from '~/workflows/constants';
import { fetchRuleDefinitions, fetchTrigger } from '~/workflows/dataService';
import { useAllVariables } from '~/workflows/hooks';
import { CreateTriggerPayload, RuleDefinitionsReponse, TRule } from '~/workflows/types';
import { removeItemsNotAvailableInOrchestration } from '~/workflows/util';
import { AudienceContext } from '../AudienceContext';
import TargetRuleConfiguration from './TargetRuleConfiguration';

const TargetRuleWrapper = () => {
  const { triggerId, workflowId, goToNextStep, refetchTree } = useContext(AudienceContext);

  const { data = [], isLoading } = useAPI(() => fetchRuleDefinitions(RULE_GROUP_TYPES.START_TRIGGER));
  const { data: triggerData, isLoading: triggerIsLoading = false } = useAPI(
    async () => (triggerId ? fetchTrigger(triggerId) : Promise.resolve()),
    [],
  );
  const { allVariables, refetchAllVariables } = useAllVariables();

  const ruleTypesTree = removeItemsNotAvailableInOrchestration(getRulesTreeFromTypes(data)) as TRule[];

  const loadTrigger = (): CreateTriggerPayload | null => {
    if (isLoading || !triggerId || !triggerData) {
      return null;
    }

    const triggerWithNames = addNamesFromRuleDefinitionsToPath(
      data as RuleDefinitionsReponse[],
      'criteria',
    )(triggerData) as CreateTriggerPayload;
    const transformedCriteria = transformCriteria(triggerWithNames?.criteria);

    return {
      ...triggerWithNames,
      criteria: transformedCriteria,
    };
  };

  const trigger = loadTrigger();
  return (
    <>
      {!isLoading && !triggerIsLoading ? (
        <TargetRuleConfiguration
          allVariables={allVariables ?? []}
          ruleTypesTree={ruleTypesTree}
          refetchAllVariables={refetchAllVariables}
          triggerData={trigger}
          workflowId={workflowId}
          triggerId={triggerId}
          goToNextStep={goToNextStep}
          refetchTree={refetchTree}
        />
      ) : (
        <Spin />
      )}
    </>
  );
};

export default TargetRuleWrapper;
