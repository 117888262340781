import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import * as fromModals from '~/modals';
import i18n from '~/i18n';
import { changeUrl, getSiteId } from '~/common';
import ConnectorSection from '~/profiles/components/ConnectorSection';
import {
  fetchConnectors,
  deleteConnector,
  toggleActivation,
  isFetchingConnectors,
  hideDependantsContainer,
  showDependantsContainer,
  changeDependantTypesSelectedTab,
  getServerConnectors,
  SERVER_CONNECTORS_KEY,
  isDependantsContainerVisible,
  getSelectedTab,
  getSelectedConnector,
  fetchConnectorTypes,
  isFetchingConnectorTypes,
  getConnectorTypes,
} from '~/profiles/connectors';
import { SERVER_CONNECTOR_CATEGORY } from '../types';
import { commonActions } from './commonActions';
import { getSelectedId } from '../selectors';
import { getConnectorImage } from '../connectorUtils';

const mapStateToProps = (state, ownProps) => {
  const siteId = getSiteId();
  return {
    title: ownProps.t('partners:server.title'),
    description: ownProps.t('partners:server.description'),
    isFetching: isFetchingConnectors(state),
    list: getServerConnectors(state),
    listKey: SERVER_CONNECTORS_KEY,
    imageUrl: getConnectorImage(siteId),
    dependantsContainerIsVisible: isDependantsContainerVisible(state),
    selectedConnector: getSelectedConnector(state, getSelectedId(state)),
    selectedTab: getSelectedTab(state),
    isFetchingConnectorTypes: isFetchingConnectorTypes(state),
    connectorTypes: getConnectorTypes(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchData: () => dispatch(fetchConnectors(SERVER_CONNECTOR_CATEGORY)),
  fetchConnectorTypes: () => dispatch(fetchConnectorTypes()),
  onViewClick: connector => changeUrl(`profiles/partners/list/stats/${connector.partnerId}`),
  onEditClick: connector => changeUrl(`profiles/partners/edit/${connector.partnerId}`),
  onActivationClick: connector => dispatch(toggleActivation(connector)),
  onDeleteClick: connector =>
    dispatch(
      fromModals.showDeleteModal({
        title: i18n.t('partners:deleteModal.title'),
        message: i18n.t('partners:deleteModal.message', { partnerName: connector.partnerName }),
        onConfirm: () => dispatch(deleteConnector(connector)),
      }),
    ),
  onDependantIconClick: (connectorId, dependantType) => {
    dispatch(showDependantsContainer(connectorId, dependantType));
  },
  onDependantsTabChange: selectedTab => {
    dispatch(changeDependantTypesSelectedTab(selectedTab));
  },
  onDependantsContainerClose: () => dispatch(hideDependantsContainer()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    actions: item => commonActions(item, dispatchProps, ownProps),
  })),
  translate('partners'),
)(ConnectorSection);
