/**
 * @description Angular module for the customer journey new trigger
 * @memberOf webUi.ui.customer.journey
 * @namespace webUi.ui.customer.journey.form.triggerNew
 */
angular.module('webUi.ui.customer.journey.form.triggerNew', [])
    .config(['$stateProvider', function config($stateProvider) {
        $stateProvider
            .state('site.customer.journeys.form.triggerNew', {
                url: '/triggerNew',
                isReact: true
            });
    }
    ]);
