import React from 'react';
import cx from 'classnames';
import Icons from '~/components/src/Icons';
import './styles.scss';

type JourneyItemProps = {
  className: string;
  footer: React.ReactElement;
  name: string;
  type: 'goal' | 'exit';
  buttons: React.ReactElement;
  onClick: () => void;
  isShowVariantStats: boolean;
  isExpanded: boolean;
};

export const JourneyItem = ({
  className,
  footer,
  name,
  type,
  buttons,
  onClick,
  isShowVariantStats = false,
  isExpanded = false,
}: JourneyItemProps): React.ReactElement => (
  <div
    className={cx(
      'JourneyItem group relative cursor-pointer rounded-xl border-2 bg-white ',
      {
        'border-2 border-amber-500': type === 'goal',
        'border-2 border-red-500': type === 'exit',
      },
      className,
    )}
    onClick={onClick}
  >
    <div className="flex items-center justify-between p-1">
      <div className="flex gap-2">
        <Icons
          icon={type}
          className={cx('h-6 w-6 shrink-0', {
            'text-amber-500': type === 'goal',
            'text-red-500': type === 'exit',
          })}
        />
        <p className="text-sm font-medium text-gray-600">{name}</p>
      </div>
      {isShowVariantStats && (
        <Icons icon={isExpanded ? 'upArrow' : 'downArrow'} className="text-red h-6 w-6 shrink-0 p-1" />
      )}
    </div>
    {footer}
    {buttons && (
      <div className="hid absolute z-10 h-8 w-full items-center justify-evenly gap-2 rounded-md border-2 border-gray-300 bg-white drop-shadow-md group-hover:flex">
        {buttons}
      </div>
    )}
  </div>
);
