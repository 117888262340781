import { get } from 'lodash';
import i18n from '~/i18n';
import { RulesTypes } from './types';

export const RULE_DEFAULT_TIME_PERIOD = '30d';
export const RULE_DEFAULT_FIXED_WAIT_TIME = '30d';

// When adding a new rule make sure to check if it has dependecies and update the dependencies call in Transformations
export const RulesMap = {
  [RulesTypes.Engagement]: {
    title: 'Engagement',
    ruleTypeName: 'ENGAGEMENT',
    subtitle: i18n.t('audiences:rules.descriptions.EngagementRule'),
    icon: 'engagementRule',
  },
  [RulesTypes.Conversion]: {
    title: 'Conversion',
    ruleTypeName: 'CONVERSION',
    subtitle: i18n.t('audiences:rules.descriptions.ConversionRule'),
    icon: 'conversionRule',
  },
  [RulesTypes.ExternalFact]: {
    title: 'External Fact',
    ruleTypeName: 'EXTERNAL',
    subtitle: i18n.t('audiences:rules.descriptions.CustomRule'),
    icon: 'customRule',
  },
  [RulesTypes.AiFact]: {
    title: 'AI Fact',
    ruleTypeName: 'AI',
    subtitle: i18n.t('audiences:rules.descriptions.AIFactRule'),
    icon: 'aiFactRule',
  },
  [RulesTypes.Visit]: {
    title: 'Visit',
    ruleTypeName: 'VISIT',
    subtitle: i18n.t('audiences:rules.descriptions.VisitRule'),
    icon: 'visitRule',
  },
  [RulesTypes.Experiment]: {
    title: 'Experiment',
    ruleTypeName: 'EXPERIMENT',
    subtitle: i18n.t('audiences:rules.descriptions.ExperimentRule'),
    icon: 'experimentRule',
  },
  [RulesTypes.ReusableAudience]: {
    title: 'Reusable Audience',
    ruleTypeName: 'REUSABLE_SELECTION',
    subtitle: i18n.t('audiences:rules.descriptions.ReusableSelectionRule'),
    icon: 'reusableSelectionRule',
  },
  [RulesTypes.FixedTime]: {
    title: i18n.t('audiences:rules.name.fixedWaitTime'),
    ruleTypeName: 'FIXED_WAIT_TIME',
    subtitle: i18n.t('audiences:rules.descriptions.FixedTimeRule'),
    icon: 'fixedTimeRule',
  },
  [RulesTypes.JourneyOverlap]: {
    title: i18n.t('audiences:rules.name.journeyOverlapRule'),
    ruleTypeName: 'JOURNEY_OVERLAP',
    subtitle: i18n.t('audiences:rules.descriptions.JourneyOverlapRule'),
    icon: 'journeyOverlapRule',
  },
  [RulesTypes.BannerView]: {
    title: i18n.t('audiences:rules.name.bannerView'),
    ruleTypeName: 'BANNER_VIEW',
    subtitle: i18n.t('audiences:rules.descriptions.BannerViewRule'),
    icon: 'bannerViewRule',
  },
  [RulesTypes.UserPreferences]: {
    title: i18n.t('audiences:rules.name.userPreferences'),
    ruleTypeName: 'USER_PREFERENCES',
    subtitle: i18n.t('audiences:rules.descriptions.UserPreferencesRule'),
    icon: 'userPreferencesRule',
  },
  [RulesTypes.Sync]: {
    title: i18n.t('audiences:rules.name.sync'),
    ruleTypeName: 'SYNC',
    subtitle: i18n.t('audiences:rules.descriptions.SyncRule'),
    icon: 'syncRule',
  },
  [RulesTypes.ThirdPartySync]: {
    title: i18n.t('audiences:rules.name.thirdPartySync'),
    ruleTypeName: 'THIRD_PARTY_SYNC',
    subtitle: i18n.t('audiences:rules.descriptions.ThirdPartySyncRule'),
    icon: 'thirdPartySyncRule',
  },
  [RulesTypes.PageView]: {
    title: i18n.t('audiences:rules.name.pageView'),
    ruleTypeName: 'PAGE_VIEW',
    subtitle: i18n.t('audiences:rules.descriptions.PageViewRule'),
    icon: 'pageViewRule',
  },
  [RulesTypes.DeleteMergedData]: {
    title: i18n.t('audiences:rules.name.deleteMergedData'),
    ruleTypeName: 'DELETE_MERGED_DATA',
    subtitle: i18n.t('audiences:rules.descriptions.DeleteMergedDataRule'),
    icon: 'deleteMergedDataRule',
  },
  [RulesTypes.DeleteProfile]: {
    title: i18n.t('audiences:rules.name.deleteProfile'),
    ruleTypeName: 'DELETE_PROFILE',
    subtitle: i18n.t('audiences:rules.descriptions.DeleteProfileRule'),
    icon: 'deleteProfileRule',
  },
  [RulesTypes.DeleteProfileMapping]: {
    title: i18n.t('audiences:rules.name.deleteProfileMapping'),
    ruleTypeName: 'DELETE_PROFILE_MAPPING',
    subtitle: i18n.t('audiences:rules.descriptions.DeleteProfileMappingRule'),
    icon: 'deleteProfileMappingRule',
  },
};

export const RulesOrder = [
  RulesTypes.Visit,
  RulesTypes.Engagement,
  RulesTypes.Conversion,
  RulesTypes.AiFact,
  RulesTypes.ExternalFact,
  RulesTypes.Experiment,
  RulesTypes.ReusableAudience,
  RulesTypes.FixedTime,
  RulesTypes.JourneyOverlap,
  RulesTypes.BannerView,
  RulesTypes.UserPreferences,
  RulesTypes.Sync,
  RulesTypes.ThirdPartySync,
  RulesTypes.PageView,
  RulesTypes.DeleteMergedData,
  RulesTypes.DeleteProfile,
  RulesTypes.DeleteProfileMapping,
];

export const hasSubMenu = ruleTypeName =>
  [
    RulesTypes.Engagement,
    RulesTypes.ExternalFact,
    RulesTypes.AiFact,
    RulesTypes.Experiment,
    RulesTypes.ReusableAudience,
    RulesTypes.JourneyOverlap,
  ].includes(ruleTypeName);

export const hasTimeCondition = ruleTypeName =>
  [RulesTypes.Visit, RulesTypes.Conversion, RulesTypes.Engagement].includes(ruleTypeName);

export const canBeInverted = ruleTypeName => ![RulesTypes.FixedTime].includes(ruleTypeName);

export const supportsAddingProperties = ruleTypeName =>
  ![RulesTypes.FixedTime, RulesTypes.JourneyOverlap].includes(ruleTypeName);

export const getAllCriteria = state => state.common.rules.allIds.map(id => state.common.rules.byId[id]);

export const isFetchingRule = state => get(state, 'common.rules.ui.isFetchingAll');

export const isFetchingVariables = state => get(state, 'common.rules.variables.isFetching');

export const getRulesWithSubMenu = state => {
  const nodes = getAllCriteria(state);
  return nodes.filter(node => node.clazz !== RulesTypes.Visit && node.clazz !== RulesTypes.Conversion);
};

const makeSubMenuItem = ruleType => ({
  title: ruleType.name,
  ruleDefinitionId: ruleType.ruleDefinitionId,
  availableFilters: ruleType.filters,
  type: ruleType.type,
  ruleDefinitionName: ruleType.ruleDefinitionName,
  ruleName: ruleType.name,
});

export const getRulesOfType = (ruleTypes, ruleTypeName) =>
  ruleTypes
    ?.filter(node => node.clazz === ruleTypeName)
    .map(node => ({
      ruleDefinitionId: node.ruleDefinitionId,
      name: node.ruleName,
      type: node.clazz,
      filters: node.filters,
      ruleDefinitionName: node.ruleDefinitionName,
    }));

export const getSubmenuForRuleType = (ruleTypes, ruleTypeName) =>
  getRulesOfType(ruleTypes, ruleTypeName)?.map(makeSubMenuItem);

export const getRulesTreeFromTypes = (ruleTypes, excludeRules = {}) => {
  if (ruleTypes?.length === 0) {
    return [];
  }

  const filteredRulesOrder = RulesOrder.filter(ruleTypeName =>
    ruleTypes?.some(ruleType => ruleType.clazz === ruleTypeName),
  );

  const rulesToRemove = { ...excludeRules };

  return filteredRulesOrder
    .filter(type => !rulesToRemove[type])
    .map(type => {
      const { title, subtitle } = RulesMap[type];

      const subMenu = hasSubMenu(type) ? getSubmenuForRuleType(ruleTypes, type) : null;

      const ruleType = ruleTypes?.find(ruleType => ruleType.clazz === type);
      const ruleDefinitionId = ruleType?.ruleDefinitionId || null;
      const availableFilters = ruleType?.filters || [];

      return {
        groupName: ruleType?.ruleCategory?.label || '',
        type,
        title,
        subtitle,
        subMenu,
        availableFilters,
        ruleDefinitionId,
      };
    });
};

export const getRulesTree = (state, excludeRules = {}) => {
  const ruleTypes = getAllCriteria(state);
  return getRulesTreeFromTypes(ruleTypes, excludeRules);
};

export const getAllRuleVariables = state => state.common.rules.variables.values;
