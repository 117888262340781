import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import Fields from '~/components/src/Form/Fields';
import { isRequired } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import {
  ReduxFormInputField,
  ReduxFormSensitiveField,
  ReduxFormSelectField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import Btn from '~/components/src/Btn';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import constants from '../../constants';
import FormHeader from '../formHeader/FormHeader';

class AppNexusForm extends Component {
  componentDidMount() {
    this.fetchAdvertisers();
  }

  clearAdvertisers() {
    const { change, setAdvertisers } = this.props;
    setAdvertisers([]);
    change('APPNEXUS.advertiserId', null);
  }

  fetchAdvertisers() {
    const { partner, canUpdate, fetchAdvertisers, values } = this.props;

    const { password } = values;

    if (canUpdate) {
      const params = {
        partner: constants.APPNEXUS,
        partnerId: partner.partnerId,
        username: partner.configuration.username,
      };

      if (password) params.password = password;
      return fetchAdvertisers(params);
    }

    if (!values.password || !values.username) return null;

    return fetchAdvertisers({
      partner: constants.APPNEXUS,
      password: encodeURI(values.password),
      username: values.username,
    });
  }

  render() {
    const { t, values, partner, advertisers, canUpdate, touch } = this.props;
    return (
      <FormSection name={constants.partnerTypes.APPNEXUS}>
        <FormHeader
          t={t}
          partner={partner}
          title={values.name}
          description={t('form.notification.storeMappingMessage')}
          partnerNumber={values.partnerNumber || partner.partnerNumber}
        />
        <ContainerFormSection>
          <Field
            label={t('form.formFields.username')}
            name="username"
            component={ReduxFormInputField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            validate={isRequired}
            type="text"
            onChange={() => {
              this.clearAdvertisers();
            }}
          />
          <FieldWithEditMode
            label={t('form.formFields.password')}
            name="password"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
            onChange={() => {
              this.clearAdvertisers();
            }}
          />
          <div className="Form-actions u-marginVerticalL u-flex-justify-end">
            <Btn
              color="blue"
              disabled={!values.username || (!values.password && !canUpdate)}
              onClick={() => {
                this.fetchAdvertisers();
              }}
            >
              {t('form.formFields.showAdvertisers')}
            </Btn>
          </div>
          <Field
            label={t('form.formFields.advertiser')}
            name="advertiserId"
            component={ReduxFormSelectField}
            options={advertisers}
            disabled={advertisers.length === 0}
            validate={!canUpdate && isRequired}
            touch={touch}
          />
          <Field label={t('form.formFields.useDefaultSeparators')} name="useDefaults" component={Fields.Checkbox} />
          {!values.useDefaults && (
            <>
              <Field
                label={t('form.formFields.separator1')}
                name="separator1"
                component={ReduxFormInputField}
                validate={isRequired}
                type="text"
              />
              <Field
                label={t('form.formFields.separator2')}
                name="separator2"
                component={ReduxFormInputField}
                validate={isRequired}
                type="text"
              />
              <Field
                label={t('form.formFields.separator3')}
                name="separator3"
                component={ReduxFormInputField}
                validate={isRequired}
                type="text"
              />
              <Field label={t('form.formFields.sendZeroValue')} name="includeValue" component={Fields.Checkbox} />
            </>
          )}
        </ContainerFormSection>
      </FormSection>
    );
  }
}

export default AppNexusForm;
